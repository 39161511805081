import { ThreeBounce } from 'better-react-spinkit';
import React, { useContext, useState } from 'react';
import GoBackButton from '../../../../../components/Buttons/GoBackButton';
import PrimaryButton from '../../../../../components/Buttons/PrimaryButton';
import { DialogContext } from '../../../../../store/context/DialogContext';
import { doGET } from '../../../../../util/HttpUtil';
import ProofOfDocuments from '../../BusinessLiteForms/components/ProofOfDocument';

const ProofOfAddressDocument = ({
    hideButtons,
    isAskForInfo,
    remark,
    recordId,
    asLabel,
    setStep,
    handleUpdate,
    setHasError,
    setErrorMessage,
    data
}) => {

    const [loading, setLoading] = useState(false)

    const { showError } = useContext(DialogContext)
    const checkIsDocumentUploaded = async () => {
        setLoading(true)
        try {
            const response = await doGET(`/api/application-doc/grid?form_id=${recordId}${data?.ubo_id ? `&ubo_id=${data?.ubo_id}` : ""}`)
            if (response?.status == 200) {
                if (response?.data?.rows[0]?.attachment?.urls?.length) {
                    setLoading(false)
                    return true
                }
            }
        } catch (error) {
            showError(error)

        }
        setLoading(false)
        return false

    }
    const handleSubmit = async () => {
        let errors = [];

        const isDocumentUploaded = await checkIsDocumentUploaded()

        if (!isDocumentUploaded) {
            errors?.push("Please upload proof of address.")
            showError("Please upload proof of address.")
        }
        if (errors.length > 0) {
            setHasError(true);
            setErrorMessage(errors.join(' '));
            return;
        }

        setHasError(false);
        setLoading(true)
        await handleUpdate({ nextStep: isAskForInfo ? Number.MAX_SAFE_INTEGER : 5, isfetchDetail: true, isLastStep: true });
        setLoading(false)

    };


    return (
        <div className='row'>
            <ProofOfDocuments
                isAskForInfo={isAskForInfo}
                remark={remark}
                recordId={recordId}
                asLabel={asLabel}
                handleUpdate={handleUpdate}
                label={"Address Proof"}
                field={"addressProof"}
                showCategory={"ProofOfAddress"}
                type={"ProofOfAddress"}
                ubo_id={data?.ubo_id}
            />

            {!hideButtons && <div className='d-flex w-100 justify-content-between mt-3'>
                {isAskForInfo ? <div className='flex-1'></div> : <GoBackButton loading={loading} onClick={() => setStep(3)} />}
                <PrimaryButton
                    loading={loading}
                    className={"px-3"}
                    onClick={() => { handleSubmit() }}
                >
                    {loading ? <ThreeBounce color="white" size={8} /> : <>{"Submit"} </>}
                </PrimaryButton>
            </div>}

        </div>
    );
};

export const InputLabel = ({ label, required, labelStyle }) => (
    <div style={{ minWidth: "120px", fontSize: 16, margin: "5px 0px 0px 5px", fontWeight: "500", ...labelStyle }}>
        {label}
        {required && <font color="red">*</font>}
    </div>
);

export default ProofOfAddressDocument;
