import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Assuming you're using react-router for navigation
import { observer } from 'mobx-react-lite';
import { doPOST } from '../../util/HttpUtil';
import { UserContext } from '../../store/context/UserContext';

const Login = () => {
    const navigate = useNavigate();
    const { populateUserContent } = useContext(UserContext)

    const verifyLogin = async (authorizationToken) => {
        try {
            const response = await doPOST(`/auth/login`)

            if (response.status === 200) {
                localStorage.setItem("BearerToken", response.data.bearerToken);
                localStorage.setItem("RefreshToken", response.data.refreshToken);
                localStorage.setItem("TokenExpiry", response.data.expiry);
                await populateUserContent();
                navigate("/applications", { replace: true }); 
                window.location.reload();
            }
        } catch (error) {
            console.error('Authorization token is missin');
            console.log("error", error)
        }

    }


    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const authorizationToken = searchParams.get('Authorization');

        if (authorizationToken) {
            localStorage.setItem('BearerToken', authorizationToken);

            verifyLogin(authorizationToken)
        } else {
            console.error('Authorization token is missing');
        }
    }, [navigate]);

    return (
        <div style={{height : "100vh", width : "100vw"}} className='d-flex align-items-center justify-content-center flex-1 h-100 w-100 '>
            <div className="main-heading">
                Logging in ...
            </div>
        </div>
    );
};

export default observer(Login);
