import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import UserGuideService from './UserGuideService'
import { RxCross2 } from "react-icons/rx";
import { useLocation } from 'react-router-dom';
import "./style.css"

function UserGuide() {
    const [link, setLink] = useState(null);
    const location = useLocation();

    useEffect(() => {
        if (location.pathname) {
            setLink(location.pathname.split("/")[1])
        }

    }, [UserGuideService.isVisible, location])

    return (
        <div className="h-100 w-100 App pb-5">
            <div className="d-flex justify-content-between align-items-center mb-2 ">
                <div className='fs-4 fw-600'>User Guide</div>
                <div className='ms-auto p-2' style={{ cursor: "pointer" }} onClick={UserGuideService.toggleModal}><RxCross2 size={20} />
                </div>
            </div>

            <div class="parent-container">
                <iframe className='' src={`https://docs.caas.fvbank.us/docs/user-guide/${link}`} title="FVBank User Guide"></iframe>
            </div>
        </div>
    )
}

export default observer(UserGuide)