import React, { useState, useEffect, useCallback } from "react";
import { SelectField } from "../../SelectField/SelectField";
import { toJS } from "mobx";
import LanguageSelectService from "./LanguageSelectService";
import { SelectFieldMultiple } from "../../SelectField/SelectFieldMultiple";
import Image from "../../Image/Image";

export default function LanguageSelect(props) {
  const [languages, setLanguage] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      try {
        if (languages.length === 0) {
          await LanguageSelectService.fetch();
          setLanguage(
            toJS(LanguageSelectService.languages).map((c) => {
              return {
                ...c,
                value: c?._id,
                label: (
                  <div className="d-flex row">
                    <div className="col-3">
                      <Image
                        src={c?.pic}
                        alt="make-model"
                        style={{
                          display: "inline",
                          maxHeight: "15px",
                          maxWidth: "30px",
                        }}
                      />
                    </div>
                    <div className="col-9 ps-3">
                      {c?.name}
                    </div>
                  </div>
                ),
              };
            })
          );
        }
      } catch (error) {
        console.log(error.message);
      }
    };
    fetch();
  }, [languages.length]);


  const filterOption = useCallback((option, inputValue) => {

    return !inputValue ||
      ['name'].some(
        key =>
          option?.data?.[key]?.toLowerCase().includes(inputValue.toLowerCase())
      );
  }, []);

  return (
    <React.Fragment>
      {props.asLabel && props.multi ? (
        <div>
          {/* {JSON?.stringify(props?.value)} */}
          {props.value?.length > 0 ? (
            props.value?.map((selectedValue, index) => (
              <span key={index}>
                {languages.find((lang) => lang.code === selectedValue)?.name}
                {index < props.value.length - 1 && ", "}
              </span>
            ))
          ) : (
            <div style={{ color: "grey" }}>--</div>
          )}
        </div>
      ) : (
        languages.length > 0 ? (
          !props?.multi ? (
            <SelectField
              filterOption={filterOption}
              className={props?.className}
              onChange={props?.onChange}
              outline={props?.outline}
              data={languages}
              asLabel={props.asLabel}
              value={
                languages.find(
                  (x) => x?.value === props?.value || x?.label === props?.value
                )?.value
              }
              label={props?.label ?? "Language"}
              error={
                props?.value
                  ? "Please Select " +
                  (props?.label ? props?.label : "Language")
                  : ""
              }
              showErr={props?.showErr}
              inline={props?.inline}
              borderNone={props?.borderNone}
              RightComponent={props?.RightComponent}
              onClickRight={props?.onClickRight}
                {...props}

            />
          ) : (
            <SelectFieldMultiple
              filterOption={filterOption}
              isDisabled={props?.isDisabled}
                  outline={props?.outline}

              style={props?.style}
              className={props?.className}
              onChange={(v) => props?.onChange(v.map((c) => c?.code))}
              data={languages}
              label={props?.label ?? "Language(s)"}
              value={
                props?.value
                  ? languages.filter((c) => props?.value?.includes(c?.code))
                  : ""
              }
              error={
                !props?.value
                  ? "Please Select " +
                  (props?.label ? props?.label : "Language")
                  : ""
              }
              required={props?.required}
              showErr={props?.showErr}
            />
          )
        ) : (
          <SelectField
            onChange={props?.onChange}
            data={languages}
            RightComponent={props?.RightComponent}
            onClickRight={props?.onClickRight}
            value={props?.value}
            className={props?.className}
            label={props?.label ? props?.label : "Language"}
            isDisabled={true}
            asLabel={props.asLabel}
            error={
              props?.value
                ? "Please Select " +
                (props?.label ? props?.label : "Language")
                : ""
            }
            showErr={props?.showErr}
            inline={props?.inline}
            borderNone={props?.borderNone}
          />
        )
      )}
    </React.Fragment>
  );
}
