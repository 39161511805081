import React, { useState, useEffect } from 'react';
import EditRiskCalculation from './EditRiskCalculation';
import RiskCalculationService from './RiskCalculationService';
import { observer } from 'mobx-react-lite';

const EditRiskCalculationWrapper = ({ editId, onChange }) => {
  const [key, setKey] = useState(0);

  useEffect(() => {
    setKey(prevKey => prevKey + 1);
  }, [RiskCalculationService?.version]);

  return <EditRiskCalculation key={key} editId={editId} onChange={onChange} />;
};

export default observer(EditRiskCalculationWrapper);