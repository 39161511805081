import React, { useState } from 'react';
import { Alert, Accordion, AccordionBody, AccordionHeader, AccordionItem } from 'reactstrap';
import { MdInfoOutline } from 'react-icons/md'; // Information icon
import "./styles.css";

// Function to safely display key-value pairs
const displayData = (data) => {
    if (!data || Object.keys(data).length === 0) return <div className="fv_webhook-no-data">No Data Available</div>;
    return (
        <div className="fv_webhook-data-section">
            {Object.keys(data).map((key) => (
                <div key={key} className="fv_webhook-data-item">
                    <strong>{key}:</strong> {typeof data[key] === 'string' ? data[key] : JSON.stringify(data[key], null, 2)}
                </div>
            ))}
        </div>
    );
};

// WebhookDisplay component with Reactstrap Accordion
const WebhookDisplay = ({ webhookData }) => {
    const { amlData, kycData, userData, Error: errorDetails } = webhookData?.data?.payload || {};

    // Parse JSON strings to objects with error handling
    const parseData = (data) => {
        try {
            return data ? JSON.parse(data) : null;
        } catch (e) {
            return null;
        }
    };

    const parsedAMLData = parseData(amlData);
    const parsedKYCData = parseData(kycData);
    const parsedUserData = parseData(userData);

    const [openAccordion, setOpenAccordion] = useState("");

    const toggleAccordion = (id) => {
        setOpenAccordion(openAccordion === id ? "" : id);
    };

    return (
        <div className="fv_webhook-container">
            {/* Display informational alert */}
            <Alert color="info" className="fv_webhook-alert">
                <div className="fv_webhook-alert-content">
                    <MdInfoOutline size={20} className="fv_webhook-icon" />
                    <span className="fv_webhook-alert-text">Account Creation Information</span>
                </div>
            </Alert>


            <Accordion open={openAccordion} toggle={toggleAccordion}>
                {/* Display AML Data */}
                {parsedAMLData && (
                    <AccordionItem>
                        <AccordionHeader targetId="1">AML Data</AccordionHeader>
                        <AccordionBody accordionId="1">
                            {displayData(parsedAMLData)}
                        </AccordionBody>
                    </AccordionItem>
                )}

                {/* Display KYC Data */}
                {parsedKYCData && (
                    <AccordionItem>
                        <AccordionHeader targetId="2">KYC Data</AccordionHeader>
                        <AccordionBody accordionId="2">
                            {displayData(parsedKYCData)}
                        </AccordionBody>
                    </AccordionItem>
                )}

                {/* Display User Data */}
                {parsedUserData && (
                    <AccordionItem>
                        <AccordionHeader targetId="3">User Data</AccordionHeader>
                        <AccordionBody accordionId="3">
                            {displayData(parsedUserData)}
                        </AccordionBody>
                    </AccordionItem>
                )}

                {/* Display Error Details */}
                {errorDetails && (
                    <AccordionItem>
                        <AccordionHeader targetId="4">Error Details</AccordionHeader>
                        <AccordionBody accordionId="4">
                            {displayData(errorDetails)}
                        </AccordionBody>
                    </AccordionItem>
                )}

                {(!parsedAMLData && !parsedKYCData && !parsedUserData && !errorDetails) && (
                    <div>No information available</div>
                )}
            </Accordion>
        </div>
    );
};

export default WebhookDisplay;
