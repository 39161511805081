import React from "react";
import { CountrySelect } from "..";
export default function PhoneNum({
  showErr,
  inline,
  required,
  borderNone,
  className,
  value,
  isDisabled,
  onClickRight,
  onChange = () => { },
  label,
  outline,
  onblur,
  error,
  inputStyle,
  countrySelectStyle,
  RightComponent,
  rightComponentStyle = {},
  style,
  autoFocus = false
}) {
  const MAX_PHONE_LENGTH = 10;
  const handlePhoneChange = (e) => {
    const enteredPhone = e?.target?.value;
    if (enteredPhone.length <= MAX_PHONE_LENGTH) {
      onChange({ ...value, phone: Number(enteredPhone) });
    }
  };



  return (
    <div className={className + "  "} style={style}>

      {outline ??

        <div style={{ minWidth: "120px ", fontSize: 16, margin: "0px 0px 5px 5px", fontWeight: "500", }}>
          {label}{required && <font color="red">*</font>}
        </div>

      }


      <div
        style={{ margin: "0px 0px 0px 0px" }}
        className={" input-group flex-1 "}
      >
        <div className="input-group-prepend">
          <CountrySelect
            className={`m-0 `}
            innerContainerStyle={{ borderRadius: "15px 0px 0px 15px", }}
            style={{ borderRadius: "15px 0px 0px 15px", ...countrySelectStyle }}
            inputGroup
            value={value?.countryCode ? value?.countryCode : "91"}
            isd={true}
            onChange={(v) => {
              onChange({ ...value, countryCode: v?.value })
            }}
            outline
            noLabel
            showErr={showErr}
            required={required}
            error={error}
            inline={inline}
            borderNone={borderNone}
            isDisabled={isDisabled}
          />
        </div>
        <input
          onBlur={() => {
            if (onblur) {
              onblur()
            }
          }}
          required={true}
          placeholder={`${"Phone No."}${required ? "*" : ""}`}
          disabled={isDisabled}
          style={{
            height: "50px",
            borderRadius: "0px 15px 15px 0px",
            paddingLeft: "5px",
            borderTop: "none",
            borderBottom: "none",
            borderRight: "none",
            borderTop: "none",
            fontSize: "14px",
            outline: "none",
            backgroundColor: "#F1FAFC",
            ...inputStyle
          }}
          value={value?.phone}
          // onChange={handlePhoneChange}
          onChange={(e) => {
            // Regex for national worldwide phone number
            const phoneNumber = e?.target?.value;
            const regex = /^[0-9]{0,10}$/;
            if (regex.test(phoneNumber) || phoneNumber === "") {
              onChange({
                ...value,
                phone: phoneNumber === "" ? null : Number(phoneNumber),
              });
            }
          }}
          type="number"
          className="form-control"
        />


      </div>
      {/* <div>P</div> */}
      {RightComponent && (
        <div
          onClick={(e) => (onClickRight ? onClickRight(e) : null)}
          style={{ height: "32px", marginRight: '0px', ...rightComponentStyle }}
          className={onClickRight ? "component_pointer inputfield__rightComponent" : "inputfield__rightComponent "}
        >
          {RightComponent()}
        </div>
      )}
    </div>
  );
}
function phoneComponent() {
  return (
    <i
      style={{ fontSize: "16px", padding: "4px", color: " #1678AE" }}
      className="text-icon mdi mdi-phone"
    />
  );
}