import React from 'react';
import "../../IndividualLite.scss"
import ButtonGroup from './ButtonGroup';

const FEATURES = [
    { id: 0, text: "USD Account" },
    { id: 1, text: "ACH and Domestic Wires" },
    { id: 2, text: "SWIFT Payments Globally" },
    { id: 3, text: "Cross Border Payments in 11 currencies" },
    { id: 4, text: "Digital Asset Custody" },
    { id: 5, text: "Convert Digital Assets" },
    { id: 6, text: "Instant Peer to Peer transfers" },
    { id: 7, text: "FV Bank Visa Debit Cards" },
]

const Page1 = ({loading, step, onBackClick, onNextClick, asLabel}) => {
    return (
        <div className='pt-3'>
            <p className=''>
                {"FV Bank offers a full-featured business account with FAST onboarding to qualified applicants. If you are interested in applying for a FAST approval business account (approvals in 1-3 days), you will need to answer a few prequalification questions. These will determine if your application can be processed in our FAST program."}
            </p>

            <div className='py-3'>
                <h3>Business Account Plus Features</h3>
                <ul>
                    {
                        FEATURES.map(e => (
                            <li style={{ fontWeight: 'bold' }} key={e.id}>{e.text}</li>
                        ))
                    }
                </ul>
            </div>

            <div className='pb-3'>
                <p className='bold-text'>
                    FV Bank Business Plus accounts are designed for operational purposes only and have limits on certain activity and balances. These limits are designed to meet most business operational needs. Business Plus customers can always upgrade their account based upon their needs and by passing FV Bank's additional due diligence requirements for a Business Prime account.
                </p>
            </div>

            <h3>Basic limits include:</h3>
            <p className='bold-text'>Maximum portfolio balance of $500,000</p>
            <p className='bold-text'>Maximum of 20 payments  per month</p>
            <p className='bold-text'>Maximum of 20 deposits per month</p>
            <p className='bold-text'>If you think a Business Plus account is right for your business, we can proceed with some pre-qualification questions.</p>

            <p className='bold-text pt-3'>If you need a Business Prime Account, we will set an appointment with a sales team member.</p>
            <p className='bold-text pt-3'>
                You can always open a Business Plus account and upgrade to Business Prime at a later time. Business Prime accounts are for business with higher volume needs, MSB's, Non bank financial institutions and other higher risk business cases involving third party funds.
            </p>


            <ButtonGroup loading={loading} asLabel={asLabel} onNextClick={onNextClick} step={step} onBackClick={onBackClick} />


        </div>
    )
}

export default Page1;