import { makeAutoObservable } from "mobx";
import { getUiPrefRows } from "../../util/FilterUtil";
import { doDELETE, doGET, doPOST, doPUT } from "../../util/HttpUtil";
import { ENDPOINTS } from "./VerificationStepsConstant";
class Service {
  records = [];
  STRUCTURE = [];
  page = 1;
  rowsPerPage = null;
  filterOption = "";
  total = -1;
  version = 0;
  allRecords = {}
  constructor() {
    makeAutoObservable(this);
  }

  arrayToObject(arr) {
    const obj = {};
    arr.forEach(item => {
      obj[item.forForm] = item.steps;
    });
    return obj;
  }

  fetchAll = async () => {
    try {
      const response = await doGET("/api/verification-step/grid?rows=-1");
      if (response?.status === 200) {
        const objRecords = this.arrayToObject(response.data.rows ?? []);
        this.allRecords = objRecords
        return objRecords
      }
    } catch (error) {
      console.log(error)
    }
  }

  

  getSteps = (key) => {
    return this.allRecords?.[key] ?? []
  }




  fetch = async (filterUrl) => {
    if (!this?.rowsPerPage) {
      const rows = await getUiPrefRows("form.grid")
      this.rowsPerPage = rows ?? 40
    }

    if (filterUrl) {
      this.filterOption = filterUrl;
    }
    const response = await doGET(
      ENDPOINTS.grid(this.page, this.rowsPerPage, filterUrl)
    );

    if (response?.status === 200) {
      this.records = response.data.rows;
      this.page = response?.data?.page;
      if (this.page === 1) this.total = response?.data?.total;
    }
  };

  edit = async function (data, id) {
    const response = await doPUT(ENDPOINTS?.update, data);
    if (response.status === 200) {
      this.version += 1;
      this.fetchAll()
    }

  };

  get = async (id) => {
    const response = await doGET(ENDPOINTS.get(id));

    if (response.status === 200) {
      return response.data ? response.data : response;
    }
  };

  save = async (data) => {
    this.records.push(data);
    const response = await doPOST(ENDPOINTS.save, data);
    this.fetchAll()
    return response?.data?._id;
  };

  delete = async (id) => {
    const response = await doDELETE(ENDPOINTS.delete(id));
    if (response.status === 200) {
      this.fetch();
      this.fetchAll()
    }
  };

  bulkDelete = async (ids = []) => {
    const response = await doDELETE(ENDPOINTS.bulkDelete(), ids?.filter(id => id != null));
    if (response.status === 200) {
      this.fetch();
      this.fetchAll()
    }
  };

  onPaginationChange = async (page, rowsPerPage) => {
    this.page = page;
    this.rowsPerPage = rowsPerPage;
    await this.fetch(this.filterOption);

  };

}

const VerificationStepsService = new Service();
export default VerificationStepsService;

