export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl, client_id) => {
    let url = `/api/customer/grid?`;
    if (sizePerPage) url += `rows=${sizePerPage}&`;
    if (filterUrl) url += `${filterUrl}&`;
    if (client_id) url += `client_id=${client_id}&`;
    if (page) url += `page=${page}`
    return url;
  },

  commentGrid: (userId) => `/api/comment/grid?by=${userId}`,
  // get: (id,client_id) => `/api/customer/detail?id=${id}`,
  get: (id, client_id) => {
    let url = `/api/customer/detail?id=${id}`;
    if (client_id) {
      url += `?client_id=${client_id}`;
    }
    return url;
  }
  ,
  delete: (id) => `/api/customer/delete?id=${id}`,
  save: `/api/customer/create`,
  update: `/api/customer/update`,
};


export const SELECTSTRUCTURE = {
  status: [
    { label: "Active", value: 1 },
    { label: "Inactive", value: 0 },
  ],
}


export const STRUCTURE = [

  {
    label: "User Name",
    filterLabel: "User Name",
    filterName: "name",
    type: "text",
  },
  {
    label: "User Email",
    filterLabel: "User Email",
    filterName: "email",
    type: "text",
  },
  {
    label: "User Department",
    filterLabel: "User Department",
    filterName: "dept",
    type: "text",
  },
  {
    label: "User Designation",
    filterLabel: "User Designation",
    filterName: "designation",
    type: "text",
  },
  {
    label: "User Status",
    filterLabel: "User Status",
    filterName: "status",
    type: "select",
    data: SELECTSTRUCTURE?.status
  },
  {
    label: "Phone Number",
    filterLabel: "Phone Number",
    filterName: "phoneNo.phone",
    type: "text",
    icon: "mdi mdi-phone  font-size-16 ",
  },
  // {
  //   label: "User Phone Number",
  //   filterLabel: "User Phone Number",
  //   filterName: "phoneNo",
  //   type: "select",
  //   data: SELECTSTRUCTURE?.phoneNo
  // }
];
