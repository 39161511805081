import React, { useEffect, useRef } from "react";
import { CountrySelect, SalutationSelect } from "..";


export default function NameField({
    showErr,
    inline,
    error,
    required,
    borderNone,
    className,
    value,
    isDisabled,
    outline,
    name,
    salutationStyle,
    inputStyle,
    salutation,
    onNameChange = () => { },
    onSalutationChange = () => { },
    label,
}) {
    const inputRef = useRef(null)

    useEffect(() => {
        if (!name && inputRef.current) {
            inputRef.current.value = ""
        }
    }, [name])

    return (
        <div className={className + " "}>
            {outline ??

                <div style={{ minWidth: "120px ", fontSize: 16, margin: "0px 0px 5px 5px", fontWeight: "500", }}>
                    {label ?? "Name"} {required && <font color="red">*</font>}
                </div>

            }
            <div
                style={{ margin: "5px 0px 5px 0px", height: "36px" }}
                className={" input-group flex-1 "}
            >
                <div className="input-group-prepend">
                    <SalutationSelect
                        className={`m-0`}
                        innerContainerStyle={{ borderRadius: "15px 0px 0px 15px", paddingLeft: "8px" }}
                        style={{ borderRadius: "15px 0px 0px 15px", width: "80px" }}
                        inputGroup
                        value={salutation}
                        onChange={(v) => {
                            onSalutationChange(v)
                        }}
                        outline
                        noLabel
                        required={true}
                        inline={inline}
                        borderNone={borderNone}
                        isDisabled={isDisabled}
                    />
                </div>
                <input
                    ref={inputRef}
                    required={true}
                    placeholder={`${label || "Name"}${required ? "*" : ""}`}
                    style={{ 
                        height: "50px",
                        borderRadius: "0px 15px 15px 0px",
                        paddingLeft: "5px",
                        borderTop: "none",
                        borderBottom: "none",
                        borderRight: "none",
                        borderTop: "none",
                        fontSize: "14px",
                        outline: "none",
                        backgroundColor: "#F1FAFC",
                        ...inputStyle }}
                    value={name}
                    onChange={(e) => {
                        onNameChange(e?.target?.value)
                    }}
                    type="text"

                    className="form-control"
                />


            </div>
            {(error?.length && showErr) ? <div className="selectfield__error mt-3">{error}</div> : null}

        </div>
    );
}

