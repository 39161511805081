import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const StatusDropDown = ({ asLabel, label, data, value, onChange, className }) => {
  const [hoverTextColor, setHoverTextColor] = useState(null);
  const [hoverBGColor, setHoverBGColor] = useState(null);
  const [statusInfo, setStatusInfo] = useState(data.find(option => option.value == value));

  useEffect(() => {
    setStatusInfo(data.find(option => option.value === value));
  }, [value, data]);

  if (asLabel) {
    return <div className=" my-0 mx-2" style={{
      display: "flex",
      alignItems: "center",
      border: `1px solid #ced4da`,
      borderRadius: "5px",
      backgroundColor: "white",
      padding: "6px",
      paddingTop: "3px",
      paddingBottom: "3px"
    }}>

      <div style={{ marginRight: "10px", color: "#000" }}>{label}</div>
      <div style={{ display: "flex", alignItems: "center" }}>
        {statusInfo?.backgroundColor && <span className="dot me-2" style={{ backgroundColor: hoverTextColor ? hoverTextColor : statusInfo?.backgroundColor, marginTop: '0px' }}></span>}
        <div>{statusInfo?.label ?? ""}</div>
      </div>

    </div>
  }

  return (
    <div className={className}>
      <UncontrolledDropdown className="ms-0">
        <DropdownToggle
          caret
          outline={true}
          onMouseOver={() => {
            if (statusInfo?.backgroundColor) {
              setHoverTextColor("white");
              setHoverBGColor(statusInfo?.backgroundColor);
            }
          }}
          onMouseOut={() => {
            setHoverTextColor(null);
            setHoverBGColor(null);
          }}
          color="#ced4da"
          className='mx-0 my-0'
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            border: `1px solid #ced4da`,
            color: hoverTextColor ? hoverTextColor : statusInfo?.textColor,
            cursor: 'pointer',
            borderRadius: "5px",
            backgroundColor: hoverBGColor ? hoverBGColor : "white",
            padding: "6px",
            paddingTop: "3px",
            paddingBottom: "3px"
          }}
        >
          <div style={{ marginRight: "10px", color: "#000" }}>{label}</div>
          <div style={{ display: "flex", alignItems: "center" }}>
            {statusInfo?.backgroundColor && <span className="dot me-2" style={{ backgroundColor: hoverTextColor ? hoverTextColor : statusInfo?.backgroundColor, marginTop: '0px' }}></span>}
            <div>{statusInfo?.label ?? ""}</div>
          </div>
        </DropdownToggle>
        <DropdownMenu>
          {data?.filter((v) => v?.value != value).map((item, index) => (
            <DropdownItem key={index} id={item.value} onClick={() => onChange(item.value)}>
              <span className="dot" style={{ backgroundColor: item.backgroundColor, marginTop: '0px' }}></span> {item.label}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
};

StatusDropDown.propTypes = {
  label: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default StatusDropDown;
