import React from 'react'
import { Col, Row } from 'reactstrap';
import Agreements from '../../BusinessLiteForms/components/Agreements';
import { InputLabel, handleValidation } from '../../components/IndividualLite/UserDetailsForm';
import { InputField } from '../../../../../components';
import AntDateSelect from '../../../../../components/DateSelects/AntDateSelect';
import ButtonGroup from '../../BusinessLiteForms/components/ButtonGroup'

const TEXT = [
    {
        id: 0,
        label: "IMPORTANT INFORMATION ABOUT PROCEDURES FOR OPENING AN ACCOUNT:",
        subText: [
            {
                ind: 10,
                subtext: "To help the government fight the funding of terrorism and money laundering activities, federal law requires all financial institutions to obtain, verify, and record information that identifies each person who opens an account. What this means for you: When you open an account, we will ask for your company formation documents, business licenses, business address, tax identification number, and other information that will allow us to identify your business. We may ask to see your other identifying or ownership documents."
            },
        ],
    },
    {
        id: 1,
        label: "Acknowledgement of Receipt",
        subText: [
            {
                ind: 11,
                subtext: "I hereby acknowledge receipt of this Business Customer Identification Program Notice and further acknowledge that I understand its contents"
            },
        ],
    },
    {
        id: 4,
        label: "Acknowledgement And Agreement",
        subText: [
            {
                ind: 14,
                subtext: "I hereby acknowledge the information provided in this application is true and accurate as of the date set forth opposite my signature below. I consent to the use of information contained herein for the sole purpose of verifying the identity of my business and preventing fraud and understand that this information will not be used or shared for any other purpose with any other party. I further represent that I am an authorized representative of the business."
            },
        ],
    },
    {
        id: 2,
        label: "Regulation GG – Unlawful Internet Gambling Disclosure",
        subText: [
            {
                ind: 12,
                subtext: "I hereby acknowledge receipt of FV Bank International Inc.’s Regulation GG Unlawful Internet Gambling Disclosure. By signing below, I certify that I am not now engaged in, and during the life of the Account, shall not engage in any activity or business that is unlawful under the Unlawful Internet Gambling Enforcement Act of 2006, 31 USG 5361, et seq., (the “UIGEA”). I understand that I may not use this Account or any other service to receive any funds, transfer, credit, instrument, or proceeds that arise from a business that is unlawful under the UIGEA. I agree that if I am asked to process a transaction that I believe is restricted under the UIGEA, I will block the transaction and take any other action deemed to be reasonable under the UIGEA."
            },
        ],
    },
    {
        id: 3,
        label: "Marijuana-Related Business Disclosure",
        subText: [
            {
                ind: 13,
                subtext: "I hereby acknowledge receipt of FV Bank International Inc’s Marijuana-Related Business Disclosure."
            },
            {
                ind: 23,
                subtext: "By signing below, I certify that I am not now engaged in, and during the life of the Account, will not conduct any marijuana-related business activity, as outlined in the Marijuana Related Business Disclosure. Failing to alert FV Bank International Inc. that a Beneficial Owner(s) own or directly control a marijuana related business or are utilizing FV Bank International, Inc. to receive, accept, hold or maintain funds derived from a directly marijuana-related business are grounds for immediate termination of all banking services."
            },
        ],
    },
]

const Page9 = ({ asLabel, data, hasError, handleChange, pageNum, loading, step, decrementStep, handleSubmit }) => {
    const handleNext = async () => {
        await handleSubmit(true)
    }

    return (
        <>
            <div className='mb-3'>
                {
                    TEXT.map(el => {
                        return (
                            <div key={el.id}>
                                <h2 className='pt-2' key={el.id}>{el.label}</h2>
                                {
                                    el.subText.map(e => (<p key={e.ind}>{e.subtext}</p>))
                                }
                            </div>
                        )
                    })
                }

                <Row className='mb-3'>
                    <Col className='mt-3 col-xl-6 col-12 d-flex justify-content-xl-start justify-content-center'>
                        <Agreements
                            asLabel={asLabel}
                            data={data}
                            handleChange={handleChange}
                        />
                    </Col>
                    <Col className='mt-3 col-xl-6 col-12'>
                        <InputLabel label="Name of Signature" labelStyle={{ fontSize: 15 }} required={true} />
                        <Row>
                            <InputField
                                className="col-md-6"
                                placeholder={"Enter First Name"}
                                value={data?.signerFirstName}
                                onChange={(v) => handleChange("signerFirstName", v)}
                                error={handleValidation("signerFirstName", data?.signerFirstName)}
                                showErr={hasError}
                                type="text"
                                isDisabled={asLabel}
                            />
                            <InputField
                                className="col-md-6"
                                placeholder={"Enter Last Name"}
                                value={data?.signerLastName}
                                onChange={(v) => handleChange("signerLastName", v)}
                                error={handleValidation("signerLastName", data?.signerLastName)}
                                showErr={hasError}
                                type="text"
                                isDisabled={asLabel}
                            />
                        </Row>
                    </Col>
                </Row>

                <AntDateSelect
                    placeholder={"dd/mm/yyyy"}
                    value={data?.dateOfAgreement}
                    onChange={(v) => handleChange("dateOfAgreement", v)}
                    error={"The date entered cannot be earlier than today"}
                    showErr={hasError}
                    className="mb-4"
                    label="Date of Application"
                    isDisabled={asLabel}
                />
            </div>
            <ButtonGroup
                pageNum={pageNum}
                showPageNum={true}
                loading={loading}
                asLabel={asLabel}
                limit={10}
                onNextClick={handleNext}
                step={step}
                onBackClick={decrementStep}
            />
        </>
    )
}

export default Page9;