import React, { useState, useMemo } from 'react';
import { DataGrid, EditLink, Layout } from '../../components';
import { CardBody } from 'reactstrap';
import moment from 'moment';
import { ThreeBounce } from 'better-react-spinkit';
import DetailPageModal from '../../components/Layout/DetailPage/DetailPageModal';
import { observer } from "mobx-react-lite";
import AlertDetail from './AlertDetail';

const Alerts = ({ data, loading }) => {

    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10000);
    const [showDetailPage, setShowDetailPage] = useState(false);
    const[detailData,setDetailData] = useState({});
    const GridColumns = useMemo(() => ({
        version: 1,
        columns: [
            {
                title: "Date",
                accessor: "created_at",
                width: 100,
                renderer: (dataObj) => moment.unix(dataObj.created_at).format('DD/MM/YYYY'),
                show: true,
                required: true,
            },
            {
                title: "Title",
                accessor: "title",
                show: true,
                required: true,
                width: 300,
            },
            {
                title: "Alert Type",
                accessor: "alert_type",
                show: true,
                required: true,
            },
            {
                title: "Source",
                accessor: "source",
                show: true,
                required: true,
            },
            {
                title: "Disposition",
                accessor: "disposition",
                show: true,
                required: true,
            },
            {
                title: "Status",
                accessor: "status",
                show: true,
                required: true,
            },
            {
                title: "Action",
                accessor: "action",
                fixed: "right",
                width: 90,
                show: true,
                required: true,
            }
            // {
            //     title: "Preview in Unit21",
            //     accessor: "unit21_id",
            //     renderer: (dataObj) => <div style={{ textDecoration: 'underline' }}>{`Unit21 ID: ${dataObj.unit21_id}`}</div>,
            //     show: true,
            //     required: true,
            //     fixed: "right",
            // },
            // {
            //     title: "SAR",
            //     accessor: "sar",
            //     show: true,
            //     required: true,
            // },
        ]
    }), []);

    return (
        <>
            <Layout
                hideAdd
                showDetailPage={showDetailPage}
                backDetailPage={async () => {
                    setShowDetailPage(false);
                }}
                gridLoading={loading}
                title={"Alerts"}
                page={page}
            // rows={ApplicationsService.rows}
            // total={ApplicationsService.total}
            // fetch={ApplicationsService.fetch}
            >

                <Layout.Table>
                    <DataGrid
                        data={data}
                        onSelectChange={() => { }}
                        total={data.length}
                        uiPreference="alerts.grid"
                        headers={GridColumns}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        hidePagination
                        onPaginationChange={({ page, rowsPerPage }) => {
                            setPage(page);
                            setRowsPerPage(rowsPerPage);
                        }}
                        renderLastCol={(alert) => {
                            return (
                                <>
                                    <EditLink
                                        onClick={() => {
                                            setDetailData(alert)
                                            setShowDetailPage(true);
                                            // setIsEditDataChanged(false);
                                        }}
                                    />

                                </>
                            );
                        }}
                    />
                </Layout.Table>
                <Layout.DetailPageTitle>
                    Alert Details
                </Layout.DetailPageTitle>
                <Layout.DetailPageBody>
                 <AlertDetail data={detailData}/>
                </Layout.DetailPageBody>
            </Layout>



        </>
    );
};

export default observer(Alerts);


