import { observer } from "mobx-react-lite";
import React, { useEffect, useState ,useContext} from "react";
import { doGET, doPOST } from "../../util/HttpUtil";
import CityCountrySelect from "./CityCountrySelect";
import { AutoSuggest } from 'react-autosuggestions';
import './style.css';
import { I18nContext } from "../../store/context/i18nContext";
import useDebounce from "../../util/Debounce";

const AddressField = ({
  className,
  value,
  style,
  onChange = () => { },
  label,
  outline
}) => {

  const [addressLines, setAddressLines] = useState([])
  const [addresses, setAddresses] = useState([])
  const {t} =useContext(I18nContext);

  const findSuggestions = useDebounce(async (key) => {
    try {
      if (key && value?.city && key?.length > 2) {
        let selectedAddress = addresses.find(a => a?.addressLine === key);
        if (selectedAddress && selectedAddress?.addressLine) {
          setAddresses([]);
          setAddressLines([]);
          fetchCoordinates(selectedAddress);
          return;
        }

        const response = await doGET(`/api/o/maputil/autocomplete?key=${key}&country=India&city=${value?.city}`);
        if (response.status === 200) {
          if (response?.data?.length) {
            setAddresses(response?.data);
            setAddressLines(response?.data?.map(address => address?.addressLine));
          }
        } else {
          console.log("Error");
        }
      }
    } catch (error) {
      console.log("Error", error);
    }
  }, 800); // Set debounce delay to 800 ms



  const fetchCoordinates = async (selectedAddress) => {
    try {
      if (selectedAddress?.ref_id && selectedAddress?.addressLine) {
        const response = await doPOST(`/api/o/geocode/address/fromId`, selectedAddress)
        if (response.status === 200) {
          onChange(response?.data)
        }
      }
    } catch (error) {
      console.log("Error", error)
    }
  }

  useEffect(() => {

  }, [value?.city, value?.addressLine])

  return (
    <div className={className + " d-flex justify-content-center align-items-center "} style={{...style}}>
      {outline ??
        <div
          style={{ width: "120px", fontSize: 12, fontWeight: "500" }}
        >
          {label ?? t("Address")}
        </div>}
      <div style={{ margin: "10px 0px 5px 0px", height: "36px" }} className="input-group flex-1">
        <div className="input-group-prepend col-4" >
          <CityCountrySelect
            style={{ borderRadius: "15px 0px 0px 15px", fontSize: '16px' }}
            value={value}
            onChange={(v) => {
              onChange({
                ...value,
                city: v?.value,
                country: v?.country,
                state: v?.state,
                lat: v?.lat,
                lng: v?.lng,
                addressLine: v?.addressLine
              })
            }}
          />
        </div>
        <div className="autosuggestWrapper">
          <AutoSuggest
            name="addressLine"
            options={addressLines}
            handleChange={v => {
              onChange({ ...value, addressLine: v })
              findSuggestions(v)
            }}
            value={value?.addressLine}
          />
        </div>
      </div>
    </div>
  );
}

export default observer(AddressField);


