import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { FaCheckCircle, FaHourglassStart, FaSpinner, FaTimesCircle } from 'react-icons/fa';
import { LuHourglass } from 'react-icons/lu';
import { downloadBlob } from '../../pages/Form/thirdParty/ondatoGroupedByUBOs/OndatoGroupedByUBOs';
import { DialogContext } from '../../store/context/DialogContext';
import { doGET } from '../../util/HttpUtil';
import DeleteLink from '../Buttons/Icons/deleteButton';
import DataGrid from '../DataGrid/DataGrid';
import DownloadService from './DownloadService';
import './style.css';

const REPORT_STATUS_ICONS = {
  ENQUEUE: <div className='d-flex align-items-center' style={{ color: '#FFA500' }}> <FaHourglassStart className='me-1' size={17} /> Queued</div>,
  IN_PROGRESS: <div className='d-flex align-items-center' style={{ color: '#22CEEB' }}><FaSpinner className='me-1 fa-spin' size={17} /> In Progress</div>,
  FAILED: <div className='d-flex align-items-center' style={{ color: '#FF6347' }}><FaTimesCircle className='me-1' size={17} /> Failed</div>,
  GENERATED: <div className='d-flex align-items-center' style={{ color: '#32CD32' }}><FaCheckCircle className='me-1' size={17} /> Ready </div>,
};



const DownloadGrid = () => {
  const [loading, setLoading] = useState(false);
  const { showConfirm, showMessage, showError } = useContext(DialogContext);

  useEffect(() => {
    fetchItems();
  }, []);

  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: "Do you want to delete record?",
        description: "This is an unrecoverable operation.",
      })
    ) {
      setLoading(true);
      try {
        await DownloadService.delete(id);
        setLoading(false);
        showMessage("Report Deleted Successfully", "Deleted");
      } catch (e) {
        setLoading(false);
        showError(e);
      }
    }
  };

  const fetchItems = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const reports = await DownloadService.fetchAllReports();
    } catch (error) {
      console.error('Error fetching items:', error);
    } finally {
      setLoading(false);
    }

  };



  const downloadReport = async (path) => {
    setLoading(true)
    try {
      const pdfResponse = await doGET(path);
      const blob = pdfResponse.data;
      const fileName = 'report.pdf';
      downloadBlob(blob, fileName);
    } catch (error) {
      showError(error)
    } finally {
      setLoading(false)
    }

  }


  const GridColumns = {
    version: 1,
    columns: [
      {
        title: "Application No",
        accessor: "applicationNo",
        show: true,
        required: true,
      },
      {
        title: "Customer Name",
        accessor: "customerName",
        renderer: (dataObj) => dataObj.customerName || '-',
        show: true,
        required: true,
      },
      {
        title: "Form Type",
        accessor: "formType",
        show: true,
        required: true,
      },
      {
        title: "Status",
        accessor: "status",
        width : 100,
        renderer: (dataObj) => {
  
          const Icon = REPORT_STATUS_ICONS[dataObj.status] || null;
          return <div className='d-flex align-items-center '>{Icon}</div>;
        },
        show: true,
        required: true,
      },
      {
        title: "Created At",
        accessor: "createdAt",
        renderer: (dataObj) => moment.unix(dataObj.createdAt).format('DD/MM/YYYY'),
        show: true,
        required: true,
      },
      {
        title: "Download",
        accessor: "path",
        renderer: (dataObj) => <div className='d-flex align-items-center justify-content-center w-100'>
          { dataObj?.path ? 
            <a style={{ cursor: "pointer" }} href={dataObj?.path} target="_blank">
          {/* <div  onClick={() => { downloadReport(dataObj?.path) }} > */}
            <i style={{ fontSize: "16px" }} className="uil-cloud-download font-size-18 me-1"></i>
          </a> : <LuHourglass size={18} />}
        </div>,
        show: true,
        width: 80,
        fixed: "right",
        required: true,
      },
      {
        title: "Delete",
        accessor: "path",
        renderer: (dataObj) => <div className='d-flex align-items-center justify-content-center w-100'>
          <DeleteLink onClick={(event) => onDelete(event, dataObj?._id)} />
        </div>,
        show: true,
        width: 80,
        fixed: "right",
        required: true,
      },
    ]
  };

  return (
    <div>
      <div className='d-flex justify-content-between align-items-center'>
        <h3 className='mb-3'>Download Reports</h3>
        <div className="edit_cross d-block ms-2 " onClick={DownloadService.toggleModal} style={{ cursor: "pointer" }}>
          <i style={{ fontSize: "25px" }} className={` dripicons-cross`} />
        </div>
      </div>
      <DataGrid
        data={DownloadService?.reports}
        total={DownloadService.reports?.length}
        hidePagination
        hideCheckboxes
        headers={GridColumns}
        gridLoading={loading}
        page={DownloadService.pageTx}
        rowsPerPage={DownloadService.rowsPerPageTx}
        onSelectChange={fetchItems}
        onPaginationChange={(pg, rows) => {
          // DownloadService.rowsPerPageTx = rows;
          // DownloadService.pageTx = pg;
          // fetchItems();
        }}
      />
    </div>
  );
};

export default observer(DownloadGrid);