import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import './style.css';
import { UserContext } from '../../store/context/UserContext';

const HoverModal = ({ item, isVisible, position, activePath }) => {
    const modalStyle = {
        left: `${position.left}px`,
        top: `${position.top}px`,
    };

    const { userContent } = useContext(UserContext)


    if (!isVisible || !item?.subItems) {
        return (
            <div style={modalStyle} className='modal-hover'>
                <span style={{ color: '#000', fontSize: 14, text: "start" }}>{item.name}</span>
            </div>
        );
    }

    return (
        <div style={modalStyle} className='min-width-hover modal-hover'>
            {item.subItems.map((subItem, subIndex) => (
                 userContent?.rights?.includes(subItem.right) ?    <Link
                    key={subIndex}
                    to={subItem.path}
                    style={{ width: '100%' }}
                    className={`col-10 d-flex mt-2 p-2 ${activePath === subItem.path ? 'nav-sub-side-active' : ''}`}
                >
                    {subItem.logo && subItem?.logo({ color: activePath === subItem.path ? "#fff" : "black", size: 22 })}
                    <span span style={{ color: activePath === subItem.path ? "#fff" : "black", fontSize: "14px", text: "start" }} className='ms-2'>{subItem.name}</span>
                </Link> : null
            ))
            }
        </div >
    );
};

export default HoverModal;