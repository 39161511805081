import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "reactstrap";
import "./Carousel.css";

const OtpInput = ({ data, onChange, label, value, style }) => {
  const inputRef1 = useRef();
  const inputRef2 = useRef();
  const inputRef3 = useRef();
  const inputRef4 = useRef();

  const validateNumericInput = (value) => {
    return value.replace(/[^0-9]/g, '');
  };

  const handleInput = (currentRef, nextRef) => {
    currentRef.current.value = validateNumericInput(currentRef.current.value).slice(0, 1);
    if (currentRef.current.value.length >= 1) {
      nextRef.current.focus();
    }
    onChange(
      inputRef1.current.value +
      inputRef2.current.value +
      inputRef3.current.value +
      inputRef4.current.value
    );
  };

  const handleBackspace = (currentRef, prevRef, e) => {
    if (currentRef.current.value === "") {
      prevRef.current.focus();
      e.preventDefault();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text").trim().substring(0, 4);
    const validatedData = Array.from(pastedData).map(char => validateNumericInput(char)).join('');
    if (validatedData.length === 4) {
      inputRef1.current.value = validatedData[0];
      inputRef2.current.value = validatedData[1];
      inputRef3.current.value = validatedData[2];
      inputRef4.current.value = validatedData[3];
      onChange(validatedData);
    }
  };

  useEffect(() => {
    if (value === null || value === "") {
      inputRef1.current.value = "";
      inputRef2.current.value = "";
      inputRef3.current.value = "";
      inputRef4.current.value = "";
    }
  }, [value]);

  return (
    <>
      <div style={{ marginVertical: 0, marginHorizontal: 0 }}>
        <div>{label}</div>
        <div style={{ flexDirection: "row", marginTop: 0 }}>
          <Row
            className="otpInput col-12 d-flex justify-content-between"
            style={{ margin: 0 }}
          >
            {Array.from({ length: 4 }).map((_, index) => (
              <div style={{ flex: 1, padding: "0" }} key={index}>
                <input
                  type="text"
                  ref={[inputRef1, inputRef2, inputRef3, inputRef4][index]}
                  maxLength={1}
                  onPaste={handlePaste}
                  onChange={() => handleInput(
                    [inputRef1, inputRef2, inputRef3, inputRef4][index],
                    [inputRef2, inputRef3, inputRef4, inputRef4][index]
                  )}
                  onKeyDown={(e) => {
                    if (e.nativeEvent.key === "Backspace") {
                      handleBackspace(
                        [inputRef1, inputRef2, inputRef3, inputRef4][index],
                        [inputRef1, inputRef1, inputRef2, inputRef3][index],
                        e
                      );
                    }
                  }}
                  style={{ ...style }}
                  className="otp-container ps-3"
                />
              </div>
            ))}
          </Row>
        </div>
      </div>
    </>
  );
};

export default OtpInput;
