export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl) => {
    let url = `/api/utm-tracker/grid?`;
    if (sizePerPage) url += `rows=${sizePerPage}&`;
    if (filterUrl) url += `${filterUrl}&`;
    if (page) url += `page=${page}`
    return url;
  },
  gridAll: (page, sizePerPage, filterUrl) => {
    let url = `/api/utm-tracker/grid?rows=-1`;
    return url;
  },
  get: (id) => `/api/utm-tracker/detail?id=${id}`,
  save: `/api/utm-tracker/create`,
  delete: (id) => `/api/utm-tracker/delete?id=${id}`,
  bulkDelete: (id) => `/api/utm-tracker/delete`,
  update: `/api/utm-tracker/update`,
};

export const STRUCTURE = [
  {
    label: "Name",
    filterLabel: "Name",
    filterName: "name",
    type: "text",
  },
  {
    label: "Link",
    filterLabel: "Link",
    filterName: "utm_referer",
    type: "text",
  },
  {
    label: "Value",
    filterLabel: "Value",
    filterName: "value",
    type: "text",
  },
];
