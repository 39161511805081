export const ENDPOINTS = {
    grid: (page, sizePerPage, filterUrl) => {
        let url = `/api/role/grid?`;
        url += `sortBy=name&sortAsc=true&`;
        if (sizePerPage) url += `rows=${sizePerPage}&`;
        if (filterUrl) url += `${filterUrl}&`;
        if (page) url += `page=${page}`
        return url;
    },
    getRightsList: (forSuperadmin) => {
        return `/api/rights/all?for=${forSuperadmin ? "super-admin" : "supervisor"}`;
    },
    get: (id) => `/api/role/detail?id=${id}`,
    save: `/api/role/create`,
    delete: (id) => `/api/role/delete?id=${id}`,
    bulkDelete: (id) => `/api/role/delete`,
    update: `/api/role/update`,
};

export const STRUCTURE = [
    {
        label: "Name",
        filterLabel: "Role Name",
        filterName: "name",
        type: "text",
        icon: "mdi mdi-account-cog-outline font-size-16 ",
    },

];
