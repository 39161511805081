import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { DataGrid } from '../../components';
import PrimaryButton from '../../components/Buttons/PrimaryButton';
import PagesNoRights from '../Utility/pages-no-rights';
import AccountSearchService from './AccountSearchService';
import './styles.scss'; // Assuming you have a CSS file for styles
import { UserContext } from '../../store/context/UserContext';

const IndividualLiteGrid = () => {
    const [searchParams] = useSearchParams();
    const email = searchParams.get("email");
    const username = searchParams.get("username");
    let phone = "";
    if (!!searchParams.get("countryCode") && !!searchParams.get("phone"))
        phone = "+" + searchParams.get("countryCode") + " " + searchParams.get("phone");
    const accountNumber = searchParams.get("accountNumber");
    const [loading, setLoading] = useState(false);
    const [records, setRecords] = useState([]);
    const navigate = useNavigate();
    const { userContent } = useContext(UserContext);

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await AccountSearchService.fetch(email, username, phone, accountNumber);
            setRecords(response);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    const handleNavigation = (id) => {
        navigate(`/accounts/detail/${id}`);
    };

    useEffect(() => {
        if (userContent?.rights?.includes(2700)) {
            fetchData();
        }
    }, [email, username, phone, accountNumber, userContent?.rights]);

    if (!userContent?.rights?.includes(2700)) {
        return <div key={userContent?.rights} > <PagesNoRights /></div>
    }

    if (loading) {
        return (
            <div className="p-2 table-responsive fv_table_container">
                <table className="table table-striped fv_table">
                    <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Username</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.from({ length: 10 }).map((_, index) => (
                            <tr key={index}>
                                <td className="fv_table_loader_shimmer"></td>
                                <td className="fv_table_loader_shimmer"></td>
                                <td className="fv_table_loader_shimmer"></td>
                                <td className="fv_table_loader_shimmer"></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }

    if (records?.length === 0) {
        return (
            <>
                <div className='d-flex justify-content-center align-items-center fs-4 font-weight-bold'>
                    No Record Found
                </div>
                <div className='d-flex justify-content-center align-items-center mt-3 fs-5 font-weight-bold'>
                    <PrimaryButton className="fv_detail_account-button px-3" onClick={() => window.history.back()}  >
                        {"Go Back"}
                    </PrimaryButton>
                </div>
            </>
        );
    }

    const GridColumns = {
        version: 1,
        columns: [
            {
                title: "Name",
                accessor: "name",
                show: true,
                required: true,
            },
            {
                title: "Email",
                accessor: "email",
                show: true,
                required: true,
            },
            {
                title: "Username",
                accessor: "username",
                show: true,
                required: true,
            },
            {
                title: "Action",
                accessor: "",
                renderer: (dataObj) => (
                    <FaArrowRight
                        className="fv_table_action_icon"
                        onClick={() => handleNavigation(dataObj.id)}
                    />
                ),
                width: 100,
                show: true,
                required: true,
            },
        ]
    };

    return (
        <div key={userContent?.rights}  className="p-2 table-responsive fv_table_container">
            <div className='mb-3 px-2 d-flex justify-content-between align-items-center'>
                <FaArrowLeft
                    size={18}
                    className="fv_table_action_icon-black"
                    onClick={() => window.history.back()}
                />
                {/* <CyclosFilter
                    isRate={true}
                    value={"filterValues"}
                    structure={TRANSACTION_FILTER}
                    applyFunc={() => { }}
                /> */}
            </div>
            <DataGrid
                data={records}
                total={records.length}
                uiPreference="individualLite.grid"
                headers={GridColumns}
                page={1}
                rowsPerPage={40}
                onPaginationChange={({ page, rowsPerPage }) => { }}
                hidePagination
            />
        </div>
    );
};

export default observer(IndividualLiteGrid);
