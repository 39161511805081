import { makeAutoObservable } from "mobx";
import { doGET } from "../../../util/HttpUtil";

export const ENDPOINTS = {
    grid: (formType, step) => {
        let url = `/api/verification-ask/grid?rows=-1&formType=${formType}&step=${step}`;

        return url;
    },
    get: (id) => `/api/verification-ask/detail?id=${id}`,
    save: `/api/verification-ask/create`,
    delete: (id) => `/api/verification-ask/delete?id=${id}`,
    bulkDelete: (id) => `/api/verification-ask/delete`,
    update: `/api/verification-ask/update`,
};


class QuestionsSelect {
    questions = [];
    constructor() {
        makeAutoObservable(this);
    }
    fetch = async function (formType, step ) {
        try {
            const response = await doGET(ENDPOINTS.grid(formType, step));
            if (response.status === 200) {
                this.questions = response?.data?.rows
            } else {
                this.error = response.data;
            }
        } catch (err) {
            this.error = err;
        }
    };
}

const QuestionsSelectService = new QuestionsSelect();
export default QuestionsSelectService;
