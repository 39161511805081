import React, { useState, useMemo } from 'react';
import './styles.scss';
import { DataGrid } from '../../components';
import { CardBody } from 'reactstrap';
import { ThreeBounce } from 'better-react-spinkit';

const Beneficiary = ({ beneficiaryData, loading }) => {
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10000);

    const GridColumns = useMemo(() => ({
        version: 1,
        columns: [
            {
                title: "Name",
                show: true,
                renderer: (data) => <div>{data?.customValues.Beneficiary_Name}</div>,
                required: true,
            },
            {
                title: "Country",
                show: true,
                renderer: (data) => <div>{data?.customValues.Beneficiary_Country}</div>,
                required: true,
            },
            {
                title: "AML Status",
                show: true,
                renderer: (data) => <div>{data?.customValues.Beneficiary_Monitor_AML_Status}</div>,
                required: true,
            },
            {
                title: "Status",
                renderer: (data) => <div>{data?.customValues.Beneficiary_Status}</div>,
                show: true,
                required: true,
            },
            // {
            //     title: "BPI's enabled",
            //     accessor: "",
            //     show: true,
            //     required: true,
            // },
            // {
            //     title: "Counterparty TPR Verified",
            //     accessor: "",
            //     show: true,
            //     required: true,
            // },
        ]
    }), []);

    if (loading) {
        return (
            <div className="p-2 d-flex justify-content-center align-items-center">
                <ThreeBounce />
            </div>
        );
    }

    return (
        <div className="table-responsive p-2 fv_table_container">
            <CardBody style={{ height: "100%" }}>
                <div className="d-md-flex flex-1">
                    <div
                        data-pattern="priority-columns"
                        style={{
                            width: '100%',
                        }}
                    >
                        <DataGrid
                            data={beneficiaryData}
                            total={beneficiaryData?.length}
                            onSelectChange={() => { }}
                            uiPreference="beneficiaries.grid"
                            headers={GridColumns}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            hidePagination
                            onPaginationChange={({ page, rowsPerPage }) => {
                                setPage(page);
                                setRowsPerPage(rowsPerPage);
                            }}
                        />
                    </div>
                </div>
            </CardBody>
        </div>
    )
}

export default Beneficiary;
