import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Form, Row } from "reactstrap";
import {  ClientSelect, Tab } from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import UserService from "./UserService";
import { SELECTSTRUCTURE } from "./UserConstant";
import { ThreeBounce } from "better-react-spinkit";
// import VehicleProfile from "../Vehicle/Tabs/VehicleProfile";
import UserProfile from "./UserProfile";
// import Booking from '../Booking/Booking'
// import Invoice from "../Invoice/Invoice";
import Feedback from "./Feedback";


const EditUsers = ({ editId, onChange = () => { }, hasErr, selectedToggleValue }) => {
  const { t } = useContext(I18nContext);
  const { showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(false);
  const [comments, setComments] = useState(null);
  const [data, setData] = useState({
    salutation: "Mr",
    client_id: UserService?.client_id
  });
  const [step, setStep] = useState(1);

  const fetch = async () => {
    try {
      setLoading(true)
      let user;
      if (selectedToggleValue === "forClient") {
        user = await UserService.get(editId, UserService.client_id);
      } else {
        user = await UserService.get(editId);
      }
      // let user = await UserService.get(editId);
      // user?.status === 0 ? false : true
      setLoading(false)
      setData(user);
    } catch (error) {
      showError(error)
      setLoading(false)
    }
  };


  useEffect(() => {
    setData({
      salutation: "Mr",
      phoneNo: {
        countryCode: 91,
      },
      client_id: UserService?.client_id
    })
    if (editId) {
      fetch();
    }
  }, [editId, UserService?.version]);


  useEffect(() => {
    onChange(data)
  }, [data]);


  return (
    <React.Fragment>
      {loading ?
        <div className="d-flex justify-content-center align-items-center">
          <ThreeBounce size={50} color="pink" />
        </div>
        :
        <Form
          className="p-3"
          style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}
        >
          <Row className="mt-3 mb-3">
            {/* {selectedToggleValue === "forClient" && (
              <ClientSelect
                value={UserService?.client_id}
                isDisabled={true}
                onChange={(v) => setData({ ...data, client_id: v?.value })}
                className="col-sm-10"
              />
            )} */}
            {!editId ? <UserProfile
              hasErr={hasErr}
              data={data}
              setData={setData}
            /> : <Tab
              vertical={false}
              left
              activeTab={step}
              onChange={(data) => {
                setStep(data);
              }}
              className="d-flex"
            >
              <div num={1} label={t("Profile")}>
                <UserProfile
                  hasErr={hasErr}
                  data={data}
                  setData={setData}
                />
              </div>
           
              
              <div num={5} label={t("Payments")}>
                <UserProfile
                  hasErr={hasErr}
                  data={data}
                  setData={setData}
                />
              </div>
              <div num={6} label={t("Feedback")} >
                <Feedback editId={editId} />
              </div>
              <div num={7} label={t("Reminders")}>
                <UserProfile
                  hasErr={hasErr}
                  data={data}
                  setData={setData}
                />
              </div>
            </Tab>}
          </Row>
        </Form>
      }
    </React.Fragment>
  );
};

export default observer(EditUsers);
