import React from 'react';
import { InputLabel } from '../../components/IndividualLite/UserDetailsForm';
import SignPad from "../../components/IndividualLite/SignPad";

const Agreements = ({ asLabel, data, handleChange }) => {
    return (
        <div>

            <InputLabel label="Signature" required />
            <div className='mb-2'></div>
            <SignPad
                asLabel={asLabel}
                value={data?.signature}
                onChange={(sign) => handleChange('signature', sign)}
            />
        </div>
    );
}

export default Agreements;