import { useState } from "react";
import "./StatusProgress.css";

const StatusDropdown = ({ data, value, onChange }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleStatusClick = (status) => {
        onChange(status);
        setIsOpen(false);
    };

    const handleButtonClick = (e) => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <div className="">
                <button
                    className="dropdown-toggle"
                    onClick={(e) => {
                        e.stopPropagation()
                        handleButtonClick()
                    }}
                >
                    {data.length ? data.find(el => el.value === value).label : ""}
                </button>

            </div>

            {isOpen && (
                <div className="modal-action shadow-md rounded-2" style={{
                    top: 50,
                    left: 15,
                }} onClick={(e) => e.stopPropagation()}>
                    {
                        data.map((e) => {
                            return (
                                <div key={e.value} style={{
                                    backgroundColor: e.backgroundColor,
                                    padding: "5px 10px",
                                    fontSize: 14,
                                    fontWeight: value === e.value ? '600' : '400',
                                    textDecoration: value === e.value ? 'underline' : 'none',
                                    cursor: 'pointer',
                                    color: "#000"
                                }} onClick={() => handleStatusClick(e)}>{e.label}</div>
                            )
                        })
                    }
                </div>
            )}
        </>

    );
};

export default StatusDropdown;