import React from 'react';
import { Button } from 'reactstrap'; // Make sure to import Button from your UI library
import { ThreeBounce } from 'better-react-spinkit';
import { FaCheck } from 'react-icons/fa';

export default function ApproveButton({ onlyIcon = false, key = 12, onClick, disabled, loading }) {

  if (loading) {
    return (
      <div key={key} className="d-flex justify-content-end my-2">
        {onlyIcon ? <ThreeBounce size={8} color="#6649f5" /> : <Button
          disabled={disabled}
          style={{ backgroundColor: '#1678AE', height: "40px", padding: "2px 4px 2px 4px", width: "100px", borderRadius: "10px" }}
          color="success"
        >
          <ThreeBounce size={10} color="#FFFFFF" />
        </Button>}
      </div>
    );
  }
  return (
    <div className="d-flex justify-content-end my-2 me-2">
      {onlyIcon ? <div
        onClick={onClick}
        className="mx-2"
      >
        <i
          style={{ marginTop: "3px", marginRight: "5px" }}
          className="font-size-24 text-success bx bxs-cloud-upload "
        ></i>
      </div> : <Button
        onClick={onClick}
        disabled={disabled}
          style={{ cursor: disabled ? "not-allowed" : "pointer", backgroundColor:  '#1678AE' , height: "40px", padding: "2px 4px 2px 4px", width: "100px", borderRadius: "10px" }}
        color="success"
      >
        {/* <FaCheck 
            style={{ fontSize: "18px", marginRight: "5px" }}
        /> */}
        <div style={{ margin: "2px", fontSize: "16px" }}>Approve</div>
      </Button>}
    </div>
  );
}
