import React from "react";
import Switch from "react-switch";

const Offsymbol = ({ label }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#999",
        paddingRight: 2
      }}
    >
      {" "}
      {label}
    </div>
  )
}

const OnSymbol = ({ label }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#999",
        paddingRight: 2
      }}
    >
      {" "}
      {label}
    </div>
  )
}

const Checkbox = ({
  className,
  id,
  checkedIcon = false,
  uncheckedIcon = false,
  height = 20,
  width = 48,
  label,
  noLabel,
  desc,
  checked,
  onClick,
  isDisabled,
  style,
  leftAlign,
  uncheckedlabel,
  checkedlabel,
  uncheckedHandleIcon,
  checkedHandleIcon
}) => {

  return (
    <div
      style={{ ...style }}
      className={`d-flex align-items-center ${leftAlign ? "" : "justify-content-between"} my-1 ` + className}
    >
      {/* <input
        key={`${id}-${checked}`} // to refresh the component when the checked value changes
        onClick={onClick}
        checked={checked}
        disabled={isDisabled}
        type="checkbox"
        // className="form-check-input"
        id={id}
      /> */}

  
      {!noLabel  && <label htmlFor={id} style={{ minWidth: "200px ", fontSize: 16, margin: "0px 0px 5px 5px", fontWeight: "500", }}>
        {label}
      </label>}

      <Switch
        id={id}
        key={`${id}-${checked}`}
        onChange={onClick}
        checked={checked}
        disabled={isDisabled}
        handleDiameter={25}
        uncheckedIcon={uncheckedIcon ? uncheckedIcon : <Offsymbol label={uncheckedlabel} />}
        checkedIcon={checkedIcon ? checkedIcon : <OnSymbol label={checkedlabel} />}
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
        height={height}
        width={width}
        uncheckedHandleIcon={uncheckedHandleIcon ?? <i className="bx bx-x font-size-20" style={{ marginTop: "3px", marginLeft: "2px" }}></i>}
        checkedHandleIcon={checkedHandleIcon ?? <i className="bx bx-check font-size-20" style={{ marginTop: "3px", marginLeft: "2px" }}></i>}
      />
      <div className="flex-fill"></div>
    </div>
  );
};
export default Checkbox;
