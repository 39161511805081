import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import { FaChevronDown, FaChevronRight, FaPen, FaPlus, FaTrash } from 'react-icons/fa';
import DocCategoryService from "./DocCategoryService";
import "./style.scss";
import { UserContext } from '../../store/context/UserContext';

const FolderItem = observer(({ isCollapsed, category, onToggleCollapse, onCreateSubCategory }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editName, setEditName] = useState(category.name);
  const [isAddingChild, setIsAddingChild] = useState(false);
  const [newChildName, setNewChildName] = useState("");
  const { userContent } = useContext(UserContext)

  const handleEdit = async () => {
    try {
      await DocCategoryService.update({ ...category, name: editName });
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating category:", error);
    }
  };

  const handleAddChild = async () => {
    if (!newChildName) {
      setIsAddingChild(false);
      return;
    }
    try {
      await onCreateSubCategory(newChildName, category._id);
      setIsAddingChild(false);
      setNewChildName("");
    } catch (error) {
      console.error("Error adding subcategory:", error);
    }
  };

  return (
    <div key= {userContent?.rights} className="folder-item">
      <div className="folder-header d-flex align-items-center">
        <span className="me-2" onClick={onToggleCollapse}>
          {category.children && category.children.length > 0 ? (
            isCollapsed ? <FaChevronRight /> : <FaChevronDown />
          ) : null}
        </span>

        {isEditing ? (
          <input
            autoFocus={true}
            type="text"
            value={editName}
            onChange={(e) => setEditName(e.target.value)}
            onBlur={handleEdit}
            onKeyDown={(e) => e.key === "Enter" && handleEdit()}
          />
        ) : (
          <span>{category.name}</span>
        )}
        <div className="icon-container ms-auto d-flex align-items-center">
          {userContent?.rights?.includes(2602) ? <FaPen className='mx-2' onClick={() => setIsEditing(true)} /> : <></>}
          {!category?.parent_id && userContent?.rights?.includes(2601) ? <FaPlus className='mx-2' onClick={() => setIsAddingChild(true)} /> : <></>}
          {userContent?.rights?.includes(2603) ? <FaTrash className='mx-2' onClick={async () => {
            try {
              await DocCategoryService.delete(category._id);
            } catch (error) {
              console.error("Error deleting category:", error);
            }
          }} /> : <></>}
        </div>
      </div>
      {isAddingChild && (
        <div className='d-flex'>
          <input
            autoFocus={true}
            type="text"
            placeholder="Subcategory name"
            value={newChildName}
            onChange={(e) => setNewChildName(e.target.value)}
            onBlur={handleAddChild}
            onKeyDown={(e) => e.key === "Enter" && handleAddChild()}
            className="mt-2 child-folder-input"
            style={{ flex: 1 }}
          />
        </div>
      )}
    </div>
  );
});

export default FolderItem;
