import React, { useContext } from 'react';
import { InputField } from '../../../../../components';
import CheckBoxFieldMutiple from '../../../../../components/CheckBox/CheckBoxFieldMutiple';
import CustomTextArea from '../../../../../components/InputField/CustomTextArea';
import ButtonGroup from '../../BusinessLiteForms/components/ButtonGroup';
import { QuestionRadioGroup } from '../../components/IndividualLite/DeclarationForm';
import { ques10, ques11, ques12, ques9 } from '../constants';
import { DialogContext } from '../../../../../store/context/DialogContext';
import { Form, FormGroup } from 'react-bootstrap';
import _ from 'lodash';


export const QuestionRadioGroupMultiple = ({ field, question, data, handleChange, showErr, style, options, required, error }) => (
    <div>
        <FormGroup>
            <Form.Label className='mt-3' style={{ minWidth: "120px", padding: "0px", fontSize: 16, fontWeight: "500", ...style }}>
                {question}
                {!!required ? <font color="red">*</font> : ""}
            </Form.Label>
            <div className='row'>
                {
                    options.map((e, i) => (
                        <div key={i} className='col-12 col-md-6 mb-2'>
                            <Form.Check
                                id={e.value}
                                type="radio"
                                label={e.label}
                                name={field}
                                value={e.value}
                                checked={_.get(data, field) === e.value}
                                onChange={() => handleChange(field, e.value)}
                                className='me-2'
                            />
                        </div>
                    ))
                }
            </div>

            {(error?.length && showErr) ? <em className="selectfield__error">{error}</em> : null}
        </FormGroup>
    </div>
);


const handleValidation = (field, value, data) => {
    if (field === "cryptocurrencyBusinessModel") {
        if (!value || value?.length === 0) {
            return "Please select at least one business model in the crypto/blockchain industry.";
        }
        return "";
    }

    if (field === "custodiedWallets") {
        if (!value || value?.length === 0) {
            return "Please indicate if you provide custodial wallets or if your customers have self-custody.";
        }
        return "";
    }

    if (field === "cryptocurrencyTypes") {
        if (!value || value?.length === 0) {
            return "Please select at least one type of cryptocurrency your organization works with.";
        }
        return "";
    }

    if (field === "analyticsServices") {
        if (!value || value?.length === 0) {
            return "Please select at least one blockchain analytics tool you use in your business.";
        }
        return "";
    }

    if (field === "cryptocurrencyBusinessModelOther") {
        if (!!data?.cryptocurrencyBusinessModel?.includes("Other") && (!value || value?.trim() === "")) {
            return "Please explain your crypto business model since you selected 'Other' above.";
        }
        return "";
    }

    if (field === "cryptocurrencyTypesOther") {
        if (!!data?.cryptocurrencyTypes?.includes("Other") && (!value || value?.trim() === "")) {
            return "Please list other cryptocurrencies offered since you selected 'Other' above.";
        }
        return "";
    }

    if (field === "cryptoCustodyLicenses") {
        if (!!data?.cryptocurrencyBusinessModel?.includes("Custody") && (!value || value?.length === 0)) {
            return "Please identify which type of license you operate under since you offer crypto custody services.";
        }
        return "";
    }

    if (field === "analyticsServicesOther") {
        if (!!data?.analyticsServices?.includes("Other") && (!value || value?.trim() === "")) {
            return "Please specify the blockchain analytics tools you use since you selected 'Other'.";
        }
        return "";
    }

    if (field === "cryptoTransactionScreening") {
        if (!value || value?.trim() === "") {
            return "This field is required. Please indicate how you screen crypto transactions.";
        }
        return "";
    }

    if (field === "cryptoComplianceProgram") {
        if (!value || value?.trim() === "") {
            return "This field is required. Please list your primary counterparties involved in the cryptocurrency transactions.";
        }
        return "";
    }

    return "";
};

const Page5 = ({ asLabel, data, handleChange, pageNum, loading, step, decrementStep, handleSubmit }) => {
    const [hasError, setHasError] = React.useState(false);
    const { showError } = useContext(DialogContext);

    const validateFields = () => {
        const requiredFields = [
            'dealsInCryptocurrency',
            'requiresLicense',
            'hasLicense',
            'cryptocurrencyBusinessModel',
            'cryptocurrencyBusinessModelOther',
            'custodiedWallets',
            'cryptocurrencyTypes',
            'cryptocurrencyTypesOther',
            'cryptoCustodyLicenses',
            'analyticsServices',
            'analyticsServicesOther',
            'cryptoTransactionScreening',
            'cryptoComplianceProgram',
        ];

        let errors = [];

        requiredFields.forEach(field => {
            const fieldError = handleValidation(field, data[field], data);
            if (fieldError) {
                errors.push(fieldError);
            }
        });

        if (errors.length > 0) {
            setHasError(true);
            showError(errors[0]);
            return false;
        }

        setHasError(false);
        return true;
    };

    const handleNext = async () => {
        if (validateFields()) {
            await handleSubmit(false);
        }
    };

    return (
        <>
            <div className='mb-3'>
                <h2 style={{ fontWeight: 'bolder' }} className='pt-3'>Cryptocurrency and Digital Assets Involvement</h2>

                <QuestionRadioGroup
                    style={{ fontSize: 15 }}
                    field={"dealsInCryptocurrency"}
                    question="Confirm that your Company deals in cryptocurrency."
                    data={data}
                    handleChange={handleChange}
                    showErr={hasError}
                    error={handleValidation('dealsInCryptocurrency', data?.dealsInCryptocurrency, data)}
                    isDisabled={asLabel}
                    defaultValue={false}
                    required
                />

                <QuestionRadioGroup
                    style={{ fontSize: 15 }}
                    field={"requiresLicense"}
                    question="Do you require a license to operate your business model in your jurisdiction of registration?"
                    data={data}
                    handleChange={handleChange}
                    showErr={hasError}
                    error={handleValidation('requiresLicense', data?.requiresLicense, data)}
                    isDisabled={asLabel}
                    defaultValue={false}
                    required
                />

                <QuestionRadioGroup
                    style={{ fontSize: 15 }}
                    field={"hasLicense"}
                    question="Do you have a license or exemption?"
                    data={data}
                    handleChange={handleChange}
                    showErr={hasError}
                    error={handleValidation('hasLicense', data?.hasLicense, data)}
                    isDisabled={asLabel}
                    defaultValue={false}
                    required
                />

                <CheckBoxFieldMutiple
                    labelStyle={{ fontSize: 15 }}
                    label="What is your business model in the crypto/blockchain industry?"
                    data={ques9}
                    placeholder="Select"
                    value={data.cryptocurrencyBusinessModel ? data.cryptocurrencyBusinessModel : []}
                    onChange={(v) => handleChange('cryptocurrencyBusinessModel', v)}
                    error={handleValidation('cryptocurrencyBusinessModel', data?.cryptocurrencyBusinessModel, data)}
                    showErr={hasError}
                    isDisabled={false}
                    required
                />

                {data?.cryptocurrencyBusinessModel?.includes("Other") ? (
                    <CustomTextArea
                        labelStyle={{ fontSize: 15 }}
                        placeholder=""
                        value={data?.cryptocurrencyBusinessModelOther}
                        onChange={(v) => { handleChange("cryptocurrencyBusinessModelOther", v); }}
                        error={handleValidation('cryptocurrencyBusinessModelOther', data?.cryptocurrencyBusinessModelOther, data)}
                        showErr={hasError}
                        type="text"
                        label="Please explain your crypto business model since you selected 'Other' above."
                        className="mt-3"
                        isDisabled={asLabel}
                        required
                    />
                ) : null}

                <QuestionRadioGroupMultiple
                    field={"custodiedWallets"}
                    question="Do you provide custodial wallets or do your customers have self-custody?"
                    data={data}
                    handleChange={handleChange}
                    showErr={hasError}
                    error={handleValidation('custodiedWallets', data?.custodiedWallets, data)}
                    isDisabled={asLabel}
                    style={{ fontSize: 15 }}
                    options={[
                        { label: "Custodial Wallets", value: "Custodial Wallets" },
                        { label: "Self Custody", value: "Self Custody" },
                        { label: "Not Applicable", value: "Not Applicable" },
                    ]}
                    required
                />

                <CheckBoxFieldMutiple
                    labelStyle={{ fontSize: 15 }}
                    label="What type of cryptocurrency does your organization work with?"
                    data={ques10}
                    placeholder="Select"
                    value={data.cryptocurrencyTypes ? data.cryptocurrencyTypes : []}
                    onChange={(v) => handleChange('cryptocurrencyTypes', v)}
                    error={handleValidation('cryptocurrencyTypes', data?.cryptocurrencyTypes, data)}
                    showErr={hasError}
                    isDisabled={false}
                    required
                />

                {data?.cryptocurrencyTypes?.includes("Other") ? (
                    <InputField
                        className="m"
                        label="Please list other cryptocurrencies offered since you selected 'Other' above."
                        labelStyle={{ fontSize: 15 }}
                        placeholder=""
                        value={data?.cryptocurrencyTypesOther}
                        onChange={(v) => { handleChange("cryptocurrencyTypesOther", v); }}
                        error={handleValidation('cryptocurrencyTypesOther', data?.cryptocurrencyTypesOther, data)}
                        showErr={hasError}
                        type="text"
                        isDisabled={asLabel}
                        required
                    />
                ) : null}

                {data?.cryptocurrencyBusinessModel?.includes("Custody") ? (
                    <CheckBoxFieldMutiple
                        labelStyle={{ fontSize: 15 }}
                        label="You indicated you offer crypto custody services. Please identify which type of license you operate under."
                        data={ques11}
                        placeholder="Select"
                        value={data.cryptoCustodyLicenses ? data.cryptoCustodyLicenses : []}
                        onChange={(v) => handleChange('cryptoCustodyLicenses', v)}
                        error={handleValidation('cryptoCustodyLicenses', data?.cryptoCustodyLicenses, data)}
                        showErr={hasError}
                        isDisabled={false}
                        required
                    />
                ) : null}

                <CheckBoxFieldMutiple
                    labelStyle={{ fontSize: 15 }}
                    label="Which blockchain analytics tools do you use in your business?"
                    data={ques12}
                    placeholder="Select"
                    value={data.analyticsServices ? data.analyticsServices : []}
                    onChange={(v) => handleChange('analyticsServices', v)}
                    error={handleValidation('analyticsServices', data?.analyticsServices, data)}
                    showErr={hasError}
                    isDisabled={false}
                    required
                />

                {data?.analyticsServices?.includes("Other") ? (
                    <InputField
                        className="m"
                        label="If 'Other', please specify."
                        labelStyle={{ fontSize: 15 }}
                        placeholder=""
                        value={data?.analyticsServicesOther}
                        onChange={(v) => { handleChange("analyticsServicesOther", v); }}
                        error={handleValidation('analyticsServicesOther', data?.analyticsServicesOther, data)}
                        showErr={hasError}
                        type="text"
                        isDisabled={asLabel}
                        required
                    />
                ) : null}

                <CustomTextArea
                    labelStyle={{ fontSize: 15 }}
                    placeholder=""
                    value={data?.cryptoTransactionScreening}
                    onChange={(v) => { handleChange("cryptoTransactionScreening", v); }}
                    error={handleValidation('cryptoTransactionScreening', data?.cryptoTransactionScreening, data)}
                    showErr={hasError}
                    type="text"
                    label="Please indicate how you screen crypto transactions for AML, Sanctions, Ransomware, and Adverse Media."
                    className="mt-3"
                    isDisabled={asLabel}
                    required
                />

                <CustomTextArea
                    labelStyle={{ fontSize: 15 }}
                    placeholder=""
                    value={data?.cryptoComplianceProgram}
                    onChange={(v) => { handleChange("cryptoComplianceProgram", v); }}
                    error={handleValidation('cryptoComplianceProgram', data?.cryptoComplianceProgram, data)}
                    showErr={hasError}
                    type="text"
                    label="List your primary counterparties involved in the cryptocurrency transactions (LP's, OTC, Exchanges, etc.)."
                    className="mt-3"
                    isDisabled={asLabel}
                    required
                />
            </div>
            <ButtonGroup
                pageNum={pageNum}
                showPageNum={true}
                loading={loading}
                asLabel={asLabel}
                limit={10}
                onNextClick={handleNext}
                step={step}
                onBackClick={decrementStep}
            />
        </>
    );
};

export default Page5;
