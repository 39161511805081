import React from 'react'
import Logo from "../../../../../assets/images/Logo.svg";

const ThankYou = ({type}) => {
  return (
      <div style={{ height: "100vh", margin: "0px 30px" }} className={'d-flex justify-content-center align-items-center'}>
          <div className={'form-content '}>

              <div className='d-flex flex-column  justify-content-center  align-items-center  '>
                  {<img
                      src={Logo}
                      alt="fv-bank"
                      height="50"
                      style={{ marginBottom: "10px" }}
                      className="logo logo-dark"
                  />}

                  <div style={{ margin: "10px 0px 30px 0px", fontSize: "24px", fontWeight: "500" }}>{type}</div>

                  <div style={{ fontSize: "30px", lineHeight: 2, marginBottom: 10 }}>
                      Thank you for submitting your application.
                  </div>
                  <div style={{ fontSize: "20px" }}>
                      We have received your Identity Information and are processing it. Please check back later.
                  </div>
                  <div style={{ fontSize: "20px" }}>
                      We appreciate your interest in opening an account with FV Bank.
                  </div>
              </div>
          </div>
      </div>
  )
}

export default ThankYou