export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl) => {
    let url = `/api/doc-type/grid?`;
    if (sizePerPage) url += `rows=${sizePerPage}&`;
    if (filterUrl) url += `${filterUrl}&`;
    if (page) url += `page=${page}`
    return url;
  },
  get: (id) => `/api/doc-type/detail?id=${id}`,
  save: `/api/doc-type/create`,
  delete: (id) => `/api/doc-type/delete?id=${id}`,
  bulkDelete: (id) => `/api/doc-type/delete`,
  update: `/api/doc-type/update`,
};

export const STRUCTURE = [
  {
    label: "Document Type",
    filterLabel: "Document Type",
    filterName: "name",
    // description: "Filter by Status",
    type: "text",
    // icon:"mdi mdi-form-select font-size-16 ",

  },
  {
    label: "Business Documents",
    filterName: "forBusiness",
    value: "Value",
    filterLabel: "Business Documents",
    type: "checkBox",
  },
];
