import React, { useContext, useState } from "react";
import { Button, Modal, ModalBody, ModalHeader, Table } from "reactstrap";
import { I18nContext } from "../../../../store/context/i18nContext";
import { doGET } from "../../../../util/HttpUtil";
import moment from "moment";
import { ImDatabase } from "react-icons/im";

const AuditLogDialog = ({ module, id }) => {
  const { t } = useContext(I18nContext);

  const [modal, setModal] = useState(false);
  const [auditData, setAuditData] = useState(null);

  const toggleModal = () => {
    setModal(!modal);
  };

  const fetchAuditLogData = async () => {
    try {
      const response = await doGET(`/api/audit/logs/${module}/${id}/grid`);
      const data = await response.data;
      setAuditData(data);
    } catch (error) {
      console.error("Error fetching audit log data:", error);
    }
  };

  const handleAuditButtonClick = () => {
    fetchAuditLogData();
    toggleModal();
  };

  return (
    <>
      <AuditLogToggleButton
        t={t}
        handleAuditButtonClick={handleAuditButtonClick}
      />
      <Modal isOpen={modal} toggle={toggleModal} style={{ minWidth: '700px' }}>
        <ModalHeader toggle={toggleModal}>
          <div>Change Log</div>
        </ModalHeader>
        <ModalBody>
          {auditData && (
            <Table bordered responsive>
              <thead>
                <tr>
                  {/* <th style={{ textAlign: 'center' }}>By</th>
                  <th style={{ textAlign: 'center', minWidth: '120px' }}>Field</th>
                  <th style={{ textAlign: 'center' }}>Old</th>
                  <th style={{ textAlign: 'center' }}>Modified</th> */}
                  <th style={{ minWidth: '140px' }}>By</th>
                  <th >Field</th>
                  <th >Old</th>
                  <th >Modified</th>
                </tr>
              </thead>
              <tbody>
                {auditData.map((log, index) => (
                  log.changes.map((change, changeIndex) => (
                    <tr key={`${index}-${changeIndex}`}>
                      {changeIndex === 0 && (
                        <td rowSpan={log.changes.length}>
                          <div className="d-flex flex-column">
                            <div>{log.by}</div>
                            <div>{moment.unix(log.at).format('DD-MM-YYYY, hh:mm A')}</div>
                          </div>
                        </td>
                      )}
                      <td>{change.field}</td>
                      <td>
                        {module === "Trip" && change.field === "passengers" ? (
                          <>
                            {change.old &&
                              JSON?.parse(change.old).map((oldDetail, i) => (
                                <>
                                  {(oldDetail?.name || oldDetail?.phone?.phone || oldDetail?.email) && (
                                    <div key={i}>
                                      {oldDetail?.salutation} {oldDetail?.name}{" "}
                                      {oldDetail?.phone?.phone} {oldDetail?.email}
                                    </div>
                                  )}
                                </>
                              ))}
                          </>
                        ) : (
                          <div>{change.old}</div>
                        )}
                      </td>
                      <td>
                        {module === "Trip" && change.field === "passengers" ? (
                          <>
                            {change.modified &&
                              JSON?.parse(change.modified).map((modifiedDetail, i) => (
                                <>
                                  {(modifiedDetail?.name || modifiedDetail?.phone?.phone || modifiedDetail?.email) && (
                                    <div key={i}>
                                      {modifiedDetail?.salutation} {modifiedDetail?.name}{" "}
                                      {modifiedDetail?.phone?.phone} {modifiedDetail?.email}
                                    </div>
                                  )}
                                </>
                              ))}
                          </>
                        ) : (
                          <div>{change.modified}</div>
                        )}
                      </td>
                    </tr>
                  ))
                ))}

              </tbody>
            </Table>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

const AuditLogToggleButton = ({ t, handleAuditButtonClick }) => {
  return (
    <div className="d-flex justify-content-end my-2">
      <button
        onClick={handleAuditButtonClick}
        className="mx-2"
        type="button"
        class="btn btn-outline-info"
        style={{ height: "28px", padding: "2px 4px 2px 4px" }}
      >
        <div
          className="d-flex justify-content-center"
          style={{ margin: "2px" }}
        >
          <ImDatabase style={{ marginRight: '7px', marginTop: '2px' }} />{t(`Audit Log`)}
        </div>
      </button>
    </div>
  );
};

export default AuditLogDialog;
