import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import {
    InputField,
    SelectField,
} from "../../components";
import { FilterModalButton } from "./FilterModalButton";
import "./style.css";

export default function CyclosFilter({ value = {}, structure, applyFunc = () => { }, className }) {
    const [enableFilter, setEnableFilter] = useState(false);
    const [modal, setModal] = useState(false);
    const location = useLocation();
    const navigation = useNavigate();
    const [openMenu, setOpenMenu] = useState(false);
    const [valueViaProps, setValueViaProps] = useState(true);
    const [filterSearch, setFilterSearch] = useState("");
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [fieldStatus, setFieldStatus] = useState(() => {
        let obj = {};
        structure.forEach((s) => {
            obj = { ...obj, [s?.filterName]: false };
        });
        return obj;
    });

    useEffect(() => {
        const reSize = () => {
            let s = window.innerWidth;
            setWindowWidth(s);
        }
        window.addEventListener('resize', reSize);
    }, [])

    const [filterValues, setFilterValues] = useState({});

    const addQueryParam = () => {
        // { ===== this function applies query params on the url for the applied filters ======= }
        const { pathname } = location;
        const queryParams = new URLSearchParams(location?.search);
        let isFilterApplied = false
        Object?.keys(filterValues)?.map(filterKey => {
            if ((filterValues[filterKey] || filterValues[filterKey] === 0) && (filterKey !== 'createdAtlt' && filterKey !== 'createdAtgt')) {
                isFilterApplied = true
                queryParams.set(filterKey, filterValues[filterKey]);
            }
            else {
                if (queryParams.has(filterKey)) {
                    queryParams.delete(filterKey);
                }
            }
        })
        if (isFilterApplied) { setValueViaProps(false) }
        navigation({
            pathname,
            search: `?${queryParams.toString()}`,
            replace: true,
        });

    };

    useEffect(() => {
        const filterObj = {};
        const fieldStatusObject = {};

        const selectedFilters = structure.reduce((acc, filter) => {
            if (value && value[filter?.filterName]) {
                acc.push(filter);
            }
            fieldStatusObject[filter?.filterName] = (value && value[filter?.filterName]) ? true : false;

            if (filter?.type === "phoneNo") {
                filterObj[filter?.filterName + "CC"] = "";
                filterObj[filter?.filterName + "PN"] = "";
            } else {
                filterObj[filter?.filterName] = (value && value[filter?.filterName]) ?? "";
            }

            return acc;
        }, []);
        setFieldStatus(fieldStatusObject);
        setSelectedFilters(selectedFilters);
        setFilterValues(filterObj);
    }, [JSON?.stringify(value)]);



    const applyClientFilter = async () => {
        addQueryParam()
        if (!enableFilter) {
            setEnableFilter(true);
            // return;
        }
        let url = "", andApplied = false;
        let startTime = '', endTime = ''
        for (let currentFilter of selectedFilters) {
            if (currentFilter?.filterType === "greaterThan") {
                andApplied = true;
                startTime = filterValues['createdAtgt']
            }

            if (currentFilter?.filterType === "lessThan") {
                andApplied = true;
                endTime = filterValues['createdAtlt']
            }
        }

        if (startTime && endTime) {
            url += `createdAt=btw[${startTime},${endTime}]`

        } else if (startTime) {
            url += `createdAt=gt[${startTime}]`
        } else if (endTime) {
            url += `createdAt=lt[${endTime}]`
        }
        if (!selectedFilters?.filterType && !andApplied) {
            for (const fv in filterValues) {
                if (filterValues[fv] || filterValues[fv] === 0) {
                    if (!andApplied) andApplied = true;
                    else url += "&";
                    url += fv + "=" + filterValues[fv];
                }
            }
        }
        applyFunc(url);
    };

    const searchButtonRef = useRef(null);

    useEffect(() => {
        searchButtonRef?.current?.addEventListener('keypress', handleClick);
        return () => {
            searchButtonRef?.current?.removeEventListener('keypress', handleClick);
        };
    }, []);

    const handleClick = (event) => {
        if (event?.key === 'Enter') {
            console.log('Clicked!');
            applyClientFilter()
            // document.getElementById('searchButtonId')?.click()
        }
    };

    const setFilterValuesFunc = (field, v) => {
        setFilterValues((filterValues) => {
            return { ...filterValues, [field]: v };
        });
    };

    const filterChoosed = (s) => {
        setOpenMenu(false);
        setFilterSearch("");
        setSelectedFilters([...selectedFilters, s]);
        setFieldStatus({ ...fieldStatus, [s?.filterName]: true });
    };

    const filterChanged = (s) => {
        if (s?.isOnClearFunc) s?.onClear(setFilterValuesFunc);
        setFilterValuesFunc(s?.filterName, "");
        setSelectedFilters(
            selectedFilters?.filter((sf) => s?.filterName !== sf.filterName)
        );
        setFieldStatus({ ...fieldStatus, [s?.filterName]: false });
    };
    function convertDate(inputDate) {
        const year = Math.floor(inputDate / 10000);
        const month = Math.floor((inputDate % 10000) / 100);
        const day = inputDate % 100;

        const formattedDate = `${day?.toString().padStart(2, '0')}-${month.toString().padStart(2, '0')}-${year}`;
        return formattedDate;
    }
    if (!value) return null;

    return (
        <React.Fragment>
            {windowWidth < 640 ? (
                <div className="d-flex flex-1 justify-content-end">
                    <FilterModalButton
                        onClick={() => {
                            setModal(true);
                        }}
                        filterLength={selectedFilters?.length}
                    />
                </div>
            ) : null}
            <div
                className={`${modal
                    ? "__backdrop"
                    : `${windowWidth < 640
                        ? "d-none"
                        : ""
                    }`
                    }`}
            >
                <div style={{ position: "relative" }} className={`${modal ? "__modal-filter" : ""}`}>
                    <div style={{ position: "relative", fontWeight: "300" }} className={className}>
                        <div className="d-flex justify-content-end">
                            <div>
                                {(selectedFilters?.length > 0 || (valueViaProps && value && Object.keys(value).length > 0 && selectedFilters?.length > 0)) && (
                                    <div
                                        className={`rounded-top rounded-bottom d-flex flex-wrap  w-sm-100 ps-1 pe-5 col-12 `}
                                        id="popup"
                                        style={{ backgroundColor: `${"#ebebeb"} `, }}
                                    >
                                        {selectedFilters?.map((s) => {
                                            switch (s?.type) {
                                                case "select":
                                                    return (
                                                        <div className="filterField_parent">
                                                            <SelectField
                                                                inline
                                                                value={filterValues[s?.filterName]}
                                                                onChange={(v) =>
                                                                    s?.onChange
                                                                        ? s?.onChange(v, setFilterValuesFunc)
                                                                        : setFilterValuesFunc(s?.filterName, v.value)
                                                                }
                                                                isDisabled={
                                                                    s?.isDisabled
                                                                        ? s?.isDisabled(filterValues)
                                                                        : false
                                                                }
                                                                label={s?.label}
                                                                data={
                                                                    s?.isDataFunc ? s?.data(filterValues) : s?.data
                                                                }
                                                                shouldFocus
                                                                className={`filterField pt-0`}
                                                            />
                                                            <i
                                                                onClick={() => filterChanged(s)}
                                                                className="dripicons-cross "
                                                                id="buttonCloseFilter"
                                                                style={{ height: "19.5px", marginTop: "4.5px", cursor: "pointer" }}
                                                            ></i>
                                                        </div>
                                                    );

                                                case "text":
                                                    return (
                                                        <div className="filterField_input">
                                                            <InputField
                                                                value={filterValues[s?.filterName]}
                                                                onChange={(v) =>
                                                                    setFilterValuesFunc(s?.filterName, v)
                                                                }
                                                                placeholder={s?.placeholder}
                                                                label={s?.label}
                                                                showClear={false}
                                                                autoFocus
                                                                inline
                                                                type="text"
                                                                className={`py-1 m-0`}
                                                                onClickRight={() => filterChanged(s)}
                                                            />
                                                            <i
                                                                onClick={() => filterChanged(s)}
                                                                className="dripicons-cross "
                                                                id="buttonCloseFilter"
                                                                style={{ height: "19.5px", marginTop: "4.5px", cursor: "pointer" }}
                                                            ></i>
                                                        </div>
                                                    );

                                                default:
                                                    return null;
                                            }
                                        })}
                                        <div style={{ height: "30px", position: "relative" }}>
                                            {selectedFilters?.length == structure?.length ? null :

                                                <InputField
                                                    // style={{ }}
                                                    outerClassName={"ps-0"}
                                                    value={filterSearch}
                                                    onChange={(v) => setFilterSearch(v)}
                                                    onClick={() => setOpenMenu(!openMenu)}
                                                    onBlur={() => setTimeout(() => setOpenMenu(false), 250)}
                                                    showClear={false}
                                                    type="text"
                                                    plain
                                                    // autoFocus
                                                    onKeyDown={() => {
                                                        console.log("1153")
                                                    }}
                                                    style={{ height: "24px", margin: "2px 0px 0px 0px", border: "none", backgroundColor: "#EFEFEF44" }}
                                                    className={`m-0 p-0`}
                                                />}
                                            <div>
                                                {(openMenu && selectedFilters?.length != 0) && (
                                                    <div
                                                        className={`shadow flex-wrap rounded-bottom  px-0 mx-0 filter-open-menu-selected`}
                                                    >
                                                        {structure
                                                            .filter(
                                                                (s) =>
                                                                    s?.filterName.toLowerCase().includes(filterSearch.toLowerCase()) ||
                                                                    s?.filterLabel.toLowerCase().includes(filterSearch.toLowerCase()) ||
                                                                    (filterSearch && s?.type === "text")
                                                            )
                                                            .map(
                                                                (s) =>
                                                                    !fieldStatus[s?.filterName] && (
                                                                        <div
                                                                            role="button"
                                                                            onClick={() => {
                                                                                if (s?.type === "checkBox") {
                                                                                    s?.url ? setFilterValuesFunc(s?.filterName, s?.url) : setFilterValuesFunc(s?.filterName, "true");
                                                                                }
                                                                                if (s?.type === "text")
                                                                                    setFilterValuesFunc(
                                                                                        s?.filterName,
                                                                                        filterSearch
                                                                                    );
                                                                                filterChoosed(s);
                                                                            }}
                                                                            className="d-flex flex-wrap justify-content-start align-items-center col-sm-12 p-0 py-1 my-0 px-3 ps-2 mx-0 mouseHoverFilter"
                                                                        >

                                                                            <div> {s?.iconComponent ? <div className="font-size-14 ms-0 me-1">{s?.iconComponent}</div> : <i className={`font-size-14 ${s?.icon ? s?.icon : " mdi mdi-star-four-points-outline "} ms-0 me-1 mt-1 `}></i>}</div>
                                                                            <div className="me-1 font-size-12">  {s?.filterLabel} </div>
                                                                            <div>
                                                                                {s?.filterType === "lessThan" && <span className="lessthan-icon">{"<"} </span>}
                                                                                {s?.filterType === "greaterThan" && <span className="greaterthan-icon">{">"} </span>}
                                                                                {s?.filterType === "equals" && <span className="equals-icon">{"="} </span>}
                                                                                {s?.filterType === "notEquals" && <span style={{ fontStyle: 'normal' }} className="notequals-icon">{"! ="} </span>}
                                                                            </div>
                                                                            <div className="desc me-1 mt-1 font-size-9" >
                                                                                {s?.type === "text" && filterSearch
                                                                                    ? filterSearch
                                                                                    : s?.description}
                                                                            </div>
                                                                        </div>
                                                                    )
                                                            )}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div
                                style={{ position: selectedFilters?.length == 0 ? "relative" : "static", fontWeight: "300" }}>
                                {(selectedFilters?.length === 0 && (Object.keys(value).length === 0 || !valueViaProps || selectedFilters?.length === 0)) && (
                                    <div
                                        className={`rounded-top ${openMenu ? " shadow " : " rounded-bottom "} d-flex flex-wrap  p-0 col-12  `}
                                        id="popup"
                                        style={{
                                            paddingBottom: ` ${selectedFilters?.length ? "  " : " 2px "
                                                }`,
                                            height: "30px",
                                            backgroundColor: `${openMenu ? "white" : ""} `,
                                            borderLeft: "1px solid #ced4da ",
                                            borderRight: "1px solid #ced4da ",
                                            borderTop: "1px solid #ced4da ",
                                            borderBottom: `${openMenu ? "none" : "1px solid #ced4da"}`,
                                        }}
                                    >
                                        <InputField
                                            value={filterSearch}
                                            onChange={(v) => setFilterSearch(v)}
                                            onFocus={() => setOpenMenu(true)}
                                            onBlur={() => setTimeout(() => setOpenMenu(false), 250)}
                                            showClear={false}
                                            plain
                                            onKeyDown={() => {
                                                console.log("1237")
                                            }}
                                            type="text"
                                            // autoFocus
                                            outerClassName={"ps-0"}
                                            style={{ height: "24px", marginTop: "2px", border: "none" }}
                                            className={`m-0 `}
                                        />
                                    </div>
                                )}

                                <div>
                                    {(openMenu && selectedFilters?.length === 0) && (
                                        <div
                                            // style={{border : "12px solid red"}}
                                            className={`shadow flex-wrap rounded-bottom  px-0 mx-0 filter-open-menu`}
                                        >
                                            {structure
                                                .filter(
                                                    (s) =>
                                                        s?.filterName.toLowerCase().includes(filterSearch.toLowerCase()) ||
                                                        s?.filterLabel.toLowerCase().includes(filterSearch.toLowerCase()) ||
                                                        (filterSearch && s?.type === "text")
                                                )
                                                .map(
                                                    (s) =>
                                                        !fieldStatus[s?.filterName] && (
                                                            <div
                                                                role="button"
                                                                onClick={() => {
                                                                    if (s?.type === "checkBox") {
                                                                        s?.url ? setFilterValuesFunc(s?.filterName, s?.url) : setFilterValuesFunc(s?.filterName, "true");
                                                                    }
                                                                    if (s?.type === "text")
                                                                        setFilterValuesFunc(
                                                                            s?.filterName,
                                                                            filterSearch
                                                                        );
                                                                    filterChoosed(s);
                                                                }}
                                                                className="d-flex flex-wrap  justify-content-start align-items-center col-sm-12 p-0 py-1 my-0 px-3 ps-2 mx-0 mouseHoverFilter"
                                                            >
                                                                <div> {s?.iconComponent ? <div className="font-size-14 ms-0 me-1">{s?.iconComponent}</div> : <i className={`font-size-14 ${s?.icon ? s?.icon : " mdi mdi-star-four-points-outline "} ms-0 me-1 mt-1 `}></i>}</div>
                                                                <div className="me-1 font-size-12">  {s?.filterLabel} </div>

                                                                <div>
                                                                    {s?.filterType === "lessThan" && <i className="lessthan-icon">{"<"} </i>}
                                                                    {s?.filterType === "greaterThan" && <i className="greaterthan-icon">{">"} </i>}
                                                                    {s?.filterType === "equals" && <i className="equals-icon">{"="} </i>}
                                                                    {s?.filterType === "notEquals" && <i className="notequals-icon">{"!="} </i>}
                                                                </div>
                                                                <div className="desc me-1 mt-1  font-size-9" >
                                                                    {s?.type === "text" && filterSearch
                                                                        ? filterSearch
                                                                        : s?.description}
                                                                </div>
                                                            </div>
                                                        )
                                                )}
                                        </div>
                                    )}
                                </div>
                            </div>
                            {
                                <Button
                                    id="searchButtonId"
                                    color="primary"
                                    onClick={(e) => { e.preventDefault(); applyClientFilter(e) }}
                                    ref={searchButtonRef}
                                    style={{
                                        position: "absolute",
                                        height: "calc(max(100%,30px))",
                                        top: !enableFilter ? "0px" : "unset",
                                        bottom: "0%",
                                        right: "0%",
                                        marginTop: !enableFilter ? "0px" : " 0px",
                                        padding: "0px",
                                    }}

                                >
                                    <i
                                        style={{ fontSize: "21px", margin: "3px" }}
                                        className="bx bx-search-alt-2"
                                    />
                                </Button>
                            }
                        </div>
                    </div>
                    {modal ? (
                        <div
                            style={{}}
                            className=" waves-effect waves-light __modal-close-btn "
                            onClick={() => { setModal(false); }}
                        >
                            <i style={{ fontSize: "25px" }} className="bx bx-x" />
                        </div>
                    ) : null}
                </div>
            </div>
        </React.Fragment >
    );
}