import React, { useEffect, useRef, useCallback, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { Button, Input, FormGroup, Label } from 'reactstrap';
import "./style.css"

function SignPad({ asLabel, value = '', style = {}, onChange = () => { } }) {
    const sigCanvas = useRef(null);
    const [uploadedSignature, setUploadedSignature] = useState('');

    const clear = () => {
        if (sigCanvas.current) {
            sigCanvas.current.clear();
            onChange('');
        }
        setUploadedSignature('');
    };

    const updateCanvas = useCallback(() => {
        if (value && sigCanvas.current && sigCanvas.current.isEmpty()) {
            sigCanvas.current.fromDataURL(value);
        }
    }, [value]);

    useEffect(() => {
        updateCanvas();
    }, [updateCanvas]);

    const handleEnd = () => {
        if (!asLabel && sigCanvas.current && !sigCanvas.current.isEmpty()) {
            const dataUrl = sigCanvas.current.toDataURL('image/png');
            onChange(dataUrl);
        }
    };

    const handleUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        clear()
        reader.onloadend = () => {
            setUploadedSignature(reader.result);
            onChange(reader.result);
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    };


    return (
        <div className='signPad'>
            <div className="bg-white rounded-2 mb-2 inner-sign-pad">
                <SignatureCanvas
                    penColor={asLabel ? "transparent" : "black"}
                    ref={sigCanvas}
                    canvasProps={{
                        className: 'sigCanvas canvas',
                        backgroundColor: '#FFF',
                        ...style
                    }}
                    onEnd={handleEnd}
                />
            </div>
            {!asLabel ? <FormGroup className="my-2">
                <Label for="uploadSign">Upload Signature</Label>
                <Input type="file" id="uploadSign" accept="image/*" onChange={handleUpload} />
            </FormGroup> : null}
            {!asLabel ? (
                <div className="d-flex">
                    <Button outline className="w-100 my-2 font-size-14 py-1" onClick={clear}>
                        Clear
                    </Button>

                </div>
            ) : null}

        </div>
    );
}

export default SignPad;
