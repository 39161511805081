import Filter from "../pages/Filter/Filter";
import AddButton from "./Buttons/AddButton";
import MenuButton from "./Buttons/MenuButton";
import BackArrow from "./Buttons/BackArrow";
import BackButton from "./Buttons/BackButton";
import CrossButton from "./Buttons/CrossButton";
import DownloadButton from "./Buttons/DownloadButton";
import DeleteLink from "./Buttons/Icons/deleteButton";

import CopyLink from "./Buttons/Icons/copyButton";
import GeneralLink from "./Buttons/Icons/generalButton";
import CheckButton from "./Buttons/Icons/checkButton";
import EditLink from "./Buttons/Icons/editButton";
import RetryLink from "./Buttons/Icons/retryButton";
import DetailLink from "./Buttons/Icons/detailButton";
import PrintLink from "./Buttons/Icons/printButton";
import MailLink from "./Buttons/Icons/mailButton";
import DownloadLink from "./Buttons/Icons/downloadButton";
import UploadLink from "./Buttons/Icons/uploadButton";
import SaveButton from "./Buttons/SaveButton";
import Breadcrumbs from "./Common/Breadcrumb";
import Pagination from "./Common/Pagination";
import Title from "./Common/Title";
import CitySelect from "./CSC/CitySelect/CitySelect";
import CountrySelect from "./CSC/CountrySelect/CountrySelect";
import StateSelect from "./CSC/StateSelect/StateSelect";
import DateSelect from "./DateSelects/DateSelect";
import DateTimeSelect from "./DateSelects/DateTimeSelect";
import TimeSelect from "./DateSelects/TimeSelect";
import DateRange from "./DateSelects/DateRange";
import RoleSelect from "./Dropdowns/RoleSelect/RoleSelect";
import LanguageSelect from "./Dropdowns/LanguageSelect/LanguageSelect";
import CountryLanguageSelect from "./Dropdowns/CountryLangSelect/CountryLangSelect";
import TimeZoneSelect from "./Dropdowns/TimeZoneSelect/TimeZoneSelect";

// import CurrencySelect from "./Dropdowns/tempCurrencySelect/CurrencySelect";
import CurrencySelect from "./Dropdowns/CurrencySelect/CurrencySelect";

import EditorField from "./EditorField/EditorField";
import { InputField } from "./InputField/InputField";
import { SearchInput } from "./InputField/SearchInput";
import CardThreeBounce from "./Loader/CardThreeBounce";
import Accordion from "./Common/Accordion";
import CalenderLink from "./Buttons/Icons/calenderButton";
import TariffNameSelect from "./Dropdowns/TariffNameSelect/TariffNameSelect";
import Checkbox from "../pages/Offers/checkbox";
import TransferGroupSelect from "./Dropdowns/TransferGroupSelect/TransferGroupSelect";
import { SelectField } from "./SelectField/SelectField";
import { SelectFieldMultiple } from "./SelectField/SelectFieldMultiple";
import { SelectFieldAdd } from "./SelectField/SelectFieldAdd";
import DynamicSelectField from "./SelectField/DynamicSelectField";
import PhoneNum from "./PhoneNum/PhoneNum";
import Modal from "./Common/Modal";
import ModalComponent from "./Modal/Modal";
import Layout from "./Layout/Layout";
import Tab from "./Common/Tab/Tab";
import FileUpload from "./FileUpload/FileUpload";
import FileUploadViewer from "./FileUpload/FileUploadViewer";
import DeleteButton from "./Buttons/DeleteButton";
import PassengerField from "./Passenger/PassengerField";
import AddressField from "./AddressField/AddressField";
import Breadcrumb from "./Breadcrumb/Breadcrumb";
// import StatusSelect from "../components/Dropdowns/StatusSelect/StatusSelect";
import FormatSelect from "./Dropdowns/PrintFormatSelect";
import CampusSelect from "./Dropdowns/CampusSelect/CampusSelect";
// import WorkShiftSelect from "./Dropdowns/WorkshiftSelect/WorkShiftSelect";
import CustomFieldDisplayTypeSelect from "./Dropdowns/CustomFieldDisplayTypeSelect/CustomFieldDisplayTypeSelect";
import CustomFieldKindSelect from "./Dropdowns/EmployeeKindSelect/EmployeKindSelect";
import CustomFieldComponent from "./CustomField/CustomFieldComponent";
import Documents from "./DocUpload/Documents";
import DataGrid from "./DataGrid/DataGrid";
import Address from "./Address";
import Image from "./Image/Image";
// import BulkUpload from "./BulkUpload/BulkUpload";
// import DownloadByTemplate from "./DownloadByTemplate/DownloadByTemplate";
import SearchButton from "./Buttons/SearchButton";
import BottomBarButton from "./Buttons/BottomBarButton";
// import TemplateSelect from "./Dropdowns/TemplateSelect/TemplateSelect";
// import OfficeSelect from "./Dropdowns/Office/OfficeSelect";

import TagSelector from "./TagSelector/TagSelector";
// import CurrencyAmountField from "./AmountField/CurrencyAmountField";
// import QuantityAmountField from "./AmountField/QuantityAmountField";
// import TaxInputField from "./AmountField/TaxInputField";
import ToggleSelector from "./Toggle/ToggleSelector";
import AutoSuggestField from "./Dropdowns/AutoSuggestField/AutoSuggestField";
import StatusSelect from "./Dropdowns/Status_Select/StatusSelect";
import NameField from "./InputGroups/NameField";
import SelectOption from "./SelectOption/SelectOption";
import SalutationSelect from "./Dropdowns/Salutation/SalutationSelect"

const handleEpandRows = (event, bookingId, expandedRows) => {
  const currentExpandedRows = expandedRows;
  const isRowExpanded = currentExpandedRows.includes(bookingId);
  let obj = {};
  isRowExpanded ? (obj[bookingId] = false) : (obj[bookingId] = true);
  const newExpandedRows = isRowExpanded
    ? currentExpandedRows.filter((id) => id !== bookingId)
    : currentExpandedRows.concat(bookingId);
  return newExpandedRows;
};
function generateRandomString(length) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }
  return result;
}

const yyyy_mm_ddtoTimeStamp = (a) => {
  if (a) {
    a = a.toString();
    const date = new Date(a);
    const unixTimestamp = Math.floor(date.getTime() / 1000);
    return unixTimestamp;
  }
};
const yyyymmddtoTimeStamp = (a) => {
  if (a) {
    a = a.toString();
    const f =
      a?.substring(0, 4) + "-" + a?.substring(4, 6) + "-" + a?.substring(6, 8);
    const date = new Date(f);
    const unixTimestamp = Math.floor(date.getTime() / 1000);
    return unixTimestamp;
  }
};

const scrollStyle = {
  minHeight: "85vh",
  // zIndex: "2",
  maxHeight: "85vh",
  // overflowY: "auto",
  // position: "fixed",
};

const itemBarSelectedStyle = {
  backgroundColor: "rgba(159, 115, 169, 0.459)",
  borderLeft: " 3px solid #9f73a9",
  color: "#000",
  fontWeight: 600,
};

const changeValueOfidx = (arr, idx, key, value) => {
  arr[idx] = { ...arr[idx], [key]: value };
  return arr;
};

const swapToIndex = (arr, idx, key) => {
  const a = [...arr];
  var b = a[key];
  a[key] = a[idx];
  a[idx] = b;
  return a;
};

const removeitemOnce = (a, idx) => {
  if (idx > -1) {
    a.splice(idx, 1);
  }
  return a;
};

function decodePolyline(encodedString) {
  if (!encodedString) {
    return null;
  }

  let time = null;
  if (encodedString.startsWith("AAA")) {
    time = parseInt(encodedString.substring(3, encodedString.indexOf("===")));
    encodedString = encodedString.substring(encodedString.indexOf("===") + 3);
  }

  let hasSpeed = false;
  if (encodedString.startsWith("AAB")) {
    time = parseInt(encodedString.substring(3, encodedString.indexOf("===")));
    encodedString = encodedString.substring(encodedString.indexOf("===") + 3);
    hasSpeed = true;
  }

  let index = 0;
  const len = encodedString.length;
  let lat = 0,
    lng = 0;
  let tym = 0,
    speed = 0;
  const polyline = [];

  while (index < len) {
    let b, shift, result;
    shift = result = 0;
    do {
      b = encodedString.charCodeAt(index++) - 63;
      result |= (b & 0x1f) << shift;
      shift += 5;
    } while (b >= 0x20);
    const dlat = (result & 1) !== 0 ? ~(result >> 1) : result >> 1;
    lat += dlat;

    shift = result = 0;
    do {
      b = encodedString.charCodeAt(index++) - 63;
      result |= (b & 0x1f) << shift;
      shift += 5;
    } while (b >= 0x20);
    const dlng = (result & 1) !== 0 ? ~(result >> 1) : result >> 1;
    lng += dlng;

    if (time !== null) {
      shift = result = 0;
      do {
        b = encodedString.charCodeAt(index++) - 63;
        result |= (b & 0x1f) << shift;
        shift += 5;
      } while (b >= 0x20);
      const dtym = (result & 1) !== 0 ? ~(result >> 1) : result >> 1;
      tym = dtym;
    }

    if (hasSpeed) {
      shift = result = 0;
      do {
        b = encodedString.charCodeAt(index++) - 63;
        result |= (b & 0x1f) << shift;
        shift += 5;
      } while (b >= 0x20);
      const dspeed = (result & 1) !== 0 ? ~(result >> 1) : result >> 1;
      speed = dspeed;
    }

    const p = {
      lat: lat / 100000,
      lng: lng / 100000,
    };

    if (time !== null) {
      p.time = time + tym;
    }

    if (speed !== -1) {
      p.speed = speed;
    }

    polyline.push(p);
  }

  return polyline;
}

const yyyy_mm_ddThh_mmToSeconds = (dateTime) => {
  if (!dateTime) return;
  var parts = dateTime.split("T");
  var dateParts = parts[0].split("-");
  var timeParts = parts[1].split(":");
  var year = parseInt(dateParts[0]);
  if (year < 1970) return;
  var month = parseInt(dateParts[1]) - 1;
  var day = parseInt(dateParts[2]);
  var hours = parseInt(timeParts[0]);
  var minutes = parseInt(timeParts[1]);
  var dateObject = new Date(year, month, day, hours, minutes);
  return dateObject.getTime() / 1000;
};

const secondsToyyyy_mm_ddThh_mm = (seconds) => {
  if (!seconds) return;
  const date = new Date(seconds * 1000);
  var month = (date.getMonth() + 1)?.toString()?.padStart(2, "0");
  var day = date.getDate()?.toString()?.padStart(2, "0");
  var hour = date?.getHours()?.toString()?.padStart(2, "0");
  var minutes = date?.getMinutes()?.toString()?.padStart(2, "0");
  return (
    date.getFullYear() + "-" + month + "-" + day + " - " + hour + ":" + minutes
  );
};

const yyyy_mm_ddThh_mmToTimeStamp = (dateTime) => {
  if (!dateTime) return;
  const parts = dateTime.split("T");
  const dateParts = parts[0].split("-");
  const timeParts = parts[1].split(":");
  if (dateParts[0] < 1970) return;
  const dateObject = new Date(
    parseInt(dateParts[0]),
    parseInt(dateParts[1]) - 1,
    parseInt(dateParts[2]),
    parseInt(timeParts[0]),
    parseInt(timeParts[1])
  );
  return dateObject.getTime();
};

const timestampToyyyy_mm_ddThh_mm = (timestamp) => {
  if (!timestamp) return;
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hour = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  return day + "-" + month + "-" + year + " " + hour + ":" + minutes;
};

const timeStamptoyyyy_mm_dd = (a) => {
  if (a) {
    const date = new Date(a * 1000);
    const dd_mm_yyyy = date.toLocaleDateString();

    const yyyy_mm_dd = dd_mm_yyyy
      .replace(/(\d+)\/(\d+)\/(\d+)/g, "$3-$2-$1")
      .toString();
    let f = yyyy_mm_dd?.substring(0, 4);
    const one = yyyy_mm_dd.indexOf("-");
    const two = yyyy_mm_dd.lastIndexOf("-");
    f +=
      "-" +
      (two - one <= 2
        ? "0" + yyyy_mm_dd?.substring(one + 1, one + 2)
        : yyyy_mm_dd?.substring(one + 1, two)) +
      ("-" +
        ((yyyy_mm_dd.length - two <= 2 ? "0" : "") +
          yyyy_mm_dd?.substring(two + 1)));

    return f;
  }
};
//wrong toISOString converts to gmt string we need local string.
const timeStampToyyyy_mm_ddThhmm = (a) => {
  if (a) {
    return `${new Date(a * 1000).toISOString().substring(0, 16)}`;
  }
};

const yyyy_mm_ddThhmmtoTimeStamp = (a) => {
  if (a) {
    return new Date(a).getTime() / 1000;
  }
};

const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

const getDateFromSeconds = (v) => {
  return v ? new Date(parseInt(v) * 1000).toISOString().split("T")[0] : "";
};

const getSecondsFromDate = (v) => {
  const s = new Date(v);
  return parseInt(s.getTime());
};

function toggleFullscreen() {
  if (
    !document.fullscreenElement &&
    /* alternative standard method */ !document.mozFullScreenElement &&
    !document.webkitFullscreenElement
  ) {
    // current working methods
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    } else if (document.documentElement.mozRequestFullScreen) {
      document.documentElement.mozRequestFullScreen();
    } else if (document.documentElement.webkitRequestFullscreen) {
      document.documentElement.webkitRequestFullscreen(
        Element.ALLOW_KEYBOARD_INPUT
      );
    }
  } else {
    if (document.cancelFullScreen) {
      document.cancelFullScreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitCancelFullScreen) {
      document.webkitCancelFullScreen();
    }
  }
}

const reverse_date_str = (date) => {
  if (date) {
    const yyyy = date.slice(0, 4);
    const mm = date.slice(5, 7);
    const dd = date.slice(8, 10);
    return dd + "-" + mm + "-" + yyyy;
  } else return "";
};

const timeStamptoyyyymmdd = (a) => {
  if (a) {
    const date = new Date(a * 1000);
    const dd_mm_yyyy = date.toLocaleDateString();
    const yyyy_mm_dd = dd_mm_yyyy
      .replace(/(\d+)\/(\d+)\/(\d+)/g, "$3-$2-$1")
      .toString();
    let f = yyyy_mm_dd?.substring(0, 4);
    const one = yyyy_mm_dd.indexOf("-");
    const two = yyyy_mm_dd.lastIndexOf("-");
    f +=
      "" +
      ((yyyy_mm_dd.length - two <= 2 ? "0" : "") +
        yyyy_mm_dd?.substring(two + 1)) +
      "" +
      (two - one <= 2
        ? "0" + yyyy_mm_dd?.substring(one + 1, one + 2)
        : yyyy_mm_dd?.substring(one + 1, two));
    return f;
  }
};

export {
  toggleFullscreen,
  getDateFromSeconds,
  getSecondsFromDate,
  Breadcrumbs,
  Pagination,
  EditLink,
  UploadLink,
  DownloadButton,
  DeleteLink,
  PrintLink,
  DownloadLink,
  MailLink,
  AddButton,
  DeleteButton,
  MenuButton,
  SaveButton,
  CountryLanguageSelect,
  CrossButton,
  BackButton,
  DetailLink,
  CopyLink,
  RetryLink,
  Filter,
  Title,
  InputField,
  SearchInput,
  SelectField,
  EditorField,
  SelectFieldMultiple,
  SelectFieldAdd,
  CountrySelect,
  StateSelect,
  yyyymmddtoTimeStamp,
  CitySelect,
  DateRange,
  BackArrow,
  CurrencySelect,
  LanguageSelect,
  TimeZoneSelect,
  FileUpload,
  FileUploadViewer,
  CheckButton,
  // SupervisorSelect,
  // SupervisorRoleSelect,
  DateSelect,
  DateTimeSelect,
  TimeSelect,
  handleEpandRows,
  yyyy_mm_ddtoTimeStamp,
  timeStamptoyyyy_mm_dd,
  formatBytes,
  changeValueOfidx,
  swapToIndex,
  removeitemOnce,
  Accordion,
  CardThreeBounce,
  CalenderLink,
  TariffNameSelect,
  Checkbox,
  TransferGroupSelect,
  SalutationSelect,
  CustomFieldKindSelect,
  CustomFieldComponent,
  CustomFieldDisplayTypeSelect,
  PhoneNum,
  scrollStyle,
  Modal,
  itemBarSelectedStyle,
  DynamicSelectField,
  Layout,
  Tab,
  generateRandomString,
  decodePolyline,
  yyyy_mm_ddThhmmtoTimeStamp,
  timeStampToyyyy_mm_ddThhmm,
  yyyy_mm_ddThh_mmToSeconds,
  secondsToyyyy_mm_ddThh_mm,
  yyyy_mm_ddThh_mmToTimeStamp,
  timestampToyyyy_mm_ddThh_mm,
  PassengerField,
  Breadcrumb,
  AddressField,
  GeneralLink,
  reverse_date_str,
  FormatSelect,
  timeStamptoyyyymmdd,
  // DesignationSelect,
  // RankSelect,
  Documents,
  DataGrid,
  Address,
  Image,
  SearchButton,
  BottomBarButton,
  ToggleSelector,
  TagSelector,
  AutoSuggestField,
  ModalComponent,
  StatusSelect,
  RoleSelect,
  NameField,
  SelectOption,
};
