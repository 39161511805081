import React from 'react'
import Logo from "../../../../../assets/images/Logo.svg";
import NextButton from '../../../../../components/Buttons/NextButton';

const AskMoreMessage = ({ remark, data, loading, onNext = () => { } }) => {
    return (
        <div style={{ height: "100vh", margin: "0px 30px" }} className={'d-flex justify-content-center align-items-center'}>
            <div className={'form-content '}>

                <div className='d-flex flex-column  justify-content-center  align-items-center  '>
                    {<img
                        src={Logo}
                        alt="fv-bank"
                        height="50"
                        style={{ marginBottom: "10px" }}
                        className="logo logo-dark"
                    />}



                    {<div style={{ margin: "0px 0px 30px 0px", fontSize: "24px", fontWeight: "500" }}>Individual Plus</div>}

                    <div style={{ fontSize: "30px", lineHeight: 2 }}>
                        Hi {data?.firstName} {data?.lastName},
                    </div>
                    <div style={{ fontSize: "20px" }}>
                        To proceed with your application, we require additional information.
                    </div>
                    <div style={{ fontSize: "20px" }}>
                        Please ensure you fill out the details requested in the following step.
                    </div>

         
                    <div className='mt-2 pt-2' style={{ borderTop : "1px solid #EBEBEB", fontSize: "20px" }}>
                        {remark}
                    </div>
                    <div className='d-flex justify-content-end w-100 mt-3'>
                        <NextButton
                            loading={loading}
                            onClick={() => {
                                onNext();

                            }}
                        />

                    </div>

                </div>
            </div>
        </div>
    )
}

export default AskMoreMessage