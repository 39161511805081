import React from 'react';
import { Card, Row, Col } from 'reactstrap';
import moment from 'moment';
import './SarReportDetail.scss';

const SarReportDetail = ({ data }) => {
    const formatDate = (timestamp) => {
        return moment.unix(timestamp).format('MMMM Do YYYY, h:mm:ss a');
    };

    return (
        <Card className='px-4 py-4 sar-report-detail'>
            <h2>SAR Report Detail</h2>
            <Row className='mt-3'>
                <Col sm="2"><b>Report Name:</b></Col>
                <Col sm="10">{data?.name}</Col>
            </Row>
            <Row className='mt-3'>
                <Col sm="2"><b>Description:</b></Col>
                <Col sm="10">{data?.description}</Col>
            </Row>
            <Row className='mt-3'>
                <Col sm="2"><b>Status:</b></Col>
                <Col sm="10">
                    <span className={`status-label ${data?.status.toLowerCase()}`}>
                        {data?.status.replace(/_/g, ' ')}
                    </span>
                </Col>
            </Row>
            <Row className='mt-3'>
                <Col sm="2"><b>Filing Type:</b></Col>
                <Col sm="10">{data?.filing_type}</Col>
            </Row>
            <Row className='mt-3'>
                <Col sm="2"><b>Report Type:</b></Col>
                <Col sm="10">{data?.report_type}</Col>
            </Row>
            <Row className='mt-3'>
                <Col sm="2"><b>Created By:</b></Col>
                <Col sm="10">{data?.created_by}</Col>
            </Row>
            <Row className='mt-3'>
                <Col sm="2"><b>Created At:</b></Col>
                <Col sm="10">{formatDate(data?.created_at)}</Col>
            </Row>
            <Row className='mt-3'>
                <Col sm="2"><b>Updated At:</b></Col>
                <Col sm="10">{formatDate(data?.updated_at)}</Col>
            </Row>
            <Row className='mt-3'>
                <Col sm="2"><b>Status Updated At:</b></Col>
                <Col sm="10">{formatDate(data?.status_updated_at)}</Col>
            </Row>
            <Row className='mt-3'>
                <Col sm="2"><b>Entities:</b></Col>
                <Col sm="10">
                    {data?.entities.map((entity, index) => (
                        <div key={index}>
                            <span><b>Entity ID:</b> {entity.entity_id}</span><br />
                            <span><b>Entity Type:</b> {entity.entity_type}</span>
                        </div>
                    ))}
                </Col>
            </Row>
        </Card>
    );
};

export default SarReportDetail;
