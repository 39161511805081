import { makeAutoObservable } from "mobx";
import { doGET } from "../../util/HttpUtil";


class AddressField {

    cities = [];
    addressLines = [];
    triedCities = new Map();

    constructor() {
        makeAutoObservable(this);
    }

    fetch = async function () {
        try {
            const response = await doGET("/api/o/city/grid?country=India&rows=-1");
            if (response.status === 200) {
                this.cities = response?.data?.rows;
            } else {
                this.error = response.data;
            }
        } catch (err) {
            this.error = err;
        }
    };

    fetchAddressLines = async function ({ key, city }) {
        if (key && city && key?.length > 2) {
            try {
                const response = await doGET(`/api/o/maputil/autocomplete?key=${key}&country=India&city=${city}`);
                if (response.status === 200) {
                    if (response?.data?.length) {
                        const addresses = response?.data?.map(address => {
                            return { ...address, label: address?.addressLine, value: address?.addressLine }
                        });
                        console?.log(addresses)
                        this.addressLines = addresses

                    }
                } else {
                    this.error = response.data;
                }
            } catch (err) {
                this.error = err;
            }
        }
    };

    searchNAddCity = async function (key, country) {
        const cityKey = `${key}:${country}`;

        if (this.triedCities.get(cityKey)) {
            return { status: 400 }
        }

        try {
            const response = await doGET("/api/geocode/add-city?key=" + key + "&country=" + country);
            if (response.status === 200) {
                await this.fetch(); // Refresh the cities list if a new city was successfully added
                this.triedCities.set(cityKey, false); // Mark the city as successfully added
            } else {
                this.triedCities.set(cityKey, true); // Mark the city as tried and failed
                this.error = response.data;
            }
            return response
        } catch (err) {
            this.triedCities.set(cityKey, true); // Mark the city as tried and failed in case of any error
            this.error = err;
            return {status : 400, err}
        }
    }
}

const AutoFillService = new AddressField();
export default AutoFillService;