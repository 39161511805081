import React, { useEffect, useState } from "react";
import './AccountDetails.scss'; // Make sure to create and import the corresponding CSS file

const STATUS_QUES = {
    "kyc_status": "KYC Status",
    "Application_Status": "Application Status",
    "Overall_Risk_Rating": "Overall Risk Rating",
    "Business_Name": "Business Name",
    "Merchant_Partner_Enabled": "Merchant Partner Enabled",
    "aml_match_status": "AML Match Status",
    "Financial_Transactions_Enabled": "Financial Transactions Enabled",
    "API_Client_Secret": "API Client Secret",
    "complianceStatus": "Compliance Status",
    "aml_match_status_business": "AML Match Status Business",
    "Merchant_Partner_Referral_Code": "Merchent Partner Referral Code",
    "Metro_Account_USD": "Metro Account USD",
    "kyc_status_record": "KYC Status Record",
    "aml_status_record_business": "AML Status Record Business",
    "aml_status_record": "AML Status Record"
}


const STATUS_VALUES = {
    "not_started": "Not Started",
    "session_created": "Session Created",
    "started": "Started",
    "processing": "Processing",
    "completed": "Completed",
    "re_verification": "Re-Verification",
    "failed_session": "Failed Session",
    "pending_cross_checked": "Pending Cross-Checked",
    "rejected": "Rejected",
    "no_match|No Match": "No Match",
    "compliant|Compliant": "Compliant",
    "true": "Yes",
    "false": "No",
}

const RowComponent = ({ ques, value, isLink, onClick }) => {
    return (
        <div className='fv_detail_account-row d-flex p-2'>
            <div className='fv_detail_account-question'>
                {ques}
            </div>
            <div
                className={`fv_detail_account-value text-end ${isLink ? 'fv_detail_account-link' : ''}`}
                onClick={onClick}
            >
                {value}
            </div>
        </div>
    )
}

const RowComponentSkeleton = () => {
    return (
        <div className='fv_detail_account-row d-flex p-2'>
            <div className='fv_detail_account-skeleton-ques'>
                <div className='fv_detail_account-shimmer'></div>
            </div>
            <div className='fv_detail_account-skeleton-value'>
                <div className='fv_detail_account-shimmer'></div>
            </div>
        </div>
    );
};


const AccountDetails = ({ alertsCount, setStep, loading, data, userData, beneficiaryCount, userAddress, userProducts }) => {
    const [accountsData, setAccountsData] = useState([]);
    const [totalB, setTotalB] = useState({});

    const changeTab = (name) => {
        if (name === "USD") setStep(2);
    }



    const setAccountsBalance = () => {
        if (!data?.accounts) return;
        let total = 0;
        const balances = {};
        data.accounts.forEach((e) => {
            const availableBalance = parseFloat(e?.status?.availableBalance);
            if (!isNaN(availableBalance)) {
                total += availableBalance;
                const internalName = e?.currency?.internalName;
                const symbol = e?.currency?.symbol;
                if (internalName) {
                    if (!balances[internalName]) {
                        balances[internalName] = {
                            internalName: internalName,
                            totalBalance: 0,
                            symbol: symbol
                        };
                    }
                    balances[internalName].totalBalance += availableBalance;
                }
            }
        });
        const dta = Object.values(balances);
        setTotalB({ internalName: 'total', totalBalance: total, symbol: "$" })
        setAccountsData(dta);
    };

    const getStatusValue = (val) => {
        if (!!STATUS_VALUES[val]) return STATUS_VALUES[val]
        else return val;
    }

    useEffect(() => {
        setAccountsBalance();
    }, [data])

    if (loading) {
        return (
            <div className='fv_detail_account-container p-3 mt-2'>
                <div className='fv_detail_account-content'>
                    <div className='fv_detail_account-card'>
                        <h2 className='fv_detail_account-title'>Loading...</h2>
                        <div>
                            <RowComponentSkeleton />
                            <RowComponentSkeleton />
                            <RowComponentSkeleton />
                            <RowComponentSkeleton />
                            <br />
                            <RowComponentSkeleton />
                            <RowComponentSkeleton />
                            <RowComponentSkeleton />
                        </div>
                    </div>

                    <div className='fv_detail_account-card'>
                        <h2 className='fv_detail_account-title'>Loading...</h2>
                        <div>
                            <RowComponentSkeleton />
                            <RowComponentSkeleton />
                            <RowComponentSkeleton />
                            <RowComponentSkeleton />
                            <br />
                            <RowComponentSkeleton />
                            <RowComponentSkeleton />
                            <br />
                            <RowComponentSkeleton />
                            <RowComponentSkeleton />
                            <br />
                            <RowComponentSkeleton />
                            <RowComponentSkeleton />
                        </div>
                    </div>

                    <div className='fv_detail_account-card'>
                        <h2 className='fv_detail_account-title'>Loading...</h2>
                        <div>
                            <RowComponentSkeleton />
                            <RowComponentSkeleton />
                            <RowComponentSkeleton />
                            <RowComponentSkeleton />
                            <RowComponentSkeleton />
                        </div>
                    </div>
                </div>
                {/* <div className="fv_detail_account-footer d-flex justify-content-end pt-2">
                    <PrimaryButton className="fv_detail_account-button px-3" title={"Back"} />
                    <PrimaryButton className="fv_detail_account-button mx-3 px-3" title={"Generate CIP File"} />
                    <PrimaryButton className="fv_detail_account-button px-3" title={"Help Scout Link"} />
                </div> */}
            </div>
        );
    }

    return (
        <div className='fv_detail_account-container p-3 mt-2'>
            <div className='fv_detail_account-content'>
                <div className='fv_detail_account-card'>
                    <h2 className='fv_detail_account-title'>{data?.user?.display}</h2>
                    <div>
                        <RowComponent ques={"Contact"} value={data?.user?.display} />
                        <RowComponent ques={"Phone"} value={userData?.phone} />
                        <RowComponent ques={"Email"} value={userData?.email} />
                        <RowComponent ques={"Address"} value={userAddress} />
                        <br />
                        {Object.keys(userData?.customValues || {}).map(key => (
                            <RowComponent key={key} ques={STATUS_QUES[key]} value={getStatusValue(userData?.customValues[key])} />
                        ))}
                    </div>
                </div>

                <div className='fv_detail_account-card'>
                    <h2 className='fv_detail_account-title'>Account Number: {!!data?.accounts ? data?.accounts[0]?.number : ""}</h2>
                    <div>
                        {/* <RowComponent ques={"Portfolio Balance"} value={totalB?.symbol + "" + totalB?.totalBalance} />
                        <br /> */}
                        {accountsData?.map((e, ind) => (
                            <RowComponent
                                key={ind}
                                ques={`${e?.internalName} Balance`}
                                value={`${e?.symbol} ${e?.totalBalance}`}
                                isLink={e?.internalName === "USD"}
                                onClick={() => changeTab(e?.internalName)}
                            />
                        ))}
                        <br />
                        {/* <RowComponent ques={"Deposit Volume Past 30 Days"} value={"$345.333.00"} />
                        <RowComponent ques={"Deposit Count Past 30 Days"} value={"34"} />
                        <br />
                        <RowComponent ques={"Payment Volume Past 30 Days"} value={"$345.333.00"} />
                        <RowComponent ques={"Payment Count Past 30 Days"} value={"$345.333.00"} /> */}
                        {/* <br /> */}
                        <RowComponent ques={"Alerts in Past 30 Days"} value={alertsCount} isLink onClick={() => setStep(3)} />
                        <RowComponent ques={"Beneficiaries Count"} value={beneficiaryCount} isLink onClick={() => setStep(4)} />
                        {/* <RowComponent ques={"Deposit Originators"} value={"18"} isLink /> */}
                    </div>
                </div>

                <div className='fv_detail_account-card'>
                    <h2 className='fv_detail_account-title'>Products</h2>
                    <div>
                        {
                            userProducts?.map(el => (
                                <RowComponent key={el.id} ques={el.name} />
                            ))
                        }
                    </div>
                </div>
            </div>

        </div>
    )
}

export default AccountDetails;
