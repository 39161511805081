import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { DialogContext } from "../../../store/context/DialogContext";
import { I18nContext } from "../../../store/context/i18nContext";
import { SelectField } from "../../SelectField/SelectField";
import { SelectFieldMultiple } from "../../SelectField/SelectFieldMultiple";
import { BottomBarButton, ModalComponent, SearchButton } from "../../index";
import QuestionsSelectService from "./QuestionsSelectService";
import FormQuestions from "../../../pages/FormQuestions/FormQuestions";

const QuestionsSelect = ({
  style,
  isDisabled,
  className,
  onChange,
  formType,
  required,
  value,
  label,
  module,
  showErr,
  outline,
  inline,
  borderNone,
  multi,
  step,
  ...props

}) => {
  const [questions, setQuestions] = useState([]);
  const [modal, setModal] = useState(false);
  const { showError } = useContext(DialogContext)
  const { t } = useContext(I18nContext)

  const fetchQuestions = async () => {
    try {
      await QuestionsSelectService.fetch(formType, step);
    } catch (error) {
      showError("Error", error)
    }
  };

  useEffect(() => {
    fetchQuestions();
  }, []);

  const updateGarages = () => {
    setQuestions(
      toJS(QuestionsSelectService.questions)
        ?.map(({ options, ...garage }) => { 
          return {
            ...garage, 
            value: garage._id,
            label: garage.title
          };
        })
        .filter(Boolean) 
    );
  }

  useEffect(() => {
    updateGarages();
  }, [QuestionsSelectService.questions]);

  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  const selectGarage = (selectedValue) => {
    onChange({ value: multi ? selectedValue[0] : selectedValue });
    closeModal();
  };

  const selectGarages = (selectedValues) => {
    onChange(selectedValues.map((c) => c?.value ?? c));
  };


  const renderSelectField = ({ disabled }) => (
    <SelectField

      style={style}
      isDisabled={isDisabled || disabled}
      changeOptionsData={openModal}
      bottomBarChildren={() => (
        <BottomBarButton onClick={openModal} title={t("Manage Questions")} />
      )}
      outline={outline}
      // showBottomBar={true}
      className={className}
      onChange={onChange}
      data={questions}
      required={required}
      value={value}
      label={label || (!isDisabled && t("Questions"))}
      error={!value ? t(`Please Select ${label || "Questions"}`) : ""}
      showErr={showErr}
      inline={inline}
      borderNone={borderNone}
      RightComponent={() => (
        <SearchButton onClick={openModal} />
      )}
      {...props}

    />
  );

  const renderSelectFieldMultiple = () => (

    <SelectFieldMultiple

      isDisabled={isDisabled}
      style={style}
      bottomBarChildren={() => (
        <BottomBarButton onClick={openModal} title={t("Manage Questions")} />
      )}
      outline={outline}
      // showBottomBar={true}
      className={className}
      onChange={selectGarages}
      data={questions}
      value={value ? questions.filter((c) => value?.includes(c?.value)) : ""}
      error={!value ? t(`Please Select ${label || "Questions"}`) : ""}
      required={required}
      label={label || (!isDisabled && t("Questions"))}
      showErr={showErr}
      RightComponent={() => (
        <SearchButton onClick={openModal} />
      )}
    />
  );

  return (
    <>
      {modal && (
        <ModalComponent
          position={"top"}
          size={"large"}
          onToggle={closeModal}
          isOpen={modal}
        >
          <ModalComponent.Title>
            {t("Questions")}
          </ModalComponent.Title>

          <ModalComponent.Body>
            <FormQuestions
              value={value}
              insidePane={true}
              multiMode={multi}
              onSelect={multi ? selectGarages : selectGarage}
            />
          </ModalComponent.Body>

        </ModalComponent>
      )}
      {questions.length > 0
        ? multi
          ? renderSelectFieldMultiple()
          : renderSelectField({ disabled: false })
        : renderSelectField({ disabled: true })}
    </>
  );
};

export default observer(QuestionsSelect);
