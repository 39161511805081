import React, { useState, useEffect, useRef } from 'react';

function ScaledNumericInput({ refreshId, value = 0, onChange, className, label, required, scaleFactor = 1000, id }) {
  const [displayValue, setDisplayValue] = useState('0.00');
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef(null);
  const uniqueKey = useRef(`adjusted-input-${id || Math.random().toString(36).substr(2, 9)}`);

  useEffect(() => {
    const formattedValue = (value / scaleFactor).toFixed(2);
    setDisplayValue(formattedValue === '0.00' ? '0.00' : formattedValue);
  }, [value, scaleFactor, refreshId, uniqueKey]);

  const handleFocus = () => {
    setIsFocused(true);
    setTimeout(() => {
      inputRef.current.select(); // Select all text
    }, 0);
  };

  const handleBlur = () => {
    setIsFocused(false);
    let formattedValue = parseFloat(displayValue);
    if (!isNaN(formattedValue)) {
      formattedValue = formattedValue.toFixed(2);
    } else {
      formattedValue = '0.00';
    }
    setDisplayValue(formattedValue);
    onChange(Math.round(parseFloat(formattedValue) * scaleFactor));
  };

  const handleChange = (e) => {
    const inputValue = e.target.value;
    if (!isNaN(inputValue) || inputValue === '') {
      setDisplayValue(inputValue);
    }
  };

  const handleClick = () => {
    setIsFocused(true);
    inputRef.current.focus();
  };

  const renderIntegerPart = () => {
    if (!isFocused) {
      return (
        <span style={{ fontSize: '15px', color: 'black' }}>
          {displayValue.split(".")[0]}.
        </span>
      );
    } else {
      return null;
    }
  };

  const renderDecimalPart = () => {
    if (!isFocused) {
      return (
        <span className="" style={{ fontSize: "13px", color: "#000", marginBottom: "0px" }}>
          {displayValue.split('.')[1] || '00'}
        </span>
      );
    } else {
      return null;
    }
  };




  return (
    <div className={`inputfield__container ${className}`}>
      <div style={{ minWidth: '120px', fontSize: 16, margin: '0px 0px 5px 5px', fontWeight: '500' }}>
        {label}
        {required && <font color="red">*</font>}
      </div>
      <div className='inputfield__innercontainer'>
        <div className='inputfield ps-2' >
          <input
            ref={inputRef}
            type='text'
            className='inputfield__input'
            value={isFocused ? displayValue : ''}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            placeholder='Enter adjusted value'
            style={{ textAlign: "right", fontSize: isFocused ? "12px" : "0px", paddingRight: "0px" }}

          />
          <div className="position-absolute" style={{
            position: "absolute",
            right: "10px",
            top: "3px"
          }}
            key={`${uniqueKey.current}-${refreshId}-${displayValue}`}
            onClick={handleClick}>
            {renderIntegerPart()}
            {renderDecimalPart()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ScaledNumericInput;
