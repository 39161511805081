import React, { useCallback, useEffect, useRef, useState } from 'react';
import { InfoPair, InfoPairColumn } from '../ondato/Ondato';
import _ from 'lodash';
import { Card, CardBody, Container } from 'reactstrap';
import { Accordion } from 'react-bootstrap';
import { doGET } from '../../../../util/HttpUtil';
import ProofOfAddressDocument from '../../forms/components/IndividualLite/ProofOfAddressDocument';
import { FaSpinner } from 'react-icons/fa';
import { getBaseUrls } from '../../../../constants';


const StatusBadge = ({ passed }) => {
  const styleConfig = {
    true: { backgroundColor: '#d4edda', color: '#155724' },
    false: { backgroundColor: '#f8d7da', color: '#721c24' },
    default: { backgroundColor: '#e2e3e5', color: '#383d41' }
  };

  const { backgroundColor, color } = styleConfig?.[passed] || styleConfig?.default;

  return (
    <div style={{
      display: "inline-block",
      borderRadius: 5,
      padding: "6px 8px",
      fontSize: "13px",
      backgroundColor,
      color,
      textAlign: "center"
    }}>
      {_.startCase(passed ? "Passed" : "Failed")}
    </div>
  );
};

const DocumentDetail = ({ document }) => (
  <Card>
    <CardBody>
      <h5>{document?.document_type}</h5>
      <InfoPair label="Document Date" value={document?.date} />
      <InfoPair label="Name on Document" value={document?.name} />
      <InfoPair label="Address on Document" value={Object?.values(document?.address ?? {})?.filter(Boolean)?.join(', ')} />
      <InfoPair label="Document Summary" value={document?.summary} />
      <InfoPair label="Analysis" value={document?.analysis} />
      <InfoPair label="Validity" value={<StatusBadge passed={document?.is_valid_document} />} />
    </CardBody>
  </Card>

);
const CompletePoa = ({ poaData, showDoc, onChange }) => {

  const [data, setData] = useState([]);
  const baseUrls = getBaseUrls()
  const updateData = useCallback(() => {
    if (poaData) {
      const proofOfAddressMessage = poaData?.status_messages?.filter(
        (message) =>
          (message?.event === 'command_results' || message?.event === 'error') &&
          (message?.content?.command_id === 'kyc.proof_of_address_verification' || message?.content?.command_id === 'kyb.proof_of_address_verification')
      );
      setData(proofOfAddressMessage);
      onChange(proofOfAddressMessage)
    }
  }, [poaData]);

  useEffect(() => {
    updateData();
  }, [updateData])


  if (poaData?.status === "error") {
    return (<div>
      <Card>
        <CardBody>
          <InfoPair label="Status" value={<StatusBadge passed={false} />} />
        </CardBody>
      </Card>
    </div>);
  }

  if (!data?.length) {
    return (
      <div>
        <h4>
          <b>Proof Of Address is not performed yet</b>
        </h4>
      </div>
    );
  }

  return (
    <div>
      {showDoc ? <ProofOfAddressDocument
        asLabel
        recordId={poaData?._id}
      /> : null}
      <div style={{ textDecoration: "underline" }}>
        <a
          href={`${baseUrls?.parcha}/jobs/fvbank-kyc-v2/${poaData?.id}`}
          target="_blank"
          style={{
            color: '#007bff',
          }}
        >
          Proof Of Address
        </a>
      </div>
      {(data ?? [])?.map((poa, i) => (
        poa?.event == "error" ?
          <ProofOfAddressError key={i} proofOfAddressStatus={poa} poa={poaData} /> :
          <ProofOfAddressItem key={i} proofOfAddressStatus={poa} poa={poaData} />
      ))}
    </div>
  );
}
const ProofOfAddressError = ({ proofOfAddressStatus }) => {
  const { error_type, error_display } = proofOfAddressStatus?.content?.output;
  return (
    <div>
      <Card>
        <CardBody>
          <InfoPair applyValidation label="Error Type" value={error_type} />
          <InfoPairColumn applyValidation label="Error Message" value={error_display} />
        </CardBody>
      </Card>
    </div>
  );
};

const ProofOfAddressItem = ({ proofOfAddressStatus, poa }) => {
  const { passed, answer, explanation } = proofOfAddressStatus?.content?.output;
  return (
    <div>


      <Card>
        <CardBody>
          <InfoPair label="Status" value={<StatusBadge passed={passed} />} />
          <InfoPair label="Status Reason" value={answer} />
          <InfoPairColumn label="Status Explanation" value={explanation} />
        </CardBody>
      </Card>


      <Card>
        <CardBody>
          <h4><b>Input Data</b></h4>
          <InfoPair label="Full Name" value={proofOfAddressStatus?.content?.args?.input_data?.full_name} />
          <InfoPair label="Address" value={Object?.values(proofOfAddressStatus?.content?.args?.input_data?.address ?? {})?.join(", ")} />
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <h4><b>Verification Data</b></h4>
          {(proofOfAddressStatus?.content?.args?.verification_data?.proof_of_address_documents ?? [])?.map((doc, index) => (
            <DocumentDetail key={index} document={doc} />
          ))}
        </CardBody>
      </Card>
    </div>
  );
};

const ProofOfAddress = ({ formId, refreshId, applicationDocId }) => {
  const [poaList, setPoaList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [posStatuses, setPoaStatuses] = useState([])

  const fetPOAStatus = useCallback(async () => {
    setLoading(true);
    try {
  
      const poaGridResponse = await doGET(`/api/application-doc/status?id=${applicationDocId}&refresh=true`);
      if (poaGridResponse?.status === 200 && poaGridResponse?.data) {
        setPoaList([poaGridResponse?.data ?? {}]);
      }
    } catch (error) {
      console.error('Failed to fetch pao grid data:', error);
    } finally {
      setLoading(false);
    }
  }, [formId, refreshId, applicationDocId]);


  useEffect(() => {
    fetPOAStatus();
  }, [formId, refreshId, applicationDocId]);

  // const maxSeqIndex = poaList?.reduce((acc, current, idx, arr) => {
  //   return (arr[acc].seq > current.seq) ? acc : idx;
  // }, 0);

  if (loading) return <div className="d-flex justify-content-center align-items-center w-100 h-100 flex-1  fv_ondato_rule">
    <FaSpinner size={14} className="spinner" />
  </div>

  return (
    <Container className='mx-0 px-0' fluid>

      {
        <Accordion defaultActiveKey={``}>
          {(poaList ?? []).map((poa, index) => (
            <Accordion.Item eventKey={`${index}`} key={index}>
              <Accordion.Header>
                <div className='d-flex align-items-center w-100 justify-content-between'>
                  <div>
                    {poa?.seq}.   {poa?.input_payload?.self_attested_data?.first_name}  {poa?.input_payload?.self_attested_data?.middle_name}  {poa?.input_payload?.self_attested_data?.last_name}

                  </div>
                  <div className='me-2'>
                    {_.capitalize(
                      posStatuses?.[0]?.event == "error" ? "Error" :
                        (posStatuses?.[0]?.content?.command_id === 'kyc.proof_of_address_verification' || posStatuses?.[0]?.content?.command_id === 'kyb.proof_of_address_verification') ? "Completed" :
                          poa?.status ?? "Pending")}
                  </div        >
                </div>

              </Accordion.Header>
              <Accordion.Body>


                <CompletePoa poaData={poa}
                  onChange={(v) => {
                    setPoaStatuses(v)
                  }} />
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      }
    </Container>
  );
};

export default ProofOfAddress;