import React, { useContext, useEffect, useState } from 'react'
import ApproveButton from '../../../../components/Buttons/ApproveButton'
import { ModalPopup } from '../../../../components/Modal/ModalPopup'
import { FormEndpoints } from './AskForMoreInfoModal'
import { doGET, doPUT } from '../../../../util/HttpUtil'
import { DialogContext } from '../../../../store/context/DialogContext'

export const ApproveModal = ({ stepName, isOpen, setOpenModal, data, onSubmit, onToggle, formType, formId }) => {

    const [approveData, setApproveData] = useState("")
    const [loading, setLoading] = useState(false)
    const { showError, showMessage } = useContext(DialogContext)


    const apporveForm = async () => {
        setLoading(true)
        try {
            const response = await doGET(`/api/${FormEndpoints[formType]}/detail?id=${formId}`)
            if (response?.status == 200) {
                const updateResponse = await doPUT(`/api/${FormEndpoints[formType]}/update`, {
                    ...(response?.data ?? {}),
                    status: 3,
                    remark : approveData
                })
                setApproveData("")
                onSubmit(approveData)
            }
        } catch (error) {
            showError(error)
        } finally {
            setLoading(false)
        }
    }


    return (
        <div>
            <ModalPopup
                isOpen={isOpen}
                setIsOpen={(v) => {
                    setApproveData("")
                    setOpenModal(v)
                }}
                title={`Approve ${stepName ? `(${stepName})` : null}`}
                className="d-flex justify-content-center align-items-center"
                modalStyle={{ fontSize: "24px", marginTop: "200px", }}
                body={
                    <div className="pb-2" style={{ backgroundColor: "#F1FAFC", width: "650px", borderRadius: "10px" }}>

                        <div className='p-4' style={{ width: "fitContent", fontSize: "14px" }} dangerouslySetInnerHTML={{ __html: data?.details }}></div>
                        <textarea
                            type="text"
                            value={approveData}
                            onChange={(e) => {
                                setApproveData(e?.target?.value)
                            }}
                            placeholder="Add comment here..."
                            className="mx-3 ms-4 inputfield flex-1"
                            style={{
                                width: "600px",
                                borderRadius: "14px",
                                outline: "none",
                                border: "1px solid #C6F3FF",
                                backgroundColor: "#FFFFFF",
                                height: "94px",
                                marginLeft: "auto",

                                overflowY: "hidden",
                                fontSize: "15px",
                                color: "#000"
                            }}
                            rows="2"
                        />
                        <div className="mt-4" style={{ marginBottom: "20px", marginLeft: "auto", marginRight: "28px", }}>
                            <ApproveButton
                                onClick={() => {
                                    apporveForm()
                                }}
                                loading={loading}
                                style={{ marginLeft: "auto", }} />
                        </div>
                    </div>}
            />

        </div>
    )
}
