
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useRef, useState } from "react";
import { CirclePicker } from "react-color";
import { BiMinus } from "react-icons/bi"; // Confirm this is imported
import { FaMinus, FaPlus, FaTimes } from "react-icons/fa";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Button, Card, CardBody, Col, Row } from "reactstrap";
import { InputField, SelectField } from "../../components";
import ScaledNumericInput from "../../components/InputField/ScaledNumericInput";
import { useOutsideAlerter } from "../../components/dnd/board/helper";
import { DialogContext } from "../../store/context/DialogContext";
import RiskCalculationService from "./RiskCalculationService";
import "./style.css";

const EditRiskCalculation = ({ editId, onChange = () => { } }) => {

  const { showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [activeColorPicker, setActiveColorPicker] = useState(null); // NEW: Track active color picker
  const colorPickerRef = useRef(null);
  const [activeAccordion, setActiveAccordion] = useState(null);

  useOutsideAlerter(colorPickerRef, () => setActiveColorPicker(null));

  const fetch = async () => {
    if (editId) {
      setLoading(true);
      try {
        let form = await RiskCalculationService.get(editId);
        setData({ ...form });
      } catch (error) {
        showError(error)
      } finally {
        setLoading(false)
      }
    }

  };

  useEffect(() => {
    setData({})
    fetch();
  }, [editId, RiskCalculationService?.version]);

  useEffect(() => {
    onChange(data)
  }, [data]);


  const handleAddRiskIndicator = () => {
    const newRiskIndicator = {
      name: "",
      weightage: 0,
      field: "",
      valueMap: {}
    };

    const updatedRiskIndicators = [newRiskIndicator, ...(data.riskIndicators ?? [])];

    setData({
      ...data,
      riskIndicators: updatedRiskIndicators,
    });

    setActiveAccordion("0"); // Set this as a string
  };

  const getNextUniqueKey = (currentKeys) => {
    const existingKeys = currentKeys.map(key => parseInt(key.replace(/^\D+/g, ''))).filter(key => !isNaN(key));
    let nextKey = 1;
    while (existingKeys.includes(nextKey)) {
      nextKey++;
    }
    return `New Key ${nextKey}`;
  };

  const handleUpdateRiskIndicator = (index, field, value, action = 'update') => {
    const updatedRiskIndicators = [...data.riskIndicators];
    if (field === "valueMap") {
      if (action === 'remove') {
        const { [value]: _, ...remaining } = updatedRiskIndicators[index].valueMap;
        updatedRiskIndicators[index].valueMap = remaining;
      } else {
        updatedRiskIndicators[index].valueMap = {
          ...value
        };
      }
    } else {
      updatedRiskIndicators[index][field] = value;
    }
    setData({ ...data, riskIndicators: updatedRiskIndicators });
  };
  const handleDeleteRiskIndicator = (index) => {
    const updatedRiskIndicators = [...(data.riskIndicators ?? [])];
    updatedRiskIndicators.splice(index, 1);
    setData({ ...data, riskIndicators: updatedRiskIndicators });
  };


  const handleAddRiskLabel = () => {
    const newRiskLabel = {
      name: "",
      color: "",
      min: 0,
      max: 0,
    };
    setData({
      ...data,
      riskLabels: [...(data.riskLabels ?? []), newRiskLabel],
    });
  };

  const handleUpdateRiskLabel = (index, field, value) => {
    const updatedRiskLabels = [...(data.riskLabels ?? [])];
    updatedRiskLabels[index][field] = value;
    setData({ ...data, riskLabels: updatedRiskLabels });
  };

  const handleDeleteRiskLabel = (index) => {
    const updatedRiskLabels = [...(data.riskLabels ?? [])];
    updatedRiskLabels.splice(index, 1);
    setData({ ...data, riskLabels: updatedRiskLabels });
  };


  const handleHexInputChange = (e, index) => {
    const value = e.target.value;
    handleUpdateRiskLabel(index, "color", value);
  };


  if (loading) {
    return (
      <div style={{ padding: '20px' }}>
        <Skeleton height={50} className="mb-4" />
        <Skeleton count={2} height={30} className="mb-2" />
        <Skeleton height={200} className="mb-4" />
        <Skeleton count={2} height={50} className="mb-2" />
      </div>
    );
  }

  return (
    <React.Fragment>
 
        <div key={loading} style={{ width: "calc(100% - 5px)" }} className="row px-3 ">
          <Row className="mb-3 mt-4">
            <Col className="col-lg">
              <InputField
                value={data?.name}
                label={"Name"}
                onChange={(v) => {
                  setData({
                    ...data,
                    name: v,
                  });
                }}
                type="text"
              />
            </Col>

            <Col className="col-lg">
              <SelectField
                data={[
                  { label: "Individual Plus", value: "INDIVIDUAL_PLUS" },
                  { label: "Business Plus", value: "BUSINESS_PLUS" },
                  { label: "Business Prime", value: "BUSINESS_PRIME" },
                  { label: "Individual Prime", value: "INIDIVIDUAL_PRIME" },
                ]}
                value={data?.formType}
                required={true}
                onChange={(v) => {
                  setData({ ...data, formType: v?.value })
                }}
                label={"Form Type"}
                placeholder={"Form Type"}
              />
            </Col>
          </Row>
{/* 
          <div className="mt-1 d-flex justify-content-end">
            <Button
              color="primary"
              onClick={handleAddRiskLabel}
              className="d-flex align-items-center"
            >
              <FaPlus className="me-2" />
              Risk Label
            </Button>
          </div> */}
          <Card  className="fv_risk_card mt-2">
            <CardBody>
              {(data.riskLabels ?? []).map((label, idx) => (

                <div key={idx} className="d-flex align-items-end justify-content-between">
                  <div className="mb-4 position-relative" >
                    <div
                      className="color-circle"
                      style={{ backgroundColor: label.color }}
                      onClick={() => setActiveColorPicker(idx === activeColorPicker ? null : idx)}
                    />
                    {activeColorPicker === idx && (
                      <div className="color-picker-container card" ref={colorPickerRef}>
                        <FaTimes className="close-icon" onClick={() => setActiveColorPicker(null)} />
                        <CirclePicker
                          color={label.color}
                          onChangeComplete={(color) => handleUpdateRiskLabel(idx, "color", color.hex)}
                          width="180px"
                        />
                        <input
                          type="text"
                          value={label.color}
                          className="mt-2"
                          onChange={(e) => handleUpdateRiskLabel(idx, "color", e.target.value)}
                          placeholder="#000000"
                        />
                      </div>
                    )}
                  </div>

                  <Row form className="flex-1">
                    <Col md={4}>
                      <InputField
                        value={label.name}
                        label="Label Name"
                        onChange={(e) => handleUpdateRiskLabel(idx, "name", e)}
                      />
                    </Col>

                  <Col md={4}>

                    <ScaledNumericInput
                      value={label.min}
                      label="Min"
                      id = {`min-${idx}`}
                      refreshId={`${data?._id}-${loading}-${RiskCalculationService?.version}`}
                      onChange={(e) => handleUpdateRiskLabel(idx, "min", parseFloat(e))}
                    />
                  </Col>
                  <Col md={4}>
                    <ScaledNumericInput
                      value={label.max}
                      label="Max"
                      id={`max-${idx}`}
                      refreshId={`${data?._id}-${loading}-${RiskCalculationService?.version}`}
                      onChange={(e) => handleUpdateRiskLabel(idx, "max", parseFloat(e))}
                    />
                  </Col>
                </Row>

                {/* <div className="ms-2 mb-4 d-flex align-items-center justify-content-end ">
                    <div
                      className="btn btn-outline-dark d-flex align-items-center"
                      style={{
                        width: "20px",
                        height: "20px",
                        border: "1px solid #707070",
                        borderRadius: "50%",
                        cursor: "pointer",
                        padding: "1px",
                      }}
                      onClick={() => handleDeleteRiskLabel(idx)}
                    >
                      <BiMinus className="" fontSize="16" />
                    </div>
                  </div> */}

                </div>

              ))}
            </CardBody>
          </Card>

          <div className="mt-1 d-flex justify-content-end">
            <Button
              color="primary"
              onClick={handleAddRiskIndicator}
              className="d-flex align-items-center"
            >
              <FaPlus className='me-2' />
              Risk Indicator
            </Button>
          </div>

        {(data.riskIndicators ?? []).map((indicator, idx) => (
          <Accordion key={idx} open={activeAccordion} toggle={(id) => setActiveAccordion(activeAccordion === id ? null : id)}>
            <AccordionItem>
              <AccordionHeader targetId={String(idx)}>
                {indicator.name || `Indicator ${idx + 1}`}
              </AccordionHeader>
              {activeAccordion === String(idx) && (


              <AccordionBody accordionId={String(idx)}>
                <Row form>
                  <Col md={4}>
                    <InputField
                      value={indicator.name}
                      label="Indicator Name"
                      onChange={(e) => handleUpdateRiskIndicator(idx, 'name', e)}
                    />
                  </Col>
                  <Col md={4}>
                    <ScaledNumericInput
                      value={indicator.weightage}
                      label="Weightage"
                      id = {`weightage-${idx}`}
                      refreshId={`${data?._id}-${loading}-${RiskCalculationService?.version}`}
                      onChange={(e) => handleUpdateRiskIndicator(idx, 'weightage', parseInt(e))}
                    />
                  </Col>
                  <Col md={4}>
                    <InputField
                      value={indicator.field}
                      label="Field"
                      onChange={(e) => handleUpdateRiskIndicator(idx, 'field', e)}
                    />
                  </Col>
                </Row>
                <Row className="fv_risk_value_map">
                  <Col md={12}>

                    <div className="row ps-2 font-size-14 fv_risk_value_map_item">
                      <div className="ps-2 col-6">Key</div>
                      <div className="ps-2 col-5">Rating</div>
                      <div className="col-1"></div>
                    </div>

                    {Object.entries(indicator.valueMap ?? {}).map(([key, value], mapIndex) => (
                      <div key={mapIndex} className="row d-flex align-items-center fv_risk_value_map_item">
                        <InputField
                          value={key}
                          noLabel
                          onChange={(e) => {
                            let updatedMap = { ...indicator.valueMap };

                            // If the key changes (new key doesn't exist)
                            if (!updatedMap.hasOwnProperty(e)) {
                              // Create a new object to insert the key at the same position
                              const newMap = {};
                              Object.keys(updatedMap).forEach((currentKey) => {
                                if (currentKey === key) {
                                  newMap[e] = value;  // Insert new key with the old value
                                } else {
                                  newMap[currentKey] = updatedMap[currentKey];
                                }
                              });
                              updatedMap = newMap;
                            } else {
                              // If the key already exists, just update the value
                              updatedMap[e] = value;
                            }

                            handleUpdateRiskIndicator(idx, 'valueMap', updatedMap);
                          }}
                          className="fv_risk_input col-6 my-0"
                        />
                        <ScaledNumericInput
                          value={value}
                          className="fv_risk_number_input col-5  my-0"
                          id={`rating-${idx}-${mapIndex}`}
                          refreshId={`${data?._id}-${loading}-${RiskCalculationService?.version}`}
                          onChange={(e) => {
                            const updatedMap = { ...indicator.valueMap };
                            updatedMap[key] = parseInt(e);
                            handleUpdateRiskIndicator(idx, 'valueMap', updatedMap);
                          }}
                        />

                        <div className=" d-flex align-items-center justify-content-end col-1">
                          <div
                            className="btn btn-outline-dark d-flex align-items-center"
                            style={{
                              width: "20px",
                              height: "20px",
                              border: "1px solid #707070",
                              borderRadius: "50%",
                              cursor: "pointer",
                              padding: "1px",
                            }}
                            onClick={() => handleUpdateRiskIndicator(idx, 'valueMap', key, 'remove')}
                          >
                            <BiMinus className="" fontSize="16" />
                          </div>
                        </div>

                      </div>
                    ))}
                    <div className="mt-1 d-flex justify-content-end">
                      <Button
                        color="danger" onClick={() => handleDeleteRiskIndicator(idx)}
                        className="d-flex align-items-center me-2"
                      >
                        <FaMinus className='me-2' />
                        Indicator
                      </Button>
                      <Button
                        color="primary"
                        onClick={() => {
                          const currentKeys = Object.keys(indicator.valueMap ?? {});
                          const newKey = getNextUniqueKey(currentKeys);
                          const updatedMap = { ...(indicator.valueMap ?? {}), [newKey]: 0 };
                          handleUpdateRiskIndicator(idx, 'valueMap', updatedMap);
                        }}
                        className="d-flex align-items-center"
                      >
                        <FaPlus className='me-2' />
                        Ratings
                      </Button>
                    </div>
                  </Col>
                </Row>
              </AccordionBody> 
)}
            </AccordionItem>
          </Accordion>
        ))}
      </div>

    </React.Fragment >
  );
};

export default observer(EditRiskCalculation);
