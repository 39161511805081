import React from 'react'
import { Input, Row } from 'reactstrap';

const CheckBoxFieldMutiple = ({ className,
    labelStyle,
    label,
    data = [],
    placeholder,
    value,
    onChange,
    error,
    showErr,
    isDisabled,
    required }) => {
    return (
        <div className='my-3'>
            <div className='d-flex'>
                <label style={labelStyle}>{label}</label>
                {required && <font color="red">*</font>}
            </div>
            <Row className='col-12  ps-2'>
                {(data ?? [])?.map((item) => (
                    <div key={item.value} className={className ? className : `col-lg-6 mt-2 d-flex`}>
                        <Input
                            type="checkbox"
                            checked={value?.includes(item?.value)}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    onChange([...value, item.value]);
                                } else {
                                    onChange(value?.filter((v) => v !== item?.value));
                                }
                            }}
                            disabled={isDisabled}
                        />
                        <div className='px-2'>
                            {item.label}
                        </div>
                    </div>
                ))}
            </Row>

            {(error?.length && showErr) ? <div className="mt-1 selectfield__error">{error}</div> : null}

        </div>
    )
}

export default CheckBoxFieldMutiple