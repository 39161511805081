const DeleteLink = (props) => {
  return (
    <i
      role="button"
      className="mdi mdi-delete-outline text-secondary font-size-20"
      onClick={props?.onClick}
      style={props?.style}
    />
  );
};
export default DeleteLink;
