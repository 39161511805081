import React from 'react'

import { Modal } from 'reactstrap';
import closeIcon from "../../assets/Icons/close.svg"

export const ModalPopup = (props) => {

    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }
    function tog_scroll() {
        props?.setIsOpen(props?.isOpen)
        removeBodyCss()
    }


    return (
        <Modal
            isOpen={props?.isOpen}
            toggle={() => {
                tog_scroll()
            }}
            // scrollable={true}
            className={`modal-center modal-popup px-5 ${props?.className || ''}`}
            style={{ ...props?.modalStyle, }}
        >
            <div className="modal-header d-flex justify-content-between align-items-center" style={{ border: "none" }}>
                <h5 className="modal-title mt-0" style={{ fontSize: "24px", color: "black", }}>
                    {props?.title}
                </h5>
                <div style={{ cursor: "pointer" }} onClick={() => { props?.setIsOpen(false) }}>
                    <img
                        src={closeIcon}
                        alt="close-icon"
                        height="15"
                        className="logo logo-dark"
                    />
                </div>

                {/* <span aria-hidden="true">&times;</span> */}
                {/* </button> */}
            </div>
            <div className="modal-body pt-0 mb-3">
                {props?.body}
            </div>
        </Modal>
    )
}
