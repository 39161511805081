import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { DialogContext } from '../../store/context/DialogContext';
import { FaSpinner } from 'react-icons/fa';

const PDFViewer = ({ asLabel, src, placeholder = "", style, ...restProps }) => {
    const [pdfUrl, setPdfUrl] = useState();
    const [loading, setLoading] = useState(false);
    const { showError } = useContext(DialogContext);

    const fetchPDF = async (src) => {
        setLoading(true);
        try {
            const response = await axios({
                url: src,
                method: 'GET',
                responseType: 'blob', // important
                headers: {
                    Authorization: localStorage.getItem("BearerToken"),
                },
                withCredentials: true
            });
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
            setPdfUrl(url);
        } catch (error) {
            console.error("Error fetching PDF:", error);
            showError("Failed to load PDF");
        }
        setLoading(false);
    };

    useEffect(() => {
        if (src && (src.slice(1, 4) === "api")) {
            fetchPDF(src);
        }
    }, [src]);

    if (loading) {
        return <div className="image-skeleton" style={{ backgroundColor: "#eee", ...style }}>{}</div>;
    }


    if (asLabel) return <i className='bx bx-file font-size-20' /> ;
    
    return (
        <div style={{...(style ?? {}),}}>
            {pdfUrl ? (
                <object
                    // data={`${pdfUrl}#toolbar=0`}
                    data={`${pdfUrl}`}
                    type="application/pdf"
                    style={{ width: '100%', height: '80vh' }}
                    {...restProps}
                >
                    <p>Your browser does not support PDFs. <a href={pdfUrl} target="_blank" rel="noopener noreferrer">Download the PDF</a>.</p>
                </object>
            ) : (
                <i className='bx bx-file font-size-20' /> 
            )}
        </div>

    );
};

export default PDFViewer;
