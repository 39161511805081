import _ from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { FaFileDownload, FaArrowLeft, FaArrowRight, FaCheck, FaExpand, FaSpinner, FaExternalLinkAlt, FaRedo } from 'react-icons/fa';
import { RiDownloadLine } from "react-icons/ri";
import { Badge, Card, CardBody, Col, Container, Modal, ModalBody, Row } from 'reactstrap';
import { doGET } from '../../../../util/HttpUtil';
import "./style.scss";
import { Tooltip } from 'react-tooltip'; // Import the ReactTooltip component
import { Accordion } from 'react-bootstrap';
import { DialogContext } from '../../../../store/context/DialogContext';

export const downloadBlob = (blob, fileName) => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
};


export const DownloadReport = ({ reportId,  }) => {
    const [loading, setLoading] = useState(false);
    const {showError} = useContext(DialogContext); // Add this line(false);
    const downloadReport = async () => {
        setLoading(true)
        try {
            const response = await doGET(`/api/ondato/form/pdf/report?id=${reportId}`);
            if (response.status == 200) {
                const blob = response.data;
                const fileName = 'reportId.pdf';
                downloadBlob(blob, fileName);
            } else {
                throw new Error('Failed to download report');
            }
        } catch (error) {
            console.error('Error downloading the report:', error);
            showError(error)
        } finally {
            setLoading(false)
        }

    };

    if (loading) return <div className="fv_ondato_rule">
        <FaSpinner size={14} className="spinner" />
    </div>

    const tooltipId = `tooltip-${reportId}-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;

    return (
        <div>
            <FaFileDownload
                style={{ cursor: 'pointer' }}
                onClick={downloadReport}
                title="Download Report"
                size={20}
                id={tooltipId}
            />
            <Tooltip
                style={{ zIndex: '200000' }}
                anchorSelect={`#${tooltipId}`}
            >
                Download Report
            </Tooltip>
        </div>
    );
};

export const ImageFromBase64 = ({ id, fileId, label, file }) => {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [img, setImg] = useState({});
    const [modal, setModal] = useState(false);

    const toggleModal = () => setModal(!modal);

    const downloadImage = () => {
        const a = document.createElement('a');
        a.href = `data:image/jpeg;base64,${img?.encodedBase64String}`;
        a.download = file.fileName || 'download.jpeg';
        document.body.appendChild(a);
        a.click();
        a.remove();
    };

    const fetchImage = async () => {
        setLoading(true)
        try {
            const response = await doGET(`/api/ondato/file/string?id=${id}&fileId=${fileId}`)
            if (response?.status == 200) {
                setImg(response?.data)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (id && fileId)
            fetchImage()
    }, [id, fileId])




    if (loading) {
        return <div className="image-skeleton" style={{ backgroundColor: "#eee" }}></div>;
    }

    if (error) {
        return <div>Error loading image</div>;
    }

    if (!img?.encodedBase64String) return <div>No image available</div>;

    const src = `data:image/jpeg;base64,${img?.encodedBase64String}`;
    return (
        <>
            <div className='d-flex justify-content-between align-items-center mb-2 '>
                <img
                    style={{ borderRadius: "5px", width: "100%", cursor: "pointer" }}
                    src={src}
                    alt={`Image of ${img?.fileName}`}
                    onClick={toggleModal}
                />
            </div>
            <div className='d-flex justify-content-between align-items-center mt-2'>
                <small>{new Date(file.createdUtc).toLocaleString()}</small>
                <div>
                    <RiDownloadLine style={{ cursor: 'pointer', marginRight: '10px' }} onClick={downloadImage} />
                    <FaExpand style={{ cursor: 'pointer' }} onClick={toggleModal} />
                </div>
            </div>

            <Modal isOpen={modal} toggle={toggleModal} centered>
                <ModalBody>
                    <img src={src} alt={`Image of ${img?.fileName}`} style={{ width: '100%' }} />
                </ModalBody>
            </Modal>
        </>
    );
};

export const Faces = ({ kycDocument }) => {
    const [current, setCurrent] = useState(0);
    const files = kycDocument?.face?.files || [];

    const handleNext = () => {
        setCurrent((prev) => (prev + 1) % files.length);
    };

    const handlePrevious = () => {
        setCurrent((prev) => (prev - 1 + files.length) % files.length);
    };


    const arrowStyle = (isActive) => ({
        cursor: isActive ? "pointer" : "default",
        color: isActive ? "black" : "#ddd",
        padding: "5px",
        margin: "0 10px",
        borderRadius: "4px",
    });


    return (

        <div>
            <div className='d-flex justify-content-between align-items-center mb-2'>
                <h3>Attempts</h3>
                <div className='d-flex justify-content-between align-items-center  mb-2'>
                    <div
                        style={arrowStyle(files.length > 1)}
                        className='me-3 card p-1 mb-0'
                        onClick={handlePrevious}>
                        <FaArrowLeft />
                    </div>
                    <span>{`${current + 1}/${files.length}`}</span>
                    <div
                        style={arrowStyle(files.length > 1)}
                        className='ms-3 card p-1 mb-0'
                        onClick={handleNext}>
                        <FaArrowRight />
                    </div>
                </div>

            </div>
            <div>
                {files.length > 0 ? (
                    <ImageFromBase64
                        id={kycDocument?.id}
                        fileId={files[current]?.fileId}
                        file={files[current]}
                    />
                ) : (
                    <div>No image available</div>
                )}
            </div>

        </div>
    );
};

export const reduceOcrValidations = (validations) => {
    return validations.reduce((acc, { key, isValid }) => {
        const formattedKey = _.startCase(key).replace(/ /g, '');
        acc[formattedKey] = isValid;
        return acc;
    }, {});
};

export const StatusWithBadge = ({ title, status, reason }) => (
    <div className='d-flex align-items-center'>
        <h4 className='me-3'>
            <b>{title}:</b> <StatusBadge label={reason || status} status={status} />
        </h4>
    </div>
);

export const StatusBadge = ({ status, label }) => {
    let color = '';
    let backgroundColor = '';
    let textColor = '';


    switch (status) {
        case 'Approved':
        case 'Completed': // Fall through to the next case
            color = 'success';
            backgroundColor = '#d4edda';
            textColor = '#155724';
            break;
        case 'Rejected':
        case 'Expired': // Fall through to the next case
            color = 'danger';
            backgroundColor = '#f8d7da';
            textColor = '#721c24';
            break;
        case 'Awaiting':
        case 'InProgress': // Fall through to the next case
        case 'Pending': // Fall through to the next case
            color = 'warning';
            backgroundColor = '#fff3cd';
            textColor = '#856404';
            break;
        default:
            color = 'secondary';
            backgroundColor = '#e2e3e5';
            textColor = '#383d41';
            break;
    }



    return <div style={{
        display: "inline",
        borderRadius: 5,
        padding: "6px 8px",
        fontSize: "13px",
        backgroundColor: backgroundColor,
        color: textColor,
        textAlign: "center"

    }} >{_.startCase((label))}</div>;
};

export const InfoPair = ({ label, value, isValid, applyValidation,className }) => (
    <div className={`${className} fv_ondato_info-pair`}>
        <span className={`fv_ondato_label ${!applyValidation ? '' : isValid ? 'text-success' : 'text-danger'}`}>{label}</span>
        <span className={`fv_ondato_value `}>
            {value}
        </span>
    </div>
);

export const InfoPairColumn = ({ label, value, isValid, applyValidation }) => (
    <div className="fv_ondato_info-pair d-flex flex-column justify-content-start align-items-start">
        <div className={`fv_ondato_label ${!applyValidation ? '' : isValid ? 'text-success' : 'text-danger'}`}>{label}</div>
        <div className={`fv_ondato_value-column mt-1 w-100`}>
            {value}
        </div>
    </div>
);


export const Rule = ({ name, status }) => (
    <div className="fv_ondato_rule">
        {status === "Success" ? <FaCheck size={14} color='green' /> : <i style={{ fontSize: "14px", backgroundColor: "red", color: "#fff", borderRadius: "10px" }} className="dripicons-cross" />}
        <span className="fv_ondato_rule-name">{_.startCase((name))}</span>
    </div>
);

const Refresh = ({ onClick, formId, }) => {
    const tooltipId = `tooltip-${formId}-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
    return <>
        <FaRedo
            id={tooltipId}
            size={20}
            className='me-2'
            style={{ cursor: 'pointer', outline: "none" }}
            onClick={onClick}
            title="Refresh Data"
        />
        <Tooltip
            style={{ zIndex: '200000' }}
            anchorSelect={`#${tooltipId}`}
        >
            Refresh from Ondato
        </Tooltip>
    </>
}
export const OndatoViewer = ({ onRefresh = () => { }, formId, ondatoReport }) => {
    const [loading, setLoading] = useState(false)

    const refreshOndatoData = async () => {
        setLoading(true)
        try {
            const ondatoResponse = await doGET(`/api/ondato/form/status?id=${formId}&refresh=true&seq=${ondatoReport?.seq}`);
            if (ondatoResponse?.status == 200) {
                onRefresh();
            }
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }


    if (loading) return <OndatoSkeleton />


    const kycDocument = ondatoReport.kycDocument || {};
    const registration = ondatoReport.registration || {};
    const documentDetails = kycDocument.document || {};
    const faceDetails = kycDocument.face || {};
    const rules = kycDocument.rules || [];
    const ocrValidationsKeyValue = reduceOcrValidations((documentDetails?.ocrValidations ?? []));
    const tooltipId = `tooltip-${formId}-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;

    return (

        <Container className='mt-2' fluid>

            <div className='d-flex justify-content-between align-items-center'>
                <div className='flex-1 d-flex flex-wrap gap-2 justify-content-start'>
                    {kycDocument?.status && <StatusWithBadge
                        title="KYC Status"
                        status={kycDocument?.status}
                    />}
                    {kycDocument?.statusReason && <StatusWithBadge
                        title="KYC Status Reason"
                        status={kycDocument?.status}
                        reason={kycDocument?.statusReason}
                    />}
                    <StatusWithBadge
                        title="Registration Status"
                        status={ondatoReport?.status ?? "Pending"}
                    />


                </div>

                <div className='d-flex align-items-center mb-2'>

                    <Refresh
                        onClick={refreshOndatoData}
                        formId={formId}
                    />


                    <a
                        href={`https://sandbox-os.ondato.com/identifications/kyc/view/${kycDocument?.id}`}
                        target="_blank"
                        className='me-2'
                        rel="noopener noreferrer"
                        data-tip="View KYC Document"
                        id={tooltipId}
                    >
                        <FaExternalLinkAlt size={20} style={{ color: '#007bff' }} />
                    </a>
                    <Tooltip
                        style={{
                            zIndex: '200000',
                        }}
                        anchorSelect={`#${tooltipId}`}
                    >
                        View on Ondato
                    </Tooltip>


                    <DownloadReport reportId={ondatoReport?._id}/>
                </div>

            </div>

            {Object.keys(ondatoReport)?.length ? <Row>
                <Col md="6">
                    <Card>
                        <CardBody>
                            <h4><b>Documents</b></h4>

                            {Object.keys(ocrValidationsKeyValue)?.length ?
                                <div>
                                    <InfoPair isValid={ocrValidationsKeyValue?.FirstName} applyValidation label="First name" value={documentDetails?.firstName || "—"} />
                                    <InfoPair isValid={ocrValidationsKeyValue?.LastName} applyValidation label="Last name" value={documentDetails?.lastName || "—"} />
                                    <InfoPair isValid={ocrValidationsKeyValue?.Gender} applyValidation label="Gender" value={documentDetails?.gender || "—"} />
                                    <InfoPair isValid={ocrValidationsKeyValue?.DateOfBirth} applyValidation label="Date of birth" value={documentDetails?.dateOfBirth || "—"} />
                                    <InfoPair label="Country" value={documentDetails?.countryIso3 || "—"} />
                                    <InfoPair isValid={ocrValidationsKeyValue?.Nationality} applyValidation label="Nationality" value={documentDetails?.nationalityIso3 || "—"} />
                                    <InfoPair isValid={ocrValidationsKeyValue?.DocumentNumber} applyValidation label="Document number" value={documentDetails?.documentNumber || "—"} />
                                    <InfoPair label="Document type" value={documentDetails?.type || "—"} />
                                    <InfoPair isValid={ocrValidationsKeyValue?.DateOfIssue} applyValidation label="Issue date" value={documentDetails?.dateOfIssue || "—"} />
                                    <InfoPair isValid={ocrValidationsKeyValue?.DateOfExpiration} applyValidation label="Expiration date" value={documentDetails?.dateOfExpiration || "—"} /> :

                                </div> :

                                <div>
                                    <InfoPairSkeleton />
                                    <InfoPairSkeleton />
                                    <InfoPairSkeleton />
                                </div>}
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <h4><b>Rules</b></h4>
                            {rules?.length ? <>
                                {(rules ?? []).map((rule, index) => (
                                    <Rule key={index} name={rule?.name} status={rule?.status} />
                                ))}
                            </> :
                                <>
                                    <RuleSkeleton />
                                    <RuleSkeleton />
                                    <RuleSkeleton />
                                </>

                            }



                        </CardBody>
                    </Card>
                </Col>

                <Col md="6">
                    <Card>
                        <CardBody>
                            <h4><b>Registration</b></h4>
                            <InfoPair label="Registration Started" value={ondatoReport?.createdUtc ? moment(ondatoReport?.createdUtc).format("YYYY-MM-DD hh:mm") : "—"} />
                            <InfoPair label="Finished" value={ondatoReport?.modifiedUtc ? moment(ondatoReport?.modifiedUtc).format("YYYY-MM-DD hh:mm") : "—"} />
                            <InfoPair label="Email" value={registration?.email || "—"} />
                            <InfoPair label="Phone" value={registration?.phoneNumber || "—"} />
                            <InfoPair label="First name" value={registration?.firstName || "—"} />
                            <InfoPair label="Middle name" value={registration?.middleName || "—"} />
                            <InfoPair label="Last name" value={registration?.lastName || "—"} />
                            <InfoPair label="Date of birth" value={registration?.dateOfBirth || "—"} />
                            <InfoPair label="External ID" value={ondatoReport?.externalReferenceId || "—"} />
                        </CardBody>
                    </Card>

                    <Card>
                        <CardBody>

                            <div>
                                <ImageFromBase64
                                    label="Document"
                                    id={kycDocument?.id}
                                    file={documentDetails?.files?.[0]}
                                    fileId={documentDetails?.files?.[0]?.fileId}
                                />
                            </div>

                        </CardBody>
                    </Card>


                    <Card>
                        <CardBody>
                            <div>

                                <Faces kycDocument={kycDocument} />

                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row> : <OndatoSkeleton />}
        </Container>
    )
}
export const Ondato = ({ formId, refreshId }) => {
    const [ondatoReports, setOndatoReports] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchOndatoData = async () => {
        setLoading(true);
        try {
            const ondatoResponse = await doGET(`/api/ondato/grid?form_id=${formId}`);
            if (ondatoResponse?.status === 200 && ondatoResponse?.data?.rows) {
                setOndatoReports(ondatoResponse.data?.rows);
            }
        } catch (error) {
            console.error('Failed to fetch Ondato data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchOndatoData();
    }, [formId, refreshId]);

    if (loading) return <OndatoSkeleton />;

    const maxSeqIndex = ondatoReports.reduce((acc, current, idx, arr) => {
        return (arr[acc].seq > current.seq) ? acc : idx;
    }, 0);


    if (!ondatoReports?.length) return <>
        <div>
            <h4><b>Ondato KYC Verification is not performed yet</b></h4>
            <OndatoSkeleton />
        </div>
    </>;

    return (
        <Container fluid>
            {ondatoReports.length > 1 ? (
                <Accordion defaultActiveKey={`${maxSeqIndex}`}>
                    {ondatoReports.map((report, index) => (
                        <Accordion.Item eventKey={`${index}`} key={index}>
                            <Accordion.Header>
                                <div className='d-flex justify-content-between align-items-center w-100 '>
                                    <span>
                                        {report?.seq}.          {report?.registration?.firstName} {report?.registration?.middleName} {report?.registration?.lastName}
                                    </span>

                                    <span className=' me-3'>
                                        {report?.kycDocument?.status ?
                                            <>
                                                {`KYC Status: ${report?.kycDocument?.status}  `}
                                            </> : <>Registration Status: {report?.status ?? "Pending"}  </>}
                                    </span>
                                </div>

                            </Accordion.Header>
                            <Accordion.Body>
                                <OndatoViewer formId={formId} ondatoReport={report} />
                            </Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion>
            ) : (
                ondatoReports.length === 1 && <OndatoViewer onRefresh={fetchOndatoData} formId={formId} ondatoReport={ondatoReports[0]} />
            )}
        </Container>
    );
};

export const SkeletonLoader = ({ width }) => (
    <div className={`fv_ondato_skeleton`} style={{ width }}></div>
);

export const InfoPairSkeleton = () => (
    <div className="fv_ondato_info-pair">
        <SkeletonLoader width="30%" />
        <SkeletonLoader width="60%" />
    </div>
);

export const RuleSkeleton = () => (
    <div className="fv_ondato_rule">
        <FaSpinner size={14} className="spinner" />
        <SkeletonLoader width="80%" />
    </div>
);

export const OndatoSkeleton = () => (
    <Container fluid>
        <Row>
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <h4><b>Documents</b></h4>
                            <InfoPairSkeleton />
                            <InfoPairSkeleton />
                            <InfoPairSkeleton />
                            <InfoPairSkeleton />
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <h4><b>Rules</b></h4>
                            <RuleSkeleton />
                            <RuleSkeleton />
                            <RuleSkeleton />
                            <RuleSkeleton />
                            <RuleSkeleton />
                        </CardBody>
                    </Card>
                </Col>

                <Col>
                    <Card>
                        <CardBody>
                            <h4><b>Registration</b></h4>
                            <InfoPairSkeleton />
                            <InfoPairSkeleton />
                            <InfoPairSkeleton />
                            <InfoPairSkeleton />
                        </CardBody>
                    </Card>

                    <Card>
                        <CardBody>

                            <div>
                                <ImageFromBase64
                                    label="Face"

                                />
                            </div>
                        </CardBody>
                    </Card>

                    <Card>
                        <CardBody>

                            <div>
                                <ImageFromBase64
                                    label="Face"

                                />
                            </div>
                        </CardBody>
                    </Card>

                </Col>
            </Row>
        </Row>
    </Container>
)

export default Ondato;
