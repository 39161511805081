import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import "./style.css"
import CoreModals from "./CoreModals";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

const Layout = (props) => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const isSmallScreen = window.innerWidth <= 992;
  const [showSidebarMenu, setShowSidebarMenu] = useState(isSmallScreen ? false : true);

  useEffect(() => {
    const handleResize = () => {
      setShowSidebarMenu(window.innerWidth <= 992 ? false : true);
    }
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [])

  

  return (
    <React.Fragment>
      <div style={{ position: "relative" }} id="layout-wrapper sidebar-enable">
        <header id={showSidebarMenu ? "page-topbar" : "page-topbar-expanded"}>
          <Header
            theme={"dark"}
            isMenuOpened={isMenuOpened}
            openLeftMenuCallBack={() => { setIsMenuOpened(!isMenuOpened); }}
            ShowSidebarMenu={showSidebarMenu}
            ToggleSidebarMenu={() => { setShowSidebarMenu(!showSidebarMenu); }}
          />
        </header>

        <CoreModals />
        
        <div className={showSidebarMenu ? " main-layout page-content" : " main-layout-collapsed "}>{props.children}</div>

        <Sidebar
          ShowSidebarMenu={showSidebarMenu}
          ToggleSidebarMenu={() => { setShowSidebarMenu(!showSidebarMenu); }}
        />
      </div>

      <ProfileMenu />
    </React.Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  isPreloader: PropTypes.bool,
  layoutWidth: PropTypes.any,
  topbarTheme: PropTypes.any,
};

export default Layout;
