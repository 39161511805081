import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FaChevronDown, FaChevronRight, FaSpinner } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import Logo from "../../../../assets/images/Logo.svg";
import { DialogContext } from '../../../../store/context/DialogContext';
import { doGET, doPUT } from '../../../../util/HttpUtil';
import { ThankYou } from '../components';
import { validateEmail, validatePhoneNumber } from '../components/IndividualLite/UserDetailsForm';
import Page1 from './components/Page1';
import Page2 from './components/Page2';
import Page3 from './components/Page3';
import Page4 from './components/Page4';
import Page5 from './components/Page5';
import Page6 from './components/Page6';
import Page7 from './components/Page7';
import Page8 from './components/Page8';
import Documents from './components/DocumentsPage';
import Page9 from './components/Page9';
import { dateFormatToYYYYMMDD } from '../../../../components/DateSelects/AntDateSelect';

const RenderAccordion = ({ steps }) => {
    const [openSteps, setOpenSteps] = useState(steps.map(() => false));
    const toggleStep = (index) => {
        setOpenSteps((prev) => {
            const newOpenSteps = [...prev];
            newOpenSteps[index] = !newOpenSteps[index];
            return newOpenSteps;
        });
    };

    return (
        <div>
            {steps
                .filter(Boolean)
                .map((child, index) => {
                    const isOpen = openSteps[index];
                    return (
                        <div key={index}>
                            <div
                                className='my-3'
                                style={{ fontSize: "16px", fontWeight: "500", cursor: "pointer", display: "flex", alignItems: "center" }}
                                onClick={() => toggleStep(index)}
                            >
                                {isOpen ? <FaChevronDown size={16} style={{ marginRight: "10px" }} /> : <FaChevronRight size={16} style={{ marginRight: "10px" }} />}
                                {`Step: ${index+1} ${child.props.label}`}
                            </div>
                            {isOpen && child}
                        </div>
                    );
                })}
        </div>
    );
};

const checkIsAskForInfo = (steps) => {
    return (steps ?? [])?.find((v) => v?.status == 2);
}

const getStepNumber = (status) => {
    switch (status) {
        case "Application Form":
            return 6;
        case "KYC Verification":
            return 5;
        case "Sanctions Screening":
            return 0;
        case "Proof of Address":
            return 4;
        case "Risk Assessment":
            return 0;
        default:
            return 0;
    }
}

const ApplyBusinessPrime = ({ asLabel, formId, refreshId }) => {
    const { edit_id } = useParams();
    let { showError, showMessage } = useContext(DialogContext);

    const [data, setData] = useState({});
    const [applicationForm, setApplicationForm] = useState({});
    // const [ondatoStatus, setOndatoStatus] = useState({});
    // const [poaCurrentSeqData, setPoaCurrentSeqData] = useState({});
    const [isAskForInfo, setIsAskForInfo] = useState(null);
    const [verificationData, setVerificationData] = useState(null);
    const [hasError, setHasError] = useState(false);
    const [editId, setEditID] = useState(edit_id ?? formId);

    const [loading, setLoading] = useState(false);
    const [dataLoading, setDataLoading] = useState(false);
    const [step, setStep] = useState(1);
    const [pageNum, setPageNum] = useState(1);

    const cryptoExchangeCheck = data?.businesses?.includes("Crypto Exchange, OTC, Liquidity Provider, Remittance or FX Business, Money Services Business");
    const fundAndFamilyCheck = data?.businesses?.includes("Fund or Family Office");
    const accountUpgradeCheck = data?.businesses?.includes("Corporate Account Requiring Higher limiits (No client funds)");

    const decrementStep = () => {
        if (step === 9) {
            if (accountUpgradeCheck) setStep(8);
            else if (fundAndFamilyCheck) setStep(7);
            else if (cryptoExchangeCheck) setStep(6);
            else setStep(3);
        }
        else if (step === 8) {
            if (fundAndFamilyCheck) setStep(7);
            else if (cryptoExchangeCheck) setStep(6);
            else setStep(3);
        }
        else if (step === 7) {
            if (cryptoExchangeCheck) setStep(6);
            else setStep(3);
        }
        else setStep(prev => prev - 1);
        setPageNum(prev => prev - 1);
    }

    function handleChange(path, value, isCheckbox = false) {
        setData(prevData => {
            let updatedData = _.cloneDeep(prevData);
            if (isCheckbox) {
                if (_.get(updatedData, path)) {
                    _.set(updatedData, path, _.without(_.get(updatedData, path), value));
                } else {
                    let updatedArray = _.get(updatedData, path) || [];
                    updatedArray.push(value);
                    _.set(updatedData, path, updatedArray);
                }
            } else {
                _.set(updatedData, path, value);
            }
            return updatedData;
        });
    }

    useEffect(() => {
        if (formId) {
            setEditID(formId);
        }
    }, [formId]);

    const fetchDetail = async (stepProp) => {
        let businessPrimeResponse = {};
        let applicationFormResponse = {};
        let askForInfoResponse = {};

        setDataLoading(true);
        try {
            if (editId) {
                localStorage.setItem("formId", editId);
                const response = await doGET(`/api/business-prime/detail?id=${editId}`);
                setData(prev => ({ ...prev, ...response?.data }));
                businessPrimeResponse = response.data;
                const applicationResponse = await doGET(`/api/application-form/detail?id=${editId}`);
                if (applicationResponse?.status == 200) {
                    setApplicationForm(applicationResponse?.data);
                    applicationFormResponse = applicationResponse?.data;
                    if (applicationFormResponse?.steps?.length) {
                        const askInfoStep = checkIsAskForInfo(applicationFormResponse?.steps);
                        // verification code to be implemented later
                        if (Object.keys(askInfoStep ?? {}).length) {
                            const askInfoStepData = { ...(askInfoStep ?? {}), isAskForInfo: true, step: getStepNumber(askInfoStep?.label) };
                            setIsAskForInfo(askInfoStepData);
                            askForInfoResponse = askInfoStepData;
                            setStep(Number.MAX_SAFE_INTEGER);
                        } else {
                            setVerificationData(null);
                            setIsAskForInfo(null);
                        }
                    }
                }
            }
        } catch (error) {
            // showError(error)
            console.log(error);
        } finally {
            setDataLoading(false);
            if (stepProp) setStep(stepProp);
        }
    }

    useEffect(() => {
        fetchDetail();
    }, [editId, refreshId]);

    const handleValidations = () => {
        if (step === 2) {
            if (!data?.hasAuditStatements && !data?.hasAnnualStatements) {
                return true;
            }
            if (!data?.hasAnnualStatements && !data?.hasFinancialForecast) {
                return true;
            }
        }
        if (step === 3) {
            if (!!data?.hasCoinConversionPlan && data?.coinConversionCount === undefined && data?.coinConversionAmount === undefined) {
                return true;
            }
            if (!!data?.hasCryptoConversionPlan && data?.cryptoConversionCount === undefined && data?.cryptoConversionAmount === undefined) {
                return true;
            }
        }
        if (step === 4) {
            if (!!data?.hasUsCustomers && !data?.registeredWithFinCen) {
                return true;
            }
            if (!!data?.hasMsbLicense && !data?.msbLicenseLocation) {
                return true;
            }
            if (!!data?.holdsLicense && !data?.licenseDetails) {
                return true;
            }
        }
        if (step === 6) {
            if (!validateEmail(data?.complianceOfficerEmail)) {
                return true;
            }
        }
        if (step === 10) {
            if (!data?.signerFirstName || !data?.signerLastName) {
                return true;
            }
            if (data?.dateOfAgreement < dateFormatToYYYYMMDD(new Date())) {
                return true;
            }
        }
        return false;
    }

    // update form
    const handleUpdate = async ({ nextStep, payload, isfetchDetail, isLastStep = false }) => {
        if ((payload ?? data)?._id) {
            setLoading(true);
            setHasError(false);
            try {
                if (!asLabel) {
                    const response = await doPUT(`/api/business-prime/update`, payload ?? data);
                    if (response?.status == 200) {
                        setData(prev => ({ ...prev, ...response?.data }));
                    }
                }
                if (isfetchDetail && nextStep) fetchDetail(nextStep);
                else if (nextStep) setStep(nextStep);

                if (isLastStep && !asLabel) {
                    // await doGET(`/api/business-prime/submit?id=${editId}`);
                    setStep(12);
                }

                setLoading(false);
            } catch (error) {
                console.error('There has been a problem with your fetch operation:', error);
                setHasError(true);
                showError(error);
            } finally {
                setLoading(false);
            }
        } else {
            if (nextStep) fetchDetail(nextStep);
        }
    };

    const handleSubmit = async (isLastStep = false) => {
        const hasErrors = handleValidations();
        if (hasErrors) {
            setHasError(true);
            showError("Error while submitting");
            return;
        }

        setHasError(false);
        let next = step;
        if (step === 3) {
            if (cryptoExchangeCheck) next = 4;
            else if (fundAndFamilyCheck) next = 7;
            else if (accountUpgradeCheck) next = 8;
            else next = 9;
        }
        else if (step === 6) {
            if (fundAndFamilyCheck) next = 7;
            else if (accountUpgradeCheck) next = 8;
            else next = 9;
        }
        else if (step === 7) {
            if (accountUpgradeCheck) next = 8;
            else next = 9;
        }
        else {
            next++;
        }
        await handleUpdate({ nextStep: next, isLastStep });
        setPageNum(prev => prev + 1);
    };

    if (!asLabel) {
        if (step >= 11) {
            return <ThankYou type={"Business Prime"} />;
        }
    }

    const getComponent = () => {
        switch (step) {
            case 1:
                return (
                    <Page1
                        asLabel={asLabel}
                        data={data}
                        handleChange={handleChange}
                        pageNum={pageNum}
                        loading={loading}
                        step={step}
                        decrementStep={decrementStep}
                        handleSubmit={handleSubmit}
                    />
                );
            case 2:
                return (
                    <Page2
                        asLabel={asLabel}
                        data={data}
                        handleChange={handleChange}
                        hasError={hasError}
                        pageNum={pageNum}
                        loading={loading}
                        step={step}
                        decrementStep={decrementStep}
                        handleSubmit={handleSubmit}
                    />
                );
            case 3:
                return (
                    <Page3
                        asLabel={asLabel}
                        data={data}
                        handleChange={handleChange}
                        hasError={hasError}
                        pageNum={pageNum}
                        loading={loading}
                        step={step}
                        decrementStep={decrementStep}
                        handleSubmit={handleSubmit}
                    />
                );
            case 4:
                return (
                    <Page4
                        asLabel={asLabel}
                        data={data}
                        handleChange={handleChange}
                        hasError={hasError}
                        pageNum={pageNum}
                        loading={loading}
                        step={step}
                        decrementStep={decrementStep}
                        handleSubmit={handleSubmit}
                    />
                );
            case 5:
                return (
                    <Page5
                        asLabel={asLabel}
                        data={data}
                        handleChange={handleChange}
                        hasError={hasError}
                        pageNum={pageNum}
                        loading={loading}
                        step={step}
                        decrementStep={decrementStep}
                        handleSubmit={handleSubmit}
                    />
                );
            case 6:
                return (
                    <Page6
                        asLabel={asLabel}
                        data={data}
                        handleChange={handleChange}
                        hasError={hasError}
                        pageNum={pageNum}
                        loading={loading}
                        step={step}
                        decrementStep={decrementStep}
                        handleSubmit={handleSubmit}
                    />
                );
            case 7:
                return (
                    <Page7
                        asLabel={asLabel}
                        data={data}
                        handleChange={handleChange}
                        hasError={hasError}
                        pageNum={pageNum}
                        loading={loading}
                        step={step}
                        decrementStep={decrementStep}
                        handleSubmit={handleSubmit}
                    />
                );
            case 8:
                return (
                    <Page8
                        asLabel={asLabel}
                        data={data}
                        handleChange={handleChange}
                        hasError={hasError}
                        pageNum={pageNum}
                        loading={loading}
                        step={step}
                        decrementStep={decrementStep}
                        handleSubmit={handleSubmit}
                    />
                );
            case 9:
                return (
                    <Documents
                        data={data}
                        isAskForInfo={false}
                        remark={"Remark Here"}
                        recordId={editId}
                        asLabel={asLabel}
                        handleUpdate={handleUpdate}
                        setHasError={setHasError}

                        pageNum={pageNum}
                        loading={loading}
                        step={step}
                        decrementStep={decrementStep}
                        handleSubmit={handleSubmit}
                    />
                );
            default:
                return (
                    <Page9
                        asLabel={asLabel}
                        data={data}
                        handleChange={handleChange}
                        hasError={hasError}
                        pageNum={pageNum}
                        loading={loading}
                        step={step}
                        decrementStep={decrementStep}
                        handleSubmit={handleSubmit}
                    />
                );
        }
    };

    const getAllSteps = () => {
        const steps = [
            <Page1 asLabel={true} data={data} handleChange={handleChange} hasError={hasError} label="Basic Information" />,
            <Page2 asLabel={true} data={data} handleChange={handleChange} hasError={hasError} label="About your business" />,
            <Page3 asLabel={true} data={data} handleChange={handleChange} hasError={hasError} label="Expected Account Activity" />,
            cryptoExchangeCheck && <Page4 asLabel={true} data={data} handleChange={handleChange} hasError={hasError} label="Money Servcies Business" />,
            cryptoExchangeCheck && <Page5 asLabel={true} data={data} handleChange={handleChange} hasError={hasError} label="Cryptocurrency and Digital Assets Involvement" />,
            cryptoExchangeCheck && <Page6 asLabel={true} data={data} handleChange={handleChange} hasError={hasError} label="Compliance" />,
            fundAndFamilyCheck && <Page7 asLabel={true} data={data} handleChange={handleChange} hasError={hasError} label="Funds and Family Offices" />,
            accountUpgradeCheck && <Page8 asLabel={true} data={data} handleChange={handleChange} hasError={hasError} label="Corporate Account Upgrade" />,
            <Page9 asLabel={true} data={data} handleChange={handleChange} hasError={hasError} label="Review and Submit" />
        ];

        return <RenderAccordion steps={steps.filter(Boolean)} />;
    };


    return (
        <div style={{ height: "100vh", margin: "0px" }} className='d-flex justify-content-center align-items-center'>
            <Form className={asLabel ? "w-100" : 'form-content '} style={{ height: "90vh" }}>
                {dataLoading ? <div className="d-flex justify-content-center align-items-center w-100 h-100 flex-1 fv_ondato_rule">
                    <FaSpinner size={14} className="spinner" />
                </div>
                    :
                    <div className='d-flex flex-column justify-content-center align-items-center  '>
                        {asLabel ? <></> : <img
                            src={Logo}
                            alt="fv-bank-logo"
                            height="55"
                            style={{ marginBottom: "10px" }}
                            className="logo logo-dark"
                        />}
                        <div style={{ margin: "0px 0px 30px 0px", fontSize: "24px", fontWeight: "500" }}>Business Prime Account Upgrade</div>
                    </div>
                }
                {dataLoading ? null : (
                    <>
                        {asLabel ? getAllSteps() : getComponent()}
                    </>
                )}
            </Form>
        </div>
    );

}

export default ApplyBusinessPrime;