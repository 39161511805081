import React from 'react';
import { Button } from 'reactstrap'; // Make sure to import Button from your UI library
import { ThreeBounce } from 'better-react-spinkit';

export default function SaveButton({ onlyIcon = false, key = 12, onClick, disabled, loading }) {

  if (loading) {
    return (
      <div key={key} className="d-flex justify-content-end my-2">

        <Button
          onClick={onClick}
          className="mx-2"
          disabled={disabled}
          style={{ fontSize: "16px", backgroundColor: '#1678AE', height: "40px", padding: "2px 4px 2px 4px", width: "80px", borderRadius: "10px" }}
          color="success"
        >

          <div >          <ThreeBounce size={10} color="#FFFFFF" /></div>
        </Button>


      </div>
    );
  }
  return (
    <div className="d-flex justify-content-end my-2">
      {onlyIcon ? <div
        onClick={onClick}
        className="mx-2"
      >

      </div> : <Button
        onClick={onClick}
        className="mx-2"
        disabled={disabled}
        style={{ fontSize: "16px", backgroundColor: '#1678AE', height: "40px", padding: "2px 4px 2px 4px", width: "80px", borderRadius: "10px" }}
        color="success"
      >

        <div >Save</div>
      </Button>}
    </div>
  );
}
