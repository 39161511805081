import React, { useContext } from 'react';
import { QuestionRadioGroup } from '../../components/IndividualLite/DeclarationForm';
import CustomTextArea from '../../../../../components/InputField/CustomTextArea';
import { InputLabel } from '../../components/IndividualLite/UserDetailsForm';
import { InputField } from '../../../../../components';
import { Row } from 'reactstrap';
import ButtonGroup from '../../BusinessLiteForms/components/ButtonGroup';
import { DialogContext } from '../../../../../store/context/DialogContext';

const handleValidation = (field, value, data) => {
    if (field === 'complianceOfficerFirstName' || field === 'complianceOfficerLastName') {
        if (!value || value?.trim() === '') {
            return "This field is required.";
        }
        return "";
    }

    if (field === 'complianceOfficerPhone') {
        const phoneRegex = /^[+]?[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/;
        if (!value) {
            return "Phone number is required.";
        } else if (!phoneRegex.test(value)) {
            return "Please enter a valid phone number.";
        }
        return "";
    }

    if (field === 'complianceOfficerEmail') {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!value || value?.trim() === '') {
            return "Email is required.";
        } else if (!emailRegex.test(value)) {
            return "Please enter a valid email address.";
        }
        return "";
    }

    if (field === 'numberOfComplianceStaff') {
        if (!value || isNaN(value)) {
            return "Please enter a valid number.";
        }
        return "";
    }

    if (field === 'monitoringTools') {
        if (!value || value?.trim() === '') {
            return "This field is required. Please indicate the tools used by the company for monitoring.";
        }
        return "";
    }

    if (field === 'noCustomerAgreementExplanation') {
        if (!!data?.hasCustomerAgreement === false && (!value || value?.trim() === '')) {
            return "Please explain why you do not have a terms and conditions agreement with your customers.";
        }
        return "";
    }

    if (field === 'upcomingAmlReview') {
        if (!!data?.amlCftReviewed === false && (value === null || value === undefined)) {
            return "This should be marked 'Yes' since you will not have an independent BSA/AML review within the next 12 months.";
        }
        return "";
    }

    if ((value === null || value === undefined || value?.length == 0))
    return "Field is required."

    return "";
};

const Page6 = ({ asLabel, data, handleChange, pageNum, loading, step, decrementStep, handleSubmit }) => {

    const [hasError, setHasError] = React.useState(false);
    const { showError } = useContext(DialogContext);

    const validateFields = () => {
        const requiredFields = [
            'complianceOfficerFirstName',
            'complianceOfficerLastName',
            'complianceOfficerPhone',
            'complianceOfficerEmail',
            'numberOfComplianceStaff',
            'monitoringTools',
            'noCustomerAgreementExplanation',
            'upcomingAmlReview',
        ];

        let errors = [];

        requiredFields.forEach(field => {
            const fieldError = handleValidation(field, data[field], data);
            if (fieldError) {
                errors.push(fieldError);
            }
        });

        if (errors?.length > 0) {
            setHasError(true);
            showError(errors[0]);
            return false;
        }

        setHasError(false);
        return true;
    };

    const handleNext = async () => {
        if (validateFields()) {
            await handleSubmit(false);
        }
    };

    return (
        <>
            <div className='mb-3'>
                <h2 style={{ fontWeight: 'bolder' }} className='pt-3'>Compliance</h2>

                <InputLabel label="Name of Compliance Officer or equivalent" required labelStyle={{ fontSize: 15 }} />
                <Row>
                    <InputField
                        className="col-md-6"
                        placeholder={"First"}
                        value={data?.complianceOfficerFirstName}
                        onChange={(v) => handleChange("complianceOfficerFirstName", v)}
                        error={handleValidation("complianceOfficerFirstName", data?.complianceOfficerFirstName, data)}
                        showErr={hasError}
                        type="text"
                        isDisabled={asLabel}
                    />
                    <InputField
                        className="col-md-6"
                        placeholder={"Last"}
                        value={data?.complianceOfficerLastName}
                        onChange={(v) => handleChange("complianceOfficerLastName", v)}
                        error={handleValidation("complianceOfficerLastName", data?.complianceOfficerLastName, data)}
                        showErr={hasError}
                        type="text"
                        isDisabled={asLabel}
                    />
                </Row>

                <Row>
                    <InputField
                        className="col-sm-6"
                        label={"Phone Number"}
                        type="number"
                        placeholder={"+1-212-456-7890"}
                        value={data?.complianceOfficerPhone}
                        onChange={(v) => handleChange(`complianceOfficerPhone`, v)}
                        error={handleValidation("complianceOfficerPhone", data?.complianceOfficerPhone, data)}
                        showErr={hasError}
                        isDisabled={asLabel}
                        required
                    />
                    <InputField
                        className="col-sm-6"
                        label={"Email"}
                        placeholder={"Email"}
                        type="email"
                        value={data?.complianceOfficerEmail}
                        onChange={(v) => handleChange(`complianceOfficerEmail`, v)}
                        error={handleValidation("complianceOfficerEmail", data?.complianceOfficerEmail, data)}
                        showErr={hasError}
                        isDisabled={asLabel}
                        required
                    />
                </Row>

                <QuestionRadioGroup
                    style={{ fontSize: 15 }}
                    field={"hasAmlProgram"}
                    question="Does your organization have an AML/​CFT Program (Policies and Procedures)?"
                    data={data}
                    handleChange={handleChange}
                    hasError={hasError}
                    error={handleValidation("hasAmlProgram", data?.hasAmlProgram, data)}
                    isDisabled={asLabel}
                    defaultValue={false}
                    required
                />

                <QuestionRadioGroup
                    style={{ fontSize: 15 }}
                    field={"hasAmlPolicy"}
                    question="Does your AML/​CTF policy have provisions that address enhanced due diligence?"
                    data={data}
                    handleChange={handleChange}
                    hasError={hasError}
                    error={handleValidation("hasAmlPolicy", data?.hasAmlPolicy, data)}
                    isDisabled={asLabel}
                    defaultValue={false}
                    required
                />

                <InputField
                    className="mt-3"
                    label="How many people do you have in your compliance team?"
                    labelStyle={{ fontSize: 15 }}
                    placeholder=""
                    value={data?.numberOfComplianceStaff}
                    onChange={(v) => { handleChange("numberOfComplianceStaff", v) }}
                    error={handleValidation("numberOfComplianceStaff", data?.numberOfComplianceStaff, data)}
                    showErr={hasError}
                    type="number"
                    isDisabled={asLabel}
                    required
                />

                <CustomTextArea
                    labelStyle={{ fontSize: 15 }}
                    placeholder=""
                    value={data?.monitoringTools}
                    onChange={(v) => { handleChange("monitoringTools", v) }}
                    error={handleValidation("monitoringTools", data?.monitoringTools, data)}
                    showErr={hasError}
                    type="text"
                    label="Please indicate the tools used by the company for monitoring. (KYC and Transactions)"
                    className="mt-3"
                    isDisabled={asLabel}
                    required
                />

                <QuestionRadioGroup
                    style={{ fontSize: 15 }}
                    field={"hasComplaintsPolicy"}
                    question="Does your organization have a Complaints policy?"
                    data={data}
                    handleChange={handleChange}
                    hasError={hasError}
                    error={handleValidation("hasComplaintsPolicy", data?.hasComplaintsPolicy, data)}
                    isDisabled={asLabel}
                    defaultValue={false}
                    required
                />

                <QuestionRadioGroup
                    style={{ fontSize: 15 }}
                    field={"hasCustomerAgreement"}
                    question="Does your organization have a business agreement or terms and conditions with your customer?"
                    data={data}
                    handleChange={handleChange}
                    hasError={hasError}
                    error={handleValidation("hasCustomerAgreement", data?.hasCustomerAgreement, data)}
                    isDisabled={asLabel}
                    defaultValue={true}
                    required
                />

                {data?.hasCustomerAgreement === false ? (
                    <CustomTextArea
                        labelStyle={{ fontSize: 15 }}
                        placeholder=""
                        value={data?.noCustomerAgreementExplanation}
                        onChange={(v) => { handleChange("noCustomerAgreementExplanation", v) }}
                        error={handleValidation("noCustomerAgreementExplanation", data?.noCustomerAgreementExplanation, data)}
                        showErr={hasError}
                        type="text"
                        label="Please explain why you do not have a terms and conditions agreement with your customers."
                        className="mt-3"
                        isDisabled={asLabel}
                        required
                    />
                ) : null}

                <QuestionRadioGroup
                    style={{ fontSize: 15 }}
                    field={"amlCftReviewed"}
                    question="Has your organization AML/​CFT Compliance Program been reviewed by an independent third party?"
                    data={data}
                    handleChange={handleChange}
                    hasError={hasError}
                    error={handleValidation("amlCftReviewed", data?.amlCftReviewed, data)}
                    isDisabled={asLabel}
                    defaultValue={false}
                    required
                />

                <QuestionRadioGroup
                    style={{ fontSize: 15 }}
                    field={"upcomingAmlReview"}
                    question="Will you have an independent BSA/​AML review within the next 12 months."
                    data={data}
                    handleChange={handleChange}
                    hasError={hasError}
                    error={handleValidation('upcomingAmlReview', data?.upcomingAmlReview, data)}
                    isDisabled={asLabel}
                    required
                    defaultValue={false}
                />

                <QuestionRadioGroup
                    style={{ fontSize: 15 }}
                    field={"hasSanctionsProgram"}
                    question="Do you have a sanctions program?"
                    data={data}
                    handleChange={handleChange}
                    hasError={hasError}
                    error={handleValidation('hasSanctionsProgram', data?.hasSanctionsProgram, data)}
                    isDisabled={asLabel}
                    defaultValue={false}
                    required
                />
            </div>
            <ButtonGroup
                pageNum={pageNum}
                showPageNum={true}
                loading={loading}
                asLabel={asLabel}
                limit={10}
                onNextClick={handleNext}
                step={step}
                onBackClick={decrementStep}
            />
        </>
    )
}

export default Page6;
