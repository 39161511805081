import CustomTooltip from '../Booking/components/CustomTooltip';

export const ReportGridColumns = {
  version: 1,
  columns: [

    {
      title: "Name",
      renderer: (dataObj) => {
        return <div>
          {dataObj?.name}
        </div>
      },
      show: true,
    },
    {
      title: "Frequency",
      renderer: (dataObj) => {
        return <div>
          {dataObj?.frequency}
        </div>
      },
      show: true,
    },



    {
      title: "Recipients",
      accessor: "recipients",
      renderer: (dataObj) => {
        const passengers = dataObj?.recipients || [];
        const passengerCount = passengers.length;
        const renderPassengerInfo = (passenger, index) => (
          <div className="d-flex align-items-center " key={passenger.email}>
            <div className="d-flex align-items-center">
              {index >= 0 && <span className="larger-bullet">&#8226;</span>}
              <div>{passenger.name}</div>
            </div>
            <div style={{ marginLeft: '8px' }}>{passenger.email}</div>
          </div>
        );

        return (
          <div>
            {passengerCount === 0 ? (
              <div>
                --
              </div>
            ) : passengerCount === 1 ? (
              <div>
                <CustomTooltip>
                  <CustomTooltip.Body>  {passengers[0].name}  </CustomTooltip.Body>
                  <CustomTooltip.ToolTip>
                    {renderPassengerInfo(passengers[0])}
                  </CustomTooltip.ToolTip>
                </CustomTooltip>
              </div>
            ) : (
              <div href="#" id={`not-clickable-pass-m-${dataObj?._id}${dataObj?._id}`} data-tip={passengerCount}
                style={{ color: 'blue', textDecoration: 'underline', cursor: "pointer" }}
              >
                <CustomTooltip>
                  <CustomTooltip.Body> {passengerCount} </CustomTooltip.Body>
                  <CustomTooltip.ToolTip>
                    {passengers.map(renderPassengerInfo)}
                  </CustomTooltip.ToolTip>
                </CustomTooltip>
              </div>
            )}
          </div>
        );
      },
      show: true,
    },

    {
      title: "Action",
      accessor: "action",
      fixed: "right",
      width: 90,
      show: true,
      required: true,
    },
 
  ],
};
