import React, { useContext } from 'react';
import CustomTextArea from '../../../../../components/InputField/CustomTextArea';
import { QuestionRadioGroup } from '../../components/IndividualLite/DeclarationForm';
import { Col, Row } from 'reactstrap';
import CheckBoxFieldMutiple from '../../../../../components/CheckBox/CheckBoxFieldMutiple';
import { SelectField } from '../../../../../components';
import { ques4, ques5 } from '../constants';
import ButtonGroup from '../../BusinessLiteForms/components/ButtonGroup';
import _ from 'lodash';
import { DialogContext } from '../../../../../store/context/DialogContext';

const handleValidation = (field, value, data) => {
    if (field === "primaryBusiness") {
        if (!value || value?.length < 10 || value?.length > 500) {
            return "Please provide a detailed description of your company’s primary business activities (10-500 characters).";
        }
        return "";
    }

    if (field === "productsOrServices" || field === "businessPurpose" || field === "primaryCustomer") {
        if (value && value?.length > 500) {
            return "Please limit your response to 500 characters.";
        }
        return "";
    }

    if (field === "revenueRange" && !value) {
        return "Please select your company's annual revenue range.";
    }

    if (field === "hasAnnualStatements") {
        if (!data?.hasAuditStatements && value !== true) {
            return "This must be marked 'Yes' since audited financial statements are not available.";
        }
        return "";
    }

    if (field === "hasFinancialForecast") {
        if (!data?.hasAnnualStatements && value !== true) {
            return "This must be marked 'Yes' since neither audited nor annual financial statements are available.";
        }
        return "";
    }

    if (field === 'businessInvolvements' && (!value || value?.length === 0)) {
        return "Please select at least one involvement.";
    }

    return "";
};

const Page2 = ({ asLabel, data, handleChange, pageNum, loading, step, decrementStep, handleSubmit }) => {
    
    const [hasError, setHasError] = React.useState(false);
    const { showError } = useContext(DialogContext);

    const handleNext = async () => {
        const requiredFields = [
            'primaryBusiness',
            'productsOrServices',
            'businessPurpose',
            'primaryCustomer',
            'revenueRange',
            'hasAnnualStatements',
            'hasFinancialForecast',
            'businessInvolvements',
        ];

        let errors = [];

        requiredFields.forEach(field => {
            const fieldError = handleValidation(field, data[field], data);
            if (fieldError) {
                errors.push(fieldError);
            }
        });

        if (errors.length > 0) {
            setHasError(true);
            showError(errors[0]);
            return;
        }

        setHasError(false);
        await handleSubmit(false);
    };

    return (
        <>
            <div className='mb-3'>
                <h2 style={{ fontWeight: 'bolder' }} className='pt-3'>About your business</h2>
                <CustomTextArea
                    labelStyle={{ fontSize: 15 }}
                    placeholder=""
                    value={data?.primaryBusiness}
                    onChange={(v) => { handleChange("primaryBusiness", v) }}
                    type="text"
                    label="Please describe in detail your company’s Primary business activities and who your customers are."
                    isDisabled={asLabel}
                    error={handleValidation('primaryBusiness', data?.primaryBusiness)}
                    showErr={hasError}
                />

                <QuestionRadioGroup
                    style={{ fontSize: 15 }}
                    field={"hasBusinessPlan"}
                    question="Does your business have a written business plan?"
                    data={data}
                    handleChange={handleChange}
                    hasError={false}
                    isDisabled={asLabel}
                    defaultValue={false}
                />

                <CustomTextArea
                    labelStyle={{ fontSize: 15 }}
                    placeholder=""
                    value={data?.productsOrServices}
                    onChange={(v) => { handleChange("productsOrServices", v) }}
                    error={handleValidation('productsOrServices', data?.productsOrServices)}
                    showErr={hasError}
                    type="text"
                    label="Please describe any new products or services you intend to offer in the next 12-18 months."
                    className="my-3"
                    isDisabled={asLabel}
                />

                <CustomTextArea
                    labelStyle={{ fontSize: 15 }}
                    placeholder=""
                    value={data?.businessPurpose}
                    onChange={(v) => { handleChange("businessPurpose", v) }}
                    error={handleValidation('businessPurpose', data?.businessPurpose)}
                    showErr={hasError}
                    type="text"
                    label="What is the purpose of upgrading your account with FV Bank? Please describe in detail."
                    className="my-3"
                    isDisabled={asLabel}
                />

                <CustomTextArea
                    labelStyle={{ fontSize: 15 }}
                    placeholder=""
                    value={data?.primaryCustomer}
                    onChange={(v) => { handleChange("primaryCustomer", v) }}
                    error={handleValidation('primaryCustomer', data?.primaryCustomer)}
                    showErr={hasError}
                    type="text"
                    label="Please describe who are your counterparties and primary customers? (If any)"
                    className="my-3"
                    isDisabled={asLabel}
                />

                <SelectField
                    labelStyle={{ fontSize: 15 }}
                    label="What is your company annual revenue range?"
                    value={data?.revenueRange}
                    onChange={(v) => handleChange('revenueRange', v.value)}
                    data={ques4}
                    placeholder="Under $1,000,000"
                    error={handleValidation('revenueRange', data?.revenueRange)}
                    showErr={hasError}
                    isDisabled={asLabel}
                />

                <Row>
                    <Col className='col-md-6 col-12'>
                        <QuestionRadioGroup
                            style={{ fontSize: 15 }}
                            field={"hasAuditStatements"}
                            question="Do you have audited financial statements?"
                            data={data}
                            handleChange={handleChange}
                            hasError={false}
                            isDisabled={asLabel}
                            defaultValue={true}
                        />
                    </Col>

                    <Col className='col-md-6 col-12'>
                        <QuestionRadioGroup
                            style={{ fontSize: 15 }}
                            field={"hasAnnualStatements"}
                            question="Do you have annual financial statements?"
                            data={data}
                            handleChange={handleChange}
                            hasError={hasError}
                            error={handleValidation('hasAnnualStatements', data?.hasAnnualStatements, data)}
                            isDisabled={asLabel}
                            defaultValue={true}
                        />
                    </Col>
                </Row>

     
                <QuestionRadioGroup
                    style={{ fontSize: 15 }}
                    field={"hasFinancialForecast"}
                    question="Since you do not have financials statements, do you have a financial forecast?"
                    data={data}
                    handleChange={handleChange}
                    error={handleValidation('hasFinancialForecast', data?.hasFinancialForecast, data)}
                    hasError={hasError}
                    isDisabled={asLabel}
                    defaultValue={false}
                />

                <CheckBoxFieldMutiple
                    labelStyle={{ fontSize: 15 }}
                    label="Is your business involved in any of the following (check all that apply)"
                    data={ques5}
                    placeholder="Select"
                    value={data.businessInvolvements ? data.businessInvolvements : []}
                    onChange={(v) => handleChange('businessInvolvements', v)}
                    error={handleValidation('businessInvolvements', data.businessInvolvements)}
                    showErr={hasError}
                    isDisabled={false}
                />
            </div>
            <ButtonGroup
                pageNum={pageNum}
                showPageNum={true}
                loading={loading}
                asLabel={asLabel}
                limit={10}
                onNextClick={handleNext}
                step={step}
                onBackClick={decrementStep}
            />
        </>
    )
}

export default Page2;
