import { ThreeBounce } from "better-react-spinkit";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { SelectField } from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import VerificationStepsService from "./VerificationStepsService";
import StepsStatuses from "./components/customiseSteps/StepsStatuses";
import "./style.css";

const EditVerificationSteps = ({ editId, onChange = () => { }, hasErr }) => {

  const { showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});


  const fetch = async () => {
    try {
      let form = await VerificationStepsService.get(editId);
      setData({ ...form });
    } catch (error) {
      showError(error)
    }
  };

  useEffect(() => {
    setData({})
    setLoading(true);
    if (editId) fetch();
    setLoading(false)
  }, [editId, VerificationStepsService?.version]);

  useEffect(() => {
    onChange(data)
  }, [data]);



  return (
    <React.Fragment>
      {loading ?
        <ThreeBounce /> :
        <div style={{ width: "calc(100% - 5px)" }} className="row px-3 ">

          <SelectField
            data={[
              { label: "Business Plus", value: "BUSINESS_PLUS" },
              { label: "Individual Plus", value: "INDIVIDUAL_PLUS" },
              { label: "Business Prime", value: "BUSINESS_PRIME" },
              { label: "Individual Prime", value: "INIDIVIDUAL_PRIME" },
            ]}
            value={data?.forForm}
            required={true}
            onChange={(v) => {
              setData({ ...data, forForm: v?.value })
            }}
            label={"For Form"}
            placeholder={"For Form"}
            
          />


          <StepsStatuses
            steps={data?.steps}
            onUpdate={(v) => {
              setData({ ...data, steps: v })
            }}
          />
        </div>
      }



    </React.Fragment >
  );
};

export default observer(EditVerificationSteps);
