import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "./../../../pages/UTMTracker/UTMTrackerConstant"; // Assuming you have defined ENDPOINTS
import { doGET } from "../../../util/HttpUtil";
class UTMTrackerSelectService {
    trackers = [];
    loading = false;
    error = null;

    constructor() {
        makeAutoObservable(this);
    }

    fetch = async () => {
        this.loading = true;
        try {
            const response = await doGET(ENDPOINTS.gridAll());
            if (response.status === 200) {
                this.trackers = response?.data?.rows;
                this.error = null;
            } else {
                this.error = "Failed to fetch UTM Trackers.";
            }
        } catch (err) {
            this.error = err;
        } finally {
            this.loading = false;
        }
    };
}

const UTMTrackerSelectServiceInstance = new UTMTrackerSelectService();
export default UTMTrackerSelectServiceInstance;
