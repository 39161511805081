import { toJS } from 'mobx'; // Import your Tooltip component
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { BsPersonFill, BsPersonFillAdd } from 'react-icons/bs';
import CustomTooltip from '../../../pages/Booking/components/CustomTooltip';
import { DialogContext } from '../../../store/context/DialogContext';
import SideModal from '../../Layout/SideModal/SideModal';
import { objectIdToColor, useOutsideAlerter } from '../../dnd/board/helper';
import SupervisorInfo from './SupervisorInfo';
import SupervisorSelectService from './../SupervisorSelect/SupervisorSelectService';
import './styles.css'; // Ensure your CSS file path is correct


const DropdownItem = ({ value, employee, onAdd, onRemove, onViewProfile, asLabel }) => {
    return (
        <div className="employee-dropdown-item d-flex align-items-center justify-content-between" onClick={(e) => { e.stopPropagation(); onAdd(employee) }}>

            <div className=' d-flex align-items-center'>
                <SupervisorChip
                    asLabel={asLabel}
                    selectedIds={value}
                    employee={employee}
                    onRemove={onRemove}
                />
                <span className='employee-dropdown-name'>  {employee.name}</span>
            </div>

            {employee.name == "Unassigned" ? null :  <div onClick={(e) => { e.stopPropagation(); onViewProfile(employee._id) }} className='employee-profile-btn'>
                Profile
            </div>}

        </div>
    );
};

const SupervisorChip = ({ selectedIds = [], employee, onRemove, asLabel }) => {
    const { name, _id } = employee;

    return (
        <div>

            <CustomTooltip>
                <CustomTooltip.Body>
                    {_id ? <div className={`employee-chip ${(selectedIds ?? [])?.includes(_id) ? "employee-chip-selected" : ""}`}>
                        <span style={{ backgroundColor: _id ? objectIdToColor(_id) : "#707070" }} className="employee-initials">{(name ?? "").split(' ').map(n => n[0]).join('')}</span>
                        {
                            ((!selectedIds?.length || (selectedIds ?? [])?.includes(_id)) && !asLabel) ?
                                <button className="remove-employee-button p-0 " onClick={(e) => { e.stopPropagation(); onRemove(_id) }}>
                                    <i style={{ fontSize: "10px" }} className="dripicons-cross" />
                                </button>
                                : null
                        }
                    </div> :
                        <BsPersonFill className='my-0 me-0' size={30} style={{ marginLeft: "3px", border: "1px dotted #aaa", borderRadius: "50%", padding: "3px" }} color="#aaa" />
                    }
                </CustomTooltip.Body>
                <CustomTooltip.ToolTip position="bottom">
                    <div>{name}</div>
                </CustomTooltip.ToolTip>
            </CustomTooltip>
        </div >
    );
};

const SupervisiorAssigner = ({
    value,
    onChange = () => { },
    asLabel,
    className,
    showUnassigned
}) => {
    const [employees, setEmployees] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [showEmployeeProfile, setShowEmployeeProfile] = useState(false);
    const { showError } = useContext(DialogContext); // Ensure you have DialogContext
    const wrapperRef = useRef(null); // Create a ref for the wrapper div
    useOutsideAlerter(wrapperRef, () => setShowDropdown(false));

    const fetchEmployees = async () => {
        try {
            await SupervisorSelectService.fetch();
            updateEmployees()
        } catch (error) {
            showError("Error", error)
        }
    };

    const updateEmployees = () => {
        setEmployees(
            toJS(SupervisorSelectService.supervisors)?.map((employee) => {
                return {
                    ...employee,
                    value: employee._id,
                    label: employee.name
                };

            }).filter(Boolean)
        );
    };

    useEffect(() => {
        if (SupervisorSelectService.supervisors.length === 0) fetchEmployees();
    }, []);

    useEffect(() => {
        updateEmployees()
    }, [SupervisorSelectService.supervisors]);


    const handleRemove = (id) => {
        onChange(value.filter(v => v !== id));
    };

    const handleAdd = (employee) => {
        if (!(value ?? []).includes(employee._id) && employee?._id != null) {
            onChange([...(value ?? []), employee._id]);
        } else if (employee?.name == "Unassigned") {
            onChange(null);
        }
    };

    const renderEmployeeSelectModal = () => {
        return (<div className="employee-select-dropdown">
            {[...(showUnassigned ? [{ _id: null, name: "Unassigned" }] : []), ...employees,].map((employee) => (
                <DropdownItem
                    asLabel={asLabel}
                    value={value}
                    key={employee._id}
                    onViewProfile={(v) => { setShowEmployeeProfile(v) }}
                    onRemove={handleRemove}
                    employee={employee}
                    onAdd={handleAdd} />
            ))}
        </div>
        )
    };

    const isEmployeeExistinValue = (value ?? []).some(employeeId =>
        (employees ?? []).some(employee => employee._id === employeeId)
    );

    return (
        <div className={className}>
            <div className='position-relative ' ref={wrapperRef} >
                <div className="employee-assigner" onClick={(e) => { e.stopPropagation(); setShowDropdown(true) }}>
                    {value == null && showUnassigned ?
                        <>
                            <CustomTooltip>
                                <CustomTooltip.Body>
                                    <div className={` employee-chip employee-chip-selected`}>
                                        <span className="employee-initials bg-white">
                                            <BsPersonFill className='my-0 me-0' size={30} style={{  border: "1px dotted #aaa", borderRadius: "50%", padding: "3px" }} color="#aaa" />
                                        </span>

                                        <button className="remove-employee-button p-0 " onClick={(e) => { e.stopPropagation(); onChange([]); }}>
                                            <i style={{ fontSize: "10px" }} className="dripicons-cross" />
                                        </button>

                                    </div>
                                </CustomTooltip.Body>
                                <CustomTooltip.ToolTip position="bottom">
                                    <div>Unassigned</div>
                                </CustomTooltip.ToolTip>
                            </CustomTooltip>
                        </> : (value && value.length > 0 && isEmployeeExistinValue) ? (
                            value.map(employeeId => {
                                const employee = employees.find(e => e._id === employeeId);
                                return employee ? (
                                    <SupervisorChip asLabel={asLabel} key={employee._id} employee={employee} onRemove={handleRemove} />
                                ) : null;
                            })
                        ) : (
                            <button className="add-employee-button m-0 p-0" onClick={(e) => { e.stopPropagation(); setShowDropdown(true) }}>
                                {asLabel ?
                                    <BsPersonFill className='m-0' size={32} style={{ border: "1px dotted #aaa", borderRadius: "50%", padding: "3px" }} color="#aaa" /> :
                                    <BsPersonFillAdd className='m-0' size={32} style={{ border: "1px dotted #aaa", borderRadius: "50%", padding: "3px" }} color="#aaa" />}
                            </button>
                        )}
                </div>

                {(showDropdown && !asLabel) && renderEmployeeSelectModal()}

            </div>

            <SideModal
                visible={showEmployeeProfile}
                toggleVisible={() => { setShowEmployeeProfile(false) }}
                small
            >
                <SupervisorInfo
                    editId={showEmployeeProfile}
                />

            </SideModal>

        </div>

    );
};

export default observer(SupervisiorAssigner);
