export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl) => {
    let url = `/api/risk-calculation/grid?`;
    if (sizePerPage) url += `rows=${sizePerPage}&`;
    if (filterUrl) url += `${filterUrl}&`;
    if (page) url += `page=${page}`
    return url;
  },
  get: (id) => `/api/risk-calculation/detail?id=${id}`,
  save: `/api/risk-calculation/create`,
  delete: (id) => `/api/risk-calculation/delete?id=${id}`,
  bulkDelete: (id) => `/api/risk-calculation/delete`,
  update: `/api/risk-calculation/update`,
};

export const STRUCTURE = [
  {
    label: "Name",
    filterLabel: "Name",
    filterName: "name",
    type: "text",
    icon: "mdi mdi-account-outline font-size-16 ",
  },
  {
    label: "Form",
    filterLabel: "Form",
    filterName: "formType",
    icon:"mdi mdi-form-select font-size-16 ",
    // description: "Filter by Status",
    type: "select",
    data: [
      { label: "Individual Plus", value: "INDIVIDUAL_PLUS" },
      { label: "Business Plus", value: "BUSINESS_PLUS" },
    ],
  },
  // {
  //   label: "Business Name",
  //   filterLabel: "Business Name",
  //   filterName: "businessName",
  //   type: "text",
  //   icon: "mdi mdi-office-building-outline font-size-16 ",
  // },
  // {
  //   label: "Country",
  //   filterLabel: "Country",
  //   filterName: "countryOfIncorporate",
  //   type: "text",
  //   icon: "mdi mdi-earth font-size-16 ",
  // },

];
