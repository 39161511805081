import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { FaPlus } from "react-icons/fa";
import { FiMinusCircle } from "react-icons/fi";
import Editor from "../../../../components/dnd/forms/itinerary/components/RichTextEditor/Editor/Editor";
import { DialogContext } from "../../../../store/context/DialogContext";
import "./styles.scss";
import { InputField } from "../../../../components";

const StepsStatuses = ({ steps = [], onUpdate }) => {
    const { showError, showMessage } = useContext(DialogContext);

    const handleAddStatus = () => {
        const newStatus = {
            name: "Verification Step " + (steps.length + 1),
            details: "",
        };

        onUpdate([...steps, newStatus]);
    };

    const handleDeleteStatus = (index) => {
        const updatedStatuses = steps.filter((_, idx) => idx !== index);
        onUpdate(updatedStatuses);
    };

    const handleChange = (index, field, value) => {
        const updatedStatuses = steps.map((step, idx) =>
            idx === index ? { ...step, [field]: value } : step
        );
        onUpdate(updatedStatuses);
    };

    return (
        <div className="space-statuses">
            <div className="w-100 d-flex justify-content-end">
                <button
                    onClick={handleAddStatus}
                    style={{ padding: "3px 6px 3px 3px", width: "90px" }}
                    className="d-flex align-items-center justify-content-center btn btn-outline-dark py-0 my-2"
                >
                    <FaPlus className="me-2" />
                    <div style={{ margin: "2px", float: "right" }}> Step </div>
                </button>
            </div>

            {steps.map((step, index) => (
                <div className="status-row d-flex flex-column align-items-end justify-content-end card p-2" key={index}>
                    <FiMinusCircle
                        style={{ cursor: "pointer" }}
                        color="red"
                        size={21}
                        onClick={() => handleDeleteStatus(index)}
                    />
                    <InputField
                        type="text"
                        label = "Step Name"
                        placeholder="Name"
                        className="w-100"
                        value={step.name}
                        onChange={(e) => handleChange(index, "name", e)}
                    />
                    <div className="w-100 mt-2">
                        <Editor
                            label="Step Details"
                            placeholder="Enter Detail"
                            value={step.details}
                            onChange={(value) => handleChange(index, "details", value)}
                        />
                    </div>
                </div>
            ))}
        </div>
    );
};

export default observer(StepsStatuses);
