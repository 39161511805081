import { ThreeBounce } from 'better-react-spinkit';
import React from 'react';
import { Button } from 'reactstrap'; // Make sure to import Button from your UI library

export default function PrimaryButton({ children, isDisabled, className, onClick, loading, title }) {

  const nextButtonStyle = {
    backgroundColor: '#1678AE',
    height: "40px",
    padding: "1px 8px 1px 8px",
    fontSize: "15px",
    borderRadius: "10px",
    width: "max-content",
  }


  if (loading) {
    return (
      <Button
        style={nextButtonStyle}
        variant="primary"
        disabled
        className={className}
      >
        <ThreeBounce size={10} color="#FFFFFF" />
      </Button>

    );
  }
  return (
    <Button
      style={nextButtonStyle}
      variant="primary"
      disabled={isDisabled}
      onClick={onClick}
      className={className}

    >
      {children}
    </Button>
  );
}
