import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { FaExternalLinkAlt } from "react-icons/fa";
import { LuHourglass } from "react-icons/lu";
import { MdReportGmailerrorred } from "react-icons/md";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  CheckButton,
  DataGrid,
  EditLink,
  Layout
} from "../../components";
import ApproveButton from "../../components/Buttons/ApproveButton";
import RejectButton from "../../components/Buttons/RejectButton";
import SupervisiorAssigner from "../../components/Dropdowns/SupervisiorSelect/SupervisiorAssigner";
import { ModalPopup } from "../../components/Modal/ModalPopup";
import { DialogContext } from "../../store/context/DialogContext";
import { UserContext } from "../../store/context/UserContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import { STRUCTURE } from "./ApplicationsConstant";
import { GridColumns } from "./ApplicationsGridColumns";
import ApplicationsService from "./ApplicationsService";
import EditForms from "./EditApplications";
import "./style.css";
import { FaCircle, FaHourglassStart, FaExclamationTriangle, FaCheckCircle, FaTimesCircle, FaHourglassEnd, FaBan, FaListAlt } from 'react-icons/fa';
import PagesNoRights from "../Utility/pages-no-rights";


const SupervisorFilter = observer(({ value, onChange = () => { } }) => {
  return <div style={{ backgroundColor: "#EEEEEE" }} className=" px-2 pe-3 font-size-12 d-flex align-items-center rounded-3 border" >
    <div className="me-2 ">
      Assigned To
    </div>
    <SupervisiorAssigner
      showUnassigned
      value={value}
      onChange={onChange}
    />
  </div>
})

const Applications = ({ value, insidePane, multiMode, onSelect = () => { } }) => {
  let { Bedit_id } = useParams();
  const location = useLocation();

  let navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const { t } = useContext(I18nContext);
  const { userContent } = useContext(UserContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);

  const [loading, setLoading] = useState(false);
  const [detailData, setDetailData] = useState({});
  const [editID, setEditID] = useState(Bedit_id);
  const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/applications/create" || Bedit_id);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [filterURL, setFilterURL] = useState("");
  const [filterObject, setFilterObject] = useState({});
  const [hasErr, setHasErr] = useState(false)
  const [showCustomizationOptions, setShowCustomizationOptions] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [approvalRequested, setApprovalRequested] = useState(false)

  const fetchData = async (filterUrl) => {
    setFilterURL(filterUrl);
    if (loading) return;
    setLoading(true);
    try {
      await ApplicationsService.fetch(filterUrl);
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    if (userContent?.rights?.includes(2800)) {
      const { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE)
      const supervisor_ids = ApplicationsService?.supervisor_ids || []
      setFilterObject({ ...filterObject, ...(supervisor_ids && { supervisor_ids }) })
      fetchData(updateFilterURLWithSupervisorIds(filterUrl || '', supervisor_ids));
      multiMode ? setSelectedIDs(value) : setSelectedIDs(value ? [value] : [])
    }
  }, [userContent?.rights])

  useEffect(() => {
    if (Bedit_id) {
      setEditID(Bedit_id)
    } else {
      setShowDetailPage(false)
      setEditID(null);
    }
  }, [Bedit_id])


  const onSave = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      if (editID) {
        await ApplicationsService.edit(detailData);
        setLoading(false);
        showMessage(t("Form updated successfully."));
      } else {
        const form = await ApplicationsService.save(detailData);
        setLoading(false);
        showMessage(t("Form saved successfully."));
        if (!insidePane) navigate(`/applications/edit/${form}`);
      }
      fetchData(filterURL);

    } catch (e) {
      setLoading(false);
      showError(e);
    }
    setHasErr(false)
  };

  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: t("Do you want to delete record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      setLoading(true);
      try {
        await ApplicationsService.delete(id);
        setLoading(false);
        showMessage("Form Deleted SuccessFully", "Deleted");
        navigate("/applications");
        setShowDetailPage(false);

      } catch (e) {
        setLoading(false);
        showError(e);
      }
    }
  };


  const renderLastCol = useCallback((form) => {
    return (
      <>
        {userContent?.rights?.includes(2800) ?
          <EditLink
            onClick={() => {
              if (!insidePane)
                navigate(`/applications/edit/${form?._id}`);
              setEditID(form?._id);
              setShowDetailPage(true);
            }}
          /> : <></>
        }

        {(form?.status == 6) && form?.cyclosUserId && userContent?.rights?.includes(2700) ? <FaExternalLinkAlt
          onClick={() => { navigate(`/accounts/detail/${form?.cyclosUserId}`) }}
          style={{ cursor: "pointer" }}
          size={14}
          color="primary"
        /> : <></>
        }


        {(form.status == 5) ? <LuHourglass
          size={14}
          color="#FB654E"
        /> : <></>
        }

        {(form.status == 7) ? <MdReportGmailerrorred
          size={18}
          color="#eb2d3a"
        /> : <></>
        }


        {/* <DeleteLink
          onClick={(event) => onDelete(event, form?._id)}
        /> */}

        {!multiMode && insidePane ? (
          <CheckButton
            onClick={() => {
              onSelect(form?._id);
            }}
          />
        ) : null}
      </>
    );
  }, [insidePane, multiMode, userContent])



  const updateFilterURLWithSupervisorIds = (filterUrl = "", supervisor_ids) => {
    const supervisorIdsPattern = /&supervisor_ids=(in\[[^\]]*\]|null)/;
    let newFilterURL = filterUrl.replace(supervisorIdsPattern, '');
    if (supervisor_ids?.length) {
      newFilterURL = `${newFilterURL}&supervisor_ids=in[${supervisor_ids.join(',')}]`;
    } else if (supervisor_ids == null) {
      newFilterURL = `${newFilterURL}&supervisor_ids=null`;
    }
    return newFilterURL;
  };


  const handleSupervisorFilter = async (supervisor_ids) => {
    ApplicationsService?.setSupervisorIds(supervisor_ids);
    const newFilterURL = updateFilterURLWithSupervisorIds(filterURL || '', supervisor_ids);
    const filterObj = { ...(filterObject ?? {}), supervisor_ids: supervisor_ids ?? [] };
    setFilterURL(newFilterURL);
    setFilterObject(filterObj);
    ApplicationsService?.resetPagination()
    fetchData(newFilterURL);
  };

  const parseFilterUrl = (filterUrlProp) => {
    const params = new URLSearchParams(filterUrlProp);
    const parsedObject = {};
    for (let [key, value] of params.entries()) {
      parsedObject[key] = value;
    }
    return parsedObject;
  };



  const applyFilterWrapper = (filterUrlProp) => {
    const parsedFilterObject = parseFilterUrl(filterUrlProp);

    const filterUrl = updateFilterURLWithStatus(
      updateFilterURLWithSupervisorIds(filterUrlProp || '', ApplicationsService?.supervisor_ids || []),
      ApplicationsService?.applicationStatus || ''
    );

    const updatedFilterObject = {
      ...parsedFilterObject, // Parsed URL parameters
      supervisor_ids: ApplicationsService?.supervisor_ids || [], // Append supervisor IDs
      status: ApplicationsService?.applicationStatus || '', // Append status
    };

    setFilterObject(updatedFilterObject); // Update the filter object state
    ApplicationsService?.resetPagination()
    fetchData(filterUrl); // Fetch data with the updated filter URL
  };

  const updateFilterURLWithStatus = (filterUrl = "", status) => {
    const statusPattern = /&status=(\d+|in\[[^\]]*\])/; 
    let newFilterURL = filterUrl.replace(statusPattern, ''); // Remove existing status if any
    if (status !== "") {
      newFilterURL = `${newFilterURL}&status=${status}`;
    }
    return newFilterURL;
  };

  const handleStatusFilter = async (status) => {
    ApplicationsService?.setApplicationStatus(status); // Update status in service
    const newFilterURL = updateFilterURLWithStatus(filterURL, status); // Update URL
    const filterObj = { ...(filterObject ?? {}), status }; // Update filter object
    setFilterURL(newFilterURL); // Set the updated URL
    setFilterObject(filterObj); // Set the updated filter object
    ApplicationsService?.resetPagination()
    fetchData(newFilterURL); // Refetch the data
  };


  const statusToggleData = (t) => ({
    module: [
      { label: t("All"), value: `in[0,1,2,3,4,5,6,7]`, backgroundColor: "#FFFFFF", keyColor: "#000000", icon: <FaListAlt /> }, // White with Black List Icon for "All"
      { label: t("Draft"), value: 0, backgroundColor: "#E0E0E0", keyColor: "#4A4A4A", icon: <FaCircle /> },
      { label: t("Submitted"), value: 1, backgroundColor: "#C1C1C1", keyColor: "#333333", icon: <FaHourglassStart /> },
      { label: t("Incomplete"), value: 2, backgroundColor: "#F8D76E", keyColor: "#4A4A4A", icon: <FaExclamationTriangle /> },
      { label: t("Approved"), value: 3, backgroundColor: "#B2D8B2", keyColor: "#3D5941", icon: <FaCheckCircle /> },
      { label: t("Rejected"), value: 4, backgroundColor: "#F1A7A6", keyColor: "#8B4A4A", icon: <FaTimesCircle /> },
      { label: t("Account Requested"), value: 5, backgroundColor: "#D3CCE3", keyColor: "#4A3D59", icon: <FaHourglassEnd /> },
      { label: t("Account Created"), value: 6, backgroundColor: "#B5EAD7", keyColor: "#3D5D4A", icon: <FaCheckCircle /> },
      { label: t("Account Creation Failed"), value: 7, backgroundColor: "#F2B87E", keyColor: "#7A4A2D", icon: <FaBan /> },
    ],
    toggleValue: ApplicationsService?.applicationStatus
  })

  if (!userContent?.rights?.includes(2800)) {
    return <div key={userContent?.rights} > <PagesNoRights /></div> 
  }

  return (
    <React.Fragment key={userContent?.rights}>
      <Layout
        onApplyFilter={applyFilterWrapper}
        hideAdd
        showDetailPage={showDetailPage}
        backDetailPage={async () => {
          setShowDetailPage(false);
          if (!insidePane) navigate("/applications");
          setEditID(null);
          fetchData(filterURL)
        }}
        customTitleComponent={
          <SupervisorFilter
            value={toJS(ApplicationsService?.supervisor_ids)}
            onChange={(v) => handleSupervisorFilter(v)}
          />
        }
        title={t("Applications")}
        filterValues={filterObject}
        filterStructure={STRUCTURE}
        toggleData={statusToggleData(t)}
        showToggle
        toggleType={"dropdown"}
        onToggle={(selectedValue) => {
          handleStatusFilter(selectedValue); // Call status filter handler
        }}
        onAddClick={() => {
          if (!insidePane) navigate(`/applications/create`);
          setShowDetailPage(true);
          setEditID(null);
        }}
        insidePane={insidePane}
        page={ApplicationsService.page}
        rows={ApplicationsService.rows}
        total={ApplicationsService.total}
        fetch={ApplicationsService.fetch}
      >
        <Layout.Table>
          <DataGrid
            data={ApplicationsService.records}
            total={ApplicationsService.total}
            gridLoading={loading}
            uiPreference="form.grid"
            headers={GridColumns}
            onSelectChange={(v) => {
              onSelect(v)
              setSelectedIDs(v)
            }}
            selectedIDs={selectedIDs}
            page={ApplicationsService.page}
            rowsPerPage={ApplicationsService.rowsPerPage}
            onPaginationChange={ApplicationsService.onPaginationChange}
            renderLastCol={renderLastCol}
          />
        </Layout.Table>

        <Layout.DetailPageBody>
          <EditForms
            editId={editID}
            fetchData={fetchData}
            onChange={(v) => {
              setDetailData(v)
            }}
            hasErr={hasErr}
          />
        </Layout.DetailPageBody>


      </Layout>


      <ModalPopup
        isOpen={openModal}
        setIsOpen={setOpenModal}
        title={approvalRequested ? "Approve Cooment" : "Reject Comment"}
        className="d-flex justify-content-center align-items-center"
        modalStyle={{ fontSize: "24px", marginTop: "220px" }}
        body={<div className="" style={{ backgroundColor: "#F1FAFC", width: "650px", height: "250px", borderRadius: "10px" }}>
          <textarea
            type="text"
            // value={comment}
            // onChange={handleCommentChange}
            placeholder="Add comment here..."
            className="mx-3 ms-4 mt-5 inputfield flex-1"
            style={{
              width: "600px",
              borderRadius: "14px",
              outline: "none",
              border: "1px solid #C6F3FF",
              backgroundColor: "#FFFFFF",
              height: "94px",
              marginLeft: "auto",
              lineHeight: "94px", // Center text vertically
              overflowY: "hidden",
              fontSize: "15px",
              color: "#C4C4C4"
            }}
            rows="2"
          />

          <div className="mt-4" style={{ marginBottom: "10px", marginLeft: "auto", marginRight: "28px" }}>
            {approvalRequested ?
              <ApproveButton onClick={onSave} loading={loading} style={{ marginLeft: "auto" }} />
              : <RejectButton className="me-0" onClick={(e) => setOpenModal(!openModal)} loading={loading} style={{ marginLeft: 'auto', borderRadius: "14px", marginRight: "0px important" }}
              />
            }
          </div>
        </div>}
      />

    </React.Fragment>
  );
};

export default observer(Applications);


