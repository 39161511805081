import { FaPaperclip, FaSpinner } from 'react-icons/fa';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { FaCheckDouble, FaComments, FaGlobe, FaLayerGroup, FaReplyAll, FaServer } from 'react-icons/fa';
import IoSend from '../../assets/Icons/IoSend.svg';
import { doGET, doPOST } from '../../util/HttpUtil';
import './Comment.scss';
import CommentItem from './CommentItem'; // Adjust if you have a similar component for verifications
import CustomTooltip from '../../pages/Booking/components/CustomTooltip/index';
import { Documents } from '..';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { UserContext } from '../../store/context/UserContext';

const Comment = ({ refreshId, recordType, recordOf, byType, forType, formType }) => {
    const [comment, setComment] = useState('');
    const [itemsList, setItemsList] = useState([]);
    const [filterType, setFilterType] = useState('all');
    const [modal, setModal] = useState(false);
    const toggleModal = () => setModal(!modal);
    const [uboDetail, setUboDetail] = useState(null);
    const [loading, setLoading] = useState(false);
    const [paginationLoading, setPaginationLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 10;
    const [hasMoreComments, setHasMoreComments] = useState(true);
    const [initialLoadComplete, setInitialLoadComplete] = useState(false);
    const commentListRef = useRef(null);
    const prevScrollHeightRef = useRef(0);
    const { userContent } = useContext(UserContext);

    useEffect(() => {
        setCurrentPage(1);
        setInitialLoadComplete(false);
        setHasMoreComments(true);
        fetchItems(false, 1, false, true);
    }, [recordType, recordOf, formType, refreshId]);



    const fetchItems = async (loadMore = false, forcedPage, forceInitial, forceHasMore) => {
        if (recordType && recordOf && formType) {
            if (loadMore) {
                setPaginationLoading(true);
            } else {
                setLoading(true);
            }

            try {
                const commentsResponse = await doGET(`/api/comment/grid?sortBy=at&sortAsc=false&recordType=${recordType}&recordOf=${recordOf}&rows=${rowsPerPage}&page=${forcedPage ?? currentPage}`);
                const fetchedItems = commentsResponse.data.rows.map(comment => ({
                    ...comment,
                    itemType: 'comment',
                    time: comment?.at,
                }));

                fetchedItems.sort((a, b) => a.time - b.time);

                // Append to existing comments if loading more, otherwise set fresh items
                setItemsList(prevItems => loadMore ? [...fetchedItems, ...prevItems] : fetchedItems);
                setHasMoreComments(fetchedItems.length === rowsPerPage);

                if (!(forceInitial ?? initialLoadComplete) ) {
                    setInitialLoadComplete(true);
                    setCurrentPage(2);
                } else if (loadMore) {
                    setCurrentPage(prevPage => prevPage + 1);
                }
            } catch (error) {
                console.error('Error fetching items:', error);
            } finally {
                setLoading(false);
                setPaginationLoading(false);
            }
        }
    };
    const handleScroll = () => {
        const { scrollTop, scrollHeight } = commentListRef.current;
        if (scrollTop === 0 && hasMoreComments && !paginationLoading && initialLoadComplete) {
            prevScrollHeightRef.current = scrollHeight;
            fetchItems(true);
        }
    };

    useEffect(() => {
        const listElement = commentListRef.current;
        if (listElement && itemsList.length > 0) {
            const scrollHeightDiff = listElement.scrollHeight - prevScrollHeightRef.current;
            if (scrollHeightDiff > 0 && listElement.scrollTop === 0) {
                listElement.scrollTop = scrollHeightDiff;
            }
        }
    }, [itemsList]);

    const handleCommentChange = (e) => {
        setComment(e.target.value);
    };


    const saveComment = async () => {
        try {
            const response = await doPOST('/api/comment/create', {
                recordType,
                recordOf,
                byType,
                forType,
                content: {
                    remark: comment,
                },
            });

            if (response?.status === 200) {
                return response?.data?._id;
            }
        } catch (error) {
            console.error('Error posting comment:', error);
        }
    };

    const postComment = async () => {
        setLoading(true);
        try {
            await saveComment();
            setComment('');
            resetPaginationAndFetch(); // Reset pagination after posting a comment
        } catch (error) {
            console.error('Error posting comment:', error);
        } finally {
            setLoading(false);
        }
    };

    const resetPaginationAndFetch = () => {
        setCurrentPage(1); // Reset the page to 1
        setInitialLoadComplete(false); 
        setHasMoreComments(true);
        fetchItems(false, 1, false, true);

        // Scroll to the bottom after posting
        if (commentListRef.current) {
            setTimeout(() => {
                commentListRef.current.scrollTop = commentListRef.current.scrollHeight;
            }, 100); // Delay to allow items to render
        }
    };

    const getCommentTime = (item) => {
        const itemDate = moment(item.time * 1000);
        const today = moment();
        const yesterday = moment().subtract(1, 'days');

        if (itemDate.isSame(today, 'day')) {
            return "Today";
        } else if (itemDate.isSame(yesterday, 'day')) {
            return "Yesterday";
        } else {
            return itemDate.format("Do MMMM");
        }
    };

  
    const groupItemsByDay = (items) => {
        const grouped = {};
        items.forEach(item => {
            const day = getCommentTime(item);
            if (!grouped[day]) {
                grouped[day] = [];
            }
            grouped[day].push(item);
        });
        return grouped;
    };


    const filteredItems = itemsList.filter(item => {
        if (filterType === 'all') return true;
        return filterType === 'comment' ? item?.by != "System" : item?.by == "System";
    });


    const groupedItems = groupItemsByDay(filteredItems);






    const fetchUBOs = async () => {
       if(recordOf) {
           setLoading(true);
           try {
               const uboResponse = await doGET(`/api/ubo/grid?application_id=${recordOf}`);
               const obj = {};
               (uboResponse?.data?.rows ?? []).forEach((v) => {
                   obj[v?._id] = v;
               });
               setUboDetail(obj);
           } catch (error) {
               console.error('Error fetching ubo details:', error);

           } finally {
               setLoading(false);
           }
       }
    }

    useEffect(() => {
        fetchUBOs();
    }, [recordOf])



    return (
        <div style={{ position: "relative" }} className="comment-component d-flex flex-column flex-1 ">

            <div style={{}} className="filter-icons d-flex justify-content-center mb-2">
                <CustomTooltip>
                    <CustomTooltip.Body>
                        <FaCheckDouble
                            className={`icon-filter ${filterType === 'all' ? 'active' : ''}`}
                            onClick={() => setFilterType('all')}
                            size={18}
                        />
                    </CustomTooltip.Body>
                    <CustomTooltip.ToolTip>
                        <div style={{ fontSize: "10px" }}>All</div>
                    </CustomTooltip.ToolTip>
                </CustomTooltip>

                <CustomTooltip>
                    <CustomTooltip.Body>
                        <FaComments
                            className={`icon-filter ${filterType === 'all' || filterType === 'comment' ? 'active' : ''}`}
                            onClick={() => setFilterType(filterType === 'comment' ? 'all' : 'comment')}
                            size={20}
                        />
                    </CustomTooltip.Body>
                    <CustomTooltip.ToolTip>
                        <div style={{ fontSize: "10px" }}>Supervisors Comments</div>
                    </CustomTooltip.ToolTip>
                </CustomTooltip>

                <CustomTooltip>
                    <CustomTooltip.Body>

                        <FaServer
                            className={`icon-filter ${filterType === 'all' || filterType === 'verification' ? 'active' : ''}`}
                            onClick={() => setFilterType(filterType === 'verification' ? 'all' : 'verification')}
                            size={18}
                        />
                    </CustomTooltip.Body>
                    <CustomTooltip.ToolTip>
                        <div style={{ fontSize: "10px" }}>System Generated</div>
                    </CustomTooltip.ToolTip>
                </CustomTooltip>

            </div>


            <div
                style={{ borderBottom: "1px solid #EBEBEB" }}
                className="flex-1 comments-grid"
                ref={commentListRef}
                onScroll={handleScroll}
            >
                {Object.keys(groupedItems).map(day => (
                    <div className="my-3" key={day}>
                        <div className="d-flex justify-content-center">
                            <div className="d-flex align-items-center justify-content-center" style={{ border: "1px solid #C6F3FF", borderRadius: "17px", width: "136px", height: "35px", backgroundColor: '#F1FAFC' }}>{day}</div>
                        </div>
                        {groupedItems[day].map(comment => (
                            <CommentItem uboDetail={uboDetail} key={comment._id} comment={comment} />
                        ))}
                    </div>
                ))}

                {paginationLoading && (
                    <div className="d-flex justify-content-center py-2">
                        <FaSpinner size={18} className="spinner" />
                    </div>
                )}
            </div>



            {userContent?.rights?.includes(2803) ?  <div className='d-flex align-items-center py-2 bg-white position-relative'>
                <textarea
                    type="text"
                    value={comment}
                    onChange={handleCommentChange}
                    placeholder="Write a comment..."
                    className=" my-0 ms-2 inputfield flex-1"
                    style={{ width: "100%", borderRadius: "14px", outline: "none", border: "1px solid #C6F3FF", backgroundColor: '#F1FAFC', height: "65px" }}
                    rows="2"
                />

                <div className='d-flex ps-2 align-items-center position-absolute' style={{ right: "20px", top: "50%", transform: "translateY(-50%)" }}>
                    {loading ?
                        <FaSpinner size={18} className="spinner" /> :
                        <>
                            <img src={IoSend} onClick={postComment} style={{ cursor: "pointer", height: "20px" }} />
                            <FaPaperclip onClick={toggleModal} style={{ cursor: "pointer", marginLeft: '10px', fontSize: '20px' }} />
                        </>
                    }
                    {/* <div className=' btn btn-outline-success' onClick={postComment}>Send</div> */}
                </div>
            </div> : <></>} 

            <Modal centered isOpen={modal} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>Post a comment</ModalHeader>
                <ModalBody>

                    <textarea
                        type="text"
                        value={comment}
                        onChange={handleCommentChange}
                        placeholder="Write a comment..."
                        className=" my-0 pt-2 inputfield flex-1"
                        style={{ width: "100%", borderRadius: "14px", outline: "none", border: "1px solid #C6F3FF", backgroundColor: '#F1FAFC', height: "65px" }}
                        rows="2"
                    />

                    <Documents
                        single
                        module="comment"
                        getRecordId={saveComment}
                     
                        onChange={() => {
                            setComment('');
                            setModal(!modal);
                            resetPaginationAndFetch(); // Use the same function to reset pagination and fetch comments
                        }}
                    />

                </ModalBody>
            </Modal>
        </div>
    );
};

export default Comment;
