import React, { useContext } from 'react';
import { QuestionRadioGroup } from '../../components/IndividualLite/DeclarationForm';
import CustomTextArea from '../../../../../components/InputField/CustomTextArea';
import ButtonGroup from '../../BusinessLiteForms/components/ButtonGroup';
import { DialogContext } from '../../../../../store/context/DialogContext';

const handleValidation = (field, value, data) => {
    if (field === "primaryMsbBusinessModel") {
        if (!value || value?.length < 10 || value?.length > 500) {
            return "Please provide a detailed description of your business model (10-500 characters).";
        }
        return "";
    }

    if (field === "registeredWithFinCen") {
        if (!!data?.hasUsCustomers && !value) {
            return "This must be marked 'Yes' since you have customers in the United States.";
        }
        return "";
    }

    if (field === "msbLicenseLocation") {
        if (!!data?.hasMsbLicense && (!value || value?.trim() === "")) {
            return "This field is required.";
        }
        return "";
    }

    if (field === "licenseDetails") {
        if (!!data?.holdsLicense && (!value || value?.trim() === "")) {
            return "This field is required.";
        }
        return "";
    }

    return "";
};

const Page4 = ({ asLabel, data, handleChange, pageNum, loading, step, decrementStep, handleSubmit }) => {
    const [hasError, setHasError] = React.useState(false);
    const { showError } = useContext(DialogContext);

    const validateFields = () => {
        const requiredFields = [
            'primaryMsbBusinessModel',
            'registeredWithFinCen',
            'msbLicenseLocation',
            'licenseDetails',
        ];

        let errors = [];

        requiredFields.forEach(field => {
            const fieldError = handleValidation(field, data[field], data);
            if (fieldError) {
                errors.push(fieldError);
            }
        });

        if (errors.length > 0) {
            setHasError(true);
            showError(errors[0]);
            return false;
        }

        setHasError(false);
        return true;
    };

    const handleNext = async () => {
        if (validateFields()) {
            await handleSubmit(false);
        }
    };

    return (
        <>
            <div className='mb-3'>
                <h2 style={{ fontWeight: 'bolder' }} className='pt-3'>Money Services Business</h2>

                <p className='pt-2'>
                    For the purposes of this application, FV Bank considers all of the following to be Money Services Businesses, whether a license is required or not in your jurisdictions. (Exchanges, OTC Providers, Liquidity Providers, Wallet Providers, Non-Bank Financial Institutions, VASPS, DLT's EMI's, PSD license holders, Brokers, Remittance Providers, and FX Providers.)
                </p>

                <QuestionRadioGroup
                    style={{ fontSize: 15 }}
                    field={"isMoneyServiceBusiness"}
                    question="Confirm if your business is a Money Services Business as defined above."
                    data={data}
                    handleChange={handleChange}
                    hasError={false}
                    isDisabled={asLabel}
                    defaultValue={false}
                />

                <CustomTextArea
                    labelStyle={{ fontSize: 15 }}
                    placeholder=""
                    value={data?.primaryMsbBusinessModel}
                    onChange={(v) => { handleChange("primaryMsbBusinessModel", v) }}
                    error={handleValidation('primaryMsbBusinessModel', data?.primaryMsbBusinessModel)}
                    showErr={hasError}
                    type="text"
                    label="What is your primary business model as an MSB or money transmitter?"
                    className="mt-3"
                    isDisabled={asLabel}
                    required
                />

                <QuestionRadioGroup
                    style={{ fontSize: 15 }}
                    field={"hasUsCustomers"}
                    question="Do you have customers in the United States that you transact with?"
                    data={data}
                    handleChange={handleChange}
                    hasError={false}
                    isDisabled={asLabel}
                    defaultValue={false}
                />

                <QuestionRadioGroup
                    style={{ fontSize: 15 }}
                    field={"registeredWithFinCen"}
                    question="Are you registered with FinCen?"
                    data={data}
                    handleChange={handleChange}
                    hasError={hasError}
                    error={handleValidation('registeredWithFinCen', data?.registeredWithFinCen, data)}
                    isDisabled={asLabel}
                    required={!!data?.hasUsCustomers}
                    defaultValue={false}
                />

                <QuestionRadioGroup
                    style={{ fontSize: 15 }}
                    field={"hasMsbLicense"}
                    question="Do you have issued MSB or similar licenses?"
                    data={data}
                    handleChange={handleChange}
                    hasError={false}
                    isDisabled={asLabel}
                    defaultValue={false}
                />

                <CustomTextArea
                    labelStyle={{ fontSize: 15 }}
                    placeholder=""
                    value={data?.msbLicenseLocation}
                    onChange={(v) => { handleChange("msbLicenseLocation", v) }}
                    error={handleValidation('msbLicenseLocation', data?.msbLicenseLocation, data)}
                    showErr={hasError}
                    type="text"
                    label="If Yes, please list states/countries where your MSB/MTL or similar licenses are issued."
                    className="mt-3"
                    isDisabled={asLabel}
                    required={!!data?.hasMsbLicense}
                />

                <QuestionRadioGroup
                    style={{ fontSize: 15 }}
                    field={"holdsLicense"}
                    question="Do you hold licenses in any jurisdiction?"
                    data={data}
                    handleChange={handleChange}
                    hasError={false}
                    isDisabled={asLabel}
                    defaultValue={false}
                />

                {data?.holdsLicense ? (
                    <CustomTextArea
                        labelStyle={{ fontSize: 15 }}
                        placeholder=""
                        value={data?.licenseDetails}
                        onChange={(v) => { handleChange("licenseDetails", v) }}
                        error={handleValidation('licenseDetails', data?.licenseDetails, data)}
                        showErr={hasError}
                        type="text"
                        label="List any licenses held and the jurisdiction of issuance."
                        className="mt-3"
                        isDisabled={asLabel}
                        required
                    />
                ) : null}
            </div>
            <ButtonGroup
                pageNum={pageNum}
                showPageNum={true}
                loading={loading}
                asLabel={asLabel}
                limit={10}
                onNextClick={handleNext}
                step={step}
                onBackClick={decrementStep}
            />
        </>
    );
};

export default Page4;
