import React from 'react';
import "./styles.scss";


const fixedTypes = {
  "fitness-probity": {
    "value": "Fitness & Probity",
    "data": {
      "fitness-probity": "Fitness & Probity",
    }
  },
  "warning": {
    "value": "Warnings",
    "data": {
      "warning": "Warnings"
    }
  },
  "sanction": {
    "value": "Sanctions",
    "data": {
      "sanction": "Sanctions"

    }
  },
  "pep": {
    "value": "PEPs",
    "data": {
      "pep-class-1": "PEP Class 1",
      "pep-class-2": "PEP Class 2",
      "pep-class-3": "PEP Class 3",
      "pep-class-4": "PEP Class 4",
    }
  },
  "adverse-media": {
    "value": "Adverse Media",
    "data": {
      "adverse-media-financial-crime": "Financial Crime",
      "adverse-media-violent-crime": "Violent Crime",
      "adverse-media-terrorism": "Terrorism",
      "adverse-media-fraud": "Fraud",
      "adverse-media-narcotics": "Narcotics",
      "adverse-media-sexual-crime": "Sexual Crime",
      "adverse-media-general": "Other Crime",
    }
  }
};

function AppearsOn({ types = [], name }) {
  const countCategories = {};

  // Initialize counters for each category based on types provided
  types.forEach(type => {
    const categoryKey = Object.keys(fixedTypes).find(key =>
      fixedTypes[key].data.hasOwnProperty(type)
    );

    if (categoryKey && fixedTypes[categoryKey]) {
      if (!countCategories[categoryKey]) {
        countCategories[categoryKey] = { count: 0, subtypes: [] };
      }
      countCategories[categoryKey].count++;
      countCategories[categoryKey].subtypes.push(fixedTypes[categoryKey].data[type]);
    }
  });

  if (name === "Abdul Rashid Salim Salman Khan") {
  console.log({ countCategories , types})
}

  const labels = Object.keys(countCategories).map(key => {
    if (countCategories[key].count > 1) {
      return `${fixedTypes[key].value} (${countCategories[key].count })`;
    } else {
      return countCategories[key].subtypes[0] || fixedTypes[key].value;
    }
  });

  return (
    <div className="fv_appears_on_container">
      {labels.map((label, index) => (
        <div key={index} className="fv_appears_on_label">
          {label}
        </div>
      ))}
    </div>
  );
}

export default AppearsOn;
