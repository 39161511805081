import React, { useEffect, useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import { FaCheck, FaChevronDown, FaChevronRight } from "react-icons/fa";

const Stepper = ({ children, activeTab, asLabel, onChange }) => {
  const [activeStep, setActiveStep] = useState(activeTab || 1);
  const [openSteps, setOpenSteps] = useState(() => children.map(() => true));

  useEffect(() => {
    if (activeTab) {
      setActiveStep(activeTab);
      if (asLabel) {
        setOpenSteps((prev) => prev.map((_, index) => index + 1 === activeTab));
      }
    }
  }, [activeTab, children.length, asLabel]);

  const toggleStep = (stepNum) => {
    if (!asLabel) {
      setActiveStep(stepNum);
      onChange?.(stepNum);
    } else {
      setOpenSteps((prev) =>
        prev.map((open, index) => (index + 1 === stepNum ? !open : open))
      );
    }
  };

  const renderStepsIndicator = () => (
    <Nav className="step-nav">
      {children
        .filter(Boolean)
        .map((child, index) => {
          const stepNum = index + 1;
          const isActive = activeStep === stepNum;
          const isCompleted = activeStep > stepNum;

          return (
            <NavItem key={index} className={classnames("step-item", { active: isActive, completed: isCompleted })}>
              <NavLink
                className={classnames("step-link", { active: isActive, completed: isCompleted })}
                style={{ position: "relative" }}
                onClick={() => toggleStep(stepNum)}
              >
                <span className="step-indicator" style={{ backgroundColor: isCompleted ? "#1678AE" : "#fff" }}>
                  {isCompleted ? <FaCheck color="#fff" size={20} /> : <span className="step-number"></span>}
                </span>
              </NavLink>
            </NavItem>
          );
        })}
    </Nav>
  );

  const renderAccordion = () => (
    <div>
      {children
        .filter(Boolean)
        .map((child, index) => {
          const isOpen = openSteps[index];
          return (
            <div key={index}>
              <div
                className='my-3'
                style={{ fontSize: "16px", fontWeight: "500", cursor: "pointer", display: "flex", alignItems: "center" }}
                onClick={() => toggleStep(index + 1)}
              >
                {isOpen ? <FaChevronDown size={16} style={{ marginRight: "10px" }} /> : <FaChevronRight size={16} style={{ marginRight: "10px" }} />}
                {child.props.label}
              </div>
              {isOpen && child}
            </div>
          );
        })}
    </div>
  );

  return (
    <div className="steps-progress">
      {!asLabel && renderStepsIndicator()}
      {asLabel ? renderAccordion() :
        <TabContent activeTab={activeStep}>
          {children
            .filter(Boolean)
            .map((child, index) => {
              const tabId = index + 1;
              return activeStep === tabId ? <TabPane key={index} tabId={tabId}>{child}</TabPane> : null;
            })}
        </TabContent>
      }
    </div>
  );
};

export default Stepper;
