import { makeAutoObservable } from "mobx";
import { doGET } from "../../util/HttpUtil";
import { ENDPOINTS } from "./AccountSearchConstant";

class Service {
    records = [];
    pageTx = 1;
    rowsPerPageTx = 40;
    filterOption = "";
    total = -1;
    version = 0;
    constructor() {
        makeAutoObservable(this);
    }


    fetch = async (email, username, phone, accountNumber) => {
        let url = "";
        if (!!email) url += `email:${encodeURIComponent(email)},`;
        if (!!username) url += `username:${encodeURIComponent(username)},`;
        if (!!phone) url += `phone:${encodeURIComponent(phone)},`;
        if (!!accountNumber) url += `accountNumber:${encodeURIComponent(accountNumber)},`;
        // Remove trailing comma
        url = url.replace(/,$/, '');

        const response = await doGET(
            ENDPOINTS.grid(url)
        );
        this.records = response.data;
        return response.data;
    };

    fetchUser = async (id) => {
        const response = await doGET(
            ENDPOINTS.getUser(id)
        );
        return response.data;
    };

    fetchTransactions = async (filter, id) => {
        const pagination = `page=${this.pageTx}&rows=${this.rowsPerPageTx}&`
        const filterUrl = !!filter ? filter : "";
        const response = await doGET(ENDPOINTS.transactionsGrid(id, pagination + filterUrl));
        return response.data;
    }

    fetchAccountListData = async (id) => {
        const response = await doGET(ENDPOINTS.accountListData(id));
        return response.data;
    }

    fetchBeneficiariesData = async (id) => {
        const response = await doGET(ENDPOINTS.beneficiariesData(id));
        return response.data;
    }
}

const AccountSearchService = new Service();
export default AccountSearchService;