import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Alert, DropdownItem, DropdownMenu } from "reactstrap";
import {
  CheckButton,
  DataGrid,
  DeleteButton,
  DeleteLink,
  EditLink,
  Layout,
  SaveButton
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { UserContext } from "../../store/context/UserContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import AuditLogDialog from "../Booking/components/Audit/AuditLog";
import SetupProgressService from "../Dashboard/SetUpProgressService";
import EditReports from "./EditReport";
import { STRUCTURE } from "./ReportConstant";
import { ReportGridColumns } from "./ReportGridColumns";
import ReportService from "./ReportService";
import CustomOperations from "../../components/CustomField/CustomOperations";

const Reports = ({ value, insidePane, multiMode, onSelect = () => { } }) => {
  let { report_id } = useParams();
  const location = useLocation();

  let navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const { t } = useContext(I18nContext);
  const { userContent } = useContext(UserContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);

  const [loading, setLoading] = useState(false);
  const [detailData, setDetailData] = useState({});
  const [editID, setEditID] = useState(report_id);
  const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/reports/create" || report_id);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [filterURL, setFilterURL] = useState("");
  const [filterObject, setFilterObject] = useState({});
  const [hasErr, setHasErr] = useState(false)
  const [showCustomizationOptions, setShowCustomizationOptions] = useState(false)
  const [onAlert, setOnAlert] = useState(false)

  const fetchData = async (filterUrl) => {
    setFilterURL(filterUrl);
    if (loading) return;
    setLoading(true);
    try {
      await ReportService.fetch(filterUrl);
      setLoading(false);

    } catch (e) {
      setLoading(false);
      showError(e);
    }
  };

  useEffect(() => {
    const { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE)
    setFilterURL(filterUrl)
    setFilterObject(filterObject)
    fetchData(filterUrl);
    multiMode ? setSelectedIDs(value) : setSelectedIDs(value ? [value] : [])
  }, [])


  useEffect(() => {
    setEditID(report_id)
  }, [report_id])

  const onSave = async (e) => {
    e.preventDefault();
    // if (!detailData?.address?.name) {
    //   setHasErr(true)
    //   showError(t("Please enter all required fields"));
    //   return;
    // }
    setLoading(true);
    try {
      if (editID) {
        await ReportService.edit(detailData, editID);
        setLoading(false);
        showMessage(t("Report updated successfully."));
      } else {
        const report = await ReportService.save(detailData);
        setLoading(false);
        showMessage(t("Report saved successfully."));
        if (!insidePane) navigate(`/reports/edit/${report}`);
      }
      fetchData(filterURL);

    } catch (e) {
      setLoading(false);
      showError(e);
    }
    setHasErr(false)
  };
  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: t("Do you want to delete record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      setLoading(true);
      try {
        await ReportService.delete(id);
        setLoading(false);
        showMessage("Report Deleted SuccessFully", "Deleted");
        navigate("/reports");
        setShowDetailPage(false);

      } catch (e) {
        setLoading(false);
        showError(e);
      }
    }
  };
  const onBulkDelete = async () => {
    if (
      await showConfirm({
        title: t("Do you want to delete all the selected record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      try {
        await ReportService.bulkDelete(selectedIDs);
        showMessage(t("Deleted"), "Deleted Successfully");
        setShowDetailPage(false);

      } catch (e) {
        showError(e);
      }
    }
  };


  const renderLastCol = useCallback((report) => {
    return (
      <>
        {/* {userContent?.rights?.includes(1502) && */}
          <EditLink
            onClick={() => {
              if (!insidePane)
                navigate(`/reports/edit/${report?._id}`);
              setEditID(report?._id);
              setShowDetailPage(true);

            }}
          />
        {/* }
        {userContent?.rights?.includes(1503) ? */}

          <DeleteLink
            onClick={(event) => onDelete(event, report?._id)}
          />
          {/* : null} */}
        {!multiMode && insidePane ? (
          <CheckButton
            onClick={() => {
              onSelect(report?._id);
            }}
          />
        ) : null}
      </>
    );
  }, [insidePane, multiMode, userContent])

  const markCompletedFunc = async () => {
    try {
      await SetupProgressService?.edit("report");
    } catch (error) {

    }
  }

  useEffect(() => {
    if (!SetupProgressService?.records) {
      SetupProgressService.fetch()
    }
  }
    , [SetupProgressService?.records])

  return (
    <>
      <Layout
        // //hideAdd={!userContent?.rights?.includes(1501)}
        medium
        showDetailPage={showDetailPage}
        backDetailPage={async () => {
          setShowDetailPage(false);
          if (!insidePane) navigate("/reports");
          setEditID(null);
        }}
        gridLoading={loading}
        title={t("Reports")}
        filterValues={filterObject}
        filterStructure={STRUCTURE}
        onApplyFilter={fetchData}
        onAddClick={() => {
          if (!insidePane) navigate(`/reports/create`);
          setShowDetailPage(true);
          setEditID(null);
        }}
        insidePane={insidePane}
        page={ReportService.page}
        rows={ReportService.rows}
        total={ReportService.total}
        fetch={ReportService.fetch}
      >
        <Layout.ActionMenu>
          <div className="layout-action-menu">
            <DropdownMenu>
              <>
                <DropdownItem onClick={() => { onBulkDelete() }} >{t("Bulk Delete")}</DropdownItem>
                <DropdownItem>{t("Upload")}</DropdownItem>
                <DropdownItem>{t("Download")} </DropdownItem>
              </>
            </DropdownMenu>
          </div>
        </Layout.ActionMenu>

        <Layout.Table>
          <DataGrid
            data={ReportService.records}
            total={ReportService.total}
            uiPreference="report.grid"
            headers={ReportGridColumns}
            onSelectChange={(v) => {
              onSelect(v)
              setSelectedIDs(v)
            }}
            selectedIDs={selectedIDs}
            page={ReportService.page}
            rowsPerPage={ReportService.rowsPerPage}
            onPaginationChange={ReportService.onPaginationChange}
            renderLastCol={renderLastCol}
          />
        </Layout.Table>


        <Layout.DetailPageTitle>
          {detailData?._id ? t("Edit Report") : t("Add Report")}
        </Layout.DetailPageTitle>

        <Layout.DetailPageBody>
          <EditReports
            editId={editID}
            onChange={(v) => {
              setDetailData(v)
            }}
            hasErr={hasErr}
          />
        </Layout.DetailPageBody>

        <Layout.DetailPageFooter>
          {/* {editID && userContent?.rights?.includes(1503) && (detailData?._id) ?  */}
          <DeleteButton onClick={(e) => onDelete(e, detailData?._id)} loading={loading} />
           {/* : null}
          {userContent?.rights?.includes(1502) ?  */}
          <SaveButton onClick={onSave} loading={loading} /> 
          {/* : null} */}
        </Layout.DetailPageFooter>
      </Layout>
      {showCustomizationOptions ? <CustomOperations
        isModal
        module="report"
        isOpen={showCustomizationOptions}
        toggleModal={() => { setShowCustomizationOptions(false) }}
      /> : null
      }
    </>
  );
};
export default observer(Reports);
