export const ENDPOINTS = {
    grid: ({ page, sizePerPage, filterUrl, corp_id }) => {
        let url = `/api/supervisor/grid?withExtn=true&${corp_id ? `corp_id=${corp_id}&` : ""}rows=${sizePerPage}&`;
        if (filterUrl) url += filterUrl;
        return url + "&page=" + page;
    },
    get: ({ editId, corp_id }) => `/api/supervisor/detail?id=${editId}${corp_id ? `&corp_id=${corp_id}` : ""}`,

    save: () => `/api/supervisor/create`,
    delete: (id, corp_id) => `/api/supervisor/delete?id=${id}${corp_id ? `?corp_id=${corp_id}` : ""}`,

    bulkDelete: (id) => `/api/supervisor/delete`,
    update: ({ corp_id }) => `/api/supervisor/update${corp_id ? `?corp_id=${corp_id}` : ""}`,

};

export const STRUCTURE = [
    {
        label: "Name",
        filterLabel: "Supervisor Name",
        filterName: "name",
        type: "text",
        icon: "mdi mdi-account-tie font-size-16 ",
    },
    {
        label: "email",
        filterLabel: "Email Address",
        filterName: "email",
        type: "text",
        icon: "mdi mdi-email-outline font-size-16 ",
    },
    {
        label: "Phone Number",
        filterLabel: "Phone Number",
        filterName: "phoneNo.phone",
        type: "text",
        icon: "mdi mdi-phone-outline font-size-16 ",
    },
    // {
    //     label: "Designation",
    //     filterLabel: "Supervisor's Designation",
    //     filterName: "supervisorExtn.designation",
    //     type: "text",
    // },

];
