export const ENDPOINTS = {
    grid: (filterurl) => {
        let url = `/api/cyclos/users?profileFields=${filterurl}`;
        return url;
    },
    getUser: (id) => `/api/cyclos/users?usersToInclude=${id}`,
    transactionsGrid: (id, filter) => `/api/cyclos/${id}/transactions?${filter}`,
    accountListData: (id) => `/api/cyclos/${id}/list-data`,
    beneficiariesData: (id) => `/api/cyclos/${id}/general-records`,
};

const paymentDataValues = [
    {
        label: "Canceled",
        value: "canceled",
    },
    {
        label: "Denied",
        value: "denied",
    },
    {
        label: "Expired",
        value: "expired",
    },
    {
        label: "Open",
        value: "open",
    },
    {
        label: "Closed",
        value: "closed",
    },
    {
        label: "Processed",
        value: "processed",
    },
    {
        label: "Scheduled",
        value: "scheduled",
    },
    {
        label: "Blocked",
        value: "blocked",
    },
]

export const TRANSACTION_FILTER = [
    {
        label: "Transaction Number",
        filterLabel: "Transaction No.",
        filterName: "transactionNumber",
        // description: "Filter by phone number",
        type: "text",
        // icon: "mdi mdi-phone  font-size-16 ",
    },
    {
        label: "Transfer Filters",
        filterLabel: "Transfer Filters",
        filterName: "transferFilters",
        // description: "Filter by phone number",
        type: "select",
        data: [
            {
                label: "Card Fee and Charges",
                value: "BUS_USD_Account.Card_Fee_Charges",
            },
            {
                label: "Crossborder Payments",
                value: "BUS_USD_Account.corssborder_payments",
            },
            {
                label: "Debit Card Transaction",
                value: "BUS_USD_Account.Debit_Card_Transation",
            },
            {
                label: "FV Net Transfer",
                value: "BUS_USD_Account.FV_Net_Transfer",
            },
            {
                label: "Fee",
                value: "BUS_USD_Account.fee",
            },
            {
                label: "Outbound - Domestic ACHs",
                value: "BUS_USD_Account.Outbound_Domestic_ACHs",
            },
            {
                label: "Outbound - Domestic Wires",
                value: "BUS_USD_Account.Outbound_Domestic_Wires",
            },
            {
                label: "Outbound - International Wires",
                value: "BUS_USD_Account.Outbound_International_Wires",
            },
            {
                label: "Outbound Payments",
                value: "BUS_USD_Account.BUS_System",
            },
            {
                label: "Stablecoin",
                value: "BUS_USD_Account.Payment_Stablecoin",
            },
        ]
        // icon: "mdi mdi-phone  font-size-16 ",
    },
    {
        label: "Is Authorized",
        filterLabel: "Is Authorized",
        filterName: "authorized",
        // description: "Filter by phone number",
        type: "select",
        data: [
            {
                label: "Yes",
                value: "true",
            },
            {
                label: "No",
                value: "false",
            },
        ],
        // icon: "mdi mdi-phone  font-size-16 ",
    },
    {
        label: "Authorization Status",
        filterLabel: "Auth Status",
        filterName: "authorizationStatuses",
        // description: "Filter by phone number",
        type: "select",
        data: [
            {
                label: "Authorized",
                value: "authorized",
            },
            {
                label: "Cancelled",
                value: "canceled",
            },
            {
                label: "Denied",
                value: "denied",
            },
            {
                label: "Expired",
                value: "expired",
            },
            {
                label: "Pending",
                value: "pending",
            },
        ]
        // icon: "mdi mdi-phone  font-size-16 ",
    },
    {
        label: "Kinds",
        filterLabel: "Kinds",
        filterName: "kinds",
        // description: "Filter by phone number",
        type: "select",
        data: [
            {
                label: "Charge Back",
                value: "chargeback",
            },
            {
                label: "External Payment",
                value: "externalPayment",
            },
            {
                label: "Import",
                value: "import",
            },
            {
                label: "Order",
                value: "order",
            },
            {
                label: "Payment",
                value: "payment",
            },
            {
                label: "Payment Request",
                value: "paymentRequest",
            },
            {
                label: "Recurring Payment",
                value: "recurringPayment",
            },
            {
                label: "Scheduled Payment",
                value: "scheduledPayment",
            },
            {
                label: "Ticket",
                value: "ticket",
            },
        ]
        // icon: "mdi mdi-phone  font-size-16 ",
    },
    {
        label: "Payment Request Status",
        filterLabel: "Payment Request Status",
        filterName: "paymentRequestStatuses",
        // description: "Filter by phone number",
        type: "select",
        data: paymentDataValues
        // icon: "mdi mdi-phone  font-size-16 ",
    },
    {
        label: "Recurring Ticket Status",
        filterLabel: "Recurring Ticket Status",
        filterName: "recurringPaymentStatuses",
        // description: "Filter by phone number",
        type: "select",
        data: paymentDataValues
        // icon: "mdi mdi-phone  font-size-16 ",
    },
    {
        label: "Scheduled Ticket Status",
        filterLabel: "Scheduled Ticket Status",
        filterName: "scheduledPaymentStatuses",
        // description: "Filter by phone number",
        type: "select",
        data: paymentDataValues
        // icon: "mdi mdi-phone  font-size-16 ",
    },
    {
        label: "Ticket Status",
        filterLabel: "Ticket Status",
        filterName: "ticketStatuses",
        // description: "Filter by phone number",
        type: "select",
        data: paymentDataValues
        // icon: "mdi mdi-phone  font-size-16 ",
    },
]