

export const API_METHODS = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE",
};

export const SOCKET_BASE_URL = 'http://164.52.214.106:4005'

export const ENDPOINTS = {
  cpanelLogin: "/api/o/v2/cpanel",
  cpanelgetCaptcha: `api/o/captcha/cpanelLogin?id=${Date.now()}`,
  LoginGetCaptcha: `/api/o/otp/create`,
  signUpSelf: `api/o/corporate/signup`,
  updateCorporate: `api/corporate/update`,
  getDetail: (id) => `/api/corporate/detail?id=${id}`,
  changePhone: "api/o/supervisor/change/phone",
  changeEmail: "api/o/supervisor/change/email",

  getWebContent: `api/web-content`,

  login: "/auth/login",
  forgetPassword: "/api/o/supervisor/password/reset",
  logout: "/auth/signout",
  signUp: "/auth/b2c/new/customer",
  createOrder: "/api/o/order/create",
  makeQrCode: "/api/o/make/qrcode",
  updateProfile: " /api/customer/profile",
  updateUserAddress: "/api/customer/address",
  updateUserQR: "/api/customer/qr",
  removeQR: "/api/customer/qr",
  getProfile: "/api/corp/profile/data",
  userContent: "/api/profile",
  allProfiles: "/api/profile/all",
  allProfiles: (forSuperadmin) => {
    let url = "/api/profile/all";
    if (forSuperadmin) url += `/list?for${forSuperadmin ? "superadmin" : "supervisor"}&`;
    return url;
  },
  getCaptcha: `api/o/captcha/forgetPassword?id=${Date.now()}`,
  getUserOrders: "/api/order/grid",
  // forgetPassword: "/auth/customer/password/reset",
  getInvoices: (page) =>
    `api/invoice/corp/subscription/grid?page=${page}&size=30`,
  updateOrder: "/api/order/update",
  getOrder: "/api/order/:id/detail",
  addProduct: "/api/product/save",
  getProducts: "/api/product/grid",
  getProductsById: (id) => `/api/product/detail?id=${id}`,
  delProduct: (id) => `/api/product/delete?id=${id}`,
  addressSuggestions: (query) => `/api/o/address/autocomplete?query=${query}`,
  getAddress: (id) => `/api/o/address/${id}`,
  getShippingCharges: `/api/o/order/shipping/amount`,
  // getAllFuelPump : `/api/fuelpump/getAllFuelPump`
  getAllFuelPump: `/api/o/language/allLang`,
  getAllClients: `/api/corp/clients/list?withSelf=true`,
  getAllCurrencies: `/api/o/currencies`,
  getAddressLine: (id) => `/api/o/maputil/autocomplete?key=${id}`,
  getClientGroups: `/api/clientgroup/map`,
  deleteSupervisorById: (id) => `/api/supervisor/delete?id=${id}`,
  deleteOfferById: (id) => `/api/offer/delete?id=${id}`,
  getAllSOSLogs: (page, sizePerPage, filterData) => {
    let s = `/api/soslog/data?sortBy=_id&sortAsc=false&rows=${sizePerPage}`;
    if (filterData) {
      if (filterData.from_date) s += "&from_date=" + filterData.from_date;
      if (filterData.to_date) s += "&to_date=" + filterData.to_date;
      if (filterData.status !== "") s += "&status=" + filterData.status;
    }
    return s + `&page=${page}`;
  },
  download: (filterUrl, template_id) => {
    let url = `/api/fuel/download/template?template_id=${template_id}&`;
    if (filterUrl) url += filterUrl;
    return url;
  }, // /api/fuel/download/template

  getAllSupervisor: (page, sizePerPage, filterUrl) => {
    let url = `/api/supervisor/grid?rows=${sizePerPage}&`;
    if (filterUrl) url += filterUrl;
    return url + `&page=${page}`;
  },

  getZoneById: (id) => `/api/zone/detail?id=${id}`,
  getAllZones: (page, sizePerPage, name) =>
    `api/zone/grid?rows=${sizePerPage}&name=${name}&page=${page}`,
  getCitiesFromCountry: (country) => `/api/city/select/data?country=${country}`,
  getAllZoneVendors: (page, sizePerPage, name) =>
    `/api/corp/client/vendor/data/vendors?page=1&rows=100&withSelf=true`,
  getAllZoneCLients: (id) =>
    `/api/corp/client/vendor/data/clients?page=1&rows=100&withSelf=true`,
  getCountries: `/api/o/country/list`,
  getVendors: `/api/corp/vendors/self/map`,
  getStatesByCountry: (country) => `/api/o/country/${country}/states`,
  getCitiesByCountryAndState: (country, state) =>
    `/api/o/country/${country}/state/${state}/city/list`,
  getSupervisorById: (id) => `/api/supervisor/${id}/get`,

  getAllZonesList: `/api/zone/map/list`,
  updateSOSLog: `/api/soslog/status/update`,
  getFilteredClients: (
    filterClientUniqueId,
    filterClientName,
    filterClientZone,
    filterClientCountry,
    filterClientCity
  ) =>
    `/api/corp/client/vendor/data/clients?page=1&rows=100&fullName=${filterClientName}&zone_id=${filterClientZone}&country=${filterClientCountry}&city=${filterClientCity}&withSelf=true&unique_id=${filterClientUniqueId}`,
  getFilteredVendors: (filterVendorName) =>
    `/api/corp/client/vendor/data/vendors?page=1&rows=100&fullName=${filterVendorName}&withSelf=true`,
  editZone: (zoneId) => `/api/zone/${zoneId}/edit`,
  saveNewOffer: `/api/offer/new`,
  saveNewZone: `/api/zone/save`,
  getEntityById: (id) => `/api/businessUnits/list?corp_id=${id}`,
  getUserRole: "/api/role/map",
  changeSupervisor: "/api/supervisor/save",
  getSupervisorZones: () => `/api/corp/zones/get`,
  getClientsByZone: (id) => `/api/zone/${id}/clientByZone`,
  getCitiesByZone: (id) => `/api/zone/${id}/citiesByZone`,
  deleteZoneById: (id) => `/api/zone/delete?id=${id}`,
  getAllCountries: `/api/o/country/all?rows=-1`,
  getAllSupervisorRoles: (page, sizePerPage) =>
    `/api/role/grid?rows=${sizePerPage}&page=${page}`,
  getSupervisorRoleById: (id) => `/api/role/detail?id=${id}`,
  saveNewSupervisorRole: `/api/role/save`,
  deleteSupervisorRoleById: (id) => `/api/role/delete?id=${id}`,
  getAllFuelPrices: (page, sizePerPage, filterName) => {
    let url = `/api/fuelcost/grid?rows=${sizePerPage}&page=${page}&`;
    if (filterName) url += filterName;
    return url + `&page=${page}`;
  },
  supervisorRoleMap: `/api/access/right/map`,
  getAllBookingVehicles: (page, sizePerPage, filterUrl) => {
    let url = `/api/fuel/summary/grid?sortAsc=false&sortBy=yearMonth&rows=${sizePerPage}&`;
    if (filterUrl) url += filterUrl;
    return url + `&page=${page}`;
  },
  getFuelById: (id) => `/api/fuel/detail?id=${id}`,
  deleteFuel: (id) => `/api/fuel/delete?id=${id}`,
  saveFuel: `/api/fuel/save`,
  getAllVehicles: `/api/assign/booking/vehicle/data?rows=-1`,
  getFuelSummaryTimeSheet: (id) => `/api/fuel/${id}/trips?forMonthly=true`,
  getAllFundAccounts: `/api/fund/account/grid`,
};

export const PackOptions = [
  {
    id: 1,
    value: "4",
    price: 20,
  },
  {
    id: 2,
    value: "20",
    price: 30,
  },
  {
    id: 3,
    value: "50",
    price: 50,
  },
  {
    id: 4,
    value: "100",
    price: 70,
  },
];

export const StatusOptions = [
  {
    id: 0,
    value: "Unpaid",
  },
  {
    id: 1,
    value: "Open",
  },
  {
    id: 2,
    value: "Packed",
  },
  {
    id: 3,
    value: "Dispatched",
  },
  {
    id: 4,
    value: "Delivered",
  },
];

export const AdhesiveCardTypes = [
  {
    id: 1,
    name: "Regular",
    desc: "Adheres to most surfaces, plastic, wood, paint and won't damage surface upon removal",
  },
  {
    id: 2,
    name: "Glass",
    desc: "Stickers come with an additional adhesive seal to adhere to outdoor and indoor windows.",
  },
  {
    id: 3,
    name: "Metal",
    desc: "Tougher adhesive and thicker materials to block interference when adhered to metal surfaces",
  },
];
export const adminHeaders = ["Date", "Order No.", "Quantity", "Cost", "Status"];

export const sideNavItems = [
  {
    link: "/orders",
    name: "Orders",
    role: "User",
    picture: "/assets/images/orders-icon.png",
  },
  {
    link: "/account",
    name: "Account",
    role: "User",
    picture: "../../assets/images/orders-icon.png",
  },
  {
    link: "/products",
    name: "Products",
    role: "Admin",
    picture: "../../assets/images/orders-icon.png",
  },
];

export const products = [
  {
    picture: "../../assets/images/qr-image.png",
    name: "Covid Stickers Glass",
    price: "$40 NZD",
  },
  {
    picture: "../../assets/images/qr-image.png",
    name: "Covid Stickers Glass",
    price: "$40 NZD",
  },
  {
    picture: "../../assets/images/qr-image.png",
    name: "Covid Stickers Glass",
    price: "$40 NZD",
  },
  {
    picture: "../../assets/images/qr-image.png",
    name: "Covid Stickers Glass",
    price: "$40 NZD",
  },
  {
    picture: "../../assets/images/qr-image.png",
    name: "Covid Stickers Glass",
    price: "$40 NZD",
  },
  {
    picture: "../../assets/images/qr-image.png",
    name: "Covid Stickers Glass",
    price: "$40 NZD",
  },
];

export const MapStyles = [
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [{ color: "#e9e9e9" }, { lightness: 17 }],
  },
  {
    featureType: "landscape",
    elementType: "geometry",
    stylers: [{ color: "#f5f5f5" }, { lightness: 20 }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.fill",
    stylers: [{ color: "#ffffff" }, { lightness: 17 }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [{ color: "#ffffff" }, { lightness: 29 }, { weight: 0.2 }],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry",
    stylers: [{ color: "#ffffff" }, { lightness: 18 }],
  },
  {
    featureType: "road.local",
    elementType: "geometry",
    stylers: [{ color: "#ffffff" }, { lightness: 16 }],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [{ color: "#f5f5f5" }, { lightness: 21 }],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [{ color: "#dedede" }, { lightness: 21 }],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [{ visibility: "on" }, { color: "#ffffff" }, { lightness: 16 }],
  },
  {
    elementType: "labels.text.fill",
    stylers: [{ saturation: 36 }, { color: "#333333" }, { lightness: 40 }],
  },
  {
    elementType: "labels.icon",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [{ color: "#f2f2f2" }, { lightness: 19 }],
  },
  {
    featureType: "administrative",
    elementType: "geometry.fill",
    stylers: [{ color: "#fefefe" }, { lightness: 20 }],
  },
  {
    featureType: "administrative",
    elementType: "geometry.stroke",
    stylers: [{ color: "#fefefe" }, { lightness: 17 }, { weight: 1.2 }],
  },
];



