import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Tab } from '../../components';
import SubmitButton from '../../components/Buttons/SubmitButton';
import Editor from '../../components/dnd/forms/itinerary/components/RichTextEditor/Editor/Editor';
import { DialogContext } from '../../store/context/DialogContext';
import { doGET, doPUT } from '../../util/HttpUtil';
import { UserContext } from '../../store/context/UserContext';
import PagesNoRights from '../Utility/pages-no-rights';

const ENDPOINTS = {
  update: (key) => `/api/sys-config/${key}/update`,
  detail: (key) => `/api/sys-config/${key}/detail`,
};

const tabToKey = [
  "individualLiteDisclosure",
  "businessLiteDisclosure",
  "businessPrimeDisclosure",
  "individualPrimeDisclosure",
];

const Disclosures = () => {
  const [step, setStep] = useState(1);
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(false);
  const { showError } = useContext(DialogContext);
  const { userContent } = useContext(UserContext)

  const fetch = async () => {
    setLoading(true)
    try {
      const response = await doGET(ENDPOINTS.detail(tabToKey[step - 1]));
      if (response?.status === 200) {
        setContent(response.data?.[tabToKey[step - 1]] ?? "");
      }
    } catch (error) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  const update = async () => {
    try {
      setLoading(true);
      const response = await doPUT(ENDPOINTS.update(tabToKey[step - 1]), { [tabToKey[step - 1]] : content});
      if (response.status === 200) {
        fetch();
      }
    } catch (error) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userContent?.rights?.includes(2100)) {
      fetch();
    }
  }, [step])

  const handleSubmit = (e) => {
    e.preventDefault();
    update();
  };

  const renderTabContent = () => {
   return loading ? <div>
     <Skeleton height={200} />
   </div>  :  <div>
      <Editor
        label=""
        placeholder="Enter Content"
        value={content}
        onChange={setContent}
      />
      <div className="d-flex w-100 justify-content-between mt-3">
        <div className="flex-1"></div>
         {userContent?.rights?.includes(2101) ?  <SubmitButton
          loading={loading}
          onClick={handleSubmit}
        /> : null}
      </div>
    </div>
  };


  if (!userContent?.rights?.includes(2100)) {
    return <div key={userContent?.rights} > <PagesNoRights /></div>
  }

  return (
    <div key = {userContent?.rights}>
      <Tab
        activeTab={step}
        onChange={setStep}
        outerClassName="mt-0 pt-0"
        className="d-flex"
      >
        <div num={1} label="Individual Plus">{renderTabContent()}</div>
        <div num={2} label="Business Plus">{renderTabContent()}</div>
        <div num={3} label="Individual Prime">{renderTabContent()}</div>
        <div num={4} label="Business Prime">{renderTabContent()}</div>
      </Tab>
    </div>
  );
};

export default observer(Disclosures);
