import React, { useContext, useEffect, useState, useCallback } from 'react';
import SupervisiorAssigner from '../../../../components/Dropdowns/SupervisiorSelect/SupervisiorAssigner';
import ApplicationsService from '../../ApplicationsService';
import { DialogContext } from '../../../../store/context/DialogContext';
import { debounce } from 'lodash';
import { observer } from 'mobx-react-lite';
import { UserContext } from '../../../../store/context/UserContext';

const AssignSupervisorFromGrid = ({ dataObj }) => {

    const [supervisor_ids, setSupervisor_ids] = useState(dataObj?.supervisor_ids ?? []);
    const { showError } = useContext(DialogContext);
    const { userContent } = useContext(UserContext);

    const handleAssignesChange =  debounce(async (v) => {
            try {
                const data = await ApplicationsService.get(dataObj._id);
                await ApplicationsService.edit({ ...data, supervisor_ids: v });
                setSupervisor_ids(v);
            } catch (error) {
                console.log(error);
            }
    }, 500) 

    useEffect(() => {
        setSupervisor_ids(dataObj?.supervisor_ids);
    }, [dataObj?.supervisor_ids]);

    return (
        <div className='d-flex align-items-center'>
            <SupervisiorAssigner
                value={supervisor_ids}
                asLabel={!userContent?.rights?.includes(2806)}
                onChange={(v) => { handleAssignesChange(v); }}
            />
        </div>
    );
};

export default observer(AssignSupervisorFromGrid);
