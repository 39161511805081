const productionUrls = {
    complyAdvantage: 'https://app.eu.complyadvantage.com',
    ondato: 'https://os.ondato.com',
    parcha: 'https://app.parcha.ai'
};

const stagingUrls = {
    complyAdvantage: 'https://app.eu.complyadvantage.com',
    ondato: 'https://sandbox-os.ondato.com',
    parcha: 'https://demo-ext.parcha.ai'
};

export function getBaseUrls() {
    const host = window.location.host;
    const productionHost = 'complyai.fvbank.us';
    return host === productionHost ? productionUrls : stagingUrls;
}