import React, { useContext } from 'react';
import { QuestionRadioGroup } from '../../components/IndividualLite/DeclarationForm';
import CustomTextArea from '../../../../../components/InputField/CustomTextArea';
import { InputField } from '../../../../../components';
import CheckBoxFieldMutiple from '../../../../../components/CheckBox/CheckBoxFieldMutiple';
import { ques14 } from '../constants';
import ButtonGroup from '../../BusinessLiteForms/components/ButtonGroup';
import { DialogContext } from '../../../../../store/context/DialogContext';

const handleValidation = (field, value, data) => {
    if (field === 'reasonsForLimitsOthers') {
        if (!!data?.reasonsForLimits?.includes("Other") && (!value || value?.trim() === "")) {
            return "Please specify the other reasons for higher limits.";
        }
        return "";
    }

    if (field === 'numberOfEmployees') {
        if (!value) {
            return "Please enter the number of employees.";
        } else if (isNaN(value) || value <= 0) {
            return "Please enter a valid positive number.";
        }
        return "";
    }

    if (field === 'mainDrivers') {
        if (!value || value?.trim() === "") {
            return "Please describe the main drivers for higher limits or transaction volumes.";
        }
        return "";
    }

    if (field === 'reasonsForLimits') {
        if (!value || value?.length < 2) {
            return "Please select at least two reasons for requiring higher limits.";
        }
        return "";
    }

    return "";
};

const Page8 = ({ asLabel, data, handleChange, pageNum, loading, step, decrementStep, handleSubmit }) => {

    const [hasError, setHasError] = React.useState(false);
    const { showError } = useContext(DialogContext);

    const validateFields = () => {
        const requiredFields = [
            'reasonsForLimitsOthers',
            'numberOfEmployees',
            'mainDrivers',
            'reasonsForLimits',
        ];

        let errors = [];

        requiredFields.forEach(field => {
            const fieldError = handleValidation(field, data[field], data);
            if (fieldError) {
                errors.push(fieldError);
            }
        });

        if (errors.length > 0) {
            setHasError(true);
            showError(errors[0]);
            return false;
        }

        setHasError(false);
        return true;
    };

    const handleNext = async () => {
        if (validateFields()) {
            await handleSubmit(false);
        }
    };

    return (
        <>
            <div className='mb-3'>
                <h2 style={{ fontWeight: 'bolder' }} className='pt-3'>Corporate Account Upgrade</h2>

                <QuestionRadioGroup
                    style={{ fontSize: 15 }}
                    field={"usesOwnFunds"}
                    question="Confirm that the use of your account is for your own funds and own expenses (no third parties)."
                    data={data}
                    handleChange={handleChange}
                    hasError={hasError}
                    error={handleValidation('usesOwnFunds', data?.usesOwnFunds, data)}
                    isDisabled={asLabel}
                    defaultValue={false}
                />

                <CheckBoxFieldMutiple
                    labelStyle={{ fontSize: 15 }}
                    label="Please indicate the top two reasons your account requires higher limits."
                    data={ques14}
                    placeholder="Select"
                    value={data.reasonsForLimits ? data.reasonsForLimits : []}
                    onChange={(v) => handleChange('reasonsForLimits', v)}
                    error={handleValidation('reasonsForLimits', data?.reasonsForLimits, data)}
                    showErr={hasError}
                    isDisabled={false}
                />

                {!!data?.reasonsForLimits?.includes("Other") ? (
                    <InputField
                        className="mt-3"
                        label="If Others, please specify."
                        labelStyle={{ fontSize: 15 }}
                        placeholder=""
                        value={data?.reasonsForLimitsOthers}
                        onChange={(v) => { handleChange("reasonsForLimitsOthers", v) }}
                        error={handleValidation('reasonsForLimitsOthers', data?.reasonsForLimitsOthers, data)}
                        showErr={hasError}
                        type="text"
                        isDisabled={asLabel}
                    />
                ) : null}

                <InputField
                    className="mt-3"
                    label="How many employees does your company have (include full-time and contractors)?"
                    labelStyle={{ fontSize: 15 }}
                    placeholder=""
                    value={data?.numberOfEmployees}
                    onChange={(v) => { handleChange("numberOfEmployees", v) }}
                    error={handleValidation('numberOfEmployees', data?.numberOfEmployees, data)}
                    showErr={hasError}
                    isDisabled={asLabel}
                    type="number"
                />

                <CustomTextArea
                    labelStyle={{ fontSize: 15 }}
                    placeholder=""
                    value={data?.mainDrivers}
                    onChange={(v) => { handleChange("mainDrivers", v) }}
                    error={handleValidation('mainDrivers', data?.mainDrivers, data)}
                    showErr={hasError}
                    type="text"
                    label="Describe the main drivers for higher limits or transaction volumes being required."
                    className="mt-3"
                    isDisabled={asLabel}
                />
            </div>
            <ButtonGroup
                pageNum={pageNum}
                showPageNum={true}
                loading={loading}
                asLabel={asLabel}
                limit={10}
                onNextClick={handleNext}
                step={step}
                onBackClick={decrementStep}
            />
        </>
    )
}

export default Page8;
