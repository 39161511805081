import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { UserContext } from "../../store/context/UserContext";
import UserProfile from "./UserProfile/UserProfile";

const Settings = (props) => {

    const {  userContent } = useContext(UserContext);
    const [supervisorId, setSupervisorId] = useState("")


    useEffect(() => {
        setSupervisorId(userContent?.supervisor_id)
    }, [userContent])

    return (

        <React.Fragment>
            <div className="">
                <Row>
                    <Col className={`left-scroll-independent col-12`} >
                        <Card className="mx-0 pt-4">

                            <CardBody>
                                        <UserProfile

                                            supervisorId={supervisorId} />
                            </CardBody>

                        </Card>
                    </Col>
                </Row>
            </div>
        </React.Fragment>


    );
};

export default observer(Settings);
