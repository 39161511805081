import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import DocCategoryService from '../../pages/DocCategory/DocCategoryService';
import { toJS } from 'mobx';
import { FaPlus } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

const DocTypeDropdown = ({ module = "", label, value, asLabel, onChange, className }) => {
    const [data, setData] = useState([]);
    const [selectedOption, setSelectedOption] = useState({});
    const navigate = useNavigate()

    const fetchCategories = () => {
        if (DocCategoryService.originalData?.length) {
            const filteredData = toJS(DocCategoryService.originalData)?.filter(record => module ? record.module === module : true);
            const transformedData = DocCategoryService.transformData(filteredData).map(category => ({
                label: category.title,
                options: category.children.map(child => ({
                    value: child.value,
                    label: child.title,
                    groupValue: category.value,
                    groupLabel: category.title,
                }))
            }));
            setData(transformedData);
            const selected = transformedData.flatMap(group => group.options).find(option => option.value === value);
            setSelectedOption(selected);
        } else {
            DocCategoryService.fetchSingleton();
        }

    }

    useEffect(() => {
        fetchCategories()
    }, [module, value, DocCategoryService.originalData]);

    if (asLabel) {
        return <div className=" my-0 mx-2" style={{
            display: "flex",
            alignItems: "center",
            border: `1px solid #ced4da`,
            borderRadius: "5px",
            backgroundColor: "white",
            padding: "6px",
            paddingTop: "3px",
            paddingBottom: "3px"
        }}>
            <div style={{ marginRight: "10px", color: "#000" }}>Category</div>
            {selectedOption ? `${selectedOption.groupLabel} - ${selectedOption.label}` : label}
        </div>
    }

    return (
        <div className={className}>
            <UncontrolledDropdown>
                <DropdownToggle
                    caret
                    color="#ced4da"
                    className="dropdown-toggle my-0 mx-0"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        border: `1px solid #ced4da`,
                        cursor: 'pointer',
                        borderRadius: "5px",
                        backgroundColor: "white",
                        padding: "6px",
                        paddingTop: "2px",
                        paddingBottom: "2px"
                    }}

                >
                    <div style={{ marginRight: "10px", color: "#000" }}>Category</div>
                    {selectedOption ? `${selectedOption.groupLabel ?? ""} - ${selectedOption.label ?? ""}` : label}
                </DropdownToggle>
                <DropdownMenu style={{ maxHeight: '200px', overflowY: 'auto', zIndex: 1050, position: 'fixed' }}>
                    { data.map(group => (
                        <div key={group.label}>
                            <DropdownItem header>{group.label}</DropdownItem>
                            {group.options.map(option => (
                                <DropdownItem key={option.value} onClick={() => onChange(option)}>
                                    {option.label}
                                </DropdownItem>
                            ))}

                     

                        </div>
                    ))}

                    <div className='ms-2 mt-1'>
                        {module ? <div style={{ cursor: "pointer" }} onClick={() => { navigate("/doc-type") }} className='d-flex align-items-center'>
                            <FaPlus className="mx-2" /> Create Category
                        </div> : null}
                    </div>
                </DropdownMenu>
            </UncontrolledDropdown>
        </div>
    );
};

DocTypeDropdown.propTypes = {
    module: PropTypes.string,
    label: PropTypes.string.isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
};

export default observer(DocTypeDropdown);
