import React, { useContext } from 'react'
import ProofOfDocument from '../../BusinessLiteForms/components/ProofOfDocument'
import { DialogContext } from '../../../../../store/context/DialogContext'
import { doGET } from '../../../../../util/HttpUtil'
import ButtonGroup from '../../BusinessLiteForms/components/ButtonGroup'

const Documents = ({ isAskForInfo, remark, recordId, asLabel, setHasError, handleUpdate, data, pageNum, loading, step, decrementStep, handleSubmit }) => {
    const { showError } = useContext(DialogContext)
    const handleNext = async () => {
        await handleSubmit(false)
    }

    const checkIsDocumentUploaded = async (field) => {
        try {
            const response = await doGET(`/api/file/grid?module=document&record_id=${recordId}&field=${field}`)
            if (response?.status === 200) {
                if (response?.data?.rows[0]?.attachment?.urls?.length) {
                    return true
                }
            }
        } catch (error) {
            showError(error)

        }
        return false
    }

    const handleSubmitDoc = async () => {
        let errors = [];
        let isDocumentUploaded = await checkIsDocumentUploaded("businessTaxIdProof")
        if (!isDocumentUploaded) {
            errors?.push("Please upload proof of business tax id.")
            showError("Please upload proof of business tax id.")
        }
        isDocumentUploaded = await checkIsDocumentUploaded("addressProof")
        if (!isDocumentUploaded) {
            errors?.push("Please upload proof of shareholder address.")
            showError("Please upload proof of shareholder address.")
        }
        isDocumentUploaded = await checkIsDocumentUploaded("sourceOfSHFundsProof")
        if (!isDocumentUploaded) {
            errors?.push("Please upload proof of source of funds for shareholders.")
            showError("Please upload proof of source of funds for shareholders.")
        }
        isDocumentUploaded = await checkIsDocumentUploaded("principleAddressProof")
        if (!isDocumentUploaded) {
            errors?.push("Please upload proof of principle address of business.")
            showError("Please upload proof of principle address of business.")
        }
        isDocumentUploaded = await checkIsDocumentUploaded("sourceOfBusFundsProof")
        if (!isDocumentUploaded) {
            errors?.push("Please upload proof of source of funds for business.")
            showError("Please upload proof of source of funds for business.")
        }
        if (errors.length > 0) {
            setHasError(true);
            return;
        }
        setHasError(false);
        // await initProofOfAddress();
        handleUpdate({ nextStep: isAskForInfo ? Number.MAX_SAFE_INTEGER : 6, isfetchDetail: true });
    };

    // const IdentifyDocsForUbo = () => {
    //     try {
    //         setDocuments(() => []);
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }

    // useEffect(() => {
    //     IdentifyDocsForUbo();
    // }, [])

    return (
        <>
            <div className='mb-3'>
                {
                    !!data?.hasBusinessPlan ? (
                        <ProofOfDocument
                            isAskForInfo={isAskForInfo}
                            // remark={remark}
                            recordId={recordId}
                            asLabel={asLabel}
                            label={"Please upload your business plan."}
                            field={"businessPlan"}
                            // showCategory={"businessPlan"}
                            showExpiry={false}
                            type={"businessPlan"}
                        />
                    ) : null
                }

                <ProofOfDocument
                    isAskForInfo={isAskForInfo}
                    remark={remark}
                    recordId={recordId}
                    asLabel={asLabel}
                    label={"Please upload a register of shareholders, members or partners."}
                    field={"sharholders"}
                    showCategory={"shareholderRegister"}
                    type={"sharholders"}
                />

                <ProofOfDocument
                    isAskForInfo={isAskForInfo}
                    remark={remark}
                    recordId={recordId}
                    asLabel={asLabel}
                    label={"Please upload a proof of address for the principal place of business(operating address). (Utility bill or bank statement issued with in the last 90 days, rental contract or lease agreement)"}
                    field={"principleProofOfAddress"}
                    showCategory={"ProofOfAddress"}
                    type={"principleProofOfAddress"}
                />

                <ProofOfDocument
                    isAskForInfo={isAskForInfo}
                    remark={remark}
                    recordId={recordId}
                    asLabel={asLabel}
                    label={"Please upload a source of funds, like a bank statement, trading account, invoice or contract."}
                    field={"sourcesOfFunds"}
                    showCategory={"SourceOfFunds"}
                    type={"SourceOfFunds"}
                />

                <ProofOfDocument
                    isAskForInfo={isAskForInfo}
                    remark={remark}
                    recordId={recordId}
                    asLabel={asLabel}
                    label={"Please provide a corporate structure chart which shows name, country of formation, year of incorporation and nature of business of any parent, subsidiary and/​or affiliate"}
                    field={"corpStuctureChart"}
                    showCategory={"corporateStructureChart"}
                    type={"corpStuctureChart"}
                />

                <ProofOfDocument
                    isAskForInfo={isAskForInfo}
                    remark={remark}
                    recordId={recordId}
                    asLabel={asLabel}
                    label={"Please provide the organizational chart (Organogram)"}
                    field={"orgChart"}
                    showCategory={"organizationalChart"}
                    type={"orgChart"}
                />

                {
                    !!data?.hasMsbLicense || !!data?.hasLicense ? (
                        <ProofOfDocument
                            required={false}
                            isAskForInfo={isAskForInfo}
                            remark={remark}
                            recordId={recordId}
                            asLabel={asLabel}
                            label={"Please upload a copy of your license or exemption."}
                            field={"license"}
                            showCategory={"license"}
                            type={"license"}
                        />
                    ) : null
                }

                {
                    !!data?.hasAmlProgram ? (
                        <ProofOfDocument
                            required={false}
                            isAskForInfo={isAskForInfo}
                            remark={remark}
                            recordId={recordId}
                            asLabel={asLabel}
                            label={"Please upload your AML/​CFT Program (policies and procedures)."}
                            field={"amlProgram"}
                            showCategory={"businessTaxId"}
                            type={"amlProgram"}
                        />
                    ) : null
                }

                {
                    !!data?.amlCftReviewed ? (
                        <ProofOfDocument
                            required={false}
                            isAskForInfo={isAskForInfo}
                            remark={remark}
                            recordId={recordId}
                            asLabel={asLabel}
                            label={"Please upload your independent AML/CFT review."}
                            field={"amlReview"}
                            showCategory={"businessTaxId"}
                            type={"amlReview"}
                        />
                    ) : null
                }

                {
                    !!data?.hasComplaintsPolicy ? (
                        <ProofOfDocument
                            required={false}
                            isAskForInfo={isAskForInfo}
                            remark={remark}
                            recordId={recordId}
                            asLabel={asLabel}
                            label={"Please upload your complaints policy."}
                            field={"complaintPolicy"}
                            showCategory={"businessTaxId"}
                            type={"complaintPolicy"}
                        />
                    ) : null
                }

                <ProofOfDocument
                    isAskForInfo={isAskForInfo}
                    remark={remark}
                    recordId={recordId}
                    asLabel={asLabel}
                    label={"Please upload a flow of funds diagram that integrates the role of FV Bank."}
                    field={"flowOfFunds"}
                    showCategory={"flowOfFundsDiagram"}
                    type={"flowOfFunds"}
                />

                {
                    !!data?.dealsInCryptocurrency ? (
                        <ProofOfDocument
                            required={false}
                            isAskForInfo={isAskForInfo}
                            remark={remark}
                            recordId={recordId}
                            asLabel={asLabel}
                            label={"Please upload your buiness agreement or terms and conditions."}
                            field={"businessAgreement"}
                            showCategory={"businessTaxId"}
                            type={"businessAgreement"}
                        />
                    ) : null
                }
            </div>
            <ButtonGroup
                pageNum={pageNum}
                showPageNum={true}
                loading={loading}
                asLabel={asLabel}
                limit={10}
                onNextClick={handleNext}
                step={step}
                onBackClick={decrementStep}
            />
        </>
    )
}

export default Documents;