import { ThreeBounce } from "better-react-spinkit";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Row } from "reactstrap";
import {
  InputField
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import UTMTrackerService from "./UTMTrackerService";

const EditUTMTracker = ({ editId, onChange = () => { }, hasErr }) => {

  const { showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});



  const fetch = async () => {
    setLoading(true);
    try {
      let form = await UTMTrackerService.get(editId);
      setData({ ...form });
    } catch (error) {
      showError(error)
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setData({})
    if (editId) fetch(); else {
      setLoading(false);
    }
  }, [editId, UTMTrackerService?.version]);

  useEffect(() => {
    onChange(data)
  }, [data]);




  return (
    <React.Fragment>
      {loading ?
        <ThreeBounce /> :
        <div style={{ width: "calc(100% - 5px)" }} className="row px-3 ">


          <Row>
            <InputField
              value={data?.name}
              required
              label={"Name"}
              onChange={(v) => {
                setData({
                  ...data,
                  name: v,
                });
              }}
              className="col-12"
              type="text"
            />
          </Row>


          <Row>
            <InputField
              value={data?.utm_referer}
              required
              label={"Link"}
              onChange={(v) => {
                setData({
                  ...data,
                  utm_referer: v,
                });
              }}
              className="col-12"
              type="text"
              error={data?.utm_referer && !/^[a-z0-9]*$/.test(data?.utm_referer) ? "Invalid Link. Only lowercase letters and numbers are allowed." : ""}
              showErr={!/^[a-z0-9]*$/.test(data?.utm_referer)}
            />
          </Row>
          
          <Row>
            <InputField
              value={data?.value}
              required
              label={"Value"}
              onChange={(v) => {
                setData({
                  ...data,
                  value: v,
                });
              }}
              className="col-12"
              type="text"
            />
          </Row>

        

        </div>
      }
    </React.Fragment >
  );
};

export default observer(EditUTMTracker);
