import _ from 'lodash';

export const FormQuestionsGridColumns = {
  version: 1,
  columns: [

    {
      title: "For Form",
      renderer: (dataObj) => {
        return <div>
          {_.startCase(_.toLower(dataObj?.formType ?? ""))}
        </div>
      },
      show: true,
    },

    {
      title: "Title",
      renderer: (dataObj) => {
        return <div>
          {(dataObj?.title ?? "")}
        </div>
      },
      show: true,
    },

    {
      title: "Step",
      renderer: (dataObj) => {
        return <div>
          {(dataObj?.step ?? "")}
        </div>
      },
      show: true,
    },

  
    {
      title: "Action",
      accessor: "action",
      fixed: "right",
      width: 90,
      show: true,
      required: true,
    },
  ],
};
