import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { DialogContext } from "../../../store/context/DialogContext";
import { I18nContext } from "../../../store/context/i18nContext";
import { SelectField } from "../../SelectField/SelectField";
import { SelectFieldMultiple } from "../../SelectField/SelectFieldMultiple";
import CurrencySelectService from "./CurrencySelectService";

const CurrencySelect = ({
    style,
    isDisabled,
    className,
    onChange,
    required,
    value = "INR",
    label,
    innerContainerStyle,
    showErr,
    inline,
    borderNone,
    multi,
    outline,
    inputGroup,
    noLabel,
    ...props
}) => {

    // console.log(value, "========")

    const [currencies, setCurrencies] = useState([]);
    const { showError } = useContext(DialogContext)
    const { t } = useContext(I18nContext)

    const fetchCurrencies = async () => {
        try {
            await CurrencySelectService.fetch();
            setCurrencies(toJS(CurrencySelectService.currencies));
        } catch (error) {
            showError("Error", error)
        }
    };

    useEffect(() => {
        if (CurrencySelectService.currencies.length === 0) fetchCurrencies();
    }, []);

    useEffect(() => {
        setCurrencies(toJS(CurrencySelectService.currencies));
    }, [CurrencySelectService.currencies]);

    const selectCurrencies = (selectedValues) => {
        onChange(selectedValues.map((c) => c?.value ?? c));
    };

    const renderSelectField = ({ disabled }) => (
        <SelectField
            style={style}
            isDisabled={isDisabled || disabled}
            innerContainerStyle={innerContainerStyle}
            className={className}
            onChange={onChange}
            data={currencies}
            required={required}
            value={value}
            label={label || (!isDisabled && t("Currency"))}
            error={!value ? t(`Please Select ${label || "Currency"}`) : ""}
            showErr={showErr}
            inline={inline}
            borderNone={borderNone}
            inputGroup={inputGroup}
            outline={outline}
            noLabel={noLabel}
            {...props}
        />
    );

    const renderSelectFieldMultiple = () => (
        <SelectFieldMultiple
            isDisabled={isDisabled}
            style={style}
            className={className}
            innerContainerStyle={innerContainerStyle}
            noLabel={noLabel}
            onChange={selectCurrencies}
            data={currencies}
            value={value ? currencies.filter((c) => value?.includes(c?.value)) : ""}
            error={!value ? t(`Please Select ${label || "Currencies"}`) : ""}
            required={required}
            label={t("Currencies")}
            showErr={showErr}
            inputGroup={inputGroup}
            outline={outline}
        />
    );

    return (
        <>

            <>

                {currencies.length > 0
                    ? multi
                        ? renderSelectFieldMultiple()
                        : renderSelectField({ disabled: false })
                    : renderSelectField({ disabled: true })}
            </>


        </>
    );
};

export default observer(CurrencySelect);
