import React, { useEffect, useState } from 'react';
import './style.css';

const PercentageInput = ({ required, value, label, onChange, isDisabled, className,handleValidation, error, showErr }) => {
    const [inputValue, setInputValue] = useState('');
    const [inputStyle, setInputStyle] = useState({
        border: 'none',
        padding: '12px',
        background: '#f1fafc',
        borderRadius: '10px'
      });
    useEffect(() => {
        if (value !== undefined) {
            setInputValue(value.toString().replace('%', ''));
        }
    }, [value]);

    const handleInputChange = (e) => {
        let inputValue = e.target.value.replace(/[^\d.]/g, ''); // Remove any non-digit or non-decimal point characters
    
        // Ensure there is only one decimal point
        const parts = inputValue.split('.');
        if (parts.length > 2) {
            inputValue = parts.slice(0, 2).join('.') + '.' + parts.slice(2).join('');
        }
    
        if (inputValue !== '') {
            // Update the state with the sanitized input value
            setInputValue(inputValue);
            onChange(inputValue);
        } else {
            // If the input is empty, update the state with an empty string
            setInputValue('');
            onChange('');
        }
    };
    
    

    const handleBlur = () => {
        if (inputValue !== '' && !inputValue.includes('%')) {
            setInputValue(inputValue + '%');
        }
    };

    const handleFocus = () => {
        setInputValue(inputValue.replace('%', ''));
    };

    const handleKeyPress = (e) => {
        const charCode = e.which ? e.which : e.keyCode;
        const charStr = String.fromCharCode(charCode);
    
        // Allow only numeric input, backspace, and decimal point
        if (!/^\d$/.test(charStr) && charCode !== 8 && charStr !== '.') {
            e.preventDefault();
        }
    
        // Check for existing decimal point
        if (charStr === '.' && e.target.value.includes('.')) {
            e.preventDefault();
        }
    
        // Check the input value
        const inputValue = e.target.value.replace('%', '');
        const newValue = parseFloat(inputValue + charStr);
        if (newValue > 100) {
            e.preventDefault();
        }
    };
    

    return (
        <div className="col-sm-6">
 


            <label style={{ marginTop: "5px", fontSize: "16px", fontWeight: "500" }}>{label}             {required && <font color="red">*</font>}</label>
        <div className="percentage-input-container">
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleBlur}
          className={className}
          onFocus={handleFocus}
          onKeyPress={handleKeyPress}
          placeholder="Enter a Percentage of Ownership"
          disabled={isDisabled}
          style={{
            border: "none",
            padding: "12px",
            background: "#f1fafc",
            borderRadius: "10px",
            outline: "none",
            marginTop: "5px", // Adjust the margin top as needed
            width:"100%"
          }}
        />
         {error && showErr && (
        <em className="inputfield__error">
          {error ? error : "Please enter Something"}
        </em>
      )}
      </div>
        </div>
    );
};

export default PercentageInput;
