export const ENDPOINTS = {
    grid: (module) => {
        let url = `/api/generated-report/grid`;
        return url;
    },
    gridAll: (module) => {
        let url = `/api/generated-report/grid?rows=-1`;
        return url;
    },
    delete: (id) => {
        let url = `/api/generated-report/delete?_id=${id}`;
        return url;
    },
  };
  