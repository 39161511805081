import React, { useState, useMemo, useEffect, useContext } from 'react';
import './styles.scss';
import { DataGrid } from '../../components';
import { CardBody } from 'reactstrap';
import { ThreeBounce } from 'better-react-spinkit';
import { DialogContext } from '../../store/context/DialogContext';
import { doGET } from '../../util/HttpUtil';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const Operators = ({ loading, id }) => {
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10000);
    const { showError } = useContext(DialogContext)
    const [operatorsData, setOperatorsData] = useState([])
    const navigate = useNavigate();


    const fetchOperators = async () => {
        try {
            const response = await doGET(`/api/cyclos/${id}/operators`)
            if (response.status === 200 && response?.data?.length) {
                setOperatorsData(response?.data)
            }
        } catch (error) {
            showError(error)
        }
    }

    useEffect(() => {
        fetchOperators();
    }, [])

    const GridColumns = useMemo(() => ({
        version: 1,
        columns: [
            {
                title: "Name",
                show: true,
                renderer: (data) => <div>{data?.name}</div>,
                required: true,
            },
            {
                title: "Email",
                show: true,
                renderer: (data) => <div>{data?.email}</div>,
                required: true,
            },

            {
                title: "Detail",
                renderer: (data) => <div>
                    <FaExternalLinkAlt
                        onClick={() => { navigate(`/accounts/detail/${data?.id}`) }}
                        style={{ cursor: "pointer" }}
                        size={14}
                        color="primary"
                    />
                </div>,
                show: true,
                required: true,
            },

        ]
    }), []);

    if (loading) {
        return (
            <div className="p-2 d-flex justify-content-center align-items-center">
                <ThreeBounce />
            </div>
        );
    }

    return (
        <div className="table-responsive p-2 fv_table_container">
            <CardBody style={{ height: "100%" }}>
                <div className="d-md-flex flex-1">
                    <div
                        data-pattern="priority-columns"
                        style={{
                            width: '100%',
                        }}
                    >
                        <DataGrid
                            data={operatorsData}
                            total={operatorsData?.length}
                            onSelectChange={() => { }}
                            uiPreference="beneficiaries.grid"
                            headers={GridColumns}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            hidePagination
                            onPaginationChange={({ page, rowsPerPage }) => {
                                setPage(page);
                                setRowsPerPage(rowsPerPage);
                            }}
                        />
                    </div>
                </div>
            </CardBody>
        </div>
    )
}

export default Operators;
