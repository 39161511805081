import React, { useContext } from 'react';
import { QuestionRadioGroup } from '../../components/IndividualLite/DeclarationForm';
import CheckBoxFieldMutiple from '../../../../../components/CheckBox/CheckBoxFieldMutiple';
import { ques13, ques4 } from '../constants';
import { InputField, SelectField } from '../../../../../components';
import ButtonGroup from '../../BusinessLiteForms/components/ButtonGroup';
import CustomTextArea from '../../../../../components/InputField/CustomTextArea';
import { DialogContext } from '../../../../../store/context/DialogContext';

const handleValidation = (field, value, data) => {
    if (field === 'institutionTypesOther') {
        if (!!data?.institutionTypes?.includes("Other") && (!value ||  value?.trim() === "")) {
            return "Please specify the other types of investments.";
        }
        return "";
    }

    if (field === 'licenseHolderDetails') {
        if (!!data?.requiresFundLicense && !!data?.hasFundLicense && (!value ||  value?.trim() === "")) {
            return "Please provide the licenses you hold and the jurisdiction.";
        }
        return "";
    }

    if (field === 'assets') {
        if (!value ||  value?.trim() === "") {
            return "Please select the assets under management.";
        }
        return "";
    }


    if (field === "institutionTypes") {
        if (!value || value?.length === 0) {
            return "Please indicate what types of investments does your fund or family office make";
        }
        return "";
    }


    return "";
};

const Page7 = ({ asLabel, data, handleChange, pageNum, loading, step, decrementStep, handleSubmit }) => {
    const [hasError, setHasError] = React.useState(false);
    const { showError } = useContext(DialogContext);

    const validateFields = () => {
        const requiredFields = [
            'institutionTypesOther',
            'licenseHolderDetails',
            'assets',
            'institutionTypes'
        ];

        let errors = [];

        requiredFields.forEach(field => {
            const fieldError = handleValidation(field, data[field], data);
            if (fieldError) {
                errors.push(fieldError);
            }
        });

        if (errors.length > 0) {
            setHasError(true);
            showError(errors[0]);
            return false;
        }

        setHasError(false);
        return true;
    };

    const handleNext = async () => {
        if (validateFields()) {
            await handleSubmit(false);
        }
    };

    return (
        <>
            <div className='mb-3'>
                <h2 style={{ fontWeight: 'bolder' }} className='pt-3'>Funds and Family Offices</h2>

                <QuestionRadioGroup
                    style={{ fontSize: 15 }}
                    field={"distributeFunds"}
                    question="Will you use the FV Bank account to receive funds from or distribute funds to Limited Partners?"
                    data={data}
                    handleChange={handleChange}
                    hasError={hasError}
                    error={handleValidation('distributeFunds', data?.distributeFunds, data)}
                    defaultValue={false}
                    isDisabled={asLabel}
                />

                <CheckBoxFieldMutiple
                    labelStyle={{ fontSize: 15 }}
                    label="What types of investments does your fund or family office make?"
                    data={ques13}
                    placeholder="Select"
                    value={data.institutionTypes ? data.institutionTypes : []}
                    onChange={(v) => handleChange('institutionTypes', v)}
                    error={handleValidation('institutionTypes', data?.institutionTypes, data)}
                    showErr={hasError}
                    isDisabled={false}
                />

                {!!data?.institutionTypes?.includes("Other") ? (
                    <InputField
                        className="mt-3"
                        label="If Others, please specify"
                        labelStyle={{ fontSize: 15 }}
                        placeholder=""
                        value={data?.institutionTypesOther}
                        onChange={(v) => { handleChange("institutionTypesOther", v) }}
                        error={handleValidation('institutionTypesOther', data?.institutionTypesOther, data)}
                        showErr={hasError}
                        type="text"
                        isDisabled={asLabel}
                    />
                ) : null}

                <QuestionRadioGroup
                    style={{ fontSize: 15 }}
                    field={"requiresFundLicense"}
                    question="Does operating your fund or family office require a license?"
                    data={data}
                    handleChange={handleChange}
                    hasError={hasError}
                    error={handleValidation('requiresFundLicense', data?.requiresFundLicense, data)}
                    defaultValue={false}
                    isDisabled={asLabel}
                />

                {!!data?.requiresFundLicense ? (
                    <QuestionRadioGroup
                        style={{ fontSize: 15 }}
                        field={"hasFundLicense"}
                        question="Do you have a license to operate your fund or family office?"
                        data={data}
                        handleChange={handleChange}
                        hasError={hasError}
                        error={handleValidation('hasFundLicense', data?.hasFundLicense, data)}
                        defaultValue={false}
                        isDisabled={asLabel}
                    />
                ) : null}

                {!!data?.requiresFundLicense && !!data?.hasFundLicense ? (
                    <CustomTextArea
                        labelStyle={{ fontSize: 15 }}
                        placeholder=""
                        value={data?.licenseHolderDetails}
                        onChange={(v) => { handleChange("licenseHolderDetails", v) }}
                        error={handleValidation('licenseHolderDetails', data?.licenseHolderDetails, data)}
                        showErr={hasError}
                        type="text"
                        label="Provide the licenses you hold and jurisdiction."
                        className="mt-3"
                        isDisabled={asLabel}
                    />
                ) : null}

                <QuestionRadioGroup
                    style={{ fontSize: 15 }}
                    field={"subsidiesOrSubventions"}
                    question="Are investments your fund or family office makes subject to a subscription agreement, SAFE, SAFT or similar?"
                    data={data}
                    handleChange={handleChange}
                    hasError={hasError}
                    error={handleValidation('subsidiesOrSubventions', data?.subsidiesOrSubventions, data)}
                    defaultValue={false}
                    isDisabled={asLabel}
                />

                <SelectField
                    labelStyle={{ fontSize: 15 }}
                    className="mt-3"
                    label="What is the Assets Under Management of your fund or family office?"
                    value={data?.assets}
                    onChange={(v) => handleChange('assets', v.value)}
                    data={ques4}
                    placeholder="Under $1,000,000"
                    error={handleValidation('assets', data?.assets, data)}
                    showErr={hasError}
                    isDisabled={asLabel}
                    required
                />
            </div>
            <ButtonGroup
                pageNum={pageNum}
                showPageNum={true}
                loading={loading}
                asLabel={asLabel}
                limit={10}
                onNextClick={handleNext}
                step={step}
                onBackClick={decrementStep}
            />
        </>
    )
}

export default Page7;
