import React from 'react'
import { Button } from 'reactstrap'
import { ThreeBounce } from 'better-react-spinkit'

const ButtonGroup = ({ onNextClick, hideBackButton, onBackClick, step, asLabel, loading, limit, isDisabled, showPageNum, pageNum }) => {
    const nextButtonStyle = {
        height: "40px",
        padding: "2px 4px 2px 4px",
        fontSize: "16px",
        width: "90px",
        borderRadius: "10px"
    }
    return (
        <>
            {
                !!asLabel ? null : (
                    <div className='d-flex justify-content-between align-items-center'>
                        {
                            (step === 1 || hideBackButton) ? <div></div> : <Button
                                className='btn btn-secondary me-3'
                                style={nextButtonStyle}
                                onClick={onBackClick}
                                disabled={loading || isDisabled}
                            >
                                {loading ? <ThreeBounce color="#FFF" /> : "Back"}
                            </Button>
                        }
                        {showPageNum ? <p className='fs-5'>{pageNum}</p> : <div></div>}
                        <Button
                            style={{ backgroundColor: '#1678AE', ...nextButtonStyle }}
                            onClick={onNextClick}
                            disabled={loading || isDisabled}
                        >
                            {loading ? <ThreeBounce color="#FFF" /> : step === limit ? "Submit" : "Next"}
                        </Button>
                    </div>
                )
            }
        </>
    )
}

export default ButtonGroup;