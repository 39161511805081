import React from "react";
import { InputField } from "../../components";

/**
 * Time Format 
 * 
 * 1136 -> 11:36
 * 1842 -> 18:42 / 06:42 PM
 */
export default function TimeSelect(props) {

  const handleTimeChange = (event) => {
    const timeValue = event?.replace(":", "");
    props?.onChange(timeValue);
  };

  function formatTime(time) {
    const timeString = String(time);
    if (timeString?.length === 3) {
      return "0" + timeString[0] + ":" + timeString?.slice(1);
    } else {
      return timeString?.slice(0, 2) + ":" + timeString?.slice(2);
    }
  }


  return (
    <React.Fragment>
      <InputField
        className={props?.className}
        value={formatTime(props?.value)}
        required={props?.required}
        label={props?.label}
        placeholder={props?.placeholder}
        isDisabled={props?.isDisabled}
        onChange={handleTimeChange} // Use the updated handler
        error={props?.value?.length === 0 ? "Please Choose Time" : ""}
        type="time"
        inline={props?.inline}
        outline={props?.outline}
        borderNone={props?.borderNone}
        showErr={props?.showErr}
        RightComponent={props?.RightComponent}
        onClickRight={props?.onClickRight}
        step="900"
        pattern="[0-9]{2}:[0-9]{2}"
      />
    </React.Fragment>
  );
}
