import React from 'react';

export const UTMTrackerGridColumns = {
  version: 1,
  columns: [
    {
      title: "Name",
      renderer: (dataObj) => {
        return <div>{dataObj?.name}</div>;
      },
      show: true,
    },
    {
      title: "Link",
      renderer: (dataObj) => {
        return <div>{dataObj?.utm_referer}</div>;
      },
      show: true,
    },
    {
      title: "Value",
      renderer: (dataObj) => {
        return <div>{dataObj?.value}</div>;
      },
      show: true,
    },
    {
      title: "Action",
      accessor: "action",
      fixed: "right",
      width: 90,
      show: true,
      required: true,
    },
  ],
};
