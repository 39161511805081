import React from "react";
import { Button } from "reactstrap";
import { ThreeBounce } from 'better-react-spinkit';

export default function DeleteButton({ onClick, disabled, loading }) {

  if (loading) {
    return (
      <div className="d-flex justify-content-end my-2">
        <Button
          className="mx-2"
          disabled={disabled}
          style={{ borderColor: "#dc3545", fontSize: "16px", height: "40px", padding: "2px 4px 2px 4px", width: "80px", borderRadius: "10px" }}
          color="outline-danger" 
        >
          <ThreeBounce size={10} color="#dc3545" />
        </Button>
      </div>
    );
  }
  return (
    <div className="d-flex justify-content-end my-2">

      <Button
        onClick={onClick}
        className="mx-2"
        disabled={disabled}
        style={{ borderColor: "#dc3545", fontSize: "16px", height: "40px", padding: "2px 4px 2px 4px", width: "80px", borderRadius: "10px" }}
        color="outline-danger" 
      >


        <div >Delete</div>
      </Button>
      

    </div>
  );
}
