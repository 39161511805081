import React from "react"
import { useLocation } from "react-router-dom"
import SidebarContent from "./SidebarContent"

import Logo from "../../assets/images/Logo.svg"
import LogoCollapsed from "../../assets/images/LogoCollapsed.svg"

const Sidebar = props => {
  const location = useLocation();

  return (
    <React.Fragment>
      <div className={props?.ShowSidebarMenu ? "vertical-menu" : "vertical-menu-sm"}>
        <div className="mt-3 navbar-brand-box">
          <div className="d-flex justify-content-center" style={{ height: "10%", }} >
            {props?.ShowSidebarMenu ? <img src={Logo} style={{ height: "60%", width: "80%", marginTop: "2px" }} /> : <img src={LogoCollapsed} style={{ height: "40%", width: "60%", }} />}
          </div>
        </div>
        <div data-simplebar className="h-100 ">
          {props.type !== "condensed" ? <SidebarContent location={location} ShowSidebarMenu={props?.ShowSidebarMenu} /> : <SidebarContent location={location} ShowSidebarMenu={props?.ShowSidebarMenu} />}
        </div>
      </div>
    </React.Fragment>
  )
}


export default (Sidebar)
