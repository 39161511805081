import React, { useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { StatusDisplay } from './ApplicationDocument';
import { IoWarningOutline } from 'react-icons/io5';

const DocumentGrid = ({
  applicationDocuments,
  groupedDocuments,
  matchStatusOptions,
  uboIdName,
  onRowClick,
  documentTypeMap 
}) => {



  const handleRowClick = (docType, index, docFor) => {
    if (onRowClick) {
      onRowClick(docType, index, docFor);
    }
  };
  const formatUnixTimestamp = (timestamp) => moment.unix(timestamp).format('DD-MM-YYYY hh:mm A');


  const renderDocuments = (documents, type, docFor) => (
    documents
      // .filter(doc => doc?.attachment?.urls?.length)
      .sort((a, b) => b.seq - a.seq)
      .map((doc, index) => (
        <tr key={index} onClick={() => handleRowClick(type, index, docFor)}>
          <td>{doc?.seq}</td>
          <td>{(documentTypeMap?.[doc?.type] ? <>{documentTypeMap?.[doc?.type]}</> : <span > <IoWarningOutline className='text-warning' size = {14} /> {doc?.type}</span> )}</td>
          <td>{doc?.attachment?.time ? formatUnixTimestamp(doc?.attachment?.time)  : "-"}</td>
          <td>{doc?.attachment?.time  ? (doc?.userProvidedDoc != null  && doc?.userProvidedDoc == false) ? "KYC" : "Applicant" : "-"}</td>
          <td><div className='d-flex justify-content-center'><StatusDisplay value={matchStatusOptions?.find(v => doc?.flowStatus == v.value)} /></div></td>
        </tr>
      ))
  );

  const renderDocumentSection = (docType, documents, ubo = null) => (
    <>
      <tr>
        <td colSpan="5">
          <div className="document-section">
            <h4>
              {docType === "businessDocuments" ?
                <>
                  Business Documents
                </> : <>  Documents for {ubo?.firstName ?? ""}{" "}
                  {ubo?.lastName ?? ""}</>}
            </h4>
          </div>
        </td>
      </tr>
      {Object.keys(_.groupBy(documents, 'type')).map((type, typeIndex) => (
        <>
          {typeIndex != 0 &&
            <tr key={typeIndex}>
              <td colSpan="5">
                <div className="category-row">
                  {/* {type === "SourceOfBusFundsProof" ? "Source Of Funds for Business" : _.startCase(_.camelCase(type))} */}
                </div>
              </td>
            </tr>
          }
          {renderDocuments(_.groupBy(documents, 'type')[type], type, docType === "businessDocuments" ? "businessDocuments" : ubo?._id
          )}
        </>
      ))}
    </>
  );

  return (
    <table className="table-bordered document-grid">
      <thead>
        <tr>
          <th>#</th>
          <th>Document Type</th>
          <th>Date Of Upload</th>
          <th>Source</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody className="document-grid-table-hover position-relative">
        {applicationDocuments?.length ? renderDocumentSection("businessDocuments", applicationDocuments) : null}
        {Object.keys(groupedDocuments)
          .filter((key) => key !== "applicationDocuments")
          .map((ubo_id, index) => (
            <>
              {renderDocumentSection("ubo", groupedDocuments[ubo_id], uboIdName?.[ubo_id])}
            </>
          ))}
      </tbody>
    </table>
  );
};

export default DocumentGrid;
