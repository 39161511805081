export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl) => {
    let url = `/api/verification-ask/grid?`;
    if (sizePerPage) url += `rows=${sizePerPage}&`;
    if (filterUrl) url += `${filterUrl}&`;
    if (page) url += `page=${page}`
    return url;
  },
  get: (id) => `/api/verification-ask/detail?id=${id}`,
  save: `/api/verification-ask/create`,
  delete: (id) => `/api/verification-ask/delete?id=${id}`,
  bulkDelete: (id) => `/api/verification-ask/delete`,
  update: `/api/verification-ask/update`,
};

export const STRUCTURE = [
  {
    label: "Form",
    filterLabel: "Form",
    filterName: "formType",
    // description: "Filter by Status",
    type: "select",
    icon:"mdi mdi-form-select font-size-16 ",
    data: [
      { label: "Individual Plus", value: "INDIVIDUAL_PLUS" },
      { label: "Business Plus", value: "BUSINESS_PLUS" },
    ],
  },
  {
    label: "Question",
    filterLabel: "Question",
    filterName: "title",
    type: "text",
    icon: "mdi mdi-frequently-asked-questions font-size-16 ",
  },
  {
    label: "Step",
    filterLabel: "Step",
    filterName: "step",
    // description: "Filter by Status",
    type: "select",
    icon:"mdi mdi-step-forward font-size-16 ",
    data: [
      { label: "Application Form", value: "Application Form" },
      { label: "Proof of Address", value: "Proof of Address" },
      { label: "KYC Verification", value: "KYC Verification" },
      { label: "Sanctions Screening", value: "Sanctions Screening" },
      { label: "Risk Assessment", value: "Risk Assessment" },
    ],
  },
  // {
  //   label: "Step",
  //   filterLabel: "Step",
  //   filterName: "step",
  //   type: "text",
  //   icon: "mdi mdi-earth font-size-16 ",
  // },
 
];
