import React, { useContext } from 'react';
import { Col, Row } from 'reactstrap';
import { InputField } from '../../../../../components';
import { QuestionRadioGroup } from '../../components/IndividualLite/DeclarationForm';
import CheckBoxFieldMutiple from '../../../../../components/CheckBox/CheckBoxFieldMutiple';
import { carribean, countries1, countries2, northAmerica, ques6, ques7, ques8, southAmerica } from '../constants';
import ButtonGroup from '../../BusinessLiteForms/components/ButtonGroup';
import { DialogContext } from '../../../../../store/context/DialogContext';

const handleValidation = (field, value, data) => {
    if (field === 'initialDepositAmount' || field === 'averageBalance' || field === 'achDepositCount' ||
        field === 'achDepositAmount' || field === 'domesticWireDepositCount' || field === 'domesticWireDepositAmount' ||
        field === 'internationalWireDepositCount' || field === 'internationalWireDepositAmount' ||
        field === 'achPaymentCount' || field === 'achPaymentAmount' || field === 'domesticWirePaymentCount' ||
        field === 'domesticWirePaymentAmount' || field === 'internationalWirePaymentCount' ||
        field === 'internationalWirePaymentAmount') {
        if (value === undefined || value === '' ) {
            return `Please provide a value for ${field.replace(/([A-Z])/g, ' $1')}`;
        }
        if (isNaN(value)) {
            return `Please enter a valid number for ${field.replace(/([A-Z])/g, ' $1')}`;
        }
    }

    if (field === 'paymentForms' || field === 'depositReasons' || field === 'paymentReasons') {
        if (!value || value?.length === 0) {
            return `Please select at least one option for ${field.replace(/([A-Z])/g, ' $1')}`;
        }
    }

    if (field === 'depositLocations' || field === 'paymentLocations' || field === 'internationalCustomers') {
        if (!value || value?.length === 0) {
            return `Please select at least one location for ${field.replace(/([A-Z])/g, ' $1')}`;
        }
    }

    if ((field === 'saCountries' && (data?.depositLocations?.includes("South America") || data?.paymentLocations?.includes("South America"))) ||
        (field === 'naCountries' && (data?.depositLocations?.includes("Central America") || data?.paymentLocations?.includes("Central America"))) ||
        (field === 'caribbeanCountries' && (data?.depositLocations?.includes("Caribbean") || data?.paymentLocations?.includes("Caribbean")))) {
        if (!value || value?.length === 0) {
            return `Please indicate the specific countries in ${field.replace(/([A-Z])/g, ' $1')}`;
        }
    }

    if (field === 'caribbeanCountriesOther' && !!data.caribbeanCountries?.includes("Other")) {
        if (!value) {
            return "Please specify the country if 'Other' is selected.";
        }
    }

    if ((field === 'coinConversionCount' || field === 'coinConversionAmount') && data?.hasCoinConversionPlan) {
        if (value === undefined || value === '') {
            return `Please provide the expected ${field.replace(/([A-Z])/g, ' $1')} for stablecoin conversions`;
        }
        if (isNaN(value)) {
            return `Please enter a valid number for ${field.replace(/([A-Z])/g, ' $1')} for stablecoin conversions`;
        }
    }

    if ((field === 'cryptoConversionCount' || field === 'cryptoConversionAmount') && data?.hasCryptoConversionPlan) {
        if (value === undefined || value === '') {
            return `Please provide the expected ${field.replace(/([A-Z])/g, ' $1')} for cryptocurrency conversions`;
        }
        if (isNaN(value)) {
            return `Please enter a valid number for ${field.replace(/([A-Z])/g, ' $1')} for cryptocurrency conversions`;
        }
    }

    return "";
};

const Page3 = ({ asLabel, data, handleChange, pageNum, loading, step, decrementStep, handleSubmit }) => {
    const [hasError, setHasError] = React.useState(false);
    const { showError } = useContext(DialogContext);

    const validateFields = () => {
        const requiredFields = [
            'initialDepositAmount', 'averageBalance', 'achDepositCount', 'achDepositAmount', 'domesticWireDepositCount',
            'domesticWireDepositAmount', 'internationalWireDepositCount', 'internationalWireDepositAmount', 'achPaymentCount',
            'achPaymentAmount', 'domesticWirePaymentCount', 'domesticWirePaymentAmount', 'internationalWirePaymentCount',
            'internationalWirePaymentAmount', 'paymentForms', 'depositReasons', 'paymentReasons', 'depositLocations', 'paymentLocations', 'internationalCustomers'
        ];

        if (data?.depositLocations?.includes("South America") || data?.paymentLocations?.includes("South America")) {
            requiredFields.push('saCountries');
        }

        if (data?.depositLocations?.includes("Central America") || data?.paymentLocations?.includes("Central America")) {
            requiredFields.push('naCountries');
        }

        if (data?.depositLocations?.includes("Caribbean") || data?.paymentLocations?.includes("Caribbean")) {
            requiredFields.push('caribbeanCountries');
        }

        if (!!data?.hasCoinConversionPlan) {
            requiredFields.push('coinConversionCount', 'coinConversionAmount');
        }

        if (!!data?.hasCryptoConversionPlan) {
            requiredFields.push('cryptoConversionCount', 'cryptoConversionAmount');
        }

        let errors = [];

        requiredFields.forEach(field => {
            const fieldError = handleValidation(field, data[field], data);
            if (fieldError) {
                errors.push(fieldError);
            }
        });

        if (errors.length > 0) {
            setHasError(true);
            showError(errors[0]);
            return false;
        }
        setHasError(false);
        return true;
    };

    const handleNext = async () => {
        if (validateFields()) {
            await handleSubmit(false);
        }
    };

    return (
        <>
            <div className='mb-3'>
                <h2 style={{ fontWeight: 'bolder' }} className='pt-3'>Expected Account Activity</h2>

                <Row>
                    <Col className="col-xl-6 col-12">
                        <InputField
                            label="Expected amount of initial deposit"
                            labelStyle={{ fontSize: 15 }}
                            placeholder=""
                            value={data?.initialDepositAmount}
                            onChange={(v) => { handleChange("initialDepositAmount", v) }}
                            error={handleValidation('initialDepositAmount', data?.initialDepositAmount, data)}
                            showErr={hasError}
                            type="number"
                            isDisabled={asLabel}
                        />
                    </Col>
                    <Col className="col-xl-6 col-12">
                        <InputField
                            label="Expected average balance"
                            labelStyle={{ fontSize: 15 }}
                            placeholder=""
                            value={data?.averageBalance}
                            onChange={(v) => { handleChange("averageBalance", v) }}
                            error={handleValidation('averageBalance', data?.averageBalance, data)}
                            showErr={hasError}
                            type="number"
                            isDisabled={asLabel}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col className="col-xl-6 col-12">
                        <InputField
                             
                            label="Expected number of monthly ACH deposits"
                            labelStyle={{ fontSize: 15 }}
                            placeholder=""
                            value={data?.achDepositCount}
                            onChange={(v) => { handleChange("achDepositCount", v) }}
                            error={handleValidation('achDepositCount', data?.achDepositCount, data)}
                            showErr={hasError}
                            type="number"
                            isDisabled={asLabel}
                        />
                    </Col>
                    <Col className="col-xl-6 col-12">
                        <InputField
                             
                            label="Expected amount of monthly ACH deposits"
                            labelStyle={{ fontSize: 15 }}
                            placeholder=""
                            value={data?.achDepositAmount}
                            onChange={(v) => { handleChange("achDepositAmount", v) }}
                            error={handleValidation('achDepositAmount', data?.achDepositAmount, data)}
                            showErr={hasError}
                            type="number"
                            isDisabled={asLabel}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col className="col-xl-6 col-12">
                        <InputField
                             
                            label="Expected number of monthly Domestic Wire deposits"
                            labelStyle={{ fontSize: 15 }}
                            placeholder=""
                            value={data?.domesticWireDepositCount}
                            onChange={(v) => { handleChange("domesticWireDepositCount", v) }}
                            error={handleValidation('domesticWireDepositCount', data?.domesticWireDepositCount, data)}
                            showErr={hasError}
                            type="number"
                            isDisabled={asLabel}
                        />
                    </Col>
                    <Col className="col-xl-6 col-12">
                        <InputField
                             
                            label="Expected amount of monthly Domestic Wire deposits"
                            labelStyle={{ fontSize: 15 }}
                            placeholder=""
                            value={data?.domesticWireDepositAmount}
                            onChange={(v) => { handleChange("domesticWireDepositAmount", v) }}
                            error={handleValidation('domesticWireDepositAmount', data?.domesticWireDepositAmount, data)}
                            showErr={hasError}
                            type="number"
                            isDisabled={asLabel}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col className="col-xl-6 col-12">
                        <InputField
                             
                            label="Expected number of monthly International Wire deposits"
                            labelStyle={{ fontSize: 15 }}
                            placeholder=""
                            value={data?.internationalWireDepositCount}
                            onChange={(v) => { handleChange("internationalWireDepositCount", v) }}
                            error={handleValidation('internationalWireDepositCount', data?.internationalWireDepositCount, data)}
                            showErr={hasError}
                            type="number"
                            isDisabled={asLabel}
                        />
                    </Col>
                    <Col className="col-xl-6 col-12">
                        <InputField
                             
                            label="Expected amount of monthly Interntional Wire deposits"
                            labelStyle={{ fontSize: 15 }}
                            placeholder=""
                            value={data?.internationalWireDepositAmount}
                            onChange={(v) => { handleChange("internationalWireDepositAmount", v) }}
                            error={handleValidation('internationalWireDepositAmount', data?.internationalWireDepositAmount, data)}
                            showErr={hasError}
                            type="number"
                            isDisabled={asLabel}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col className="col-xl-6 col-12">
                        <InputField
                             
                            label="Expected number of monthly ACH payments"
                            labelStyle={{ fontSize: 15 }}
                            placeholder=""
                            value={data?.achPaymentCount}
                            onChange={(v) => { handleChange("achPaymentCount", v) }}
                            error={handleValidation('achPaymentCount', data?.achPaymentCount, data)}
                            showErr={hasError}
                            type="number"
                            isDisabled={asLabel}
                        />
                    </Col>
                    <Col className="col-xl-6 col-12">
                        <InputField
                             
                            label="Expected amount of monthly ACH payments"
                            labelStyle={{ fontSize: 15 }}
                            placeholder=""
                            value={data?.achPaymentAmount}
                            onChange={(v) => { handleChange("achPaymentAmount", v) }}
                            error={handleValidation('achPaymentAmount', data?.achPaymentAmount, data)}
                            showErr={hasError}
                            type="number"
                            isDisabled={asLabel}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col className="col-xl-6 col-12">
                        <InputField
                             
                            label="Expected number of monthly Domestic Wire payments"
                            labelStyle={{ fontSize: 15 }}
                            placeholder=""
                            value={data?.domesticWirePaymentCount}
                            onChange={(v) => { handleChange("domesticWirePaymentCount", v) }}
                            error={handleValidation('domesticWirePaymentCount', data?.domesticWirePaymentCount, data)}
                            showErr={hasError}
                            type="number"
                            isDisabled={asLabel}
                        />
                    </Col>
                    <Col className="col-xl-6 col-12">
                        <InputField
                             
                            label="Expected amount of monthly Domestic Wire payments"
                            labelStyle={{ fontSize: 15 }}
                            placeholder=""
                            value={data?.domesticWirePaymentAmount}
                            onChange={(v) => { handleChange("domesticWirePaymentAmount", v) }}
                            error={handleValidation('domesticWirePaymentAmount', data?.domesticWirePaymentAmount, data)}
                            showErr={hasError}
                            type="number"
                            isDisabled={asLabel}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col className="col-xl-6 col-12">
                        <InputField
                             
                            label="Expected number of monthly International Wire payments"
                            labelStyle={{ fontSize: 15 }}
                            placeholder=""
                            value={data?.internationalWirePaymentCount}
                            onChange={(v) => { handleChange("internationalWirePaymentCount", v) }}
                            error={handleValidation('internationalWirePaymentCount', data?.internationalWirePaymentCount, data)}
                            showErr={hasError}
                            type="number"
                            isDisabled={asLabel}
                        />
                    </Col>
                    <Col className="col-xl-6 col-12">
                        <InputField
                             
                            label="Expected amount of monthly International Wire payments"
                            labelStyle={{ fontSize: 15 }}
                            placeholder=""
                            value={data?.internationalWirePaymentAmount}
                            onChange={(v) => { handleChange("internationalWirePaymentAmount", v) }}
                            error={handleValidation('internationalWirePaymentAmount', data?.internationalWirePaymentAmount, data)}
                            showErr={hasError}
                            type="number"
                            isDisabled={asLabel}
                        />
                    </Col>
                </Row>

                <CheckBoxFieldMutiple
                    labelStyle={{ fontSize: 15 }}
                    label="What forms of payment does your business accept"
                    data={ques6}
                    placeholder="Select"
                    value={data.paymentForms ? data.paymentForms : []}
                    onChange={(v) => handleChange('paymentForms', v)}
                    error={handleValidation('paymentForms', data.paymentForms, data)}
                    showErr={hasError}
                    isDisabled={asLabel}
                />

                <CheckBoxFieldMutiple
                    labelStyle={{ fontSize: 15 }}
                    label="Select all reasons you will receive deposits into your account"
                    data={ques7}
                    placeholder="Select"
                    value={data.depositReasons ? data.depositReasons : []}
                    onChange={(v) => handleChange('depositReasons', v)}
                    error={handleValidation('depositReasons', data.depositReasons, data)}
                    showErr={hasError}
                    isDisabled={asLabel}
                />

                <CheckBoxFieldMutiple
                    labelStyle={{ fontSize: 15 }}
                    label="Select all reasons you will make payments from your account"
                    data={ques8}
                    placeholder="Select"
                    value={data.paymentReasons ? data.paymentReasons : []}
                    onChange={(v) => handleChange('paymentReasons', v)}
                    error={handleValidation('paymentReasons', data.paymentReasons, data)}
                    showErr={hasError}
                    isDisabled={asLabel}
                />

                <Row>
                    <Col className="col-xl-6 col-12">
                        <CheckBoxFieldMutiple
                            labelStyle={{ fontSize: 15 }}
                            label="Locations you will receive deposits from"
                            data={countries1}
                            placeholder="Select"
                            value={data.depositLocations ? data.depositLocations : []}
                            onChange={(v) => handleChange('depositLocations', v)}
                            error={handleValidation('depositLocations', data.depositLocations, data)}
                            showErr={hasError}
                            isDisabled={asLabel}
                        />
                    </Col>
                    <Col className="col-xl-6 col-12">
                        <CheckBoxFieldMutiple
                            labelStyle={{ fontSize: 15 }}
                            label="Locations you will make payments to"
                            data={countries1}
                            placeholder="Select"
                            value={data.paymentLocations ? data.paymentLocations : []}
                            onChange={(v) => handleChange('paymentLocations', v)}
                            error={handleValidation('paymentLocations', data.paymentLocations, data)}
                            showErr={hasError}
                            isDisabled={asLabel}
                        />
                    </Col>
                </Row>

                {
                    data?.depositLocations?.includes("South America") || data?.paymentLocations?.includes("South America") ? (
                        <CheckBoxFieldMutiple
                            labelStyle={{ fontSize: 15 }}
                            label="Please indicate which countries in South America  you transact with."
                            data={southAmerica}
                            placeholder="Select"
                            value={data.saCountries ? data.saCountries : []}
                            onChange={(v) => handleChange('saCountries', v)}
                            error={handleValidation('saCountries', data.saCountries, data)}
                            showErr={hasError}
                            isDisabled={asLabel}
                        />
                    ) : null
                }

                {data?.depositLocations?.includes("Central America") || data?.paymentLocations?.includes("Central America") ? (
                    <CheckBoxFieldMutiple
                        labelStyle={{ fontSize: 15 }}
                        label="Please indicate which countries in Central America you transact with."
                        data={northAmerica}
                        placeholder="Select"
                        value={data.naCountries ? data.naCountries : []}
                        onChange={(v) => handleChange('naCountries', v)}
                        error={handleValidation('naCountries', data.naCountries, data)}
                        showErr={hasError}
                        isDisabled={asLabel}
                    />
                ) : null}

                {data?.depositLocations?.includes("Caribbean") || data?.paymentLocations?.includes("Caribbean") ? (
                    <CheckBoxFieldMutiple
                        labelStyle={{ fontSize: 15 }}
                        label="Please indicate which countries in Caribbean you transact with."
                        data={carribean}
                        placeholder="Select"
                        value={data.caribbeanCountries ? data.caribbeanCountries : []}
                        onChange={(v) => handleChange('caribbeanCountries', v)}
                        error={handleValidation('caribbeanCountries', data.caribbeanCountries, data)}
                        showErr={hasError}
                        isDisabled={asLabel}
                    />
                ) : null}

                {!!data.caribbeanCountries?.includes("Other") ? (
                    <InputField
                         
                        label="If Others, please specify"
                        labelStyle={{ fontSize: 15 }}
                        placeholder=""
                        value={data?.caribbeanCountriesOther}
                        onChange={(v) => { handleChange("caribbeanCountriesOther", v) }}
                        error={handleValidation('caribbeanCountriesOther', data?.caribbeanCountriesOther, data)}
                        showErr={hasError}
                        type="text"
                        isDisabled={asLabel}
                    />
                ) : null}

                <CheckBoxFieldMutiple
                    labelStyle={{ fontSize: 15 }}
                    label="Do you have customers in any of the following countries?"
                    data={countries2}
                    placeholder="Select"
                    value={data.internationalCustomers ? data.internationalCustomers : []}
                    onChange={(v) => handleChange('internationalCustomers', v)}
                    error={handleValidation('internationalCustomers', data?.internationalCustomers, data)}
                    showErr={hasError}
                    isDisabled={asLabel}
                />

                <QuestionRadioGroup
                    style={{ fontSize: 15 }}
                    field={"hasCoinConversionPlan"}
                    question="Do you plan on converting stablecoins to/from fiat in FV Bank? (USDC, USDT, PYUSD or similar)"
                    data={data}
                    handleChange={handleChange}
                    error={handleValidation('hasCoinConversionPlan', data?.hasCoinConversionPlan, data)}
                    hasError={hasError}
                    isDisabled={asLabel}
                    defaultValue={false}
                />

                {data?.hasCoinConversionPlan ? (
                    <Row>
                        <Col className="col-xl-6 col-12">
                            <InputField
                                 
                                label="Expected count of stablecoin conversions monthly."
                                labelStyle={{ fontSize: 15 }}
                                placeholder=""
                                value={data?.coinConversionCount}
                                onChange={(v) => { handleChange("coinConversionCount", v) }}
                                error={handleValidation('coinConversionCount', data?.coinConversionCount, data)}
                                showErr={hasError}
                                type="number"
                                isDisabled={asLabel}
                            />
                        </Col>
                        <Col className="col-xl-6 col-12">
                            <InputField
                                 
                                label="Expected amount of stablecoin conversions monthly"
                                labelStyle={{ fontSize: 15 }}
                                placeholder=""
                                value={data?.coinConversionAmount}
                                onChange={(v) => { handleChange("coinConversionAmount", v) }}
                                error={handleValidation('coinConversionAmount', data?.coinConversionAmount, data)}
                                showErr={hasError}
                                type="number"
                                isDisabled={asLabel}
                            />
                        </Col>
                    </Row>
                ) : null}

                <QuestionRadioGroup
                    style={{ fontSize: 15 }}
                    field={"hasCryptoConversionPlan"}
                    question="Do you plan on converting cryptocurrencies to/from fiat in FV Bank? (BTC, ETH, or similar)"
                    data={data}
                    handleChange={handleChange}
                    defaultValue={false}
                    hasError={hasError}
                    isDisabled={asLabel}
                />

                {data?.hasCryptoConversionPlan ? (
                    <Row>
                        <Col className="col-xl-6 col-12">
                            <InputField
                                 
                                label="Expected count of cryptocurrency conversions monthly."
                                labelStyle={{ fontSize: 15 }}
                                placeholder=""
                                value={data?.cryptoConversionCount}
                                onChange={(v) => { handleChange("cryptoConversionCount", v) }}
                                error={handleValidation('cryptoConversionCount', data?.cryptoConversionCount, data)}
                                showErr={hasError}
                                type="number"
                                isDisabled={asLabel}
                            />
                        </Col>
                        <Col className="col-xl-6 col-12">
                            <InputField
                                 
                                label="Expected amount of cryptocurrency conversions monthly"
                                labelStyle={{ fontSize: 15 }}
                                placeholder=""
                                value={data?.cryptoConversionAmount}
                                onChange={(v) => { handleChange("cryptoConversionAmount", v) }}
                                error={handleValidation('cryptoConversionAmount', data?.cryptoConversionAmount, data)}
                                showErr={hasError}
                                type="number"
                                isDisabled={asLabel}
                            />
                        </Col>
                    </Row>
                ) : null}
            </div>
            <ButtonGroup
                pageNum={pageNum}
                showPageNum={true}
                loading={loading}
                asLabel={asLabel}
                limit={10}
                onNextClick={handleNext}
                step={step}
                onBackClick={decrementStep}
            />
        </>
    )
}

export default Page3;
