import React, { useState, useEffect, useContext } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';
import { menuItems } from './Constant'
import HoverModal from './HoverModal';
import { UserContext } from '../../store/context/UserContext';

const Sidebar = ({ ShowSidebarMenu }) => {
  const location = useLocation();
  const [activePath, setActivePath] = useState("");
  const initialCollapseStatus = Array(menuItems.length).fill(false);
  const { userContent } = useContext(UserContext)
  // Example of initialCollapseStatus:
  // [false, false, false, false, ...] - Each index corresponds to an item's collapse status

  const [collapseStatus, setCollapseStatus] = useState(initialCollapseStatus);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [hoverPosition, setHoverPosition] = useState({ left: 0, top: 0 });

  useEffect(() => {
    const pathSegments = window.location.pathname.split("/");
    setActivePath('/' + pathSegments[1]); // Ensure we're matching the structure of paths
  }, [location]);

  const toggleCollapse = (sectionIndex) => {
    setCollapseStatus((prevState) => {
      const newState = prevState.map((item, idx) => {
        if (idx === sectionIndex) {
          return !item;
        } else {
          return false;
        }
      });
      return newState;
    });
  };

  useEffect(() => {
    menuItems.map((e, i) => {
      e.items.map((el) => {
        el?.subItems?.map(k => {
          if (k?.path === location.pathname) toggleCollapse(i);
        })
      })
    })
  }, [])

  const handleMouseEnter = (e, item) => {
    setHoveredItem(item);
    const rect = e.target.getBoundingClientRect();
    setHoverPosition({ left: rect.right, top: rect.top - 50 });
  };
  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  return (
    <div
      key={userContent?.rights}
      className={`${ShowSidebarMenu ? "sidebar--open " : "sidebar--collapse "} mt-3 d-flex flex-column flex-shrink-0`}>
      {menuItems.map((section, sectionIndex) => (
        <div key={sectionIndex} className="mb-2">
          {section.items.map((item, itemIndex) => (
            userContent?.rights?.includes(item.right) || !item?.right ? <div key={itemIndex} className={`${activePath === item.path ? 'nav-side-active' : ''} py-2 pt-1 mx-3 mt-2  `} onMouseEnter={(e) => handleMouseEnter(e, item)} onMouseLeave={handleMouseLeave} >
              {ShowSidebarMenu ?
                <>
                  {item?.subItems ? (
                    <div onClick={(e) => { e.stopPropagation(); toggleCollapse(sectionIndex); }} key={itemIndex} className="d-flex align-items-center mb-3 text-decoration-none">
                      {item?.logo({ size: 20, className: 'col-2', color: activePath === item.path ? "#fff" : "black", })}
                      {ShowSidebarMenu && <span className='' style={{ color: activePath === item.path ? "#fff" : "black", fontSize: "15px" }}>{item.name}</span>}
                      <div className="col-1 ms-auto " style={{ cursor: "pointer" }}>
                        {collapseStatus[sectionIndex] ? <FaChevronDown /> : <FaChevronRight />}
                      </div>
                    </div>
                  ) :
                    <div key={itemIndex} className="d-flex align-items-center text-decoration-none">
                      <Link to={item.path} className={` flex-grow-1 text-decoration-none d-flex align-items-center pt-1`} activeClassName="active">
                        {item?.logo({ size: 22, className: 'col-2', color: activePath === item.path ? "#fff" : "black", })}
                        {ShowSidebarMenu && <span className='' style={{ color: activePath === item.path ? "#fff" : "black", fontSize: "15px" }}>{item.name}</span>}
                      </Link>
                    </div>}
                </> : <>
                  {item?.subItems ? (
                    <div onClick={(e) => { e.stopPropagation(); }} key={itemIndex} className="d-flex align-items-center mb-3 text-decoration-none">
                      {item?.logo({ size: 20, className: "col-12", color: activePath === item.path ? "#fff" : "black", })}
                    </div>
                  ) :
                    <div key={itemIndex} className="d-flex align-items-center text-decoration-none">
                      <Link to={item.path} className={` flex-grow-1 text-decoration-none`} activeClassName="active">
                        {item?.logo({ size: 22, className: "col-12", color: activePath === item.path ? "#fff" : "black", })}
                        {ShowSidebarMenu && <span className='' style={{ color: activePath === item.path ? "#fff" : "black", fontSize: "15px" }}>{item.name}</span>}
                      </Link>
                    </div>}
                </>
              }

              {ShowSidebarMenu && item.subItems && collapseStatus[sectionIndex] && (
                <>
                  {
                    item.subItems.map((subItem, subIndex) => (
                      <span className=''>
                        {userContent?.rights?.includes(subItem.right) && <Link to={subItem.path} className={`col-10 d-flex  justify-content-center mt-2 py-2 ${activePath === subItem.path ? 'nav-sub-side-active' : ''}`} style={{ marginLeft: "auto" }} key={subIndex}>
                          <div className='col-1'></div>
                          <div className='col-2'>  {subItem.logo && (subItem?.logo({ color: activePath === subItem.path ? "#fff" : "black", size: 22, }))}</div>
                          <div className='col-9 ps-1'>
                            {ShowSidebarMenu && <span style={{ color: activePath === subItem.path ? "#fff" : "black", fontSize: "14px", text: "start" }}>{subItem.name}</span>}
                          </div>
                        </Link>}
                      </span>
                    ))
                  }
                </>
              )}
              {!ShowSidebarMenu && hoveredItem === item && (
                <HoverModal item={item} isVisible={hoveredItem === item} position={hoverPosition} activePath={activePath} />
              )}
            </div> : null
          ))}

        </div>
      ))}
    </div>
  );
};

export default Sidebar;