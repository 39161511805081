import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import {
    Row
} from "reactstrap";

import SupervisorService from "../../../pages/Supervisor/SupervisorService";
import { DialogContext } from "../../../store/context/DialogContext";
import { I18nContext } from "../../../store/context/i18nContext";
import { InputField } from "../../index";
const SupervisorInfo = ({ editId, onChange = () => { }, hasErr }) => {

    const { t } = useContext(I18nContext);
    const [data, setData] = useState({});
    const { showError } = useContext(DialogContext);


    const fetch = async () => {
        try {
            let supervisior = await SupervisorService.get({editId,corp_id:null});
            setData(supervisior);
        } catch (error) {
            showError(error)
        }
    };


    useEffect(() => {
       if(editId) fetch()
    }, [editId])

    return (
        <div>
            <Row className="mb-3 ">
                <InputField
                    placeholder={t("Full Name")}
                    plain
                    value={data?.name}
                    // required={true}
                    // label={t("Brand Name")}
                    style={{ fontSize: "35px", height: "45px" , marginTop : "0px !important" }}
                    onChange={(v) => setData({ ...data, name: v })}
                    noLabel
                    error={data?.name?.length === 0 ? t("Please enter Name") : ""}
                    type="text"
                    className="col-sm-12"
                />

           
        
                <InputField
                    placeholder={t("Email Address")}
                    value={data?.email}
                    required={true}
                    outline
                    is
                    label={t("Email")}
                    onChange={(v) => onChange({ ...data, email: v })}
                    error={data?.email?.length === 0 ? "Please enter Employee email" : ""}
                    showErr={hasErr}
                    type="email"
                    className="col-sm-12"
                    isDisabled={true}
                /> 

 
 
            </Row>



        </div>
    );
}
export default observer(SupervisorInfo);