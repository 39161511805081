import React from 'react';
import { Form, FormGroup } from 'react-bootstrap';
import { InputField } from '../../../../../components';
import _ from 'lodash';

export const QuestionRadioGroupMultiple = ({ isDisabled, field, question, data, handleChange, hasError, style, options, required, showErr , error}) => (
    <div>
        <FormGroup>
            <Form.Label className='mt-3' style={{ minWidth: "120px", padding: "0px", fontSize: 16, fontWeight: "500", ...style }}>
                {question}
                {!!required ? <font color="red">*</font> : ""}
            </Form.Label>
            <div className='row'>
                {
                    options.map((e, i) => (
                        <div key={i} className='col-12 col-md-6 mb-2'>
                            <Form.Check
                                id={e.value}
                                type="radio"
                                disabled = {isDisabled}
                                label={e.label}
                                name={field}
                                value={e.value}
                                checked={_.get(data, field) === e.value}
                                onChange={() => handleChange(field, e.value)}
                                className='me-2'
                            />
                        </div>
                    ))
                }
            </div>
            {data?.[field] === undefined && (showErr || hasError) && (
                <div className="ps-0 ms-0 w-100 error__message">{error}</div>
            )}
        </FormGroup>
    </div>
);

const Page6 = ({ asLabel, data, handleChange, hasError, handleValidation, validationkeyValue }) => {
    return (
        <div>
            <QuestionRadioGroupMultiple
                field={"hearAbtUs"}
                data={data}
                handleChange={handleChange}
                hasError={hasError}
                isDisabled={asLabel}
                style={{ fontSize: 15 }}
                options={validationkeyValue["hearAbtUs"]?.options}
                question={validationkeyValue["hearAbtUs"]?.label}
                error={handleValidation(`hearAbtUs`, data?.hearAbtUs)}
                showErr={hasError}
                required={validationkeyValue["hearAbtUs"]?.required}
            />
            {/* 
            <InputField
                className="my-4"
                label="Please specify"
                // required
                labelStyle={{ fontSize: 15 }}
                placeholder=""
                value={data?.hearAbtUsOther}
                onChange={(v) => { handleChange("hearAbtUs", v) }}
                error={handleValidation("hearAbtUs", data?.hearAbtUs)}
                showErr={hasError}
                type="text"
            /> */}

            <InputField
                className="my-4"
                label="Indicate the name of the conference/event/trade show"
                labelStyle={{ fontSize: 15 }}
                placeholder=""
                value={data?.showName}
                onChange={(v) => { handleChange("showName", v) }}
                error={handleValidation("showName", data?.showName)}
                showErr={hasError}
                type="text"
                isDisabled={asLabel}
                required={validationkeyValue["showName"]?.required}

            />

            <InputField
                className="my-4"
                label="If you were referred to FV Bank, please indicate the name of the Referrer"
                labelStyle={{ fontSize: 15 }}
                placeholder=""
                value={data?.refName}
                onChange={(v) => { handleChange("refName", v) }}
                error={handleValidation("refName", data?.refName)}
                showErr={hasError}
                type="text"
                isDisabled={asLabel}
                required={validationkeyValue["refName"]?.required}

            />
        </div>
    )
}

export default Page6;