import React, { useState } from "react";
import { Button, Card, CardBody, CardTitle, Input, Label } from "reactstrap";
import toastr from "toastr";
import { confirmAlert } from "react-confirm-alert"; // Import react-confirm-alert.css
import "react-confirm-alert/src/react-confirm-alert.css";

export const DialogContext = React.createContext();

export const toastrOptions = {
  positionClass: "toast-bottom-left",
  timeOut: 10000,
  extendedTimeOut: 1000,
  closeButton: true,
  progressBar: true,
  preventDuplicates: false,
  newestOnTop: true,
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "slideDown",
  hideMethod: "slideUp",
  showDuration: 300,
  hideDuration: 1000,
}
export const DialogProvider = (props) => {
  const [isDontSave, setIsDontSave] = useState(false);
  const [showLogsP, setShowLogsP] = useState(false);
  const [conf, setConf] = useState(false);


  toastr.options = toastrOptions;

  const showMessage = async (message, title) => {
    if (message?.message) {
      try {
        message = JSON?.parse(message?.message);
        message = message?.message + " " + message?.customMessage;
      } catch (e) { }
    }
    toastr.success(message, title);
  };

  const showInfo = async (message, title) => {
    if (message?.message) {
      try {
        message = JSON?.parse(message?.message);
        message = message?.message + " " + message?.customMessage;
      } catch (e) { }
    }
    toastr.info(message, title);
  };

  const showWarning = async (message, title) => {
    if (message?.message) {
      try {
        message = JSON?.parse(message?.message);
        message = message?.message + " " + message?.customMessage;
      } catch (e) { }
    }
    toastr.warning(message, title);
  };

  const showError = async (e) => {
    console.log(e);
    if (typeof e === "string") {
      toastr.error(e, "Error");
      return;
    }
    if (e?.message?.includes("{")) {
      toastr.error(
        JSON?.parse(e?.message)?.customMessage
          ? JSON?.parse(e?.message)?.customMessage
          : JSON?.parse(e?.message)?.message,
        "Error"
      );
    } else {
      toastr.error(e.message, "Error");
    }
  };

  function promise(props) {
    return new Promise(function (resolve, reject) {
      let remark = ""; 
      let checkboxChecked = false; // State for checkbox

      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <Card style={{
              width: "max-content", maxWidth: "min(80vw, 600px)", height: "max-content", ...((props?.remarkRequired && props?.checkboxRequired) && { minHeight: "350px"}),  }}>
              <CardBody>
                <CardTitle>
                  <div>{props?.title}</div>
                </CardTitle>
                <div style={{ fontSize: "15px", marginTop: "20px" }}>
                  {props?.description}
                </div>
                {/* Optional Remark Textarea */}
                {props?.remarkRequired && (
                  <textarea
                    rows="5"
                    placeholder={props?.remarkPlaceholder ?? "Remark"}
                    className="mt-3"
                    onChange={(e) => (remark = e.target.value)}
                    style={{ width: "100%", borderRadius: "0.375rem", outline: "none", border: "1px solid #ced4da", }}

                  />
                )}

                {/* Optional Checkbox */}
                {props?.checkboxRequired && (
                  <div className="mt-3 d-flex align-items-center">
                    <Label className="d-flex align-items-center" >
                      <Input
                        type="checkbox"
                        onChange={(e) => (checkboxChecked = e.target.checked)}
                      />{" "}
                      <span className="ps-2">  {props?.checkboxLabel ?? "I agree"} </span>
                    </Label>
                  </div>
                )}

                <div className="d-flex flex-wrap gap-2 justify-content-end mt-3">
                  {props?.isSaveWarn && (
                    <Button
                      type="button"
                      color="dark"
                      outline
                      className="flex-grow-1 flex-md-grow-0"
                      style={{ minWidth: '120px' }}
                      onClick={() => {
                        setIsDontSave(true);
                        resolve({ remark, checkboxChecked }); // Resolve with remark and checkbox state
                        onClose();
                      }}
                    >
                      Don't Save
                    </Button>
                  )}
                  <Button
                    type="button"
                    color="dark"
                    outline
                    className="flex-grow-1 flex-md-grow-0"
                    style={{ minWidth: '120px' }}
                    onClick={() => {
                      reject();
                      onClose();
                    }}
                  >
                    {props?.reject ? props?.reject : "Cancel"}
                  </Button>
                  <Button
                    type="button"
                    className="flex-grow-1 flex-md-grow-0"
                    style={{ minWidth: '120px' }}
                    color="danger"
                    onClick={() => {
                      resolve({ remark, checkboxChecked }); // Resolve with remark and checkbox state
                      onClose();
                    }}
                  >
                    {props?.isSaveWarn ? "Save" : props?.proceed ? props?.proceed : "OK"}
                  </Button>
                </div>
              </CardBody>
            </Card>
          );
        },
      });
    });
  }

  const showConfirm = async (props) => {
    let isConfirm = true;
    await promise(props)
      .then(function ({ remark, checkboxChecked }) {
        if (isDontSave) {
          isConfirm = 2;
        } else if (props.checkboxRequired && remark) {
          console.log("1", { checkboxChecked })
          isConfirm = { confirmed: true, remark, isChecked: checkboxChecked };
        } else if (props.checkboxRequired) {
          console.log("2", { checkboxChecked })
          isConfirm = { confirmed: true, isChecked: checkboxChecked } 
        } else {
          console.log("3", { checkboxChecked })
          isConfirm = remark ? { confirmed: true, remark } : true;
        }
      })
      .catch(function () {
        isConfirm = false;
      });
    return isConfirm;
  };

  const showConfirmWithTextArea = async (props) => {
    props.remarkRequired = props.remarkRequired !== undefined ? props.remarkRequired : true; // Default remark to mandatory
    return await showConfirm(props);
  };

  const showConfirmWithTextAreaAndCheckBox = async (props) => {
    props.remarkRequired = props.remarkRequired !== undefined ? props.remarkRequired : true; // Default remark to mandatory
    props.checkboxRequired = props.checkboxRequired !== undefined ? props.checkboxRequired : false; // Default checkbox to not required
    return await showConfirm(props);
  };


  return (
    <DialogContext.Provider
      value={{
        showMessage,
        showInfo,
        showWarning,
        showConfirm,
        showConfirmWithTextArea,
        showError, 
        setShowLogsP, 
        showLogsP, 
        setConf, 
        showConfirmWithTextAreaAndCheckBox,
        conf
      }}
    >
      {props?.children}
    </DialogContext.Provider>
  );
};
