import React, { Children, useContext } from "react";
import { Table } from "react-super-responsive-table";
import { useNavigate } from "react-router-dom";
import DetailPageModal from "./DetailPage/DetailPageModal";
import { ThreeBounce } from "better-react-spinkit";
import "./style.css";
import {
  CardBody,
  CardTitle,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
  Label,
  InputGroup,
  Alert
} from "reactstrap";
import { AddButton, Breadcrumb, Filter, ToggleSelector, DateRange } from "../../components";
import SelectButton from "../Buttons/Icons/SelectButton";
import AddNewButton from "../Buttons/AddNewButton";
import { I18nContext } from "../../store/context/i18nContext";
import { DatePicker, Space } from 'antd';
import dayjs from 'dayjs';
import moment from "moment";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import LayoutHeaderDropdown from "../Dropdowns/LayoutHeaderDropdown";

dayjs.extend(customParseFormat);

const { RangePicker } = DatePicker;

const dateFormat = 'YYYY-MM-DD';


const Layout = ({
  compact,
  children,
  insidePane,
  headingCentre,
  classNameForDetailPage,
  styleDetailPageContainer,
  backDetailPage,
  showDetailPage,
  small,
  medium,
  large,
  layoutForm,
  hideTitleBar,
  title,
  tableCardClass = "",
  customTitleComponent = () => <></>,
  filterStructure,
  filterValues,
  onApplyFilter,
  hideSearch,
  hideAdd,
  onAddClick,
  onAddNewClick,
  onSelectClick,
  customOnAddClick,
  gridLoading,
  showToggle,
  toggleData,
  showDateRange,
  onToggle,
  clientValue,
  onChangeClient,
  leftChildComponent,
  setData,
  data = [],
  fromDate,
  tillDate,
  setTillDate, toggleType,

  setFromDate
}) => {
  let _actionMenu,
    _theadFull,
    _onAlert,
    _tbodyFull,
    _table,
    _detailPageTitle,
    _detailPageBody,
    _detailPageFooter,
    _leftChild;

  const { t } = useContext(I18nContext);
  let navigate = useNavigate();



  Children.forEach(children, (child) => {
    if (child.type === Layout?.ActionMenu) {
      return (_actionMenu = child);
    }
    if (child.type === Layout?.TheadFull) {
      return (_theadFull = child);
    }
    if (child.type === Layout?.TbodyFull) {
      return (_tbodyFull = child);
    }
    if (child.type === Layout?.Table) {
      return (_table = child);
    }
    if (child.type === Layout?.DetailPageTitle) {
      return (_detailPageTitle = child);
    }
    if (child.type === Layout?.DetailPageBody) {
      return (_detailPageBody = child);
    }
    if (child.type === Layout?.DetailPageFooter) {
      return (_detailPageFooter = child);
    }
    if (child.type === Layout?.OnAlert) {
      return (_onAlert = child);
    }
    if (child.type === Layout?.LeftChild) {
      return (_leftChild = child);
    }


  });
  return (
    <React.Fragment>
      <div className={` ${insidePane ? " p-0 m-0 " : " "}`} style={{ backgroundColor: "#fff" }}>
        {headingCentre}

        <DetailPageModal
          classNameForDetailPage={classNameForDetailPage}
          insidePane={insidePane}
          style={styleDetailPageContainer}
          back={backDetailPage}
          _body={_detailPageBody}
          _title={_detailPageTitle}
          _footer={_detailPageFooter}
          show={showDetailPage}
          small={small}
          medium={medium}
          large={large}
        />
        <Row>
          <Col className={"left-scroll-independent col-md-12"} style={{ height: insidePane ? "calc(100% )" : "calc(100vh - 120px)" }}>
            {_onAlert && (
              <UncontrolledDropdown className="mt-3">
                {_onAlert}
              </UncontrolledDropdown>
            )}
            <div className={`${!insidePane ? "card" : ""} mx-0 pt-1`} style={{ height: layoutForm ? `97%` : "" }}>
              {!hideTitleBar && (
                <CardTitle className="pt-3 ps-4 pe-3 pb-0">
                  <div className={`d-flex justify-content-between flex-sm-row col-12 flex-column-reverse align-items-sm-center mt-3 mt-sm-0
 mb-0`}>
                    <div className="d-flex me-2">
                      {/* {title && <Breadcrumb title={title} />} */}
                      {customTitleComponent}
                      {showToggle &&
                        <>
                          {filterStructure && (
                            <>
                              {toggleType == "dropdown" ? <LayoutHeaderDropdown
                                data={toggleData?.module}
                                value={toggleData?.toggleValue}
                                onChange={(selectedValue) => { onToggle(selectedValue) }}
                                style={{
                                  fontSize: '12px',
                                  // maxWidth: '100%',
                                  display: 'flex',
                                  flexWrap: 'wrap',
                                  width: compact ? 'max-content' : `calc(130px)`
                                }}
                              /> : <ToggleSelector
                                style={{
                                  fontSize: '12px',
                                  // minWidth: '300px',
                                  maxWidth: '100%',
                                  display: 'flex',
                                  flexWrap: 'wrap',
                                  width: compact ? 'max-content' : `calc(${toggleData?.module?.length * 135}px)`
                                }}
                                compact
                                zeroMargin
                                data={toggleData?.module}
                                value={toggleData?.toggleValue}
                                onChange={(selectedValue) => { onToggle(selectedValue) }}
                              />
                              }
                            </>

                          )}
                        </>
                      }
                      {showDateRange &&
                        <div className="mt-3">
                          <DateRange
                            defaultValue={[null, null]}
                            disabled={[false, false]}
                            onChange={(dates) => {
                              if (dates === null) {
                                setFromDate(null);
                                setTillDate(null);
                              } else {
                                const formattedFromDate = moment.unix(dates[0]).format('YYYYMMDD');
                                const formattedTillDate = moment.unix(dates[1]).format('YYYYMMDD');
                                setFromDate(formattedFromDate);
                                setTillDate(formattedTillDate);
                              }
                            }
                            }
                          />
                        </div>
                      }
                      {/* {showClientSelect && (
                        <ClientSelect
                          value={clientValue}
                          onChange={(v) => onChangeClient(v)}
                          className="col-sm-8"
                          style={{ marginLeft: '40px', zIndex: '4' }}
                        />
                      )} */}
                    </div>
                    <div className="d-flex flex-1 ">
                      {!hideSearch &&
                        <div className="flex-1">
                          {filterStructure && (
                            <Filter
                              isRate={showToggle}
                              className="mb-2 ms-3 "
                              value={filterValues}
                              structure={filterStructure}
                              applyFunc={onApplyFilter}
                            />
                          )}
                        </div>
                      }
                      <div className="d-flex mb-2">
                        {!hideAdd &&
                          <div>
                            {onAddClick && (
                              <AddButton
                                onClick={() => {
                                  onAddClick();
                                }}
                              />
                            )}
                          </div>
                        }
                        {onAddNewClick && (
                          <AddNewButton
                            onClick={() => {
                              onAddNewClick();
                            }}
                          />
                        )}
                        {onSelectClick && (
                          <SelectButton
                            onClick={() => {
                              onSelectClick();
                            }}
                          />
                        )}
                        {/* For Template Action Menu */}
                        {customOnAddClick && (
                          <UncontrolledDropdown className="ms-2">
                            <DropdownToggle
                              style={{ padding: "0", color: "transparent" }}
                            >
                              <AddButton
                                onClick={() => { }}
                                className={"btn btn-primary"}
                              />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end">
                              <DropdownItem header>Create Template</DropdownItem>
                              <DropdownItem
                                onClick={() => {
                                  customOnAddClick(true);
                                }}
                              >
                                For Upload
                              </DropdownItem>
                              <DropdownItem
                                onClick={() => {
                                  customOnAddClick(false);
                                }}
                              >
                                For Download
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        )}
                        {_actionMenu && (
                          <UncontrolledDropdown className="ms-2">
                            <DropdownToggle
                              color="primary"
                              style={{ padding: "0px 1px", height: 30 }}
                            >
                              <div
                                style={{
                                  display: "inline-block",
                                  color: "primary",
                                }}
                              >
                                <i
                                  style={{ fontSize: "24px" }}
                                  className="bx bx-dots-horizontal-rounded"
                                />
                              </div>
                            </DropdownToggle>
                            {_actionMenu}
                            {/* <div className="layout-action-menu">

                            </div> */}
                          </UncontrolledDropdown>
                        )}
                      </div>
                    </div>
                  </div>
                </CardTitle>
              )}
              <CardBody style={{ height: "100%" }} className={tableCardClass}>
                {gridLoading ? (
                  <div className="d-flex align-items-center justify-content-center">
                    <ThreeBounce size={20} color="pink" />
                  </div>
                ) : (
                  <>
                    <div className="d-md-flex flex-1">
                      {_leftChild}
                      {/* <div className="d-flex flex-1"> */}
                      <div
                        // className="table-rep-plugin table-responsive mb-0"
                        data-pattern="priority-columns"
                        style={{
                          height: layoutForm ? `calc(100vh - 230px)` : "",
                          width: '100%',
                        }}
                      >

                        {_table}
                        <Table className="table mb-2 striped-table">
                          {_theadFull}
                          {_tbodyFull}
                        </Table>
                      </div>
                      {/* </div> */}
                    </div>
                  </>
                )}
              </CardBody>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment >
  );
};

Layout.ActionMenu = ({ children }) => <> {children} </>;
Layout.TheadFull = ({ children }) => <> {children} </>;
Layout.TbodyFull = ({ children }) => <> {children} </>;
Layout.Table = ({ children }) => <> {children} </>;
Layout.DetailPageTitle = ({ children }) => <h5 className="mb-0 w-100" style={{ fontSize: 12, fontWeight: "500", paddingLeft: "5px" }}> {children} </h5>;
Layout.DetailPageBody = ({ children }) => <> {children} </>;
Layout.DetailPageFooter = ({ children }) => <div className=" d-flex justify-content-end"> {children} </div>;
Layout.OnAlert = ({ children }) => <> {children} </>;
Layout.LeftChild = ({ children }) => <> {children} </>;


export default Layout;
