import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  CheckButton,
  DataGrid,
  DeleteButton,
  DeleteLink,
  EditLink,
  Layout,
  SaveButton
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { UserContext } from "../../store/context/UserContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import SetupProgressService from "../Dashboard/SetUpProgressService";
import { STRUCTURE } from "./DocumentTypeConstant";
import { DocumentTypeGridColumns } from "./DocumentTypeGridColumns";
import DocumentTypeService from "./DocumentTypeService";
import EditDocumentType from "./EditDocumentType";
import PagesNoRights from "../Utility/pages-no-rights";

const DocumentType = ({ value, insidePane, multiMode, onSelect = () => { } }) => {
  let { docEdit_id } = useParams();
  const location = useLocation();

  let navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const { t } = useContext(I18nContext);
  const { userContent } = useContext(UserContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);

  const [loading, setLoading] = useState(false);
  const [detailData, setDetailData] = useState({});
  const [editID, setEditID] = useState(docEdit_id);
  const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/doc-type/create" || docEdit_id);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [filterURL, setFilterURL] = useState("");
  const [filterObject, setFilterObject] = useState({});
  const [hasErr, setHasErr] = useState(false)


  const fetchData = async (filterUrl) => {
    setFilterURL(filterUrl);
    if (loading) return;
    setLoading(true);
    try {
      await DocumentTypeService.fetch(filterUrl);
      setLoading(false);

    } catch (e) {
      setLoading(false);
      showError(e);
    }
  };

  useEffect(() => {
    if (userContent?.rights?.includes(2900)) {
      const { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE)
      setFilterURL(filterUrl)
      setFilterObject(filterObject)
      fetchData(filterUrl);
      multiMode ? setSelectedIDs(value) : setSelectedIDs(value ? [value] : [])
    }
  }, [userContent?.rights])


  useEffect(() => {
    if (docEdit_id) {
      setEditID(docEdit_id)
    } else {
      if (window.location.pathname !== "/doc-type/create") {
        setShowDetailPage(false)
      }
      setEditID(null);
    }
  }, [docEdit_id, window.location.pathname])

  const onSave = async (e) => {
    e.preventDefault();

    if (!(detailData?.name)) {
      setHasErr(true);
      showError(t("Please enter Document Type"));
      return;
    }

    setLoading(true);
    try {
      if (editID) {
        await DocumentTypeService.edit(detailData, editID);
        setLoading(false);
        showMessage(t("Document Type updated successfully."));
      } else {
        const docType = await DocumentTypeService.save(detailData);
        setLoading(false);
        showMessage(t("Document Type saved successfully."));
        if (!insidePane) navigate(`/doc-type/edit/${docType}`);
      }
      fetchData(filterURL);

    } catch (e) {
      setLoading(false);
      showError(e);
    }
    setHasErr(false)
  };
  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: t("Do you want to delete record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      setLoading(true);
      try {
        await DocumentTypeService.delete(id);
        setLoading(false);
        showMessage("Document Type Deleted SuccessFully", "Deleted");
        navigate("/doc-type");
        setShowDetailPage(false);

      } catch (e) {
        setLoading(false);
        showError(e);
      }
    }
  };


  const renderLastCol = useCallback((docType) => {
    return (
      <>
        {userContent?.rights?.includes(2902) &&
          <EditLink
            onClick={() => {
              if (!insidePane)
                navigate(`/doc-type/edit/${docType?._id}`);
            setEditID(docType?._id);
              setShowDetailPage(true);

            }}
          />
        }
        {userContent?.rights?.includes(2903) && (docType?.modifiable)  ?
          <DeleteLink
            onClick={(event) => onDelete(event, docType?._id)}
          />
          : null}
        {!multiMode && insidePane ? (
          <CheckButton
            onClick={() => {
              onSelect(docType?._id);
            }}
          />
        ) : null}
      </>
    );
  }, [insidePane, multiMode, userContent])

  if (!userContent?.rights?.includes(2900)) {
    return <div key={userContent?.rights} > <PagesNoRights/></div>
  }

  

  return (
    <React.Fragment key={userContent?.rights}>
      <Layout
        hideAdd={!userContent?.rights?.includes(2901)}
        showDetailPage={showDetailPage}
        backDetailPage={async () => {
          setShowDetailPage(false);
          if (!insidePane) navigate("/doc-type");
          setEditID(null);
        }}
        gridLoading={loading}
        title={t("Document Type")}
        filterValues={filterObject}
        filterStructure={STRUCTURE}
        onApplyFilter={fetchData}
        onAddClick={() => {
          if (!insidePane) navigate(`/doc-type/create`);
          setShowDetailPage(true);
          setEditID(null);
        }}
        insidePane={insidePane}
        page={DocumentTypeService.page}
        rows={DocumentTypeService.rows}
        total={DocumentTypeService.total}
        fetch={DocumentTypeService.fetch}
      >

        <Layout.Table>
          <DataGrid
            data={DocumentTypeService.records}
            total={DocumentTypeService.total}
            uiPreference="docType.grid"
            headers={DocumentTypeGridColumns}
            onSelectChange={(v) => {
              onSelect(v)
              setSelectedIDs(v)
            }}
            selectedIDs={selectedIDs}
            page={DocumentTypeService.page}
            rowsPerPage={DocumentTypeService.rowsPerPage}
            onPaginationChange={DocumentTypeService.onPaginationChange}
            renderLastCol={renderLastCol}
          />
        </Layout.Table>


        <Layout.DetailPageTitle>
          {detailData?._id ? t("Edit Document Type") : t("Add Document Type")}
        </Layout.DetailPageTitle>

        <Layout.DetailPageBody>
          <EditDocumentType
            editId={editID}
            onChange={(v) => {
              setDetailData(v)
            }}
            hasErr={hasErr}
          />
        </Layout.DetailPageBody>

        <Layout.DetailPageFooter>

          {editID && userContent?.rights?.includes(2903) && (detailData?.modifiable) && (detailData?._id) ?
            <DeleteButton onClick={(e) => onDelete(e, detailData?._id)} loading={loading} />
          : null}

          {(userContent?.rights?.includes(2902) || userContent?.rights?.includes(2901))  ?
            <SaveButton onClick={onSave} loading={loading} />
            : null}

        </Layout.DetailPageFooter>
      </Layout>
    </React.Fragment>
  );
};

export default observer(DocumentType);


