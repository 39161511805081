import React, { useContext, useEffect, useState } from 'react'
import Logo from "../../../../../assets/images/Logo.svg";
import { DialogContext } from '../../../../../store/context/DialogContext';
import { doGET } from '../../../../../util/HttpUtil';
import ProofOfDocuments from '../../BusinessLiteForms/components/ProofOfDocument';
import SubmitButton from '../../../../../components/Buttons/SubmitButton';
import { FormEndpoints } from '../../ExtraQuestions';

const ApplicationDocRedo = ({ type, formType, formId, asLabel, recordId }) => {

    const [reqdApplicationDocs, setReqdApplicationDocs] = useState([])
    const [loading, setLoading] = useState([])
    const { showMessage, showError, showConfirm } = useContext(DialogContext)
    const [uboDetail, setUboDetail] = useState(null);

    const fetchUBOs = async () => {
       if(recordId) {
           setLoading(true);
           try {
               const uboResponse = await doGET(`/api/ubo/grid?application_id=${recordId}`);
               const obj = {};
               (uboResponse?.data?.rows ?? []).forEach((v) => {
                   obj[v?._id] = v;
               });
               setUboDetail(obj);
           } catch (error) {
               console.error('Error fetching ubo details:', error);

           } finally {
               setLoading(false);
           }
       }
    }


    const fetch = async () => {
        setLoading(true)
        try {
            const response = await doGET(`/api/application-doc/redo/list?form_id=${formId}`)
            if (response?.status == 200) {
                setReqdApplicationDocs(response?.data)
            }
        } catch (error) {
            showError(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetch()
        fetchUBOs();
    }, [recordId])

    const handleSubmit = async () => {
        setLoading(true)
        try {
            const submitResponse = await doGET(`/api/${FormEndpoints[formType]}/submit?id=${formId}&markDocSubmit=true`)
            if (submitResponse?.status == 200) {
                showMessage("Submitted")
                window.location.reload();
            }
        } catch (error) {
            showError(error)
        } finally {
            setLoading(false)
        }
    }

    const groupDocumentsByUbo = (documents) => {
        const grouped = { reqdApplicationDocs: [] };

        documents.forEach(doc => {
            if (doc?.ubo_id) {
                if (!grouped[doc?.ubo_id]) {
                    grouped[doc?.ubo_id] = [];
                }
                grouped[doc?.ubo_id].push(doc);
            } else {
                grouped?.reqdApplicationDocs.push(doc);
            }
        });

        return grouped;
    };

    const groupedDocuments = groupDocumentsByUbo(reqdApplicationDocs);

    return (
        <div style={{ height: "100vh", margin: "0px 30px" }} className={'d-flex justify-content-center align-items-center'}>
            <div className={'form-content '}>

                <div className='d-flex flex-column  justify-content-center  align-items-center  '>
                    {<img
                        src={Logo}
                        alt="fv-bank"
                        height="50"
                        style={{ marginBottom: "10px" }}
                        className="logo logo-dark"
                    />}
                    <div style={{ margin: "10px 0px 30px 0px", fontSize: "24px", fontWeight: "500" }}>{type}</div>
                    <div>
                        {groupedDocuments.reqdApplicationDocs?.length ? <>
                            <div className='mb-2'><b>Business Application Documents</b></div>
                            <div className="fv_application_document-group">
                                {groupedDocuments.reqdApplicationDocs.map((reqdApplicationDoc, index) => (
                                    <ProofOfDocuments
                                        isAskForInfo={true}
                                        recordId={recordId}
                                        asLabel={asLabel}
                                        remark={reqdApplicationDoc?.remark}
                                        seq={reqdApplicationDoc?.seq}
                                        label={reqdApplicationDoc?.type == "ProofOfAddress" ? "Proof Of Address" : (reqdApplicationDoc?.type == "BusinessTaxIdProof" ? "Business Tax Id Proof" : (reqdApplicationDoc?.type == "SourceOfFunds" ? "Source Of Funds" : (reqdApplicationDoc?.type == "SourceOfBusFundsProof" ? "Source Of Funds for Business" : (reqdApplicationDoc?.type == "Business_Proof_Address" ? "Business Proof Address" : "Document"))))}
                                        field={reqdApplicationDoc?.type == "ProofOfAddress" ? "ProofOfAddress" : (reqdApplicationDoc?.type == "BusinessTaxIdProof" ? reqdApplicationDoc?.type : (reqdApplicationDoc?.type == "SourceOfFunds" ? reqdApplicationDoc?.type : (reqdApplicationDoc?.type == "SourceOfBusFundsProof" ?reqdApplicationDoc?.type : (reqdApplicationDoc?.type == "Business_Proof_Address" ? reqdApplicationDoc?.type : false))))}
                                        // field={reqdApplicationDoc?.type == "ProofOfAddress" ? "ProofOfAddress" : false}
                                        showCategory={reqdApplicationDoc?.type == "ProofOfAddress" ? "ProofOfAddress" : false}
                                        type={reqdApplicationDoc?.type} //
                                        ubo_id={reqdApplicationDoc?.ubo_id} // 
                                    />
                                ))}
                            </div>    </> : null}

                        {Object.keys(groupedDocuments)
                            .filter(key => key !== 'reqdApplicationDocs')
                            .map((ubo_id, index) => (
                                <div key={index}>
                                    <div className='mb-2'>
                                        <b>Application Documents For {uboDetail?.[ubo_id]?.firstName ?? ""} {uboDetail?.[ubo_id]?.lastName ?? ""}</b>
                                        {/* {JSON?.stringify(uboDetail?.[ubo_id],null,2)} */}
                                    </div>
                                    {groupedDocuments[ubo_id].map((reqdApplicationDoc, docIndex) => (
                                        <div key={docIndex} className="w-100">
                                            <div className="fv_application_document-group">
                                                <ProofOfDocuments
                                                    isAskForInfo={true}
                                                    recordId={recordId}
                                                    asLabel={asLabel}
                                                    remark={reqdApplicationDoc?.remark}
                                                    seq={reqdApplicationDoc?.seq}
                                                    label={
                                                        reqdApplicationDoc?.type === "ProofOfAddress"
                                                            ? "Proof Of Address"
                                                            : reqdApplicationDoc?.type === "BusinessTaxIdProof"
                                                                ? "Business Tax Id Proof"
                                                                : reqdApplicationDoc?.type === "SourceOfFunds"
                                                                    ? "Source Of Funds"
                                                                    : reqdApplicationDoc?.type === "SourceOfBusFundsProof"
                                                                        ? "Source Of Funds for Business"
                                                                        : reqdApplicationDoc?.type === "Business_Proof_Address"
                                                                            ? "Business Proof Address"
                                                                            : "Document"
                                                    }
                                                    field={reqdApplicationDoc?.type === "ProofOfAddress" ? "ProofOfAddress" : undefined}
                                                    showCategory={reqdApplicationDoc?.type === "ProofOfAddress" ? "ProofOfAddress" : undefined}
                                                    type={reqdApplicationDoc?.type}
                                                    ubo_id={reqdApplicationDoc?.ubo_id}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ))}

                    </div>

                    <SubmitButton
                        loading={loading}
                        onClick={(e) => {

                            handleSubmit(e);
                        }}
                    />




                </div>
            </div>
        </div>
    )
}

export default ApplicationDocRedo