import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { UserContext } from "../../store/context/UserContext";
import DocCategoryService from "./DocCategoryService";
import FolderItemModule from "./FolderItemModule";
import "./style.scss";
import { ThreeBounce } from "better-react-spinkit";

const DocCategoryComponent = ({module}) => {

  const [newCategoryName, setNewCategoryName] = useState("");
  const [isCreating, setIsCreating] = useState(false);
  const [collapsedCategories, setCollapsedCategories] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { userContent } = useContext(UserContext)

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        await DocCategoryService.fetchByModule(module);
      } catch (error) {
        console.error("Error fetching categories:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchCategories();

  }, []);

  const handleCreateCategory = async (name, parentId = null) => {
    try {
      setIsCreating(true);
      await DocCategoryService.saveByModule(
        { module: module, name, parent_id: parentId },
        module
      );
      setNewCategoryName("");
    } catch (error) {
      console.error("Error creating category:", error);
    } finally {
      setIsCreating(false);
    }
  };

  const handleNewCategoryKeyDown = (event) => {
    if (event.key === "Enter" && newCategoryName.trim() !== "") {
      handleCreateCategory(newCategoryName.trim());
    }
  };

  const toggleCollapse = (id) => {
    setCollapsedCategories((prevCollapsed) => ({
      ...prevCollapsed,
      [id]: !prevCollapsed[id]
    }));
  };

  const renderCategories = (categories, level = 0) => {
    return categories
      .filter(category => category.module === module)
      .map(category => {
        try {
           return (
            <div key={category._id} style={{ paddingLeft: `${level * 20}px` }}>
              <FolderItemModule
                 module={module}
                category={category}
                isCollapsed={collapsedCategories[category._id]}
                onToggleCollapse={() => toggleCollapse(category._id)}
                onCreateSubCategory={handleCreateCategory}
              />
              {!collapsedCategories[category._id] && category.children && renderCategories(category.children, level + 1)}
            </div>
          );
        } catch (error) {
          console.error("Error rendering category:", error);
          return null;
        }
      });
  };

  if (isLoading) {
    return (
      <div className="loader-container">
        <ThreeBounce color="#36d7b7" />
      </div>
    ); // Show loader while fetching data
  }


  return (
    <div key={userContent?.rights} className="p-2">
  

      {renderCategories(toJS(DocCategoryService.recordsByModule))}
      {isCreating ? (
        <input
          autoFocus
          type="text"
          placeholder="Category name"
          value={newCategoryName}
          onBlur={() => setIsCreating(false)}
          onChange={(e) => setNewCategoryName(e.target.value)}
          onKeyDown={handleNewCategoryKeyDown}
          className="mt-2 category-creation-input"
        />
      ) : (
        userContent?.rights?.includes(2601) ? <div className="create-category mt-2 d-flex align-items-center" onClick={() => setIsCreating(true)}>
          <FaPlus className="me-2" />
          <span>Create Category</span>
        </div> : <></>
      )}
    </div>
  );
};

export default observer(DocCategoryComponent);
