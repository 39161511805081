import { ThreeBounce } from 'better-react-spinkit';
import React from "react";
import { Button } from "reactstrap";

export default function RejectButton({ onClick, disabled, loading, style, className }) {

  if (loading) {
    return (
      <div className="d-flex justify-content-end my-2">
        <Button
          type="submit"
          className={`me-2 ${className}`}
          disabled={disabled}
          style={{ borderColor: "#B91616", backgroundColor: "#fff", height: "40px", padding: "2px 4px 2px 4px", width: "100px", borderRadius: "10px" }}
          color="outline"
        >
          <ThreeBounce size={10} color="#dc3545" />
        </Button>
      </div>
    );
  }
  return (
    <div className="d-flex justify-content-end my-2">
      <Button
        onClick={onClick}
        className={`me-2 ${className}`}
        disabled={disabled}
        style={{ cursor: disabled ? "not-allowed" : "pointer",  borderColor: "#B91616", backgroundColor : "#fff", height: "40px", padding: "2px 4px 2px 4px", width: "100px", borderRadius: "10px" }}
        color="outline"
      >
        <div style={{ margin: "2px", fontSize: "16px", color: '#B91616' }}>Reject</div>
      </Button>
    </div>
  );
}
