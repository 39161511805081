import { ThreeBounce } from "better-react-spinkit";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { FaUserPlus } from "react-icons/fa";
import { FiMinusCircle } from "react-icons/fi";
import { Card, Form } from "reactstrap";
import { InputField, SelectField, TimeSelect, ToggleSelector } from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import ReportService from "./ReportService";

const initialData = {
  frequency: 'weekly',
  recipients: [{ name: '', email: '' }],
}

const EditReport = ({ editId, onChange = () => { }, hasErr }) => {
  const { t } = useContext(I18nContext);
  const { showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(initialData);

  const fetch = async () => {
    try {
      setLoading(true);
      const reportData = await ReportService.get(editId);
      setData(reportData);
    } catch (error) {
      showError(error || 'An error occurred while fetching report data.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setData(initialData)
    setLoading(true);
    if (editId) fetch();
    setLoading(false)
  }, [editId, ReportService?.version]);

  useEffect(() => {
    onChange(data)
  }, [data]);


  const handleRecipientChange = (index, field, value) => {
    const updatedRecipients = (data?.recipients ?? []).map((recipient, i) =>
      i === index ? { ...recipient, [field]: value } : recipient
    );
    setData({ ...data, recipients: updatedRecipients });
  };

  const handleAddRecipient = () => {
    setData({
      ...data,
      recipients: [...(data?.recipients ?? []), { name: '', email: '' }],
    });
  };

  const handleRemoveRecipient = (index) => {
    const filteredRecipients = data.recipients.filter((_, i) => i !== index);
    setData({ ...data, recipients: filteredRecipients });
  };


  return (
    <React.Fragment>
      {loading ?
        <ThreeBounce /> :
        <Form
          className="p-3"
          style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}
        >
          <InputField
            placeholder={`Report Title`}
            label="Report Title"

            error={
              ((data.name?.length === 0 || !data.name))
                ? `Please enter name`
                : ""
            }
            showErr={hasErr}
            value={data.name}
            onChange={(selectedValue) => {
              setData({ ...data, name: selectedValue });
            }}
          />
          <Card className="p-3 mt-3 ">


            <div style={{ fontSize: "18px", fontWeight: "500" }}>Frequency</div>
            <div className="my-2">
              <ToggleSelector
                zeroMargin
                style={{ fontSize: "16px" }}
                data={[
                  { value: 'daily', label: 'Daily' },
                  { value: 'weekly', label: 'Weekly' },
                  { value: 'monthly', label: 'Monthly' },
                  { value: 'yearly', label: 'Yearly' }
                ]}
                className="my-0"

                value={data?.frequency}
                onChange={(selectedValue) => {

                  setData(prevData => {
                    const { day, date, time, ...rest } = prevData;
                    return { ...rest, frequency: selectedValue };
                  });


                }}
              />
            </div>
          </Card>

          {['daily', 'weekly', 'monthly']?.includes(data?.frequency) &&

            <Card className="p-3">
              <div style={{ fontSize: "18px", fontWeight: "500" }}>Send Report</div>
              <div className="d-flex row align-items-center my-2 " >

                <TimeSelect
                  placeholder={`Time`}
                  type="text"
                  className="col-6 "
                  label="Time"

                  error={
                    ((data.time?.length === 0 || !data.time))
                      ? `Please enter time`
                      : ""
                  }
                  showErr={hasErr}
                  value={data.time}
                  onChange={(selectedValue) => {
                    setData({ ...data, time: selectedValue });
                  }}
                />


                {['weekly',]?.includes(data?.frequency) &&
                  <SelectField
                    placeholder={`Select Day`}
                    className="col-6"
                    label="Day of every week"
                    data={[
                      { value: 'mon', label: 'Monday' },
                      { value: 'tue', label: 'Tuesday' },
                      { value: 'wed', label: 'Wednesday' },
                      { value: 'thu', label: 'Thursday' },
                      { value: 'fri', label: 'Friday' },
                      { value: 'sat', label: 'Saturday' },
                      { value: 'sun', label: 'Sunday' }
                    ]}
                    error={
                      ((data.day?.length === 0 || !data.day))
                        ? `Please enter day`
                        : ""
                    }
                    showErr={hasErr}
                    value={data.day}
                    onChange={(selectedValue) => {
                      setData({ ...data, day: selectedValue?.value });
                    }}
                  />
                }

                {['monthly',]?.includes(data?.frequency) &&
                  < SelectField
                    placeholder={`Date`}
                    className="col-6"
                    label="Date of every month"
                    data={[{ value: 1, label: '1st' },
                    { value: 2, label: '2nd' },
                    { value: 3, label: '3rd' },
                    { value: 4, label: '4th' },
                    { value: 5, label: '5th' },
                    { value: 6, label: '6th' },
                    { value: 7, label: '7th' },
                    { value: 8, label: '8th' },
                    { value: 9, label: '9th' },
                    { value: 10, label: '10th' },
                    { value: 11, label: '11th' },
                    { value: 12, label: '12th' },
                    { value: 13, label: '13th' },
                    { value: 14, label: '14th' },
                    { value: 15, label: '15th' },
                    { value: 16, label: '16th' },
                    { value: 17, label: '17th' },
                    { value: 18, label: '18th' },
                    { value: 19, label: '19th' },
                    { value: 20, label: '20th' },
                    { value: 21, label: '21st' },
                    { value: 22, label: '22nd' },
                    { value: 23, label: '23rd' },
                    { value: 24, label: '24th' },
                    { value: 25, label: '25th' },
                    { value: 26, label: '26th' },
                    { value: 27, label: '27th' },
                    { value: 28, label: '28th' },
                    { value: 29, label: '29th' },
                    { value: 30, label: '30th' },
                    { value: 31, label: '31st' },
                    ]}
                    error={
                      ((data.date?.length === 0 || !data.date))
                        ? `Please enter Date`
                        : ""
                    }
                    showErr={hasErr}
                    value={data.date}
                    onChange={(selectedValue) => {
                      setData({ ...data, date: selectedValue?.value });
                    }}
                  />
                }



              </div>



            </Card>
          }

          <Card className="p-3">
            <div className="d-flex justify-content-between align-items-center ">
              <div style={{ fontSize: "18px", fontWeight: "500" }}>Recipients</div>

              <Button className="d-flex align-items-center " onClick={handleAddRecipient}><FaUserPlus size={20} className="me-2" /> Recipient</Button>
            </div>

            {(data?.recipients ?? [])?.map((recipient, index) => (
              <div className="d-flex align-items-center " key={index}>
                <InputField
                  placeholder={`Enter  Name`}
                  type="text"
                  className="flex-1 me-3"
                  // label="Recipient Name"
                  error={
                    ((recipient.name?.length === 0 || !recipient.name))
                      ? `Please enter  Name`
                      : ""
                  }
                  showErr={hasErr}
                  value={recipient.name}
                  onChange={(e) => handleRecipientChange(index, 'name', e)}
                />

                <InputField
                  type="email"
                  className="flex-1 me-3"
                  // label="Recipient Email"
                  placeholder={`Enter  Email`}
                  error={
                    ((recipient.email?.length === 0 || !recipient.email))
                      ? `Please enter  Name`
                      : ""
                  }
                  showErr={hasErr}
                  value={recipient.email}
                  onChange={(e) => handleRecipientChange(index, 'email', e)}
                />

                <FiMinusCircle
                  style={{
                    cursor: "pointer"
                  }}
                  className=" mx-2"
                  onClick={() => handleRemoveRecipient(index)}
                  color="red"
                  size={24}
                />

              </div>
            ))}


          </Card>

        </Form>
      }
    </React.Fragment>
  );
};

export default observer(EditReport);
