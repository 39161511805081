import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import DocCategoryService from "./DocCategoryService";
import FolderItem from "./FolderItem";
import "./style.scss";
import { SelectField } from "../../components";
import { toJS } from "mobx";
import { UserContext } from "../../store/context/UserContext";

const MODULE_OPTIONS = [
  { value: 'proofOfAddress', label: 'Proof of Address' },
  { value: 'shareholderRegister', label: 'Shareholder, Member, or Partner Register' },
  { value: 'corporateStructureChart', label: 'Corporate Structure Chart' },
  { value: 'organizationalChart', label: 'Organizational Chart' },
  { value: 'flowOfFundsDiagram', label: 'Flow of Funds Diagram' },
  { value: 'businessTaxId', label: 'Business Tax ID' },
  { value: 'sourceOfFunds', label: 'Proof of Sources of Funds' },
  { value: 'bankPassbook', label: 'Bank Passbook' },
  { value: 'passport', label: 'Passport' },
  { value: 'driverLicense', label: 'Driver License' },
  { value: 'voterId', label: 'Voter ID' },
  { value: 'socialSecurityCard', label: 'Social Security Card' },
  { value: 'utilityBill', label: 'Utility Bill' },
  { value: 'rentalAgreement', label: 'Rental Agreement' },
  { value: 'birthCertificate', label: 'Birth Certificate' },
  { value: 'marriageCertificate', label: 'Marriage Certificate' },
  { value: 'taxReturn', label: 'Tax Return' },
  { value: 'employmentLetter', label: 'Employment Letter' },
  { value: 'bankStatement', label: 'Bank Statement' },
];


const DocCategorySidebar = observer(() => {
  const [selectedModule, setSelectedModule] = useState(MODULE_OPTIONS[0].value);
  const [newCategoryName, setNewCategoryName] = useState("");
  const [isCreating, setIsCreating] = useState(false);
  const [collapsedCategories, setCollapsedCategories] = useState({});
  const { userContent } = useContext(UserContext)

  useEffect(() => {
    try {
      DocCategoryService.fetch();
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  }, []);

  const handleCreateCategory = async (name, parentId = null) => {
    try {
      await DocCategoryService.save({ module: selectedModule, name, parent_id: parentId });
      setNewCategoryName("");
      setIsCreating(false);
    } catch (error) {
      console.error("Error creating category:", error);
    }
  };

  const handleNewCategoryKeyDown = (event) => {
    if (event.key === "Enter" && newCategoryName.trim() !== "") {
      handleCreateCategory(newCategoryName.trim());
    }
  };

  const toggleCollapse = (id) => {
    setCollapsedCategories((prevCollapsed) => ({
      ...prevCollapsed,
      [id]: !prevCollapsed[id]
    }));
  };

  const renderCategories = (categories, level = 0) => {
    return categories
      .filter(category => category.module === selectedModule)
      .map(category => {
        try {
          console.log({ category: toJS(category), level });
          return (
            <div key={category._id} style={{ paddingLeft: `${level * 20}px` }}>
              <FolderItem
                category={category}
                isCollapsed={collapsedCategories[category._id]}
                onToggleCollapse={() => toggleCollapse(category._id)}
                onCreateSubCategory={handleCreateCategory}
              />
              {!collapsedCategories[category._id] && category.children && renderCategories(category.children, level + 1)}
            </div>
          );
        } catch (error) {
          console.error("Error rendering category:", error);
          return null;
        }
      });
  };

  return (
    <div key={userContent?.rights} className="p-2">
      <SelectField
        data={MODULE_OPTIONS}
        value={selectedModule}
        onChange={(v) => setSelectedModule(v?.value)}
        showErr={false}
        label="Module"
        required={true}
        className="mb-3"
      />
      {renderCategories(toJS(DocCategoryService.records))}
      {isCreating ? (
        <input
          autoFocus
          type="text"
          placeholder="Category name"
          value={newCategoryName}
          onBlur={() => setIsCreating(false)}
          onChange={(e) => setNewCategoryName(e.target.value)}
          onKeyDown={handleNewCategoryKeyDown}
          className="mt-2 category-creation-input"
        />
      ) : (
        userContent?.rights?.includes(2601) ? <div className="create-category mt-2 d-flex align-items-center" onClick={() => setIsCreating(true)}>
          <FaPlus className="me-2" />
          <span>Create Category</span>
        </div> : <></>
      )}
    </div>
  );
});

export default DocCategorySidebar;
