import React, { useEffect } from 'react';
import "./styles.scss";




const PostKYC = ({ value }) => {

    // const fetchDetail = async () => {
    //     const formId = localStorage.getItem("formId");
    //     window.location.href = `http://fvbank.local/individual-lite/${formId}`;
    // }

    // useEffect(() => {
    //     fetchDetail()
    // }, [])



    return (
        <div style={{ height: "100vh", margin: "0px 30px" }} className={'d-flex justify-content-center align-items-center'}>
            <div className={'form-content '}>
                <div style={{ fontSize: "30px", lineHeight: 2 }}>
                    Thank you for submitting your identity verification data.
                </div>
                <div style={{ fontSize: "20px" }}>
                    We have received your Identity Verification Information and are processing it. We expect the process to take 15 minutes. Please check back later. Thank you.
                </div>
                <div style={{ fontSize: "20px" }}>
                    We appreciate your interest in opening an account with FV Bank.
                </div>
            </div>
        </div>
    );
};

export default PostKYC;
