import _ from 'lodash';
import moment from 'moment';

const status = {
  0: "Not Sent",
  1: "Sent",
  2: "Failed",
}
export const EmailLogsGridColumns = {
  version: 1,
  columns: [

    {
      title: "To",
      accessor : "to",
      renderer: (dataObj) => {
        return <div>
          {dataObj?.to[0] ?? ""}
        </div>
      },
      show: true,
      width : 300
    },

    {
      title: "Status",
      accessor: "status",
      renderer: (dataObj) => {
        return <div>
          {status?.[dataObj?.status] ?? ""}
        </div>
      },
      show: true,
      width: 100
    },


    {
      title: "Message Type",
      accessor: "messageType",
      renderer: (dataObj) => {
        return <div>
          {(dataObj?.messageType ?? "")}
        </div>
      },
      show: true,
      width: 120

    },

    {
      title: "Sent At",
      accessor: "sentAt",
      renderer: (dataObj) => {
        return <div>{dataObj?.sentAt ? moment.unix(dataObj.sentAt).format('LLL') : ""}</div>;
      },
      show: true,
      width: 170

    },

    {
      title: "Created At",
      accessor: "createdAt",
      renderer: (dataObj) => {
        return <div>{dataObj?.createdAt ? moment.unix(dataObj.createdAt).format('LLL') : ""}</div>;
      },
      show: true,
      width: 170
    },
    {
      title: "Subject",
      accessor: "subject",
      renderer: (dataObj) => {
        return <div>
          {(dataObj?.subject ?? "")}
        </div>
      },
      show: true,
    },
    {
      title: "Action",
      accessor: "action",
      fixed: "right",
      width: 90,
      show: true,
      required: true,
    },
  ],
};
