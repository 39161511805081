import React, { useState, useCallback, useRef, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './styles.scss';
// import EditorToolbar, { modules, formats } from './EditorToolbar'; 

const CommandPalette = ({ show, onSelect, commands, commandIndex }) => {
    if (!show) return null;

    return (
        <ul className="command-palette">
            {commands.map((command, index) => (
                <li key={index}
                    className={commandIndex === index ? 'selected' : ''}
                    onMouseDown={(e) => onSelect(e, command)}>
                    {command.label}
                </li>
            ))}
        </ul>
    );
};

const Editor = ({ label, placeholder, value, onChange }) => {
    const [showCommands, setShowCommands] = useState(false);
    const [commandIndex, setCommandIndex] = useState(0);
    const quillRef = useRef(null);


    const commands = [
        { label: 'Bold', action: () => document.execCommand('bold') },
        { label: 'Italic', action: () => document.execCommand('italic') },
        { label: 'Underline', action: () => document.execCommand('underline') },
        // { label: 'Strike', action: () => document.execCommand('strikeThrough') } ,
        { label: 'Subscript', action: () => document.execCommand('subscript') },
        { label: 'Superscript', action: () => document.execCommand('superscript') },
        { label: 'Clear Formatting', action: () => document.execCommand('removeFormat') },
        // { label: 'Ordered List', action: () => document.execCommand('insertOrderedList') },
        // { label: 'Unordered List', action: () => document.execCommand('insertUnorderedList') },
        { label: 'Blockquote', action: () => document.execCommand('formatBlock', false, 'blockquote') },
        // { label: 'Increase Indent', action: () => document.execCommand('indent') },
        // { label: 'Decrease Indent', action: () => document.execCommand('outdent') },
        { label: 'Align Left', action: () => document.execCommand('justifyLeft') },
        { label: 'Align Center', action: () => document.execCommand('justifyCenter') },
        { label: 'Align Right', action: () => document.execCommand('justifyRight') },
        { label: 'Justify', action: () => document.execCommand('justifyFull') },
        {
            label: 'Insert Table',
            action: () => {
                const quill = quillRef.current.getEditor();
                const range = quill.getSelection();
                if (range) {
                    const tableHTML = `<table border="1" style="width:100%">
                                        <tr>
                                          <td>&nbsp;</td>
                                          <td>&nbsp;</td>
                                        </tr>
                                        <tr>
                                          <td>&nbsp;</td>
                                          <td>&nbsp;</td>
                                        </tr>
                                      </table><p><br></p>`;
                    quill.clipboard.dangerouslyPasteHTML(range.index, tableHTML);
                }
            }
        },
        {
            label: 'Insert Link',
            action: () => {
                const quill = quillRef.current.getEditor();
                const range = quill.getSelection();
                if (range) {
                    const url = prompt('Enter the URL');
                    quill.format('link', url);
                }
            }
        },
    ];


    const handleKeyDown = useCallback((event) => {
        if (event.key === '/' && !showCommands) {
            setShowCommands(true);
            event.preventDefault();
            setCommandIndex(0);
        } else if (event.key === 'ArrowUp' && showCommands) {
            setCommandIndex(prevIndex => Math.max(prevIndex - 1, 0));
            event.preventDefault();
        } else if (event.key === 'ArrowDown' && showCommands) {
            setCommandIndex(prevIndex => Math.min(prevIndex + 1, commands.length - 1));
            event.preventDefault();
        } else if (event.key === 'Enter' && showCommands) {
            commands[commandIndex].action();
            setShowCommands(false);
            event.preventDefault();
        }
    }, [showCommands, commandIndex]);

    const handleCommandSelect = useCallback((event, command) => {
        event.preventDefault();
        command.action();
        setShowCommands(false);
    }, []);

    return (
        <div className="editor-container">
            {label && <label style={{ fontSize: 16, margin: "0px 0px 5px 5px", fontWeight: "500", }}>{label}</label>}
            <div className="react-quill-container">
                {/* <EditorToolbar />  */}
                <ReactQuill
                    ref={quillRef}
                    theme="snow"
                    value={value}
                    onChange={(content, delta, source, editor) => {
                    
                        if(source != "api") {
                            onChange(editor.getHTML())

                        }
                    
                    }}
                    placeholder={placeholder}
                    onKeyDown={handleKeyDown}
                        // modules={modules}
                // formats={formats}
                />
                <CommandPalette
                    show={showCommands}
                    onSelect={handleCommandSelect}
                    commands={commands}
                    commandIndex={commandIndex}
                />
            </div>
        </div>
    );
};

export default Editor;
