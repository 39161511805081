import React, { useEffect, useState } from "react";
import { doGET, doPOST } from '../../util/HttpUtil';
import { FaFilePdf, FaPaperclip, FaTimes, FaUserCircle } from 'react-icons/fa';
import './HelpScout.scss';
import _ from "lodash";
import moment from "moment";
import { Pagination } from "../../components";

const HelpScout = ({ userEmail }) => {
    const [conversations, setConversations] = useState([]);
    const [selectedConversation, setSelectedConversation] = useState(null);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState("");
    const fetchConversations = async (page = 1) => {
        setLoading(true);
        try {
            const response = await doGET(`/api/help-scout/conversations?embed=threads&query=(email%3A%22${encodeURIComponent(userEmail)}%22)&status=all&page=${page}`);
            // const response = await doGET(`/api/help-scout/conversations?embed=threads&query=(email%3A%22leon.lim%40fvbank.us%22)&status=all&page=${page}`);
            setConversations(response?.data?._embedded?.conversations);
            setPage(response?.data?.page)
            setLoading(false);
        } catch (error) {
            console.error("Error fetching conversations:", error);
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchConversations();
    }, [userEmail]);
    const handleLinkClick = async (event, link) => {
        event.preventDefault();
        try {
            const response = await doPOST(`/api/help-scout/conversations/data`, { data: link })
            console.log(response);
            const base64Data = response.data.data;
            const byteCharacters = atob(base64Data);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/pdf' });
            const urlToOpen = URL.createObjectURL(blob);
            window.open(urlToOpen, '_blank', 'noopener,noreferrer');
        } catch (error) {
            console.error('Error making the API call', error);
        }
    };
    const openModal = (conversation) => {
        setSelectedConversation(conversation);
    };

    const closeModal = () => {
        setSelectedConversation(null);
    };

    if (loading) {
        return <div className="fv_help_scout-loading">Loading...</div>;
    }

    const getBgColor = (status) => {
        switch (status) {
            case "active":
                return "rgb(68, 185, 14)"

            case "closed":
                return "rgb(132, 131, 131)"

            default:
                return "";
        }
    }


    const onPaginationChange = (type) => async (page, rowsPerPage) => {
        await fetchConversations(page);
    };
    return (
        <div className="fv_help_scout-container">
            <table className="fv_help_scout-table">
                <thead>
                    <tr>
                        <th className="text-nowrap">Customer</th>
                        <th className="text-nowrap">Conversation</th>
                        <th className="text-nowrap">Assigned To</th>
                        <th className="text-nowrap">Number</th>
                        <th className="text-nowrap">Waiting/Closed</th>
                    </tr>
                </thead>
                <tbody>
                    {conversations.map((conversation, idx) => (
                        <tr key={conversation.id} onClick={() => openModal(conversation)}>
                            <td>
                                <div className="fv_help_scout-customer">
                                    {conversation.createdBy.photoUrl ? <img src={conversation.createdBy.photoUrl} alt="User Avatar" className="fv_help_scout-avatar" /> : <FaUserCircle className="fv_help_scout-avatar" />}
                                    <span>{conversation.createdBy.first} {conversation.createdBy.last}</span>
                                </div>
                            </td>
                            <td>
                                <span className="fv_help_scout-tag" style={{ backgroundColor: conversation.tags[0]?.color || '#000' }}>
                                    {conversation.tags[0]?.tag}
                                </span>
                                <div className="d-flex justify-content-between">
                                    <span>{conversation.subject}</span>
                                    {/* <span>{conversation?.attachments?.length ?  <FaPaperclip /> : null}</span> */}
                                </div>
                                <div className="d-flex justify-content-between">
                                    <p className="fv_help_scout-preview">{conversation.preview}...</p>

                                    <span className="fv_conversatio_thread_label mt-3">{conversation?.threads}</span>
                                </div>
                            </td>
                            <td>{conversation.assignee?.first} {conversation.assignee?.last}</td>
                            <td>#{conversation.number}</td>
                            <td>{conversation.customerWaitingSince.friendly}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Pagination
                page={page.number}
                rowsPerPage={page.size}
                total={page.totalElements}
                hideRowsPerPage={true}
                onChange={onPaginationChange()} />
            {selectedConversation && (
                <div className="fv_help_scout-modal-overlay" onClick={closeModal}>
                    <div className="fv_help_scout-modal" onClick={(e) => e.stopPropagation()}>
                        <div className="fv_help_scout-modal-header d-flex justify-content-between align-items-center">
                            <p className="fv_help_scout_preview_text">Conversation Preview</p>
                            <FaTimes className="fv_help_scout-close-icon" onClick={closeModal} />
                        </div>
                        <div className="fv_help_scout-conversation-detail">
                            <div className="fv_help_scout-conversation-header d-flex justify-content-between align-items-center mb-3">
                                <div>
                                    <p className="fv_help_scout-conversation-subject">{selectedConversation.subject}</p>
                                    <p className="fv_help_scout-tag" style={{ backgroundColor: selectedConversation.tags[0]?.color || '#000' }}>{selectedConversation.tags[0]?.tag}</p>
                                </div>
                                <div className="d-flex justify-content-end align-items-center">
                                    <p className="fv_help_scout-conversation-number"><span>#</span>{selectedConversation.number}</p>
                                    <p className="fv_help_scout-conversation-status ms-2" style={{ backgroundColor: getBgColor(selectedConversation.status) }}>{selectedConversation.status.toUpperCase()}</p>
                                </div>
                            </div>
                            {selectedConversation._embedded.threads.map((thread, index) => (
                                <>
                                    {thread?.type != "lineitem" ? <div key={index} className="fv_help_scout-thread" style={{ borderLeft: thread?.type == "note" ? "5px solid #ffc555" : thread?.type == "message" ? "5px solid #93a1b0" : "" }}>
                                        <div className="fv_help_scout-thread-header">
                                            <div>
                                                {thread.createdBy.photoUrl ? (
                                                    <img src={thread.createdBy.photoUrl} alt="User Avatar" className="fv_help_scout-thread-avatar" />
                                                ) : (
                                                    <FaUserCircle className="fv_help_scout-thread-avatar-dummy" />
                                                )}
                                            </div>
                                            <div className="d-flex justify-content-between flex-1">
                                                <div>
                                                    <p className="fv_help_scout-thread-author d-flex">{thread.createdBy.first} {thread.createdBy.last} {thread?.type == "note" ? <div className="px-2" style={{ color: " #ffc555" }}> added a note</div> : ""}</p>
                                                    {!!thread?.createdBy?.email ? <p className="fv_help_scout-thread-date"><span className="fv_help_scout-cred">From: </span> {thread?.createdBy?.email}</p> : ""}
                                                    {!!thread?.assignedTo?.email ? <p className="fv_help_scout-thread-date"><span className="fv_help_scout-cred">To: </span> {thread?.assignedTo?.email}</p> : ""}
                                                    {!!thread?.cc && thread?.cc.length !== 0 ? <p className="fv_help_scout-thread-date"><span className="fv_help_scout-cred">Cc: </span>{thread?.cc?.join(", ")}</p> : null}
                                                </div>
                                                <div>
                                                    <p className="fv_help_scout-thread-date">{moment(thread.createdAt).format('LLL')}</p>
                                                    <p className="fv_help_scout-thread-date text-end">{_.capitalize(thread.status)}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="fv_help_scout-thread-body" dangerouslySetInnerHTML={{ __html: thread.body }} />
                                        {
                                            thread?._embedded?.attachments?.map((attachment, idx) => (
                                                <div key={idx} className="d-flex px-3">
                                                    <FaPaperclip className="fv_doc_field_icon" size={20} />
                                                    <div className="fv_help_scout-thread-attachment">
                                                        <div className="d-flex">
                                                            <a
                                                                href={""}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                onClick={(e) => handleLinkClick(e, attachment._links.data.href)}
                                                            >
                                                                {attachment.filename}
                                                            </a>
                                                            <div className="px-2">
                                                                {((attachment.size) / 1024).toFixed(0) + "Kb"}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div> : <div className="d-flex justify-content-between" style={{ marginTop: "-10px", borderTop: "1px solid #e0e0e0" }}>
                                        <div className="m-auto" style={{ color: "#a5b2bd" }}>
                                            {thread?.action?.text}
                                        </div>
                                        <div>
                                            <p className="fv_help_scout-thread-date px-2">{moment(thread.createdAt).format('LLL')}</p>
                                        </div>
                                    </div>}
                                </>
                            ))}

                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default HelpScout;