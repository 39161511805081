import { useEffect } from "react";

export const applicationStatuses = ["Incomplete", "Submitted", "Ask for Info", "Approved", "Rejected"];

export const getClassForStatus = (sts) => {
    switch (sts) {
        case 0:
            return "bg-secondary";  // Typically used for draft status
        case 1:
            return "bg-warning";    // Yellow, indicating submission in progress
        case 2:
            return "bg-info";       // Blue, requesting more information
        case 3:
            return "bg-success";    // Green, indicating approval
        case 4:
            return "bg-danger";     // Red, indicating rejection
        default:
            return "bg-secondary";  // Fallback for any undefined status
    }
};

export const getColorForStatus = (sts) => {
    switch (sts) {
        case 0:
            return "#6C757D";   // Grey for draft
        case 1:
            return "#6C757D";   // Yellow for submitted
        case 2:
            return "#17A2B8";   // Blue for ask more info
        case 3:
            return "#155724";   // Green for approved
        case 4:
            return "#721c24";   // Red for rejected
        default:
            return "#6C757D";   // Default grey
    }
};


export const getBackgroundColorForStatusViaString = (sts) => {
    switch (sts) {
        case "Draft":

            return "#F2F2F2";   // Grey for draft
        case "Submitted":
            return "#F2F2F2";   // Yellow for submitted
        case "Ask For Info":
            return "#F2F2F2";   // Blue for ask more info
        case "Approved":
            return "#d4edda";   // Green for approved
        case "Rejected":
            return "#f8d7da";   // Red for rejected
        default:
            return "#F2F2F2";   // Default grey
    }
};


export const getColorForStatusViaString = (sts) => {
    switch (sts) {
        case "Draft":
            return "#6C757D";   // Grey for draft
        case "Submitted":
            return "#6C757D";   // Yellow for submitted
        case "Ask More Info":
            return "#ccb363";   // Blue for ask more info
        case "Approved":
            return "#155724";   // Green for approved
        case "Rejected":
            return "#721c24";   // Red for rejected
        default:
            return "#6C757D";   // Default grey
    }
};



export const getBackgroundColorForStatus = (sts) => {
    switch (sts) {
        case 0:
            return "#F2F2F2";   // Grey for draft
        case 1:
            return "#F2F2F2";   // Yellow for submitted
        case 2:
            return "#F2F2F2";   // Blue for ask more info
        case 3:
            return "#d4edda";   // Green for approved
        case 4:
            return "#f8d7da";   // Red for rejected
        default:
            return "#F2F2F2";   // Default grey
    }
};


export function useOutsideAlerter(ref, onOutsideClick) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                onOutsideClick();
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, onOutsideClick]);
}

export const generateUniqueId = () => {
    // Simple function to generate a unique-enough identifier for our purposes
    return 'xxxx-xxxx-xxxx-xxxx'.replace(/[x]/g, () => {
        const r = Math.random() * 16 | 0;
        return r.toString(16);
    });
};

export function objectIdToColor(param ) {
    let id = param ?? generateUniqueId()
    let hash = 0;
    for (let i = 0; i < id.length; i++) {
        hash = id.charCodeAt(i) + ((hash << 5) - hash);
    }

    // Convert the hash to a 6 digit hexadecimal color code
    let color = '#';
    for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xFF;
        color += ('00' + value.toString(16)).substr(-2);
    }

    return color;
}
