import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DropdownItem, DropdownMenu } from "reactstrap";
import {
  CheckButton,
  DataGrid,
  DeleteButton,
  DeleteLink,
  EditLink,
  Layout,
  SaveButton
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { UserContext } from "../../store/context/UserContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import EditVerificationSteps from "./EditVerificationSteps";
import { STRUCTURE } from "./VerificationStepsConstant";
import { VerificationStepsGridColumns } from "./VerificationStepsGridColumns";
import VerificationStepsService from "./VerificationStepsService";
import "./style.css";

const VerificationSteps = ({ value, insidePane, multiMode, onSelect = () => { } }) => {
  let { vedit_id } = useParams();
  const location = useLocation();

  let navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const { t } = useContext(I18nContext);
  const { userContent } = useContext(UserContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);

  const [loading, setLoading] = useState(false);
  const [detailData, setDetailData] = useState({});
  const [editID, setEditID] = useState(vedit_id);
  const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/verification-steps/create" || vedit_id);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [filterURL, setFilterURL] = useState("");
  const [filterObject, setFilterObject] = useState({});
  const [hasErr, setHasErr] = useState(false)
  const [showCustomizationOptions, setShowCustomizationOptions] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [approvalRequested, setApprovalRequested] = useState(false)

  const fetchData = async (filterUrl) => {
    setFilterURL(filterUrl);
    if (loading) return;
    setLoading(true);
    try {
      await VerificationStepsService.fetch(filterUrl);
      setLoading(false);

    } catch (e) {
      setLoading(false);
      showError(e);
    }
  };

  useEffect(() => {
    const { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE)
    setFilterURL(filterUrl)
    setFilterObject(filterObject)
    fetchData(filterUrl);
    multiMode ? setSelectedIDs(value) : setSelectedIDs(value ? [value] : [])
  }, [])


  useEffect(() => {
    setEditID(vedit_id)
  }, [vedit_id])

  const onSave = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      if (editID) {
        await VerificationStepsService.edit(detailData, editID);
        setLoading(false);
        showMessage(t("Verification Step updated successfully."));
      } else {
        const verificationStep = await VerificationStepsService.save(detailData);
        setLoading(false);
        showMessage(t("Verification Step saved successfully."));
        if (!insidePane) navigate(`/verification-steps/edit/${verificationStep}`);
      }
      fetchData(filterURL);

    } catch (e) {
      setLoading(false);
      showError(e);
    }
    setHasErr(false)
  };
  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: t("Do you want to delete record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      setLoading(true);
      try {
        await VerificationStepsService.delete(id);
        setLoading(false);
        showMessage("Verification Step Deleted SuccessFully", "Deleted");
        navigate("/verification-steps");
        setShowDetailPage(false);

      } catch (e) {
        setLoading(false);
        showError(e);
      }
    }
  };


  const renderLastCol = useCallback((verificationStep) => {
    return (
      <>
        {userContent?.rights?.includes(1502) &&
          <EditLink
            onClick={() => {
              if (!insidePane)
                navigate(`/verification-steps/edit/${verificationStep?._id}`);
              setEditID(verificationStep?._id);
              setShowDetailPage(true);

            }}
          />
        }
        {userContent?.rights?.includes(1503) ?

          <DeleteLink
            onClick={(event) => onDelete(event, verificationStep?._id)}
          />
          : null}
        {!multiMode && insidePane ? (
          <CheckButton
            onClick={() => {
              onSelect(verificationStep?._id);
            }}
          />
        ) : null}
      </>
    );
  }, [insidePane, multiMode, userContent])


  return (
    <>
      <Layout
      
        showDetailPage={showDetailPage}
        backDetailPage={async () => {
          setShowDetailPage(false);
          if (!insidePane) navigate("/verification-steps");
          setEditID(null);
        }}
        gridLoading={loading}
        title={t("Verification Steps")}
        filterValues={filterObject}
        filterStructure={STRUCTURE}
        onApplyFilter={fetchData}
        onAddClick={() => {
          if (!insidePane) navigate(`/verification-steps/create`);
          setShowDetailPage(true);
          setEditID(null);
        }}
        insidePane={insidePane}
        page={VerificationStepsService.page}
        rows={VerificationStepsService.rows}
        total={VerificationStepsService.total}
        fetch={VerificationStepsService.fetch}
      >


        <Layout.ActionMenu>
          <div className="layout-action-menu">
            <DropdownMenu>
              <>

                <DropdownItem onClick={() => { setShowCustomizationOptions(true) }}>{t("Customise")} </DropdownItem>
              </>

            </DropdownMenu>

          </div>
        </Layout.ActionMenu>
        <Layout.Table>
          <DataGrid
            data={VerificationStepsService.records}
            total={VerificationStepsService.total}
            uiPreference="verification-steps.grid"
            headers={VerificationStepsGridColumns}
            onSelectChange={(v) => {
              onSelect(v)
              setSelectedIDs(v)
            }}
            selectedIDs={selectedIDs}
            page={VerificationStepsService.page}
            rowsPerPage={VerificationStepsService.rowsPerPage}
            onPaginationChange={VerificationStepsService.onPaginationChange}
            renderLastCol={renderLastCol}
          />
        </Layout.Table>


        <Layout.DetailPageTitle>
          {detailData?._id ? t("Edit Verification Step") : t("Add Verification Step")}
        </Layout.DetailPageTitle>

        <Layout.DetailPageBody>
          <EditVerificationSteps
            editId={editID}
            onChange={(v) => {
              setDetailData(v)
            }}
            hasErr={hasErr}
          />
        </Layout.DetailPageBody>

        <Layout.DetailPageFooter>

          {editID && userContent?.rights?.includes(1503) && (detailData?._id) ? <DeleteButton onClick={(e) => onDelete(e, detailData?._id)} loading={loading} /> : null}
          {userContent?.rights?.includes(1502) ? <SaveButton onClick={onSave} loading={loading} /> : null}
   
        </Layout.DetailPageFooter>
      </Layout> 



    </>
  );
};

export default observer(VerificationSteps);


