import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './style.css';
import range from 'lodash/range';
import moment from 'moment';
import { getMonth, getYear } from "date-fns";

const years = range(1990, parseInt(moment().format('YYYY')) + 20, 1);
const months = [
  "January", "February", "March", "April", "May", "June", "July", "August",
  "September", "October", "November", "December"
];

const CustomHeader = ({
  date, changeYear, changeMonth, decreaseMonth, increaseMonth,
  prevMonthButtonDisabled, nextMonthButtonDisabled,
}) => (
  <div className='d-flex align-items-center justify-content-center gap-1 m-2'>
    <button
      style={{ padding: '2px 0' }}
      className='border-0 bg-light px-2 rounded-2'
      onClick={decreaseMonth}
      disabled={prevMonthButtonDisabled}
    >
      {"<"}
    </button>
    <select
      className='border-0 bg-light py-1 px-2 rounded-2'
      value={getYear(date)}
      onChange={({ target: { value } }) => changeYear(value)}
    >
      {years.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>

    <select
      className='border-0 bg-light py-1 px-2 rounded-2'
      value={months[getMonth(date)]}
      onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
    >
      {months.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>

    <button
      style={{ padding: '2px 0' }}
      className='border-0 bg-light px-2 rounded-2'
      onClick={increaseMonth}
      disabled={nextMonthButtonDisabled}
    >
      {">"}
    </button>
  </div>
);

export const yyyymmddToDateFormat = (yyyymmdd) => {
  if (!yyyymmdd || yyyymmdd.length !== 8) return null;
  const year = yyyymmdd.slice(0, 4);
  const month = parseInt(yyyymmdd.slice(4, 6), 10) - 1; // month is 0-indexed in JavaScript Date
  const day = yyyymmdd.slice(6, 8);
  return new Date(year, month, day);
};

export const dateFormatToYYYYMMDD = (date) => {
  if (!date) return '';
  const year = date.getFullYear().toString();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return year + month + day;
};

export default function DateSelectCustom({
  value, onChange, isDisabled, placeholder, label, error, showErr, className, required, minDate
}) {
  const [startDate, setStartDate] = useState(() => yyyymmddToDateFormat(value));
  const [inputError, setInputError] = useState('');

  useEffect(() => {
    setStartDate(yyyymmddToDateFormat(`${value}`));
  }, [value]);

  const handleDateChange = (date) => {
    setStartDate(date);
    const formattedDate = dateFormatToYYYYMMDD(date);
    onChange(formattedDate);
    setInputError(''); // Reset error on user interaction
  };

  const handleBlur = () => {
    if (!startDate) setInputError(error);
  };

  // Get today's date
  const today = new Date();

  return (
    <div className={` ${className}`}>
      {label &&
        <div style={{ minWidth: "120px", fontSize: 16, margin: "10px 0px 5px 5px", fontWeight: "500" }}>
          {label}
          {required && <font color="red">*</font>}
        </div>
      }

      <DatePicker
        selected={startDate}
        onChange={handleDateChange}
        onBlur={handleBlur}
        placeholderText={placeholder}
        dateFormat="dd/MM/yyyy"
        disabled={isDisabled}
        className="form-control my-form-input" // Add your form input class here
        wrapperClassName="date-picker react-datepicker-wrapper"
        renderCustomHeader={CustomHeader}
        minDate={minDate && today} // Prevent selecting past dates
      />
      {showErr && (
        <em className="inputfield__error">
          {(error && showErr) ? inputError : null}
        </em>
      )}
    </div>
  );
}
