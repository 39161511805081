import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { FaExternalLinkAlt, FaFileDownload, FaRedo, FaSpinner } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { doGET, doPOST } from '../../../../util/HttpUtil';
import { InfoPair, InfoPairSkeleton, downloadBlob } from '../ondato/Ondato';
import AppearsOn from './AppearsOn';
import StatusDropDown from './StatusDropDown';
import { DialogContext } from '../../../../store/context/DialogContext';


export const matchStatusOptions = [
  { label: "No match", value: "no_match", backgroundColor: "#52BE80", textColor: "#155724" },
  { label: "False positive", value: "false_positive", backgroundColor: "#FFBF00", textColor: "#856404" },
  { label: "Potential match", value: "potential_match", backgroundColor: "#2980B9", textColor: "#0c5460" },
  { label: "True positive", value: "true_positive", backgroundColor: "#FF7F50", textColor: "#721c24" },
  { label: "Unknown", value: "unknown", backgroundColor: "#AEB6BF", textColor: "#383d41" },
];

export const matchStatusRejectedOptions = [
  { label: "Potential match", value: "potential_match", backgroundColor: "#2980B9", textColor: "#0c5460" },
  { label: "True positive", value: "true_positive", backgroundColor: "#FF7F50", textColor: "#721c24" },];

export const riskLevelOptions = [
  { label: "Low", value: "low", backgroundColor: "#52BE80", textColor: "#155724" },
  { label: "Medium", value: "medium", backgroundColor: "#2980B9", textColor: "#856404" },
  { label: "High", value: "high", backgroundColor: "#FF7F50", textColor: "#721c24" },
  { label: "Unknown", value: "unknown", backgroundColor: "#AEB6BF", textColor: "#383d41" },
];


export function isUrl(string) {
  try {
    new URL(string);
    return true;
  } catch (e) {
    return false;
  }
}

export const Refresh = ({ onClick, formId, }) => {
  const tooltipId = `tooltip-${formId}-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
  return <>
    <FaRedo
      id={tooltipId}
      size={20}
      className='me-2'
      style={{ cursor: 'pointer', outline: "none" }}
      onClick={onClick}
      title="Refresh Data"
    />
    <Tooltip
      style={{ zIndex: '200000' }}
      anchorSelect={`#${tooltipId}`}
    >
      Refresh from Comply
    </Tooltip>
  </>
}

export const DownloadReport = ({ reportId, seq }) => {
  const [loading, setLoading] = useState(false);
  const {showError} = useContext(DialogContext);
  const downloadReport = async () => {
    setLoading(true)
    try {
      const response = await doGET(` /api/comply/advantage/form/cert?id=${reportId}`);
      if (response.status == 200) {
        const blob = response.data;
        const fileName = `${reportId}.pdf`;
        downloadBlob(blob, fileName);
      } else {
        showError("Failed to download Certificate")
        throw new Error('Failed to download Certificate');
      }
    } catch (error) {
      console.error('Error downloading the report:', error);
    } finally {
      setLoading(false)
    }

  };

  if (loading) return <div className="fv_ondato_rule">
    <FaSpinner size={14} className="spinner" />
  </div>

  const tooltipId = `tooltip-${reportId}-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;

  return (
    <div>
      <FaFileDownload
        style={{ cursor: 'pointer' }}
        onClick={downloadReport}
        title="Download Certificate"
        size={20}
        id={tooltipId}
      />
      <Tooltip
        style={{ zIndex: '200000' }}
        anchorSelect={`#${tooltipId}`}
      >
        Download Certificate
      </Tooltip>
    </div>
  );
};

export const ComplianceBadge = ({ status }) => {
  let backgroundColor = '';
  let textColor = '';

  switch (status) {
    case 'no_match':
      backgroundColor = '#d4edda'; // light green
      textColor = '#155724'; // dark green
      break;
    case 'false_positive':
      backgroundColor = '#fff3cd'; // light yellow
      textColor = '#856404'; // dark yellow
      break;
    case 'potential_match':
      backgroundColor = '#d1ecf1'; // light blue
      textColor = '#0c5460'; // dark blue
      break;
    case 'true_positive':
      backgroundColor = '#f8d7da'; // light red
      textColor = '#721c24'; // dark red
      break;
    case 'unknown':
      backgroundColor = '#e2e3e5'; // light gray
      textColor = '#383d41'; // dark gray
      break;
    case 'true_positive_approve':
      backgroundColor = '#bee5eb'; // lighter blue
      textColor = '#004085'; // navy blue
      break;
    case 'true_positive_reject':
      backgroundColor = '#d6d8db'; // darker gray
      textColor = '#1b1e21'; // almost black
      break;
    default:
      backgroundColor = '#e2e3e5'; // default light gray
      textColor = '#383d41'; // default dark gray
      break;
  }

  const text = {
    'no_match': 'No Match',
    'false_positive': 'False Positive',
    'potential_match': 'Potential Match',
    'true_positive': 'True Positive',
    'unknown': 'Unknown',
    'true_positive_approve': 'Approved True Positive',
    'true_positive_reject': 'Rejected True Positive'
  }[status] || 'Status Unknown';

  return (
    <div
      className='ms-2'
      style={{
        display: "inline",
        borderRadius: 5,
        padding: "6px 8px",
        fontSize: "13px",
        backgroundColor: backgroundColor,
        color: textColor,
        textAlign: "center"
      }}>
      {_.startCase(text)}
    </div>
  );
};

export const ComplyAdvantage = ({ formId, refreshId }) => {

  const [complyAdvantageReport, setComplyAdvantageReport] = useState({});
  const [loading, setLoading] = useState(false)
  const [complyObject, setComplyObject] = useState(false)


  const fetchComplyData = async (refresh) => {
    setLoading(true)
    try {
      const complyAdvantageResponse = await doGET(`/api/comply/advantage/form/detail?id=${formId}${refresh ? "&refresh=true" : ""}`)
      if (complyAdvantageResponse?.status == 200) {
        setComplyAdvantageReport(complyAdvantageResponse?.data?.data)
        setComplyObject(complyAdvantageResponse?.data)
      }
    } catch (error) {

    } finally {
      setLoading(false)
    }

  }

  useEffect(() => {
    fetchComplyData(false)
  }, [refreshId])

  if (loading) return <ComplyAdvantageSkeleton />

  const tooltipId = `tooltip-${formId}-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;


  const handleStatusChange = async (payload) => {
    try {
      const updatedStatus = await doPOST(`/api/comply/advantage/status/change`, {
        _id: complyObject?._id,
        risk_level: complyObject?.risk_level,
        match_status: payload,
      });

      fetchComplyData(true)
    } catch (error) {
      console.error('Failed to change status:', error);
    }
  };


  const handleRIskLevelChange = async (payload) => {
    try {
      const updatedStatus = await doPOST(`/api/comply/advantage/status/change`, {
        _id: complyObject?._id,
        match_status: complyObject?.match_status,
        risk_level: payload,
      });
      fetchComplyData(true)

    } catch (error) {
      console.error('Failed to change status:', error);
    }
  };

  return (
    <Container fluid>
      {Object.keys(complyAdvantageReport)?.length ?
        <Row>
          <div className='d-flex justify-content-end align-items-center'>


            <StatusDropDown
              label="Match Status"
              data={matchStatusOptions}
              value={complyObject?.match_status}
              onChange={handleStatusChange}
              className={"me-2 mb-1"}
            />

            <StatusDropDown
              label="Risk Level"
              data={riskLevelOptions}
              value={complyObject?.risk_level}
              onChange={handleRIskLevelChange}
              className={"me-2 mb-1"}
            />

            
            <div className='d-flex align-items-center mb-2'>

              <Refresh
                onClick={() => { fetchComplyData(true) }}
                formId={formId}
              />


              <a
                href={`https://app.eu.complyadvantage.com/app/case-management/search/${complyAdvantageReport?.ref}`}
                target="_blank"
                className='me-2'
                rel="noopener noreferrer"
                data-tip="View KYC Document"
                id={tooltipId}
              >
                <FaExternalLinkAlt size={20} style={{ color: '#007bff' }} />
              </a>
              <Tooltip
                style={{
                  zIndex: '200000',
                }}
                anchorSelect={`#${tooltipId}`}
              >
                View on Comply
              </Tooltip>


              <DownloadReport reportId={formId} />
            </div>

          </div>
          <Col>
            <Row className='p-0'>
              <Col md="12">
                <Card>
                  <CardBody>
                    <h4>Compliance Check Details</h4>
                    <InfoPair label="Match Status" value={complyObject?.match_status ? <ComplianceBadge status={complyObject?.match_status} /> : "—"} />
                    <InfoPair label="Risk Level" value={complyObject?.risk_level ?? "—"} />
                    <InfoPair label="Total Blacklist Hits" value={complyAdvantageReport?.total_blacklist_hits ?? "—"} />
                    <InfoPair label="Total Hits" value={complyAdvantageReport?.total_hits ?? "—"} />
                    <InfoPair label="Total Matches" value={complyAdvantageReport?.total_matches ?? "—"} />
                    <InfoPair label="Submitted Term" value={complyAdvantageReport?.submitted_term ?? "—"} />
                    <InfoPair label="Search Term" value={complyAdvantageReport?.search_term ?? "—"} />
                    <InfoPair label="Creation Date" value={complyAdvantageReport?.created_at ?? "—"} />
                    <InfoPair label="Last Updated" value={complyAdvantageReport?.updated_at ?? "—"} />

                  </CardBody>
                </Card>
              </Col>

              <Col md="12">
                <Card>
                  <CardBody>
                    <h4>Tags</h4>
                    {complyAdvantageReport?.labels?.map((label, index) => (
                      <InfoPair key={index} label={`${label.name}`} value={label.value} />
                    ))}
                  </CardBody>
                </Card>
              </Col>

              <Col md="12">
                <Card>
                  <CardBody>
                    <h4>Filters</h4>
                    <InfoPair label="Entity Type" value={complyAdvantageReport?.filters?.entity_type ?? "—"} />
                    <InfoPair label="Fuzziness" value={complyAdvantageReport?.filters?.fuzziness ?? "—"} />
                    <InfoPair label="Exact Match" value={complyAdvantageReport?.filters?.exact_match ? "Yes" : "No"} />
                  </CardBody>
                </Card>
              </Col>

              {complyAdvantageReport?.hits?.length ?  <Col md="12">
                <Card>
                  <CardBody>
                    <h4>Hits</h4>
                    <Accordion defaultActiveKey="0" alwaysOpen>
                      {complyAdvantageReport?.hits?.map((hit, index) => (
                        <Accordion.Item eventKey={index?.toString()} key={index}>
                          <Accordion.Header>  <div className='d-flex justify-content-between align-items-center w-100 me-2  '> <span>{hit?.doc?.name} <ComplianceBadge status={hit?.match_status} /> </span>  <AppearsOn types={hit?.doc?.types} /></div>  </Accordion.Header>
                          <Accordion.Body>
                            <InfoPair label="Name" value={hit?.doc?.name} />
                            <InfoPair label="Entity Type" value={hit?.doc?.entity_type} />
                            {/* <InfoPair label="Match Status" value={hit?.match_status ? <ComplianceBadge status={hit?.match_status} /> : "—"} /> */}
                            <InfoPair label="Score" value={hit?.score} />
                            <InfoPair label="Whitelisted" value={hit?.is_whitelisted ? "Yes" : "No"} />

                            <Accordion>
                              <Accordion.Item eventKey={`${index}-fields`}>
                                <Accordion.Header>Doc Fields</Accordion.Header>
                                <Accordion.Body>
                                  {hit?.doc?.fields?.map((field, fieldIndex) => (
                                    <div key={fieldIndex}>
                                      <p><strong>{field.name}: </strong>

                                        {isUrl(field.value) ? (
                                          <a href={field.value} target="_blank" rel="noopener noreferrer">
                                            {field.value}
                                          </a>
                                        ) : (
                                          field.value
                                        )}
                                      </p>
                                    </div>
                                  ))}
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>


                            <Accordion>
                              <Accordion.Item eventKey={`${index}-associates`}>
                                <Accordion.Header>Associates</Accordion.Header>
                                <Accordion.Body>
                                  {hit?.doc?.associates?.map((associate, assocIndex) => (
                                    <div key={assocIndex}>
                                      <p><strong>{associate.name} ({associate.association})</strong> </p>
                                    </div>
                                  ))}
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>


                            <Accordion>
                              <Accordion.Item eventKey={`${index}-media`}>
                                <Accordion.Header>Media</Accordion.Header>
                                <Accordion.Body>
                                  {hit?.doc?.media?.map((media, mediaIndex) => (
                                    <div className='mt-2' style={{ borderBottom: "1px solid #dee2e6" }} key={mediaIndex}>
                                      <p><strong>Date: </strong> {media.date}</p>
                                      <p><strong>Title: </strong> {media.title}</p>
                                      <p><strong>Snippet: </strong> {media.snippet}</p>
                                      <p><strong>URL: </strong> <a href={media.url} target="_blank" rel="noopener noreferrer">Read more</a></p>
                                    </div>
                                  ))}
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </Accordion.Body>
                        </Accordion.Item>
                      ))}
                    </Accordion>
                  </CardBody>
                </Card>
              </Col> : null}
            </Row>
          </Col>
        </Row> :
        <div>
          <h4><b>Compliance Check is not performed yet</b></h4>
          <ComplyAdvantageSkeleton />
        </div>}
    </Container>
  )
}






export const ComplyAdvantageSkeleton = () => (
  <Container className='p-0 m-0' fluid>
    <Row className='p-0 m-0'>
      <Col>
        <Row className='p-0'>
          <Col md="12">
            <Card>
              <CardBody>
                <h4>Compliance Check Details</h4>
                <InfoPairSkeleton />
                <InfoPairSkeleton />
                <InfoPairSkeleton />
                <InfoPairSkeleton />
                <InfoPairSkeleton />
                <InfoPairSkeleton />


              </CardBody>
            </Card>
          </Col>

          <Col md="12">
            <Card>
              <CardBody>
                <h4>Tags</h4>
                <InfoPairSkeleton />
                <InfoPairSkeleton />

              </CardBody>
            </Card>
          </Col>

          <Col md="12">
            <Card>
              <CardBody>
                <h4>Filters</h4>
                <InfoPairSkeleton />
                <InfoPairSkeleton />
                <InfoPairSkeleton />
                <InfoPairSkeleton />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  </Container>
)

export default ComplyAdvantage;