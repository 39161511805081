export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl) => {
    let url = `/api/verification-step/grid?`;
    if (sizePerPage) url += `rows=${sizePerPage}&`;
    if (filterUrl) url += `${filterUrl}&`;
    if (page) url += `page=${page}`
    return url;
  },
  get: (id) => `/api/verification-step/detail?id=${id}`,
  save: `/api/verification-step/create`,
  delete: (id) => `/api/verification-step/delete?id=${id}`,
  bulkDelete: (id) => `/api/verification-step/delete`,
  update: `/api/verification-step/update`,
};

export const STRUCTURE = [
  {
    label: "Name",
    filterLabel: "Name",
    filterName: "name",
    type: "text",
  },
  {
    label: "Business Name",
    filterLabel: "Business Name",
    filterName: "businessName",
    type: "text",
  },
  {
    label: "Country",
    filterLabel: "Country",
    filterName: "countryOfIncorporate",
    type: "text",
  },
 
];
