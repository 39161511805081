import React, { useContext, useState } from 'react';
import { InputField, PhoneNum } from '../../components';
import PrimaryButton from '../../components/Buttons/PrimaryButton';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import PagesNoRights from '../Utility/pages-no-rights';
import { UserContext } from '../../store/context/UserContext';

const SearchBar = ({ placeholder, value, onChange, label, required, isDisabled }) => (
    <div className='d-flex flex-column mb-3'>
        <InputField
            className="flex-1"
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            type="text"
            label={label}
            required={!!required}
            isDisabled={!!isDisabled}
        />
    </div>
);

const AccountSearch = () => {
    const [data, setData] = useState({
        email: "",
        username: "",
        phone: "",
        accountNumber: "",
        countryCode: "",
    });
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { userContent } = useContext(UserContext);

    const handleChange = (key, value) => {
        setData(prevData => ({ ...prevData, [key]: value }));
    };

    const handleSubmit = () => {
        setLoading(true);
        const queryParams = new URLSearchParams(
            Object.entries(data).filter(([key, value]) => value)
        ).toString();
        navigate(`/accounts/search?${queryParams}`);
        setLoading(false);
    };

    if (!userContent?.rights?.includes(2700)) {
        return <div key={userContent?.rights} > <PagesNoRights /></div> 
    }

    return (
        <div key={userContent?.rights} className="p-2">
            <SearchBar
                placeholder="Search by Email"
                value={data.email}
                onChange={(value) => handleChange("email", value)}
                label="Email"
            />
            <SearchBar
                placeholder="Search by Username"
                value={data.username}
                onChange={(value) => handleChange("username", value)}
                label="Username"
            />
            {/* <SearchBar
                placeholder="Search by Phone"
                value={data.phone}
                onChange={(value) => handleChange("phone", value)}
                label="Phone"
            /> */}
            <PhoneNum
                placeholder={"Enter PhoneNum "}
                value={{
                    countryCode: data?.countryCode,
                    phone: data?.phone,
                }}
                label={"Phone"}
                error={data?.phoneNo && data?.phoneNo.length === 0 ? "Please enter Phone Number" : ""}
                className="flex-1"
                style={{ marginBottom: 25 }}
                onChange={(v) => {
                    setData({
                        ...data,
                        countryCode: v?.countryCode,
                        phone: v?.phone,
                    })
                }}
                type="text"
            />
            <SearchBar
                placeholder="Search by Account Number"
                value={data.accountNumber}
                onChange={(value) => handleChange("accountNumber", value)}
                label="Account Number"
            />
            <div className="d-flex justify-content-center">
                <PrimaryButton
                    className="px-3 mb-2"
            
                    onClick={handleSubmit}
                    loading={loading}
                >
                    {"Search"}
                </PrimaryButton>
            </div>
        </div>
    );
};

export default observer(AccountSearch);
