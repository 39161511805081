export const ques1 = [
    { key: 0, label: 'Ultimate Benificial Owner', value: 'Ultimate Benificial Owner' },
    { key: 1, label: 'Authorized Representative', value: 'Authorized Representative' },
    { key: 2, label: 'Employee/Executive', value: 'Employee/Executive' },
];

export const ques2 = [
    { key: 0, label: 'Crypto Exchange, OTC, Liquidity Provider, Remittance or FX Business, Money Services Business', value: 'Crypto Exchange, OTC, Liquidity Provider, Remittance or FX Business, Money Services Business' },
    { key: 1, label: 'Money Servcies Business - No crypto involvement', value: 'Money Servcies Business - No crypto involvement' },
    { key: 2, label: 'Fund or Family Office', value: 'Fund or Family Office' },
    { key: 3, label: 'Corporate Account Requiring Higher limiits (No client funds)', value: 'Corporate Account Requiring Higher limiits (No client funds)' },
    { key: 4, label: 'Other', value: 'Other' },
];

export const ques3 = [
    { key: 0, label: 'Our own funds', value: 'Our own fundsBusiness' },
    { key: 1, label: 'Client funds', value: 'Client funds' },
    { key: 2, label: 'Both', value: 'Both' },
];

export const ques4 = [
    { key: 1, label: 'Under $1,000,000', value: 'Under $1,000,000' },
    { key: 2, label: '$1,000,000 - $5,000,000', value: '$1,000,000 - $5,000,000' },
    { key: 3, label: '$5,000,000 - $10,000,000', value: '$5,000,000 - $10,000,000' },
    { key: 4, label: 'More than $10,000,000', value: 'More than $10,000,000' },
];

export const ques5 = [
    { key: 0, label: 'Ultimate Benificial Owners', value: 'Ultimate Benificial Owners' },
    { key: 1, label: 'Senior Managers (CEO, CFO, COO)', value: 'Senior Managers (CEO, CFO, COO)' },
    { key: 2, label: 'Directors', value: 'Directors' },
    { key: 3, label: 'Corporate Name or jurisdiction', value: 'Corporate Name or jurisdiction' },
    { key: 4, label: 'Business Model/​Purpose', value: 'Business Model/​Purpose' },
];

export const ques6 = [
    { key: 0, label: 'Bank transfers', value: 'Bank transfers' },
    { key: 1, label: 'Credit Cards', value: 'Credit Cards' },
    { key: 2, label: 'Cryptocurrency', value: 'Cryptocurrency' },
    { key: 3, label: 'Cash', value: 'Cash' },
    { key: 4, label: 'Checks', value: 'Checks' },
];

export const ques7 = [
    { key: 0, label: 'Receiving funds against invoices from customers (does not include purchase of crypto)', value: 'Receiving funds against invoices from customers (does not include purchase of crypto)' },
    { key: 1, label: 'Receiving funds from customers to purchase crypto', value: 'Receiving funds from customers to purchase crypto' },
    { key: 2, label: 'Receiving funds from Investors (equity, debt, token)', value: 'Receiving funds from Investors (equity, debt, token)' },
    { key: 3, label: 'Receiving funds from customers in an omnibus account (does not include purchase of crypto /​ token)', value: 'Receiving funds from customers in an omnibus account (does not include purchase of crypto /​ token)' },
    { key: 4, label: 'Receiving own funds from other accounts', value: 'Receiving own funds from other accounts' },
    { key: 5, label: 'Receiving funds from the liquidation of your own cryptocurrency', value: 'Receiving funds from the liquidation of your own cryptocurrency' },
];

export const ques8 = [
    { key: 0, label: 'Own operational expenses', value: 'Own operational expenses' },
    { key: 1, label: 'Operational expenses (third party)', value: 'Operational expenses (third party)' },
    { key: 3, label: 'Salaries or Commissions', value: 'Salaries or Commissions' },
    { key: 4, label: 'Own investments (equity, debt, token)', value: 'Own investments (equity, debt, token)' },
    { key: 5, label: 'Customer purchases (tokens)', value: 'Customer purchases (tokens)' },
];

export const countries1 = [
    { key: 0, label: 'USA', value: 'USA' },
    { key: 1, label: 'Canada', value: 'Canada' },
    { key: 2, label: 'Europe', value: 'Europe' },
    { key: 3, label: 'Asia (Excluding China)', value: 'Asia (Excluding China)' },
    { key: 4, label: 'China', value: 'China' },
    { key: 5, label: 'South America', value: 'South America' },
    { key: 6, label: 'Central America', value: 'Central America' },
    { key: 7, label: 'Caribbean', value: 'Caribbean' },
];

export const northAmerica = [
    { key: 0, label: 'Belize', value: 'Belize' },
    { key: 1, label: 'Costa Rica', value: 'Costa Rica' },
    { key: 2, label: 'El Salvador', value: 'El Salvador' },
    { key: 3, label: 'Guatemala', value: 'Guatemala' },
    { key: 4, label: 'Honduras', value: 'Honduras' },
    { key: 5, label: 'Mexico', value: 'Mexico' },
    { key: 6, label: 'Nicaragua', value: 'Nicaragua' },
    { key: 7, label: 'Panama', value: 'Panama' },
];

export const carribean = [
    { key: 0, label: 'Antigua and Barbuda', value: 'Antigua and Barbuda' },
    { key: 1, label: 'Bahamas, The', value: 'Bahamas, The' },
    { key: 2, label: 'Barbados', value: 'Barbados' },
    { key: 3, label: 'British Virgin Islands', value: 'British Virgin Islands' },
    { key: 4, label: 'Cayman Islands', value: 'Cayman Islands' },
    { key: 5, label: 'Cuba', value: 'Cuba' },
    { key: 6, label: 'Dominica', value: 'Dominica' },
    { key: 7, label: 'Dominican Republic', value: 'Dominican Republic' },
    { key: 8, label: 'Puerto Rico', value: 'Puerto Rico' },
    { key: 9, label: 'Saint Kitts and Nevis', value: 'Saint Kitts and Nevis' },
    { key: 10, label: 'Saint Vincent and the Grenadines', value: 'Saint Vincent and the Grenadines' },
    { key: 11, label: 'Trinidad and Tobago', value: 'Trinidad and Tobago' },
    { key: 12, label: 'Other', value: 'Other' },
];

export const southAmerica = [
    { key: 0, label: 'Argentina', value: 'Argentina' },
    { key: 1, label: 'Bolivia', value: 'Bolivia' },
    { key: 2, label: 'Brazil', value: 'Brazil' },
    { key: 3, label: 'Chile', value: 'Chile' },
    { key: 4, label: 'Colombia', value: 'Colombia' },
    { key: 5, label: 'Ecuador', value: 'Ecuador' },
    { key: 6, label: 'French Guiana', value: 'French Guiana' },
    { key: 7, label: 'Guyana', value: 'Guyana' },
    { key: 8, label: 'Paraguay', value: 'Paraguay' },
    { key: 9, label: 'Peru', value: 'Peru' },
    { key: 10, label: 'Suriname', value: 'Suriname' },
    { key: 11, label: 'Uruguay', value: 'Uruguay' },
    { key: 12, label: 'Venezuela', value: 'Venezuela' },
];

export const ques9 = [
    { key: 0, label: 'Exchange', value: 'Exchange' },
    { key: 1, label: 'OTC', value: 'OTC' },
    { key: 2, label: 'Custody', value: 'Custody' },
    { key: 3, label: 'Defi', value: 'Defi' },
    { key: 4, label: 'Broker', value: 'Broker' },
    { key: 5, label: 'Hosted Wallet', value: 'Hosted Wallet' },
    { key: 7, label: 'Other', value: 'Other' },
];

export const ques10 = [
    { key: 0, label: 'BTC', value: 'BTC' },
    { key: 1, label: 'ETH', value: 'ETH' },
    { key: 2, label: 'USDT', value: 'USDT' },
    { key: 3, label: 'USDC', value: 'USDC' },
    { key: 4, label: 'DOGE', value: 'DOGE' },
    { key: 5, label: 'MATIC', value: 'MATIC' },
    { key: 7, label: 'BNB', value: 'BNB' },
    { key: 8, label: 'Other', value: 'Other' },
];

export const ques11 = [
    { key: 0, label: 'MSB', value: 'MSB' },
    { key: 1, label: 'DLT', value: 'DLT' },
    { key: 2, label: 'Bank', value: 'Bank' },
    { key: 3, label: 'MTL', value: 'MTL' },
    { key: 4, label: 'EMI', value: 'EMI' },
    { key: 5, label: 'Other', value: 'Other' },
    { key: 7, label: 'None', value: 'None' },
];

export const ques12 = [
    { key: 0, label: 'Chainalysis', value: 'Chainalysis' },
    { key: 1, label: 'TRM Labs', value: 'TRM Labs' },
    { key: 2, label: 'Elliptic', value: 'Elliptic' },
    { key: 3, label: 'Coinfirm', value: 'Coinfirm' },
    { key: 4, label: 'Other', value: 'Other' },
];

export const ques13 = [
    { key: 0, label: 'Equity', value: 'Equity' },
    { key: 1, label: 'Tokens/​Digital Assets', value: 'Tokens/​Digital Assets' },
    { key: 2, label: 'Debt Instruments', value: 'Debt Instruments' },
    { key: 3, label: 'Other', value: 'Other' },
];

export const ques14 = [
    { key: 0, label: 'Payroll', value: 'Payroll' },
    { key: 1, label: 'Operating Expenses', value: 'Operating Expenses' },
    { key: 2, label: 'Commissions', value: 'Commissions' },
    { key: 3, label: 'Legal and Licensing Fees', value: 'Legal and Licensing Fees' },
    { key: 4, label: 'Trade Settlements', value: 'Trade Settlements' },
    { key: 5, label: 'Other', value: 'Other' },
];

export const countries2 = [
    { key: 0, label: 'Abkhazia', value: 'Abkhazia' },
    { key: 1, label: 'Afganistan', value: 'Afganistan' },
    { key: 2, label: 'Angola', value: 'Angola' },
    { key: 3, label: 'Belarus', value: 'Belarus' },
    { key: 4, label: 'Burma', value: 'Burma' },
    { key: 5, label: 'Burundi', value: 'Burundi' },
    { key: 6, label: 'Central African Republic', value: 'Central African Republic' },
    { key: 7, label: 'Congo', value: 'Congo' },
    { key: 8, label: 'Cuba', value: 'Cuba' },
    { key: 9, label: 'Cyprus', value: 'Cyprus' },
    { key: 10, label: 'Georgia', value: 'Georgia' },
    { key: 11, label: 'Guinea-Bissau', value: 'Guinea-Bissau' },
    { key: 12, label: 'Iran', value: 'Iran' },
    { key: 13, label: 'Iraq', value: 'Iraq' },
    { key: 14, label: 'Ivory Coast', value: 'Ivory Coast' },
    { key: 15, label: 'Lebanon', value: 'Lebanon' },
    { key: 16, label: 'Liberia', value: 'Liberia' },
    { key: 17, label: 'Lybia', value: 'Lybia' },
    { key: 18, label: 'Mali', value: 'Mali' },
    { key: 19, label: 'Nagorno-Karabakh', value: 'Nagorno-Karabakh' },
    { key: 20, label: 'Nicaragua', value: 'Nicaragua' },
    { key: 21, label: 'Northern Cyprus', value: 'Northern Cyprus' },
    { key: 22, label: 'North Korea', value: 'North Korea' },
    { key: 23, label: 'Russia', value: 'Russia' },
    { key: 24, label: 'Sahrawi Arab Democratic Republic', value: 'Sahrawi Arab Democratic Republic' },
    { key: 25, label: 'Somalia', value: 'Somalia' },
    { key: 26, label: 'Somaliland', value: 'Somaliland' },
    { key: 27, label: 'South Ossetia', value: 'South Ossetia' },
    { key: 28, label: 'Sudan', value: 'Sudan' },
    { key: 29, label: 'Syria', value: 'Syria' },
    { key: 30, label: 'Ukraine', value: 'Ukraine' },
    { key: 31, label: 'Venezuela', value: 'Venezuela' },
    { key: 32, label: 'Yemen', value: 'Yemen' },
    { key: 33, label: 'Zimbabwe', value: 'Zimbabwe' },
    { key: 34, label: 'None of the above', value: 'None of the above' },
];

export const REQUIRED_FIELDS = {
    1: [],
}