import { ThreeBounce } from "better-react-spinkit";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Input, Row } from "reactstrap";
import {
  InputField
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import DocCategoryComponent from "../DocCategory/DocCategoryComponent";
import { InputLabel } from "../Form/forms/components/IndividualLite/UserDetailsForm";
import DocumentTypeService from "./DocumentTypeService";

const EditDocumentType = ({ editId, onChange = () => { }, hasErr }) => {

  const { showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});


  const fetch = async () => {
    try {
      let form = await DocumentTypeService.get(editId);
      setData({ ...form });
    } catch (error) {
      showError(error)
    }
  };

  useEffect(() => {
    setData({ modifiable: true })
    setLoading(true);
    if (editId) fetch();
    setLoading(false)
  }, [editId, DocumentTypeService?.version]);

  useEffect(() => {
    onChange(data)
  }, [data]);




  return (
    <React.Fragment>
      {loading ?
        <ThreeBounce /> :
        <div style={{ width: "calc(100% - 5px)" }} className="row px-3 ">


          <Row>
            <InputField
              value={data?.name}
              required
              label={"Document Type"}
              onChange={(v) => {
                setData({
                  ...data,
                  name: v,
                });
              }}
              className="col-12"
              type="text"
            />
          </Row>


          <Row className="d-flex align-items-center">


            <div className="d-flex align-items-center col-12 col-sm-4">
              <Input
                type="checkbox"
                checked={data.isParchaRequired}
                onChange={(e) => {
                  setData({
                    ...data,
                    isParchaRequired: e.target.checked
                  });
                }}
                label="AI Assistance Required?"
              />
              <label className="ms-2 mt-2">AI Assistance Required?</label>
            </div>

            <div className="d-flex align-items-center col-12 col-sm-4">
              <Input
                type="checkbox"
                checked={data.forBusiness}
                onChange={(e) => {
                  setData({
                    ...data,
                    forBusiness: e.target.checked
                  });
                }}
                label="For Business?"
              />
              <label className="ms-2 mt-2">For Business?</label>
            </div>


            {/* <div className="d-flex align-items-center col-12 col-sm-4">
              <Input
                type="checkbox"
                checked={!data.modifiable}
                onChange={(e) => {
                  setData({
                    ...data,
                    modifiable: !e.target.checked
                  });
                }}
                label="For System"
              />
              <label className="ms-2 mt-2">For System?</label>
            </div>

            {!data?.modifiable ?  <InputField
              value={data?.key}
              label={"Document Key"}
              onChange={(v) => {
                setData({
                  ...data,
                  key: v,
                });
              }}
              className="col-12 col-sm-4"
              type="text"
            /> : null } */}

          </Row>

          <div className="mt-2" />
          {data?._id ? <div>
            <InputLabel label={"Document Categories"} />
            <DocCategoryComponent
              module={data?.key ?? data?._id}
            />
          </div> : null}
        

        </div>
      }
    </React.Fragment >
  );
};

export default observer(EditDocumentType);
