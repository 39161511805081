import _ from 'lodash';
import moment from 'moment';
import AssignSupervisorFromGrid from './components/AssignSupervisorFromGrid/AssignSupervisorFromGrid';
import StatusProgressSquares from './components/StatusProgressSquares';
import { StatusDisplayInvert, applicationStatuses } from './thirdParty/applicationDocument/ApplicationDocument';

export const GridColumns = {
  version: 1,
  columns: [
    {
      title: "Application No.",
      accessor: "applicationNo",
      renderer: (dataObj) => {
        return <div>
          {dataObj?.applicationNo ?? ""}
        </div>
      },
      width : 120,
      show: true,
    },
    
    {
      title: "Name",
      accessor : "name",
      renderer: (dataObj) => {
        return <div>
          {dataObj?.customerName ?? ""} 
        </div>
      },
      show: true,
    },
    {
      title: "Email",
      accessor: "email",
      renderer: (dataObj) => {
        return <div>
          {dataObj?.email ?? ""}
        </div>
      },
      show: true,
      width: 200,

    },

    {
      title: "Application Type",
      accessor: "applicationType",
      renderer: (dataObj) => {
        return <div>
          {_.startCase(_.toLower(dataObj?.formType ?? ""))}
        </div>
      },
      show: true,
      width: 120,
    },
    {
      title: "Created At",
      accessor: "createdAt",
      renderer: (dataObj) => {
        return <div>
          {moment(dataObj?.createdAt * 1000).format('DD-MM-YYYY, hh:mm a')}
        </div>
      },
      show: true,
    },
    {
      title: "Introducer",
      accessor: "introducer",
      renderer: (dataObj) => {
        return <div>
          {dataObj?.introducer ?? ""}
        </div>
      },
      show: true,
      width: 100,
    },
    {
      title: "Assigned To",
      accessor: "assignedTo",
      show: true,
      renderer: (dataObj) => {
        return <AssignSupervisorFromGrid dataObj={dataObj}/>
      }
    },
    {
      title: "Status",
      accessor: "status",
      renderer: (dataObj) => {
    
        return <div>
          <StatusDisplayInvert style={{ padding: "0px 6px", minWidth : "120px"}} hideIcons value={applicationStatuses?.find(v => dataObj?.status == v.value)} />
        </div>
      },
      show: true,
      width: 140,

    },

    {
      title: "Steps",
      accessor: "steps",
      renderer: (dataObj) => {
        const steps = dataObj.steps ?? [];
        return <div>
          <StatusProgressSquares
            data={steps}
            isAccountCreated = {dataObj?.status == 6}
            isQueued= {dataObj?.status == 5}
          />
        </div>
      },
      show: true,
      width: 140,

    },
    {
      title: "Action",
      accessor: "action",
      fixed: "right",
      width: 80,
      show: true,
      required: true,
    },
  ],
};
