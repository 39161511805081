import { ThreeBounce } from "better-react-spinkit";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Form, Row } from "reactstrap";
import { InputField, NameField, PhoneNum, RoleSelect } from "../../components";
// import FileUpload from "../../components/DocUpload/FileUpload";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import SupervisorService from "./SupervisorService";
import { isValidEmail } from "./Supervisor";


const EditSupervisors = ({ editId, onChange = () => { }, hasErr, corp_id, hideForApiRole }) => {
    const { t } = useContext(I18nContext);
    const { showError } = useContext(DialogContext);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        salutation: "Mr"
    });
    const fetch = async () => {
        setLoading(true);
        try {
            let supervisors = await SupervisorService.get({ editId, corp_id });
            setData({
                ...supervisors,
                phoneNo: {
                    ...supervisors.phoneNo,
                    countryCode: supervisors?.phoneNo?.countryCode ? supervisors?.phoneNo?.countryCode : 91,
                }
            });
            setLoading(false);
        } catch (error) {
            showError(error)
            setLoading(false);
        }
    };

    useEffect(() => {
        setData({
            phoneNo: {
                countryCode: 91,
                phone: ''
            },
            salutation: "Mr"
        })
        if (editId) fetch();
    }, [editId, SupervisorService?.version]);

    useEffect(() => {
        onChange(data)
    }, [data]);


    return (
        <React.Fragment>
            {loading ?
                <div className="d-flex mt-5 justify-content-center align-items-center  flex-1">
                    <ThreeBounce size={50} color="pink" />
                </div>
                :
                <Form className="px-5" style={{ overflowY: "auto", height: "100%", overflowX: "visible" }}>
                    <Row className="mt-3 mb-3  align-items-center ">
                        <div className="col-12  col-md-6 "
                        >
                     
                            <NameField
                                onNameChange={(v) => setData({ ...data, name: v })}
                                onSalutationChange={(v) => setData({ ...data, salutation: v })}
                                name={data?.name}
                                salutation={data?.salutation}
                                label={t("Name")}
                                required={true}
                                placeholder={t("Name")}
                                error={!data?.name ? ("Please enter a valid name") : ""}
                                showErr={hasErr}
                            />
                        </div>
                        {/* {editId &&
                            <div className="d-flex align-items-center justify-content-between col-sm-6">
                                <div style={{ minWidth: "200px ", fontSize: 16, margin: "0px 0px 5px 5px", fontWeight: "500", }}>{t("Profile Picture")}</div>
                                <FileUpload
                                    type="image"
                                    className="d-flex justify-content-center col-sm-12 col-md-6 mt-4"
                                    value={data?.pic}
                                    id={data?._id}
                                    module={"supervisor"}
                                    field={"pic"}
                                    onUpload={() => {
                                        fetch()
                                    }}
                                    onDelete={() => {
                                        fetch()
                                    }}
                                />
                            </div>
                        } */}

                       
                        {data?.supervisorExtn?.isAPI ?
                            <>
                                <InputField
                                    placeholder={t("API key")}
                                    value={data?.supervisorExtn?.key}
                                    label={"API Key"}
                                    onChange={(v) => setData({ ...data, supervisorExtn: { ...data?.supervisorExtn, key: v } })}
                                    error={data?.supervisorExtn?.key?.length === 0 ? t("Please enter Key") : ""}
                                    showErr={hasErr}
                                    type="text"
                                    className="col-sm-12 col-md-6"
                                />
                                <InputField
                                    placeholder={t("API secret")}
                                    value={data?.supervisorExtn?.secret}
                                    label={"API secret"}
                                    onChange={(v) => setData({ ...data, supervisorExtn: { ...data?.supervisorExtn, secret: v } })}
                                    error={data?.supervisorExtn?.secret?.length === 0 ? t("Please enter secret") : ""}
                                    showErr={hasErr}
                                    type="text"
                                    className="col-sm-12 col-md-6"
                                />
                            </> :
                            <>
                        
                                <InputField
                                    placeholder={t("Email")}
                                    value={data?.email}
                                    required={true}
                                    label={t("Email")}
                                    onChange={(v) => setData({ ...data, email: v })}
                                    error={data?.email?.length === 0 ? t("Please enter email")
                                        : !isValidEmail(data?.email)
                                            ? t("Please enter a valid email")
                                            : ""}
                                    showErr={hasErr}
                                    type="email"
                                    className="col-sm-12 col-md-6"
                                />
                                <PhoneNum
                                    placeholder={t("Enter PhoneNum ")}
                                    value={{
                                        countryCode: data?.phoneNo?.countryCode,
                                        phone: data?.phoneNo?.phone,
                                    }}
                                    label={t("Phone")}
                                    error={data?.phoneNo && data?.phoneNo.length === 0 ? t("Please enter Phone Number") : ""}
                                    showErr={hasErr}
                                    className="col-sm-6"
                                    onChange={(v) => {
                                        setData({
                                            ...data,
                                            phoneNo: {
                                                ...data?.phoneNo,
                                                countryCode: v?.countryCode,
                                                phone: v?.phone,
                                            }
                                        })
                                    }}
                                    type="text"
                                />

                                <RoleSelect
                                    placeholder={t("Select Roles ")}
                                    value={data?.supervisorExtn?.roles}
                                    label={t("Roles")}
                                    multi={true}
                                    onChange={(v) => {
                                        setData({
                                            ...data,
                                            supervisorExtn: {
                                                ...data?.supervisorExtn,
                                                roles: v
                                            }
                                        });
                                    }}
                                    type="text"
                                    required
                                    className="col-sm-12 col-md-6 "
                                    error={!data?.roles?.length ? ("Please select roles") : ""}
                                    showErr={hasErr}
                                />



                                <InputField
                                    placeholder={t("Enter Department")}
                                    value={data?.supervisorExtn?.department}
                                    label={t("Department")}
                                    onChange={(v) => setData({ ...data, supervisorExtn: { ...data?.supervisorExtn, department: v } })}
                                    error={data?.supervisorExtn?.department?.length === 0 ? t("Please enter department") : ""}
                                    showErr={hasErr}
                                    type="text"
                                    className="col-sm-12 col-md-6"
                                />
                                <InputField
                                    placeholder={t("Enter Designation")}
                                    value={data?.supervisorExtn?.designation}
                                    label={t("Designation")}
                                    onChange={(v) => setData({ ...data, supervisorExtn: { ...data?.supervisorExtn, designation: v } })}
                                    error={data?.supervisorExtn?.designation?.length === 0 ? t("Please enter designation") : ""}
                                    showErr={hasErr}
                                    type="text"
                                    className="col-sm-12 col-md-6"
                                />
                             
                            </>
                        }
                    </Row>

                </Form>
            }

        </React.Fragment>
    );
};
export default observer(EditSupervisors);
