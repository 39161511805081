import React from 'react';
import { FaCamera, FaRegCheckCircle, FaRegIdCard, FaShieldAlt } from 'react-icons/fa';
import GoBackButton from '../../../../../components/Buttons/GoBackButton';
import NextButton from '../../../../../components/Buttons/NextButton';

const PreKYCInfo = ({ isAskForInfo, loading, asLabel, onNext = () => { }, onBack = () => { } }) => {
    return (
        <div className={'user-note d-flex flex-column justify-content-center align-items-center'}>


            <div className="user-note-content">
                <div className="user-note-item">
                    <FaRegIdCard className="icon" />
                    <span>You will need to provide a valid ID document for verification.</span>
                </div>
                <div className="user-note-item">
                    <FaCamera className="icon" />
                    <span>Be ready to take a live selfie to confirm your identity.</span>
                </div>
                <div className="user-note-item">
                    <FaRegCheckCircle className="icon" />
                    <span>Make sure to follow the instructions for a clear and accurate photo capture.</span>
                </div>
                <div className="user-note-item">
                    <FaShieldAlt className="icon" />
                    <span>Your data is protected with us. We adhere to the highest security and compliance standards.</span>
                </div>
            </div>



            {!asLabel && <div className='d-flex w-100 justify-content-end'>
                {/* {isAskForInfo ?  <div className='flex-1'></div> : <div className='flex-1'>
                    <GoBackButton
                        loading={loading}
                        onClick={() => {
                            onBack()
                        }}
                    />
                </div> } */}

                <NextButton
                    loading={loading}
                    onClick={() => {
                        onNext()
                    }}
                />

            </div>}




        </div>
    )
}

export default PreKYCInfo