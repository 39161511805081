import React, { useContext, useEffect, useState } from 'react';
import { doGET, doPUT } from '../../../../util/HttpUtil'; // Adjust path as needed
import './style.scss'; // This is where you will write your CSS styles
import { Button } from 'reactstrap';
import { DialogContext } from '../../../../store/context/DialogContext';
import { UserContext } from '../../../../store/context/UserContext';


const EXTREME_RISK_LABEL = { name: "Extreme Risky", color: "#721c24", min: Number.MIN_SAFE_INTEGER, max: Number.MAX_SAFE_INTEGER };

const getRiskLabel = (value, riskLabels) => {
  if (isNaN(value) || value <= 0) {
    return null;
  }

  for (const label of riskLabels) {
    if (value >= (label.min / 1000) && value < (label.max / 1000)) {
      return label;
    }
  }

  return EXTREME_RISK_LABEL;
};


const formatValue = (value, divisor) => {
  if (isNaN(value) || value === undefined || value === null) {
    return '-';
  }
  return `${(value / divisor).toFixed(2)}`;
};

const RiskAssessment = ({ reload, formId, formType, refreshId }) => {
  const [riskData, setRiskData] = useState(null);
  const [riskCalculation, setRiskCalculation] = useState(null);
  const [loading, setLoading] = useState(false);
  const { showConfirmWithTextArea, showConfirmWithTextAreaAndCheckBox,  showError, showMessage } = useContext(DialogContext);
  const { userContent, } = useContext(UserContext);

  useEffect(() => {
    const fetchRiskData = async () => {
      setLoading(true);
      try {
        const response = await doGET(`/api/application-risk/detail?id=${formId}`);
        if (response.status === 200) {
          setRiskData(response.data);

          const riskCalculationResponse = await doGET(`/api/risk-calculation/grid?formType=${formType}`);
          setRiskCalculation(riskCalculationResponse?.data?.rows?.[0] ?? {})
        } else {
          throw new Error('Failed to fetch risk data');
        }
      } catch (error) {
        console.error('Error fetching risk data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchRiskData();
  }, [formId, refreshId]);

  if (loading) {
    return <RiskAssessmentSkeleton />;
  }

  if (!riskData) {
    return (
      <div className="fv_risk_assessment_error">
        <h4><b>Risk Assessment is not performed yet</b></h4>
      </div>
    );
  }

  const formattedScore = formatValue(riskData.finalScore, 1000000);
  const riskLabel = getRiskLabel(formattedScore, riskCalculation.riskLabels ?? []);

  const riskLevelText = riskLabel ? riskLabel.name : '-';
  const riskLevelColor = riskLabel ? riskLabel.color : '#ffffff';



  const handleRejectForUbo = async (event) => {
    event.stopPropagation();
    const result = await showConfirmWithTextAreaAndCheckBox({
      title: `Do you want to manually reject Risk Assessment ?`,
      description: `Upon rejecting this, it will reject entire application.`,
      remarkRequired: true,
      checkboxRequired: true,
      checkboxLabel: "Notify Applicant" 
    })
    if (result === true || (result && result.confirmed)) {
      setLoading(true);
      try {
        await doPUT(`/api/application-risk/update${result?.isChecked ? "?notifyApplicant=true" : ""}`, {
          ...riskData,
          status: 4,
          remark: result?.remark ?? ""
        });
        showMessage(`Risk Assessment Rejected`, "Success");
        reload()
      } catch (e) {
        setLoading(false);
        showError(e);
      }
    }
  };
  const handleApproveForUbo = async (event) => {
    event.stopPropagation();
    const result = await showConfirmWithTextArea({
      title: `Do you want to manually approve Risk Assessment ?`,
      description: `Please enter reason`,
      remarkRequired: true,
    })
    if (result === true || (result && result.confirmed)) {
      setLoading(true);
      try {
        await doPUT('/api/application-risk/update', {
          ...riskData,
          status: 3,
          remark: result?.remark ?? ""
        });
        showMessage(`Risk Assessment Approved `, "Success");
        reload()
      } catch (e) {
        setLoading(false);
        showError(e);
      }
    }
  };


  const hasUnrejectRight = (status) => {
    if (status == 4) { // rejected
      return userContent?.rights?.includes(2804);
    }
    return true; // if not rejected then can approve or askformore
  };

  return (
    <React.Fragment key={userContent?.rights}>

      <div className="fv_risk_assessment_card">
        {(riskData?.status !=5  && userContent?.rights?.includes(2802)) ? <div className='d-flex justify-content-end '>
          {riskData?.status != 4 && <Button className='py-1 mb-1 me-2 ' style={{ width: "max-content" }} color="danger" onClick={(event) => handleRejectForUbo(event)}>
            Reject
          </Button>
          }
          {riskData?.status != 3 &&
            hasUnrejectRight(riskData?.status)
            && <Button className='py-1 mb-1 me-2 ' style={{ width: "max-content" }} color="success" onClick={(event) => handleApproveForUbo(event)}>
              Approve
            </Button>}
        </div> : <></>}
        <h3 className='d-flex align-items-center'>Risk Assessment Report
          <span className='mx-2 px-2 py-1' style={{ borderRadius: "5px", color: riskLevelColor, backgroundColor: `${riskLevelColor}44`, width: "max-content", }}>
            {riskLevelText}
          </span></h3>
        <table className="fv_risk_assessment_details">
          <thead>
            <tr>
              <th>Indicator</th>
              <th>Value</th>
              <th>Weightage</th>
              <th>Score</th>
              {/* <th>Weightage * Score</th> */}
            </tr>
          </thead>
          <tbody>
            {riskData.scores.map((item, index) => (
              <tr key={index} className="fv_risk_assessment_item">
                <td>{item.indicator}</td>
                <td>{item.value}</td>
                <td>{formatValue(item.weightage, 1000)}</td>
                <td>{formatValue(item.score, 1000000)}</td>
                {/* <td>{formatValue(item.weightage * item.score, 1000000)}</td> */}
              </tr>
            ))}
            <tr className="fv_risk_assessment_final_score">
              <td colSpan="3"><b>Final Score</b></td>
              <td>{formatValue(riskData.finalScore, 1000000)}</td>
            </tr>


          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
}

export default RiskAssessment;

const RiskAssessmentSkeleton = () => {
  return (
    <div className="fv_risk_assessment_card">
      <h3 className="fv_risk_assessment_skeleton_title shimmer-effect"></h3>
      <table className="fv_risk_assessment_details">
        <thead>
          <tr>
            <th><div className="fv_risk_assessment_skeleton_line shimmer-effect"></div></th>
            <th><div className="fv_risk_assessment_skeleton_line shimmer-effect"></div></th>
            <th><div className="fv_risk_assessment_skeleton_line shimmer-effect"></div></th>
            <th><div className="fv_risk_assessment_skeleton_line shimmer-effect"></div></th>
            <th><div className="fv_risk_assessment_skeleton_line shimmer-effect"></div></th>
          </tr>
        </thead>
        <tbody>
          {[1, 2, 3].map((index) => (
            <tr key={index} className="fv_risk_assessment_item_skeleton">
              <td><div className="fv_risk_assessment_skeleton_line shimmer-effect"></div></td>
              <td><div className="fv_risk_assessment_skeleton_line shimmer-effect"></div></td>
              <td><div className="fv_risk_assessment_skeleton_line shimmer-effect"></div></td>
              <td><div className="fv_risk_assessment_skeleton_line shimmer-effect"></div></td>
              <td><div className="fv_risk_assessment_skeleton_line shimmer-effect"></div></td>
            </tr>
          ))}
          <tr className="fv_risk_assessment_final_score_skeleton">
            <td colSpan="4"><div className="fv_risk_assessment_skeleton_line shimmer-effect"></div></td>
            <td><div className="fv_risk_assessment_skeleton_line shimmer-effect"></div></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};