import { ThreeBounce } from 'better-react-spinkit';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { CardBody } from 'reactstrap';
import { DataGrid } from '../../components';
import './styles.scss'; // Assuming you have a CSS file for styles
import CyclosFilter from '../Filter/CyclosFilter';
import { TRANSACTION_FILTER } from './AccountSearchConstant';
import { prepareFilterArtifacts } from '../../util/FilterUtil';
import { useLocation } from 'react-router-dom';
import AccountSearchService from './AccountSearchService';


const Transactions = ({ loading, fetchTxns, txData }) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [filterObject, setFilterObject] = useState({});

    useEffect(() => {
        const { filterObject } = prepareFilterArtifacts(queryParams, TRANSACTION_FILTER)
        setFilterObject(filterObject)
    }, [])

    if (loading) {
        return (
            <div className="p-2 d-flex justify-content-center align-items-center">
                <ThreeBounce />
            </div>
        );
    }

    const GridColumns = {
        version: 1,
        columns: [
            {
                title: "Date",
                accessor: "date",
                width: 100,
                renderer: (dataObj) => moment(dataObj.date).format('DD/MM/YYYY'),
                show: true,
                required: true,
            },
            {
                title: "Transaction Number",
                accessor: "transactionNumber",
                width: 150,
                show: true,
                required: true,
            },
            {
                title: "Gateway",
                accessor: "gateway",
                renderer: (dataObj) => !!dataObj.gateway ? dataObj.gateway : "-",
                show: true,
                required: true,
            },
            {
                title: "Amount",
                accessor: "amount",
                show: true,
                required: true,
            },
            {
                title: "Beneficiary",
                accessor: "beneficiary",
                renderer: (dataObj) => dataObj.related.kind === "user" ? dataObj.related.user.display : "System",
                show: true,
                required: true,
            },
            {
                title: "Type",
                accessor: "creationType",
                show: true,
                required: true,
            },
            {
                title: "Status",
                accessor: "authorizationStatus",
                show: true,
                required: true,
            },
            {
                title: "Purpose",
                accessor: "description",
                show: true,
                width: 450,
                required: true,
            },
            // {
            //     title: "Supporting Docs",
            //     accessor: "supportingDocs",
            //     renderer: () => <div style={{ textDecoration: 'underline' }}>{"Link"}</div>,
            //     show: true,
            //     required: true,
            //     fixed: "right",
            // },
        ]
    };

    return (
        <div className="table-responsive fv_table_container p-2">
            <CardBody style={{ height: "100%" }}>
                <div className="d-md-flex flex-1">
                    <div
                        data-pattern="priority-columns"
                        style={{
                            width: '100%',
                        }}
                    >
                        <div className='my-2 p-2'>
                            <CyclosFilter
                                structure={TRANSACTION_FILTER}
                                applyFunc={fetchTxns}
                                value={filterObject}
                            />
                        </div>
                        <DataGrid
                            data={txData}
                            total={txData.length}
                            uiPreference="transaction.grid"
                            headers={GridColumns}
                            page={AccountSearchService.pageTx}
                            rowsPerPage={AccountSearchService.rowsPerPageTx}
                            onSelectChange={fetchTxns}
                            onPaginationChange={(pg, rows) => {
                                console.log(pg, rows)
                                AccountSearchService.rowsPerPageTx = rows
                                AccountSearchService.pageTx = pg;
                                fetchTxns();
                            }}
                        />
                    </div>
                </div>
            </CardBody>
        </div>
    )
}

export default observer(Transactions);
