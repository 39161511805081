import _ from "lodash";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { GrDocumentPdf } from "react-icons/gr";
import { Col, Row } from "reactstrap";
import { formatBytes } from "..";
import { Image } from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { doDELETE } from "../../util/HttpUtil";
import PDFLabel from "../Image/PDFLabel";

function getFilenameFromPath(path) {
  const parts = path.split('/');
  return parts[parts.length - 1];
}


const truncateFilename = (filename) => {
  if (filename.length > 20) {
    const start = _.slice(filename, 0, 5).join('');
    const end = _.slice(filename, -7).join('');
    return `${start}.....${end}`;
  }
  return filename;
};


const DropZone = ({ asLabel, inline, value, onChange = () => { }, single, fileSizeText, fileSize, onDeleteAttachment = () => { }, onDelete, module, field, id }) => {
  const [selectedFiles, setSelectedFiles] = useState(value ? value : []);
  const { showConfirm, showMessage, showError } = useContext(DialogContext)
  const { t } = useContext(I18nContext);

  function handleAcceptedFiles(files) {
    // const acceptedFiles = files.filter(file => {
    //   const fileType = file?.type?.split("/")[0];
    //   return fileType === "image" || fileType === "application" && file.name.endsWith(".pdf");
    // });

    const acceptedFiles = files.filter(file => {
      const fileType = file?.type?.split("/")[0];
      const isAcceptedType = fileType === "image" || (fileType === "application" && file.name.endsWith(".pdf"));
      const isWithinSizeLimit = file.size >= (5 * 1024) && file.size <= (fileSize ? fileSize : 100000) * 1024;

      if (!isAcceptedType) {
        showError(t("Only PDF files and images are accepted!"));
        return false;
      }

      if (!isWithinSizeLimit) {
        showError(t(`File must be between 5 KB and 10 KB. ${file.name} is not within the limit.`));
        return false;
      }

      return isAcceptedType && isWithinSizeLimit;
    });

    // if (acceptedFiles?.length === 0) {
    //   showError(t("Only Pdf Files and Images are Accepted !"));
    //   return;
    // }
    files.map((file) =>
      Object.assign(file, {
        key: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    if (single) {
      setSelectedFiles([files[0]]);
      onChange([files[0]]);
    } else {
      setSelectedFiles([...selectedFiles, ...files]);
      onChange([...selectedFiles, ...files]);
    }
  }

  useEffect(() => {
    setSelectedFiles(value ? value : []);
  }, [value]);

  const handleDelete = async () => {
    if (
      await showConfirm({
        title: t("Do you want to delete file?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      try {
        const data = {
          _id: id,
          field: field,
          module: module,
        }
        const response = await doDELETE("/api/file/delete/single", data);
        if (response.status === 200) {
          showMessage("Deleted", "Deleted");
          onDelete()
        }
      } catch (e) {
        showError(e);
      }
    }
  };
  return (
    <Row style={{ width: "100%", }}>

      <div className={`${inline ? " d-flex flex-wrap p-0 " : ""}`}>
        {(!single || selectedFiles && selectedFiles.length == 0 ||
          !selectedFiles[0].path) && selectedFiles.map((f, i) => {
            return (
              <div
                className="mt-1 mb-0 shadow-none px-0 dz-processing dz-image-preview dz-success dz-complete"
                key={i + "-file"}
              >
                <div
                  className={`${inline ? "pb-2 px-2" : "p-2"}`}
                >
                  <div style={{ position: "relative" }} className="d-flex align-items-center justify-content-between">

                    <FileUploadRowItem hideIndexes file={f} index={i} />

                    {!asLabel && <div
                      style={{ position: "absolute", right: "-8px", top: "-8px" }}
                      className=" d-flex align-items-end justify-content-end cursor-pointer"
                      onClick={(index) => {
                        if (f?.size) {
                          const updatedSelectedFiles = [...selectedFiles];
                          updatedSelectedFiles.splice(i, 1);
                          setSelectedFiles(updatedSelectedFiles);
                        } else if (typeof f === 'string') {
                          handleDelete();
                        } else if (f?.key?.slice(1, 13) === "api/o/assets") {
                          onDeleteAttachment(i).then((deleted) => {
                            if (deleted) {
                              const updatedSelectedFiles = [...selectedFiles];
                              updatedSelectedFiles.splice(index, 1);
                              setSelectedFiles(updatedSelectedFiles);
                            }
                          });
                        }
                      }}
                    >
                      <i
                        style={{
                          fontSize: "14px",
                          border: "2px solid red",
                          borderRadius: "20px",
                          color: "red",
                        }}
                        className="dripicons-cross text-red"
                      />
                    </div>}
                  </div>
                </div>
              </div>
            );
          })}
      </div>

      {asLabel ? null :
        <Dropzone
          onDrop={(acceptedFiles) => {
            handleAcceptedFiles(acceptedFiles);
          }}
          accept={{
            "image/*": [".png", ".gif", ".jpeg", ".jpg"],
            'application/pdf': []
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <div className="dropzone needsclick ms-2 mt-2">
              <div className="dz-message " {...getRootProps()}>
                <input {...getInputProps()} />
                {single && selectedFiles && selectedFiles.length == 1 && selectedFiles[0].path ?
                  <div className="d-flex align-items-center justify-content-between">
                    <FileUploadRowItem file={selectedFiles[0]} index={0} />
                    <div className=" d-flex align-items-end justify-content-end">
                      <h4>
                        {/* <img src={upload} alt="upload" height="50" /> */}
                        <div className=" font-size-14 mt-2" style={{ color: "#AAA" }}>{t("Drag & Drop your files here or Choose Files.")}</div>
                        <div className=" font-size-14 mt-2" style={{ color: "#AAA" }}>{t(` ${fileSizeText ? fileSizeText : "100 MB"} max file size`)}</div>
                      </h4>
                    </div>
                  </div>
                  :
                  <h4>
                    {/* <img src={upload} alt="upload" height="50" /> */}
                    <div className=" font-size-14 mt-2" style={{ color: "#AAA" }}>{t("Drag & Drop your files here or Choose Files.")}</div>
                    <div className=" font-size-14 mt-2" style={{ color: "#AAA" }}>{t(` ${fileSizeText ? fileSizeText : "100 MB"} max file size`)}</div>
                  </h4>
                }
              </div>
            </div>
          )
          }
        </Dropzone >}

    </Row >
  );
};

const FileUploadRowItem = ({ hideIndexes, file, index }) => {

  return (
    <div style={{ border: "1px solid #f0f0f0" }} className="d-flex pe-3 flex-1 w-100 align-items-center">

      {!hideIndexes && <Col className="col-auto">
        <div className="h3 fw-bold me-3">{index + 1}.</div>
      </Col>}

      <Col className="col-auto">
        {file?.type?.split("/")[0] === "application" && file.name.endsWith(".pdf") || file?.key?.endsWith('.pdf') ?
          <PDFLabel src={file?.key ?? file} />
          :
          typeof file === 'string' || !file.path ?
            <Image style={{ width: "60px", height: "60px" }} width="60px" height="60px" src={file?.key ?? file} />
            : <img src={file?.key}
              className=""
              style={{ objectFit: "contain", width: "60px", height: "60px" }} width="60px" height="60px" alt={file?.name}
            />}
      </Col>
      <Col className="ms-2 col-auto">

        <h3 className="text-muted font-weight-bold">
          {truncateFilename(file?.name ?? getFilenameFromPath(file?.key ?? file))}
        </h3>
        <p className="mb-0">
          <h4>
            {file.formattedSize}
          </h4>
        </p>
      </Col>
    </div>
  )
}

export default observer(DropZone); 
