export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl) => {
    let url = `/api/custom-field/grid?`;
    if (sizePerPage) url += `rows=${sizePerPage}&`;
    if (filterUrl) url += `${filterUrl}&`;
    if (page) url += `page=${page}`
    return url;
  },
  get: (id) => `/api/custom-field/detail?id=${id}`,
  save: `/api/custom-field/create`,
  delete: (id) => `/api/custom-field/delete?id=${id}`,
  bulkDelete: (id) => `/api/custom-field/delete`,
  update: `/api/custom-field/update`,
};

export const STRUCTURE = [
  {
    label: "CustomField Code",
    filterLabel: "CustomField Code",
    filterName: "code",
    type: "text",
  },
  {
    label: "CustomField Name",
    filterLabel: "CustomField Name",
    filterName: "address.name",
    type: "text",
  },
  {
      label: "Has Self Drive ",
      filterLabel: "Has Self Drive",
      renderer: (dataObj) => {
        return <div>
          {dataObj?.hasSelfDrive ? 'true' : 'false'}
        </div>;
      }, 
      filterName: "hasSelfDrive",
      type: "text",
    },
];
