import { ThreeBounce } from "better-react-spinkit";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { FaExternalLinkAlt, FaRedo, FaSpinner } from "react-icons/fa";
import { TbCloudDownload } from "react-icons/tb";
import { Button } from "reactstrap";
import { ModalComponent, generateRandomString } from "../../components";
import ApproveButton from "../../components/Buttons/ApproveButton";
import RejectButton from "../../components/Buttons/RejectButton";
import Comment from "../../components/CommentByModule";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import ApplicationsService from "./ApplicationsService";
import StatusProgress from "./components/StatusProgress";
import { ApproveModal } from "./components/modals/ApproveModal";
import { AskForMoreInfoModal } from "./components/modals/AskForMoreInfoModal";
import { RejectModal } from "./components/modals/RejectModal";
import ApplyIndividualLite from "./forms/ApplyIndividualLite";
import ApplyBusinessLite from "./forms/BusinessLiteForms/ApplyBusinessLite";
import ApplyBusinessPrime from "./forms/BusinessPrime/ApplyBusinessPrime";
import "./style.css";
import ApplicationDocument, { StatusDisplayInvert, applicationStatuses } from "./thirdParty/applicationDocument/ApplicationDocument";
import ComplyAdvantageGroupedByUBOs from "./thirdParty/complyAdvantage/ComplyAdvantageGroupedByUBOs";

import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import DownloadService from "../../components/Download/DownloadService";
import SupervisiorAssigner from "../../components/Dropdowns/SupervisiorSelect/SupervisiorAssigner";
import { doGET } from "../../util/HttpUtil";
import UserNameModal from "./components/modals/UserNameModal";
import { downloadBlob } from "./thirdParty/ondato/Ondato";
import OndatoGroupedByUBOs from "./thirdParty/ondatoGroupedByUBOs/OndatoGroupedByUBOs";
import RiskAssessment from "./thirdParty/riskAssessment/RiskAssessment";
import { UserContext } from "../../store/context/UserContext";

import {
  Alert,
} from "reactstrap"
import { MdReportGmailerrorred } from "react-icons/md";
import WebhookDisplay from "./forms/components/WebhookDisplay/WebHookDisplay";
import PagesNoRights from "../Utility/pages-no-rights";

const Refresh = ({ onClick }) => {
  const tooltipId = generateRandomString(15)
  return <>
    <Tooltip
      style={{
        zIndex: '200000',
      }}
      anchorSelect={`#${tooltipId}`}
    >
      Refresh Application
    </Tooltip>
    <div id={tooltipId}>
      <Button
        type="button"
        onClick={onClick}
        color="primary"
        className={"btn ms-1 btn-primary "}
        style={{ padding: "3px" }}
      >
        <FaRedo
          size={16}
          color="#fff"
          title="Refresh Data"
        />
      </Button>
    </div>
  </>
}


const RedirectToLink = ({ onClick }) => {
  const tooltipId = generateRandomString(15)

  return (
    <>
      <Tooltip
        style={{
          zIndex: '200000',
        }}
        anchorSelect={`#${tooltipId}`}
      >
        View Created Account
      </Tooltip>
      <div id={tooltipId}>
        <Button
          type="button"
          onClick={onClick}
          color="primary"
          className="btn ms-1 btn-primary"
          style={{ padding: "3px" }}
        >
          <FaExternalLinkAlt
            size={16}
            color="#fff"
            title="Go to Link"
          />
        </Button>
      </div>
    </>
  );
}



const getStatusColor = (status) => {
  switch (status) {
    case 2:
      return "#fff3cd";
    case 3:
      return "#d4edda";
    case 4:
      return "#f8d7da";
    default:
      return "#F2F2F2";
  }
};

const EditApplications = ({ fetchData, editId, hideButtons, onChange = () => { } }) => {
  const { t } = useContext(I18nContext);
  const navigate = useNavigate()
  const { userContent } = useContext(UserContext);
  const { showError, showMessage, showConfirm } = useContext(DialogContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [openModal, setOpenModal] = useState(false)
  const [approvalRequested, setApprovalRequested] = useState(false)
  const [askForMoreModal, setAskForMoreModal] = useState(false)
  const [step, setStep] = useState(1);
  const [refreshId, setRefreshId] = useState(generateRandomString(10));
  const [stepList, setStepList] = useState([])
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [usernameModal, setUsernameModal] = useState(false)
  const toggleShowMessages = () => {
    setShowMessageModal(!showMessageModal);
  }

  const fetch = async () => {
    setLoading(true)
    try {
      let form = await ApplicationsService.get(editId);
      if (form?.steps) {
        const t = form?.steps?.map((v, index) => {
          return { ...v, value: index + 1, backgroundColor: getStatusColor(v.status) }
        })
        setStepList(t)
      }
      if (form?._id) {
        setData({ ...form });
      }
    } catch (error) {
      showError(error)
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    setData({})
    if (editId && userContent?.rights?.includes(2800)) {
    fetch(); 
    }
  }, [editId, ApplicationsService?.version, userContent?.rights]);

  useEffect(() => {
    onChange(data)
  }, [data]);


  const reload = () => {
    fetch()
    setRefreshId(generateRandomString(10))
  }

  const askForMoreInfo = async (v) => {
    fetch()
    setAskForMoreModal(false)
  }

  const downloadReport = async () => {
    if (loading) return;
    setLoading(true)
    if (data?.formType == "BUSINESS_LITE" || data?.formType == "BUSINESS_PLUS") {
      try {
        const response = await doGET(`/api/generated-report/get?id=${editId}&emailReport=true`);
        const status = response?.data?.status;
        showMessage(status === "GENERATED" ? "Downloading Report...." : ["ENQUEUE", "IN_PROGRESS"].includes(status) ? "Report download has been queued." : status);
        if (status === "GENERATED") {
          const pdfResponse = await doGET(response.data.path);
          downloadBlob(pdfResponse.data, 'report.pdf');
        } else if (["ENQUEUE", "IN_PROGRESS"].includes(status)) {
          DownloadService.startPolling();
        }
      } catch (error) {
        showError(error);
      } finally {
        setLoading(false);
      }
      return;
    }
    try {
      const response = await doGET(`/api/application-form/${editId}/download/report`)
      if (response.status == 200) {
        const blob = response.data;
        const fileName = 'reportId.pdf';
        downloadBlob(blob, fileName);
      }
    } catch (error) {
      showError(error)
      console.log(error)
    } finally {
      setLoading(false)
    }
  }


  const tooltipId = generateRandomString(15)


  const handleAssignesChange = async (v) => {
    try {
      await ApplicationsService.edit({ ...data, supervisor_ids: v });
    } catch (error) {
      showError(error)
    }
  }


  const extractErrors = (errorMessage) => {
    if (!errorMessage) return [];
    return errorMessage?.split(',')?.filter(msg => msg?.trim())?.map((msg, index) => (
      <li key={index}>{msg?.trim()}</li>
    ));
  };

  const hasUnrejectRight = (status) => {
    if (status == 4) { // rejected
      return userContent?.rights?.includes(2804);
    }
    return true; // if not rejected then can approve or askformore
  };

  if (!userContent?.rights?.includes(2800)) {
    return <div key={userContent?.rights} > <PagesNoRights /></div> 
  }
  return (
    <div className="d-flex flex-column h-100">

      <div className="zr_layout-header d-flex justify-content-between" style={{ flexShrink: 0 }} >
        <h4 style={{ fontWeight: 600 }} className="py-0 my-0 d-flex align-items-center "> <span style={{ color: "#888" }} className="me-2  "> #{data?.applicationNo ?? "Application"}  </span>  <b> {data?.customerName ?? ""}  </b>  </h4>

        <div className="d-flex align-items-center me-4 pe-2">

          <span className="me-2"> <StatusDisplayInvert value={applicationStatuses?.find(v => data?.status == v.value)} /> </span>

          <div className={`${(data?.supervisor_ids ?? [])?.length ? "me-3" : "me-0"}`}>
            <SupervisiorAssigner
              value={data?.supervisor_ids}
              asLabel={!userContent?.rights?.includes(2806)}
              onChange={(v) => { handleAssignesChange(v) }}
            />
          </div>

          <Tooltip
            style={{
              zIndex: '200000',
            }}
            anchorSelect={`#${tooltipId}`}
          >
            Download Report
          </Tooltip>

          <div id={tooltipId}>
            <Button
              type="button"
              color="primary"
              onClick={downloadReport}
              className={"btn ms-2 btn-primary"}
              style={{ padding: "3px" }}
            >
              {loading ? <div className="fv_ondato_rule py-0">
                <FaSpinner size={18} className="spinner" />
              </div> : <> <TbCloudDownload color="#fff" className="" size={20} /> </>}
            </Button>
          </div>

          <Refresh onClick={() => { reload() }} />
          {data?.cyclosUserId && (data?.status == 6) && userContent?.rights?.includes(2700) ? <RedirectToLink onClick={() => { navigate(`/accounts/detail/${data?.cyclosUserId}`) }} /> : null}
        </div>

      </div>
      {
        <div style={{ width: "calc(100% - 5px)" }} className="row px-0 ">
          <div className="col-12 col-md-8 px-0">
            <StatusProgress
              data={stepList}
              value={step}
              refreshId={refreshId}
              onChange={setStep}
              toggleShowMessages={toggleShowMessages}
            />

            {stepList[step - 1]?.label === "Application Form" && (
              <div>


                <div className="scrollable-form-area card p-3 px-4 m-0 mb-2">

                  {data?.status == 7 && <Alert color="danger" role="alert" className="mb-0">
                    <div className="d-flex justify-content-start align-items-center">
                      <MdReportGmailerrorred
                        size={22}
                        className="me-1"
                        color="#eb2d3a"
                      />
                      <div className="font-size-18">
                        Account Creation Error
                      </div>

                    </div>

                    {extractErrors(data?.errorMessage)}

                  </Alert>}


                  {data?.formType == "BUSINESS_PRIME" ? <ApplyBusinessPrime refreshId={refreshId} asLabel formId={editId} value={data} /> : data?.formType == "BUSINESS_LITE" || data?.formType == "BUSINESS_PLUS" ?
                    <ApplyBusinessLite refreshId={refreshId} asLabel formId={editId} value={data} />
                    : <ApplyIndividualLite refreshId={refreshId} asLabel formId={editId} value={data} />}

                  {data?.webhookData && data?.status > 4 ? <WebhookDisplay webhookData={data?.webhookData} /> : null}
                </div>
                <div className="d-flex justify-content-end align-items-center w-100">

                  {(!hideButtons && ![5, 6]?.includes(data?.status) && userContent?.rights?.includes(2805) && [3, 7]?.includes(data?.status)) ?
                    <div className="d-flex justify-content-end my-2 me-2">
                      <Button
                        onClick={() => setUsernameModal(true)}
                        className=""
                        style={{ borderColor: "#1678AE", fontSize: "16px", height: "40px", padding: "2px 4px 2px 4px", width: "140px", borderRadius: "10px" }}
                        color="outline-primary"
                      >
                        {loading ? <ThreeBounce color="#1678AE" size={8} /> : <>{t("Create Account")} </>}
                      </Button>
                    </div> : null}

                  {(
                    userContent?.rights?.includes(2802) &&
                    hasUnrejectRight(stepList[step - 1]?.status) &&
                    !hideButtons &&
                    ![5, 6]?.includes(data?.status)
                  ) ?
                    <div className="d-flex justify-content-end my-2 me-2">
                      <Button
                        onClick={() => { setAskForMoreModal(true) }}
                        className=""
                        style={{ borderColor: "#1678AE", fontSize: "16px", height: "40px", padding: "2px 4px 2px 4px", width: "100px", borderRadius: "10px" }}
                        color="outline-primary"
                      >
                        {loading ? <ThreeBounce color="#1678AE" size={8} /> : <>{t("Ask More")} </>}
                      </Button>
                    </div> : null
                  }

                  {(
                    !hideButtons &&
                    ![5, 6]?.includes(data?.status) &&
                    userContent?.rights?.includes(2802))
                    ? <RejectButton
                      onClick={(e) => {
                        setOpenModal(true);
                        setApprovalRequested(false);
                      }}
                      disabled={stepList[step - 1]?.status == 4}
                      loading={loading}
                    /> : null}

                  {(
                    !hideButtons &&
                    ![5, 6]?.includes(data?.status) &&
                    hasUnrejectRight(stepList[step - 1]?.status) &&
                    userContent?.rights?.includes(2802))
                    ? <ApproveButton
                      onClick={(e) => {
                        setOpenModal(true);
                        setApprovalRequested(true);
                      }}
                      disabled={stepList[step - 1]?.status == 3}
                      loading={loading}
                    /> : null}
                </div>

              </div>
            )}

            {stepList[step - 1]?.label === "KYC Verification" && (
              <div className="scrollable-content-area  p-1 m-3">

                <OndatoGroupedByUBOs reload={reload} refreshId={refreshId} formId={editId} />
              </div>
            )}

            {stepList[step - 1]?.label === "Sanctions Screening" && (
              <div className="scrollable-content-area p-1  m-3">
                {<ComplyAdvantageGroupedByUBOs reload={reload} refreshId={refreshId} formId={editId} />}
              </div>
            )}

            {(stepList[step - 1]?.label === "Document Verification" || stepList[step - 1]?.label === "Documents") && (
              <div className="scrollable-content-area p-1 m-3">
                <ApplicationDocument
                  reload={reload}
                  showDocumentGrid={(data?.formType != "INDIVIDUAL_PLUS" && data?.formType != "INDIVIDUAL_LITE")}
                  refreshId={refreshId}
                  application_id={editId}
                  formType={data?.formType}
                />

              </div>
            )}


            {stepList[step - 1]?.label === "Risk Assessment" && (
              <div className="scrollable-content-area p-1 m-3">
                <RiskAssessment reload={reload} refreshId={refreshId} formId={editId} formType={data?.formType} />
              </div>
            )}

          </div>

          <div className="col-0 d-none d-md-block col-md-4 px-0">
            <Comment
              recordType={"application-form"}
              recordOf={editId}
              refreshId={refreshId}
              formType={data?.formType}
              byType={5}
              forType={[5]}
            />
          </div>


        </div>
      }


      <AskForMoreInfoModal
        isOpen={askForMoreModal}
        onToggle={() => { setAskForMoreModal(false) }}
        formType={data?.formType}
        stepName={stepList[step - 1]?.label ?? ""}
        onSubmit={(v) => {
          reload()
          askForMoreInfo(v)
        }}
        formId={editId}
      />

      <ApproveModal
        isOpen={openModal && approvalRequested}
        stepName={stepList[step - 1]?.label ?? ""}
        setOpenModal={setOpenModal}
        onSubmit={async (v) => {
          reload()
          setOpenModal(false);
        }}
        formType={data?.formType}
        formId={editId}

      />

      <RejectModal
        isOpen={openModal && !approvalRequested}
        stepName={stepList[step - 1]?.label ?? ""}
        setOpenModal={setOpenModal}
        onSubmit={async (v) => {
          reload()
          setOpenModal(false);
        }}
        formType={data?.formType}
        formId={editId}
      />

      <UserNameModal
        isOpen={usernameModal}
        setOpenModal={setUsernameModal}
        onCreateAccount={async (v) => {
          reload()
          setUsernameModal(false);
        }}
        formType={data?.formType}
        formId={editId}
        email={data?.email}
        userName={data?.cyclosUserName}
      />

      <ModalComponent
        position={"centered"}
        size={"full-screen"}
        isOpen={showMessageModal}
        onToggle={toggleShowMessages}
      >
        <ModalComponent.Title>
          Messages
        </ModalComponent.Title>
        <ModalComponent.Body>
          <Comment
            recordType={"application-form"}
            recordOf={editId}
            refreshId={refreshId}
            formType={data?.formType}
            byType={5}
            forType={[5]}
          />
        </ModalComponent.Body>
      </ModalComponent>

    </div >
  );
};

export default observer(EditApplications);