export const agreements = [
    { key: 'bridgeAuthorization', label: 'Bridge Authorization Agreement and Terms' },
    { key: 'depositTerms', label: 'Deposit Terms and Conditions' },
    { key: 'onlineBankingTerms', label: 'Online Banking Terms and Conditions' },
    { key: 'privacyPolicy', label: 'Privacy Policy' },
    { key: 'standardFees', label: 'Standard Fees' },
];

export const GroupedFields = [
    //Common Fields among user and shareholdeer
    'userfirstName',
    'userlastName',
    'userBuildingNumber',
    'userStreet',
    'userCity',
    'userState',
    'userPinCode',
    'userCountry',
    'userphone',
    'useremail',
    'usermobilePhone',

    //User Fields
    'role',
    'accessType',
    'entityControl',
    'isDirector',
    'isAuthorized',
    'isPrimaryUser',
    'isAgent',

    // //entitiy Fields
    // 'entities_relations',
    // 'name',
    // 'entity_country',
    // 'businessNature',

    //shareholder Fields

    'sourceOfWealth',
    'shareholderCountry',
    'percentOwner',
    'taxId'

]

export const cleanupMappings = [
    {
        conditionPath: 'haveAltAddress',
        conditionValue: false,
        keys: [
            'altAddress.buildingNumber',
            'altAddress.street',
            'altAddress.city',
            'altAddress.state',
            'altAddress.pinCode',
            'altAddress.country'
        ]
    },
    {
        conditionPath: 'website',
        conditionValue: false,
        keys: ['url']
    },
    {
        conditionPath: 'website',
        conditionValue: true,
        keys: ['engage']
    },
    {
        conditionPath: 'inOperation',
        conditionValue: false,
        keys: ['operationStartDate']
    },
    {
        conditionPath: 'inOperation',
        conditionValue: true,
        keys: ['operateEstDate']
    },
    {
        conditionPath: 'additionalBankAccount',
        conditionValue: false,
        keys: ['additionalBankAccountInstName']
    },
    {
        conditionPath: 'penalty',
        conditionValue: false,
        keys: ['penaltyDetails']
    },
    {
        conditionPath: 'litigation',
        conditionValue: false,
        keys: ['litigationDetails']
    },
    {
        conditionPath: 'Business_Registration_Country',
        conditionValue: "US",
        keys: ['Business_Tax_ID'],
        conditionOperator: '==='
    },
    {
        conditionPath: 'Business_Registration_Country',
        conditionValue: "US",
        keys: ['EIN'],
        conditionOperator: '!=='
    },
];
