import { ThreeBounce } from "better-react-spinkit";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { DialogContext } from "../../store/context/DialogContext";
import FormQuestionsService from "./EmailLogsService";

const EditEmailLogs = ({ editId, onChange = () => { }, hasErr }) => {
  const { showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  const fetch = async () => {
    if (!editId) {
      setLoading(false);
      return; // Ensure we do not proceed without a valid editId
    }

    try {
      setLoading(true);
      let form = await FormQuestionsService.get(editId);
      let parser = new DOMParser();
      let doc = parser.parseFromString(form.body, "text/html");
      let footer = doc.querySelector(".footer");
      if (footer) footer.remove();

      form.body = doc.body.innerHTML;
      setData(form);
      setLoading(false);
    } catch (error) {
      showError(`An error occurred: ${error.message || error}`);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetch();
    return () => setLoading(false); // Cleanup function to set loading false when component unmounts
  }, [editId, FormQuestionsService?.version]);

  useEffect(() => {

  }, [data]);

  return (
    <React.Fragment>
      {loading ? (
        <ThreeBounce />
      ) : (
        <div>
          <div dangerouslySetInnerHTML={{ __html: data?.body }} />
        </div>
      )}
    </React.Fragment>
  );
};

export default observer(EditEmailLogs);
