import React, { useContext, useEffect, useState } from "react";
import { Button } from 'reactstrap';
import { InputField, ModalComponent } from '../../../../components';
import QuestionsSelect from '../../../../components/Dropdowns/QuestionsSelect/QuestionsSelect';
import { doGET, doPUT } from "../../../../util/HttpUtil";
import { DialogContext } from "../../../../store/context/DialogContext";
import { ThreeBounce } from "better-react-spinkit";
import QuestionsSelectService from "../../../../components/Dropdowns/QuestionsSelect/QuestionsSelectService";
import { toJS } from "mobx";


export const FormEndpoints = {
    INDIVIDUAL_LITE: "individual-lite",
    BUSINESS_LITE: "business-lite",
    INDIVIDUAL_PLUS: "individual-lite",
    BUSINESS_PLUS: "business-lite",
    INIDIVIDUAL_PRIME: "individual-prime",
    BUSINESS_PRIME: "business-prime"
}

export const AskForMoreInfoModal = ({ stepName, isOpen, onToggle, formType, onSubmit, formId }) => {

    const [data, setData] = useState({ questions: [], remark: "", adhocQuestion: "" })
    const [applicationData, setApplicationData] = useState()
    const { showMessage, showError, } = useContext(DialogContext)
    const [loading, setLoading] = useState(false);


    const updateAsks = async () => {
        if (data?.questions?.length === 0 && (data?.adhocQuestion ?? "").trim() === "") {
            showError("Please select a question or type an adhoc question");
            return;
        }
        if ((data?.remark ?? "").trim() === "") {
            showError("Please enter a remark for the applicant");
            return;
        }
        try {
            setLoading(true);
            let asks = data?.questions?.map((ques) => {
                return {
                    question_id: ques,
                    question: toJS(QuestionsSelectService.questions ?? [])?.find(v => v?._id == ques)?.title 
                }
            })

            if (data?.adhocQuestion) {
                asks?.push({ question: data?.adhocQuestion })
            }


            if (applicationData?.asks && asks?.length > 0) {
                asks = [...(applicationData?.asks ?? []), ...asks];
            }

            const payload = {
                ...applicationData,
                status: 2,
                remark: data?.remark ?? "",
                ...(asks.length > 0 && { asks })
            };


            const response = await doPUT(`/api/${FormEndpoints[formType]}/update`, payload);
            if (response?.status == 200) {
                onSubmit()
                // setData({ questions: [], remark: "", adhocQuestion: "" })
            }


        } catch (error) {
            showError(error)
        }
        setLoading(false);
    }

    const fetchAsks = async () => {
        try {
            const response = await doGET(`/api/${FormEndpoints[formType]}/detail?id=${formId}`)
            if (response?.status == 200) {
                setApplicationData(response?.data)
            }
        } catch (error) {
            showError(error)
        }
    }

    useEffect(() => {
        if (isOpen && formType && formId) {
            fetchAsks()
        }
    }, [formId, formType, isOpen])

    return (
        <ModalComponent
            position={"centered"}
            size={"medium"}
            isOpen={isOpen}
            onToggle={() => {
                onToggle();
                setData({ questions: [], remark: "" })
            }}
        >
            <ModalComponent.Title>
                Ask For More Info
                {stepName ? <span> (</span> : null}

                {stepName}

                {stepName ? <span>)</span> : null}

            </ModalComponent.Title>
            <ModalComponent.Body>

                {/* {JSON?.stringify(data?.questions)} */}
                <div style={{ marginBottom: "200px" }} className='pb-5 '>
                    {stepName == "Application Form" && <QuestionsSelect
                        value={data?.questions}
                        multi
                        step={stepName}
                        className={"mb-2"}
                        formType={formType}
                        onChange={(v) => setData({ 
                            ...data, 
                            questions: v, 
                        })}
                    />}

                    {stepName == "Application Form" && <InputField
                        placeholder={("Adhoc Question")}
                        value={data?.adhocQuestion}
                        label={("Adhoc Question")}
                        maxLength = {300}
                        onChange={(v) => setData({ ...data, adhocQuestion: v })}
                        type="text"
                        className="col-sm-12"
                    />}

                    <textarea
                        type="text"
                        value={data?.remark}
                        onChange={(e) => {
                            setData({ ...data, remark: e?.target?.value })
                        }}
                        placeholder="Add remark for applicant..."
                        className=" mt-3 inputfield flex-1"
                        style={{
                            // width: "600px",
                            borderRadius: "14px",
                            outline: "none",
                            border: "1px solid #C6F3FF",
                            backgroundColor: "#FFFFFF",
                            height: "194px",
                            margin: "auto",
                            overflowY: "hidden",
                            fontSize: "15px",
                            color: "#000"
                        }}
                        rows="2"
                    />

                </div>
            </ModalComponent.Body>
            <ModalComponent.Footer>
                <div className="d-flex justify-content-end ">
                    <Button
                        disabled={loading }
                        onClick={updateAsks}
                        className=""
                        style={{ borderColor: "#1678AE", fontSize: "16px", height: "40px", padding: "2px 4px 2px 4px", width: "100px", borderRadius: "10px" }}
                        color="primary"
                    >
                        {
                            loading ? (
                                <ThreeBounce size={12} color="#FFF" />
                            ) : (
                                <div >Submit</div>
                            )
                        }
                    </Button>
                </div>
            </ModalComponent.Footer>
        </ModalComponent>
    )
}
