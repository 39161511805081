import React from 'react'
import { Row } from 'reactstrap'

const AlertDetail = ({data
}) => {
    const convertDate = (timestamp) =>{
        const date = new Date(timestamp * 1000);
        const readableDate = date?.toLocaleString(); 
        return readableDate
    }
  return (
    <div className='px-2'>
    <Row className='d-flex mt-3'>
      <h2 className='col-sm-2'>Title:</h2>
      <div
        className='px-3 col-sm-10' style={{fontSize:"larger"}}>
      {data?.title}
      </div>
    </Row>
    <Row className='d-flex mt-3'>
      <h3 className='col-sm-2'>Description:</h3>
      <div
        className='px-3 col-sm-10' style={{fontSize:"larger"}}>
      {data?.description}
      </div>
    </Row>
    <Row className='d-flex mt-3'>
      <h3 className='col-sm-2'>Source:</h3>
      <div
        className='px-3 col-sm-10' style={{fontSize:"larger"}}>
      {data?.source}
      </div>
    </Row>
    <Row className='d-flex mt-3'>
      <h3 className='col-sm-2'>Disposition:</h3>
      <div
        className='px-3 col-sm-10' style={{fontSize:"larger"}}>
      {data?.disposition}
      </div>
    </Row>
    <Row className='d-flex mt-3'>
      <h3 className='col-sm-2'>Start Date:</h3>
      <div
        className='px-3 col-sm-10' style={{fontSize:"larger"}}>
      {convertDate(data?.start_date)}
      </div>
    </Row>
    <Row className='d-flex mt-3'>
      <h3 className='col-sm-2'>End Date:</h3>
      <div
        className='px-3 col-sm-10' style={{fontSize:"larger"}}>
      {convertDate(data?.end_date)}
      </div>
    </Row>
    <Row className='d-flex mt-3'>
      <h3 className='col-sm-2'>Assigned To</h3>
      <div
        className='px-3 col-sm-10' style={{fontSize:"larger"}}>
      {data?.assigned_to}
      </div>
    </Row>
    
    </div>
  )
}

export default AlertDetail