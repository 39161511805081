import React, { useContext, useEffect, useState } from "react";
import { DialogContext } from "../../../store/context/DialogContext";
import { I18nContext } from "../../../store/context/i18nContext";
import ProfileService from "../ProfileService";
import { InfoPair } from "../../Form/thirdParty/ondato/Ondato";
import SupervisorSelect from "../../../components/Dropdowns/SupervisorSelect/SupervisorSelect";
import moment from "moment";

function UserProfile({ supervisorId }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(false);
  const { t } = useContext(I18nContext);
  const { showError } = useContext(DialogContext);

  const fetch = async () => {
    try {
      let tag = await ProfileService.getProfileDetail(supervisorId);
      setData(tag);
    } catch (error) {
      showError(error)
    }
  };

  useEffect(() => {
    setData(null)
    setLoading(true);
    if (supervisorId) fetch();
    setLoading(false)
  }, [supervisorId]);

  return (
    <>
 
      <InfoPair label="Name" value={`${data?.salutation ?? "Mr"} ${data?.name ?? "—"}`} />
      <InfoPair label="Email" value={data?.email ?? "—"} />
      <InfoPair label="Created At" value={moment(data?.createdAt * 1000).format('DD-MM-YYYY, hh:mm a')} />
      <InfoPair label="Created By" value={<SupervisorSelect
        value={data?.createdBy}
        onChange={() => { }}
        asLabel
      />} />


    </>
  );
}

export default UserProfile;
