import React from 'react';
import { Documents } from '../../../../../components';

const ProofOfDocuments = ({
    supportedFiles = ["image", "pdf"],
    single = false,
    enableOnline,
    seq,
    isAskForInfo,
    remark,
    recordId,
    asLabel,
    label,
    field,
    showCategory,
    ubo_id,
    type,
    formId,
    required = true,
    error  = ""
}) => {

    return (
        <div className='row document-proof w-100 flex-1'>
            <InputLabel label={label} required={required} />
            <div className='mt-2 ps-3 mb-0 pb-0'>
                {isAskForInfo ? remark : ""}
            </div>
            <Documents
                asLabel={asLabel}
                inline
                seq={seq}
                field={field}
                record_id={recordId}
                showCategory={showCategory}
                ubo_id={ubo_id}
                type={type}
                formId={formId}
                hideUpload
                enableOnline={enableOnline}
                supportedFiles={supportedFiles}
                single={single}
                error={error}
            />
        </div>
    );
};

export const InputLabel = ({ label, required, labelStyle }) => (
    <div style={{ minWidth: "120px", fontSize: 16, margin: "5px 0px 0px 5px", fontWeight: "500", ...labelStyle }}>
        {label}
        {required && <font color="red">*</font>}
    </div>
);

export default ProofOfDocuments;