export const ENDPOINTS = {
  grid: (page, sizePerPage, filterUrl) => {
    let url = `/api/report/grid?`;
    if (sizePerPage) url += `rows=${sizePerPage}&`;
    if (filterUrl) url += `${filterUrl}&`;
    if (page) url += `page=${page}`
    return url;
  },
  get: (id) => `/api/report/detail?id=${id}`,
  save: `/api/report/create`,
  delete: (id) => `/api/report/delete?id=${id}`,
  bulkDelete: (id) => `/api/report/delete`,
  update: `/api/report/update`,
};

export const STRUCTURE = [
  {
    label: "Report Name",
    filterLabel: "Report Name",
    filterName: "name",
    type: "text",
    icon: "mdi mdi-file-document-outline font-size-16 ",
  },
  {
    label: "Frequency",
    filterLabel: "Frequency",
    filterName: "frequency",
    type: "select",
    data: [
      { value: 'daily', label: 'Daily' },
      { value: 'weekly', label: 'Weekly' },
      { value: 'monthly', label: 'Monthly' },
      { value: 'yearly', label: 'Yearly' }
    ],
    icon: "mdi mdi-update font-size-16 ",
  },
 
];
