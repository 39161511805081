import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom';
import AccountDetails from './AccountDetails';
import Alerts from './Alerts';
import { Tab } from '../../components';
import Transactions from './Transactions';
import AccountSearchService from './AccountSearchService';
import { observer } from 'mobx-react-lite';
import Beneficiary from './Beneficiary';
import EditApplications from '../Form/EditApplications';
import HelpScout from './HelpScout'; // Import the new HelpScout component
import PrimaryButton from '../../components/Buttons/PrimaryButton';
import { doGET, doPOST } from '../../util/HttpUtil';
import SarReports from './SarReports';
import Operators from './Operators';

const AccountSearchDetails = () => {
    const { id } = useParams();
    const [step, setStep] = useState(1);
    const [loading, setLoading] = useState(false);
    const [accListData, setAccListData] = useState({});
    const [applicationCaas, setApplicationCaas] = useState("");
    const [beneficiaryData, setBeneficiaryData] = useState([]);
    const [alertsData, setAlertsData] = useState([]);
    const [sarData, setSarData] = useState([]);

    const [txData, setTxData] = useState([]);
    const [userData, setUserData] = useState({});
    const [userAddress, setUserAddress] = useState("");
    const [userProducts, setUserProducts] = useState([]);
    const [completeUserDetail, setCompleteUserDetail] = useState();

    const helpScoutUrl = useMemo(() => {
        return `https://secure.helpscout.net/search/?query=${encodeURIComponent(userData?.email)}`;
    }, [userData?.email]);

    const fetchAccListData = async () => {
        try {
            const response = await AccountSearchService.fetchAccountListData(id);
            setAccListData(response);
        } catch (error) {
            console.log(error);
        }
    }

    const fetchTxns = async (filter) => {
        try {
            const res = await AccountSearchService.fetchTransactions(filter, id);
            setTxData(res);
        } catch (error) {
            console.log(error);
        }
    }

    const fetchBeneficiaryData = async () => {
        try {
            const res = await AccountSearchService.fetchBeneficiariesData(id);
            setBeneficiaryData(res);
        } catch (error) {
            console.log(error);
        }
    }

    const fetchUserData = async () => {
        try {
            const res = await AccountSearchService.fetchUser(id);
            setUserData(res[0]);
        } catch (error) {
            console.log(error);
        }
    };

    const fecthData = async () => {
        setLoading(true);
        await fetchAccListData();
        await fetchTxns();
        await fetchUserData();
        await fetchBeneficiaryData();
        await fetchAlertsData();
        await fetchSARData();
        await fetchUserProducts();
        await findAddress();
        setLoading(false);
    }

    const fetchAlertsData = async () => {
        try {
            let _id = id
            // if (id.startsWith('-')) {
            //     _id = id.substring(1);
            // }
            const response = await doPOST("/api/unit21/list/alerts", {
                "associated_entities": [_id]
            });
            setAlertsData(response?.data?.alerts ?? []);
        } catch (error) {
            console.error("Error fetching alerts:", error);
        }
    };
    const fetchSARData = async () => {
        try {
            const response = await doGET(`/api/sar-reports/grid?user=${id}`);
            // const response = await doGET(`/api/sar-reports/grid`);

            console.log(response);
            setSarData(response?.data?.rows ?? []);
        } catch (error) {
            console.error("Error fetching alerts:", error);
        }
    };
    useEffect(() => {
        if(id) {
            setStep(1);
            fecthData();
        }
 
    }, [id])

    const findAndUpdateApplicationCaas = async () => {
        try {
            const encodedEmail = encodeURIComponent(userData?.email);
            const response = await doGET(`/api/application-form/grid?email=${encodedEmail}`);
            if (response.status === 200 && response?.data?.rows?.length) {
                setApplicationCaas(response?.data?.rows?.[0])
            }
        } catch (error) {
            console.log(error)
        }
    }

    const fetchUserProducts = async () => {
        try {
            const response = await doGET(`/api/cyclos/${id}/users`)
            if (response.status === 200) {
                if (!!response.data?.addresses && response.data?.addresses.length !== 0) {
                    const temp = response.data?.addresses[0]
                    setUserAddress(`${temp?.buildingNumber} ${temp?.street} ${temp?.city} ${temp?.region}`);
                    setCompleteUserDetail(response.data)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const findAddress = async () => {
        try {
            const response = await doGET(`/api/cyclos/users/${id}/products`)
            if (response.status === 200) {
                setUserProducts(response.data?.userProducts ?? [])
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (userData?.email) {
            findAndUpdateApplicationCaas()
        }

    }, [userData?.email])


    return (
        <div className='p-1'>
            <Tab
                activeTab={step}
                onChange={(data) => {
                    setStep(data);
                }}
                outerClassName={"mt-0 pt-0"}
                className="d-flex"
            >
                <div num={1} label={"Details"}>
                    <AccountDetails
                        userAddress={userAddress}
                        beneficiaryCount={beneficiaryData?.length}
                        alertsCount={alertsData?.length}
                        userData={userData}
                        loading={loading}
                        data={accListData}
                        setStep={setStep}
                        userProducts={userProducts}
                    />
                </div>
                <div num={2} label={`Transactions (${loading ? 0 : txData?.length})`}>
                    <Transactions txData={txData} loading={loading} fetchTxns={fetchTxns} />
                </div>
                <div num={3} label={"Alerts"}>
                    <Alerts data={alertsData} id={id} loading={loading} />
                </div>
                <div num={4} label={"Beneficiaries"}>
                    <Beneficiary beneficiaryData={beneficiaryData} loading={loading} id={id} />
                </div>
             
                {(applicationCaas?._id ) ?
                    <div num={5} label={"Application"}>
                        <EditApplications
                            hideButtons
                            editId={applicationCaas?._id}
                            fetchData={() => { }}
                            onChange={(v) => {
                                // setDetailData(v)
                            }}
                        // hasErr={hasErr}
                        />
                    </div> : null
                }

                <div num={6} label={"Help Scout"}>
                    {/* // const response = await doGET(`/api/help-scout/conversations?embed=threads&query=(email%3A%22${encodeURIComponent(userEmail)}%22)&status=all`); */}

                    <HelpScout userEmail={userData?.email} />
                </div>
                <div num={7} label={"SAR Reports"}>
                    <SarReports data={sarData} loading={loading} id={id} />
                </div>
                
                {completeUserDetail && completeUserDetail?.group?.internalName != "Individuals_Lite" && <div num={8} label={"Operators"}>
                    <Operators  loading={loading} id={id} />
                </div>}

            </Tab>

            <div className="fv_detail_account-footer d-flex justify-content-end pt-2">
                <PrimaryButton
                    className="fv_detail_account-button px-3 me-3"
                    onClick={() => window.history.back()}
                >
                    {"Go back"}
                </PrimaryButton>
                {applicationCaas?._id ? <PrimaryButton loading={loading} className="fv_detail_account-button me-3 px-3" onClick={() => setStep(5)} >
                    {"View Application"}
                </PrimaryButton> : null}

                <PrimaryButton
                    className="fv_detail_account-button px-3"
                    loading={loading}
                    onClick={() => window.open(helpScoutUrl, '_blank')}
                >
                    {"Help Scout"}
                </PrimaryButton>
            </div>

        </div>
    )
}

export default observer(AccountSearchDetails);
