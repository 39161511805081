import { ThreeBounce } from "better-react-spinkit";
import React, { useContext, useEffect, useState } from "react";
import { Button, Row } from "react-bootstrap";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { doDELETE, doGET, doPOST } from "../../util/HttpUtil";
import DropZone from "./Dropzone";
import PrimaryButton from "../Buttons/PrimaryButton";
import { InputField } from "../InputField/InputField";
import DateSelectCustom from "../DateSelects/AntDateSelect";
import DocTypeSelect from "./DocTypeSelect";


const DocField = ({
  key = 1,
  showCategory,
  onDelete = () => { },
  value,
  onChange = () => { },
  data = { attachment: { name: "", info: "", refNo: "", urls: [] }, version: 0, },
  record_id = "",
  getRecordId = async () => { },
  module = "",
  inline,
  field,
  asLabel,
  showExpiry,
  type,
  formId,
  ubo_id,
  onSubmit = () => { },
  seq
}) => {

  const { showError, showMessage, showConfirm } = useContext(DialogContext);
  const { t } = useContext(I18nContext);
  const [document, setDocument] = useState(data);
  const [loading, setLoading] = useState(false);

  const handleSaveCard = async () => {
    setLoading(true)
    const formData = new FormData();
    const formUrls = []
    for (let i = 0; i < document?.attachment?.urls.length; i++) {
      if (document?.attachment.urls[i]?.key?.slice(1, 13) === "api/o/assets") {
        formUrls.push(document?.attachment.urls[i])
      } else {
        formData.append(`file`, document?.attachment.urls[i]);
      }
    }

    let recordId;

    if (record_id) {
      recordId = record_id
    } else {
      record_id = await getRecordId()
    }
    const docObject = {
      record_id,
      module,
      expiry: document?.expiry,
      version: document.version,
      docCategory_id: document?.docCategory_id,
      ubo_id: ubo_id,
      form_id: recordId,
      type: type,
      ...(seq && { seq: seq }),
      attachment: {
        name: document?.attachment.name,
        info: document?.attachment.info,
        refNo: document?.attachment.refNo,
        urls: formUrls
      },
    };

    if (data?._id) docObject._id = data?._id
    if (field) { docObject.field = field; }
    formData.append("data", JSON?.stringify(docObject));
    try {
      let response;
      if (module)
        response = await doPOST(`/api/file/upload`, formData);
      else
        response = await doPOST(`/api/application-doc/upload`, formData);
      if (response?.status === 200) {
        showMessage("Successfully Uploaded");
        setDocument({ ...response?.data })
        onChange(response?.data?._id);
        onSubmit()
      }
    } catch (error) {
      showError(error);
    } finally {
      setLoading(false)
    }

  };

  const handleDelete = async () => {
    if (
      await showConfirm({
        title: t("Do you want to delete file?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      try {
        const response = await doDELETE(`/api/file/${data?._id}/delete`);
        if (response.status === 200) {
          showMessage("Deleted", "Deleted");
          onDelete()
        }
      } catch (e) {
        showError(e);
      }
    }
  };

  const handleAttachmentDelete = async (index) => {
    if (
      await showConfirm({
        title: t("Do you want to delete file?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      try {
        const response = await doDELETE(`/api/${module ? "file" : "application-doc"}/delete/attachment`, {
          "record_id": record_id,
          "_id": document?._id,
          "module": module,
          "index": index,
        });
        if (response.status === 200) {
          setDocument({ ...response.data, });
          showMessage("Deleted", "Deleted");
          return true;
        }
      } catch (e) {
        showError(e);
      }
    } else {
      return false;
    }
  };

  const fetch = async () => {
    try {
      const response = await doGET(`/api/file/${value}/detail`);
      if (response?.status === 200) {
        setDocument(response.data);
      }
    } catch (error) {
      showError(error);
    }
  }

  useEffect(() => {
    if (value && module) {
      fetch()
    }
  }, [value])

  useEffect(() => {
    setDocument(data)
  }, [data])

  return (
    <div className="border border-light rounded-2 border" key={key}>
      <div className=" position-relative p-3   " >
        {(
          <div >
            {/* <div className={`${inline ? "d-flex align-items-end" : ""}`}> */}

            <div className="mx-1">
              {/* {JSON?.stringify(document)} */}


              <DropZone
                asLabel={asLabel}
                value={document?.attachment?.urls}
                inline={inline}
                onDeleteAttachment={handleAttachmentDelete}
                onChange={(v) => {
                  setDocument({ ...document, attachment: { ...document?.attachment, urls: v } });
                }}
              />


            </div>
            <Row >
              {showCategory && <DocTypeSelect
                module={showCategory}
                placeholder="Select Document Type"
                label={"Select Document Type"}
                value={document?.docCategory_id}
                onChange={({ value, parent_id }) => {
                  console.log({ value, parent_id });
                  setDocument(prev => ({ ...prev, docCategory_id: value }));
                }}
                className="mt-3 col-lg-6 col-sm-6"
                isDisabled={asLabel}
              />
              }
              {(asLabel && showExpiry) && <DateSelectCustom
                value={document?.expiry}
                placeholder={"dd/mm/yyyy"}
                onChange={(e) => setDocument({ ...document, expiry: e })}
                label={t("Expiry Date")}
                isDisabled={asLabel}
                className="col-sm-6"
                required
              />}


            </Row>

            <Row className="d-flex justify-content-end">
              {asLabel ? null : <div className="mt-3 col-lg-3 col-sm-12" style={{ marginRight: "-20px" }}>
                <PrimaryButton
                  style={{ fontSize: 12, }}
                  loading={loading}
                  isDisabled={loading || !document?.attachment?.urls?.length || (showExpiry && !document?.expiry)}
                  onClick={() => {
                    handleSaveCard(key);
                  }}
                >
                  {loading ? <ThreeBounce color="white" size={8} /> : <>{t("Upload")} </>}

                </PrimaryButton></div>
              }
            </Row>





          </div>
        )}
      </div>
    </div >
  );
};

export default DocField;
