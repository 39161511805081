import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import UserIcon from "../../../assets/images/header/User.svg";
import { UserContext } from "../../../store/context/UserContext";
import "./ProfileMenu.scss";

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}
const ProfileMenu = (props) => {

  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const { logout, userContent, populateAllProfiles } = useContext(UserContext);
  const userInitial = userContent?.name ? userContent.name.charAt(0).toUpperCase() : '';


  const isSmallScreen = window.innerWidth <= 768;
  return (
    <div className={`profile-menu ${menu ? 'profile-menu-clicked' : ''}`}>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block me-0"
        style={{ marginLeft: "auto" }}
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <div className="user-avatar-circle d-sm-none">
            {userInitial}
          </div>
          <div className="ms-1 fw-medium font-size-13 justify-content-center align-items-center p-2 hide-on-small-screen"
            onClick={userContent?.superAdmin_id ? null : populateAllProfiles}
            style={{
              border: "1px solid #F0F0F0",
              borderRadius: "22px",
              width: "240px",
              height: "54px",
              backgroundColor: "#F0F0F0",
              display: isSmallScreen ? 'none !important' : "flex"
            }}
          >
            <div className="col-3 d-flex justify-content-center">
              <img src={UserIcon} style={{ height: "20px" }} />
            </div>
            <span className="col-7 d-flex" style={{ justifySelf: "start", fontSize: "18px" }}>{userContent?.name}{" "}</span>
            <i className="uil-angle-down d-inline-block col-2 me-2" style={{ fontSize: "30px" }}></i>{" "}
          </div>

        </DropdownToggle>
        <DropdownMenu className="">
          
          <DropdownItem style={{
            cursor: "pointer"
          }} tag="a" onClick={() => { props?.router?.navigate("/profile") }} >
            {" "}
            <i className="uil uil-user-circle font-size-18 align-middle text-muted me-1"></i>
            {props?.t("Profile")}{" "}
          </DropdownItem>

       
          <div className="dropdown-divider" />
          <Link
            to="/"
            onClick={() => {
              logout();
            }}
            className="dropdown-item"
          >
            <i className="uil uil-sign-out-alt font-size-18 align-middle me-1 text-muted"></i>
            <span>{props?.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>


    </div>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = (state) => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
