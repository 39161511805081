import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import "./style.css";
import StatusDropdown from './StatusDropdown';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';

const getKeyColor = (status) => {
  switch (status) {
    case 2:
      return "#856404"; // Dark Yellow
    case 3:
      return "#155724"; // Dark Green
    case 4:
      return "#721c24"; // Dark Red
    default:
      return "#4A4A4A"; // Default Dark Grey
  }
};


const StatusProgress = ({
  onChange = () => { },
  value = 1,
  data = [],
  refreshId,
  toggleShowMessages
}) => {
  const [smallScreen, setSmallScreen] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setSmallScreen(true);
      }
      else setSmallScreen(false);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (!value && data.length) {
      onChange(data[0])
    }
  }, [value, data, onChange, refreshId]);

  const handleStatusClick = (status) => {
    onChange(status.value);
  };

  const getFadedColor = (color) => `${color}bb`; // Append 'bb' for 73% opacity

  const statusLabels = {
    0: "Draft",
    1: "Submitted",
    2: "Incomplete",
    3: "Approved",
    4: "Rejected",
    5: "Locked"
  };

  return (
    <div>
      {
        smallScreen ? (
          <div className='d-flex align-items-center justify-content-between'>
            <StatusDropdown
              data={data}
              value={value}
              refreshId={refreshId}
              onChange={handleStatusClick}
            />

            <PrimaryButton
              className=""
              onClick={toggleShowMessages}
            >
              {"Messages"}
            </PrimaryButton>
          </div>
        ) : (
          <div className="fv_status_progress">
            {data.map((status, index) => (
              <div
                key={status.value}
                tabIndex={0}
                onClick={() => handleStatusClick(status)}
                className={`fv_status_progress_segment `}
                style={{
                  backgroundColor: status.backgroundColor,
                  // backgroundColor: getFadedColor(status.backgroundColor),
                  // backgroundColor: value === status.value ? status.backgroundColor : getFadedColor(status.backgroundColor),
                  fontWeight: value === status.value ? '600' : '400',
                  fontSize: value === status.value ? 13 : 12,
                  // textDecoration: value === status.value ? 'underline' : 'none',
                  color: '#000000',

                }}
              >
                <div className='d-flex flex-column'>
                  {status.label == "Document Verification" ? "Documents" : status.label}    

                  <div style={{ fontSize: 11, fontWeight: '400', color: getKeyColor(status.status) }}>
                    {statusLabels[status.status]}
                  </div>

                </div>
            

              </div>
            ))}
          </div>
        )
      }
    </div>
  );
};

export default observer(StatusProgress);
