import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import { FaChevronDown, FaChevronRight, FaPen, FaPlus, FaSpinner, FaTrash } from 'react-icons/fa';
import DocCategoryService from "./DocCategoryService";
import "./style.scss";
import { UserContext } from '../../store/context/UserContext';

const FolderItemModule = ({ isCollapsed, category, onToggleCollapse, onCreateSubCategory, module }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editName, setEditName] = useState(category.name);
  const [isAddingChild, setIsAddingChild] = useState(false);
  const [newChildName, setNewChildName] = useState("");
  const [isProcessing, setIsProcessing] = useState(false); // Loader state
  const { userContent } = useContext(UserContext)

  const handleEdit = async () => {
    try {
      setIsProcessing(true);
      await DocCategoryService.updateByModule({ ...category, name: editName }, module);
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating category:", error);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleAddChild = async () => {
    if (!newChildName) {
      setIsAddingChild(false);
      return;
    }
    try {
      setIsProcessing(true);
      await onCreateSubCategory(newChildName, category._id);
      setIsAddingChild(false);
      setNewChildName("");
    } catch (error) {
      console.error("Error adding subcategory:", error);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div key={userContent?.rights} className="folder-item">
      <div className="folder-header d-flex align-items-center">
        <span className="me-2" onClick={onToggleCollapse}>
          {category.children && category.children.length > 0 ? (
            isCollapsed ? <FaChevronRight /> : <FaChevronDown />
          ) : null}
        </span>

        {isEditing ? (
          <input
            autoFocus={true}
            type="text"
            value={editName}
            onChange={(e) => setEditName(e.target.value)}
            onBlur={handleEdit}
            onKeyDown={(e) => e.key === "Enter" && handleEdit()}
          />
        ) : (
          <span>{category.name}</span>
        )}
        <div className="icon-container ms-auto d-flex align-items-center">
          {userContent?.rights?.includes(2602) ?
            isProcessing ? (
              <div className="processing-indicator">
                <FaSpinner className="spinner" />
              </div>

            ) :
              <FaPen className='mx-2' onClick={() => setIsEditing(true)} /> :
            <></>}
          {!category?.parent_id && userContent?.rights?.includes(2601) ?

            isProcessing ? (
             <div></div>
            ) :
              <FaPlus className='mx-2' onClick={() => setIsAddingChild(true)} />

          
           
           : <></>}
          {userContent?.rights?.includes(2603) ? 
          
            isProcessing ? (
              <div></div>

            ) :
               <FaTrash className='mx-2' onClick={async () => {
            try {
              setIsProcessing(true);
              await DocCategoryService.deleteByModule(category._id, module);
            } catch (error) {
              console.error("Error deleting category:", error);
            } finally {
              setIsProcessing(false);
            }
          }} /> : <></>}
        </div>
      </div>
      {isAddingChild && (
        <div className='d-flex'>
          <input
            autoFocus={true}
            type="text"
            placeholder="Subcategory name"
            value={newChildName}
            onChange={(e) => setNewChildName(e.target.value)}
            // onBlur={handleAddChild}
            onKeyDown={(e) => e.key === "Enter" && handleAddChild()}
            className="mt-2 child-folder-input"
            style={{ flex: 1 }}
          />
        </div>
      )}

    </div>
  );
};

export default observer(FolderItemModule);
