import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState, useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
    CheckButton,
    DataGrid,
    EditLink,
    DeleteLink,
    Layout,
    SaveButton,
    DeleteButton,
    ClientSelect
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import EditUsers from "./EditUser";
import { STRUCTURE } from "./UserConstant";
import { GridColumns } from "./UserGridColumns";
import UserService from "./UserService";
import { DropdownItem, DropdownMenu } from "reactstrap";
import AuditLogDialog from "../Booking/components/Audit/AuditLog";
import { UserContext } from "../../store/context/UserContext";

const Users = ({
    label,
    value,
    insidePane,
    multiMode,
    Multi,
    hideCheckboxes = false,
    onSelect = () => { }
}) => {

    let { user_id } = useParams();
    const location = useLocation();

    let navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);

    const { t } = useContext(I18nContext);
    const { userContent } = useContext(UserContext);
    const { showError, showMessage, showConfirm } = useContext(DialogContext);

    const [loading, setLoading] = useState(false);
    const [detailData, setDetailData] = useState({});
    const [editID, setEditID] = useState(user_id);
    const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/user/create" || user_id);
    const [selectedIDs, setSelectedIDs] = useState([]);
    const [filterURL, setFilterURL] = useState("");
    const [filterObject, setFilterObject] = useState({});
    const [hasErr, setHasErr] = useState(false)

    const toggleData = {
        module: [
            { label: t("Customers"), value: "forCustomers" },
            { label: t("Client Customers"), value: "forClient" },
        ],
        toggleValue: "forCustomers"
    }

    const [selectedToggleValue, setSelectedToggleValue] = useState(toggleData?.toggleValue);

    const fetchData = async (filterUrl) => {
        setFilterURL(filterUrl);
        if (loading) return;
        setLoading(true);
        try {
            await UserService.fetch(filterUrl);
        } catch (e) {
            showError(e);
        }
        setLoading(false);
    };

    useEffect(() => {
        const { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE)
        setFilterURL(filterUrl)
        setFilterObject(filterObject)
        fetchData(filterUrl);
        setSelectedIDs(value)
        // multiMode ? setSelectedIDs(value) : setSelectedIDs(value ? [value] : [])
    }, [])


    useEffect(() => {
        setEditID(user_id)
    }, [user_id])

    useEffect(() => {
        if (selectedToggleValue == 'forCustomers') {
            UserService.onClientChange(null)
        }

    }, [selectedToggleValue])

    const renderLastCol = useCallback((user) => {
        return (
            <>
                {userContent?.rights?.includes(2302) &&
                    <EditLink
                        onClick={() => {
                            if (!insidePane)
                                navigate(`/user/edit/${user?._id}`);
                            setEditID(user?._id);
                            setShowDetailPage(true);
                        }}
                    />
                }
                {userContent?.rights?.includes(2303) &&
                    <DeleteLink
                        onClick={(event) => {
                            onDelete(event, user?._id, user?.name);
                        }}
                    />
                }
                {!multiMode && insidePane ? (
                    <CheckButton
                        onClick={() => {
                            onSelect(user);
                        }}
                    />
                ) : null}
            </>
        );
    }, [insidePane, multiMode, userContent])



    const onSave = async (e) => {
        e.preventDefault();
        if (!(detailData?.name && detailData?.email && detailData?.salutation && detailData?.phoneNo?.phone)) {
            setHasErr(true)
            showError(t("Please enter all required fields"));
            return;
        }
        setLoading(true);
        try {
            if (editID) {
                await UserService.edit(detailData, editID);
                showMessage(t("Record updated successfully."));
            } else {
                const user = await UserService.save(detailData);
                showMessage(t("Record saved successfully."));
                if (!insidePane) navigate(`/user/edit/${user}`);
            }
            fetchData(filterURL);

        } catch (e) {
            showError(e);
        }
        setHasErr(false)
        setLoading(false);
    };

    const onDelete = async (event, id, name) => {
        event.stopPropagation();
        if (
            await showConfirm({
                title: `Delete ${name}`,
                description: "This is an unrecoverable operation.",
            })
        ) {
            try {
                const response = await UserService.delete(id);
                showMessage("User deleted successfully");
                setShowDetailPage(false);
                navigate(`/user`);
            } catch (e) {
                showError(e);
            }
        }
    };

    return (
        <React.Fragment key={userContent?.rights}>
            <>
                <Layout
                    hideAdd={!userContent?.rights?.includes(2301)}
                    medium
                    toggleData={toggleData}
                    showToggle={!label}
                    onToggle={(selectedValue) => {
                        setSelectedToggleValue(selectedValue);
                        if (selectedValue === "forCustomers") {
                            UserService.onClientChange(null)
                            navigate(`/user`);
                        } else if (selectedValue === "forClient") {
                            navigate('/user');
                        }
                    }}
                    showClientSelect={selectedToggleValue === "forClient"}
                    showDetailPage={showDetailPage}
                    backDetailPage={async () => {
                        setShowDetailPage(false);
                        if (!insidePane) navigate("/user");
                        setEditID(null);
                    }}
                    gridLoading={loading}
                    title={t(label)}
                    filterValues={filterObject}
                    filterStructure={STRUCTURE}
                    onApplyFilter={fetchData}
                    onAddClick={() => {
                        if (!insidePane) navigate(`/user/create`);
                        setShowDetailPage(true);
                        setEditID(null);
                    }}
                    insidePane={insidePane}
                    page={UserService.page}
                    rows={UserService.rows}
                    total={UserService.total}
                    onChangeClient={selectedToggleValue === "forClient" ?
                        (selectedClientId) => {
                            UserService.onClientChange(selectedClientId?.value);
                        } : undefined
                    }
                    clientValue={selectedToggleValue === "forClient" ? UserService?.client_id ?? "selectFirst" : undefined}
                    // clientValue={UserService?.client_id ?? "selectFirst"}
                    fetch={UserService.fetch}
                >

                    {/* <Layout.ActionMenu>
                        <div className="layout-action-menu">
                            <DropdownMenu>
                                <>
                                    <DropdownItem>{t("Upload")}</DropdownItem>
                                    <DropdownItem> {t("Download")} </DropdownItem>
                                </>
                            </DropdownMenu>

                        </div>
                    </Layout.ActionMenu> */}
                    <Layout.Table>
                        <DataGrid
                            hideCheckboxes={hideCheckboxes}
                            data={UserService.records}
                            total={UserService.total}
                            uiPreference="user.grid"
                            headers={GridColumns}
                            onSelectChange={(v) => {
                                // onSelect(v)
                                // setSelectedIDs(v)
                            }}
                            selectedIDs={selectedIDs}
                            page={UserService.page}
                            rowsPerPage={UserService.rowsPerPage}
                            onPaginationChange={UserService.onPaginationChange}
                            renderLastCol={renderLastCol}
                        />
                    </Layout.Table>



                    <Layout.DetailPageTitle>
                        {detailData?._id ? t("Edit Customer") : t("Add Customer")}
                    </Layout.DetailPageTitle>

                    <Layout.DetailPageBody>
                        <EditUsers
                            editId={editID}
                            onChange={(v) => {
                                setDetailData(v)
                            }}
                            hasErr={hasErr}
                            selectedToggleValue={selectedToggleValue}

                        />
                    </Layout.DetailPageBody>

                    <Layout.DetailPageFooter>
                        {editID ? (
                            <AuditLogDialog module="Customer" id={editID} />
                        ) : null

                        }

                        {editID && userContent?.rights?.includes(2303) ? (
                            <DeleteButton loading={loading} onClick={(e) => onDelete(e, editID)} />
                        ) : null
                        }
                        {userContent?.rights?.includes(2302) || userContent?.rights?.includes(2301) && <SaveButton onClick={onSave} loading={loading} />}
                    </Layout.DetailPageFooter>
                </Layout>
            </>
        </React.Fragment>
    );
};
export default observer(Users);
