import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  CheckButton,
  DataGrid,
  DeleteButton,
  DeleteLink,
  EditLink,
  Layout,
  SaveButton
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { UserContext } from "../../store/context/UserContext";
import { I18nContext } from "../../store/context/i18nContext";
import { prepareFilterArtifacts } from "../../util/FilterUtil";
import EditUTMTracker from "./EditUTMTracker";
import { STRUCTURE } from "./UTMTrackerConstant";
import { UTMTrackerGridColumns } from "./UTMTrackerGridColumns";
import UTMTrackerService from "./UTMTrackerService";

const UTMTracker = ({ value, insidePane, multiMode, onSelect = () => { } }) => {
  let { trackerEdit_id } = useParams();
  const location = useLocation();

  let navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const { t } = useContext(I18nContext);
  const { userContent } = useContext(UserContext);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);

  const [loading, setLoading] = useState(false);
  const [detailData, setDetailData] = useState({});
  const [editID, setEditID] = useState(trackerEdit_id);
  const [showDetailPage, setShowDetailPage] = useState(insidePane ? false : window.location.pathname === "/tracker/create" || trackerEdit_id);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [filterURL, setFilterURL] = useState("");
  const [filterObject, setFilterObject] = useState({});
  const [hasErr, setHasErr] = useState(false)


  const fetchData = async (filterUrl) => {
    setFilterURL(filterUrl);
    if (loading) return;
    setLoading(true);
    try {
      await UTMTrackerService.fetch(filterUrl);
      setLoading(false);

    } catch (e) {
      setLoading(false);
      showError(e);
    }
  };

  useEffect(() => {
    const { filterUrl, filterObject } = prepareFilterArtifacts(queryParams, STRUCTURE)
    setFilterURL(filterUrl)
    setFilterObject(filterObject)
    fetchData(filterUrl);
    multiMode ? setSelectedIDs(value) : setSelectedIDs(value ? [value] : [])
  }, [])


  useEffect(() => {
    if (trackerEdit_id) {
      setEditID(trackerEdit_id)
    } else {
      if (window.location.pathname !== "/tracker/create") {
        setShowDetailPage(false)
      }
      setEditID(null);
    }
  }, [trackerEdit_id, window.location.pathname])

  const onSave = async (e) => {
    e.preventDefault();

    const regexUtmReferer = /^[a-z0-9]*$/;  // Regex to allow only lowercase letters and numbers, no spaces

    if (!detailData?.name || !detailData?.utm_referer) {
      setHasErr(true);
      if (!detailData?.name) {
        showError(t("Please enter Name"));
      } else if (!detailData?.utm_referer) {
        showError(t("Please enter Link"));
      }
      return;
    }

    // Check if utm_referer follows the required regex pattern
    if (!regexUtmReferer.test(detailData?.utm_referer)) {
      setHasErr(true);
      showError(t("Invalid Link. Only lowercase letters and numbers are allowed, no spaces."));
      return;
    }

    if (!detailData?.value) {
      setHasErr(true);
      if (!detailData?.value) {
        showError(t("Please enter Value"));
      }
      return;
    }



    setLoading(true);
    try {
      if (editID) {
        await UTMTrackerService.edit(detailData, editID);
        setLoading(false);
        showMessage(t("Tracker updated successfully."));
      } else {
        const utmTracker = await UTMTrackerService.save(detailData);
        setLoading(false);
        showMessage(t("Tracker saved successfully."));
        if (!insidePane) navigate(`/tracker/edit/${utmTracker}`);
      }
      fetchData(filterURL);

    } catch (e) {
      setLoading(false);
      showError(e);
    }
    setHasErr(false)
  };
  const onDelete = async (event, id) => {
    event.stopPropagation();
    if (
      await showConfirm({
        title: t("Do you want to delete record?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      setLoading(true);
      try {
        await UTMTrackerService.delete(id);
        setLoading(false);
        showMessage("Tracker Deleted Successfully", "Deleted");
        navigate("/tracker");
        setShowDetailPage(false);

      } catch (e) {
        setLoading(false);
        showError(e);
      }
    }
  };


  const renderLastCol = useCallback((utmTracker) => {
    return (
      <>
        {/* {userContent?.rights?.includes(2902) && */}
          <EditLink
            onClick={() => {
              if (!insidePane)
                navigate(`/tracker/edit/${utmTracker?._id}`);
              setEditID(utmTracker?._id);
              setShowDetailPage(true);

            }}
          />
        {/* } */}
        {/* {userContent?.rights?.includes(2903) ? */}
          <DeleteLink
            onClick={(event) => onDelete(event, utmTracker?._id)}
          />
          {/* : null} */}
        {!multiMode && insidePane ? (
          <CheckButton
            onClick={() => {
              onSelect(utmTracker?._id);
            }}
          />
        ) : null}
      </>
    );
  }, [insidePane, multiMode, userContent])





  return (
    <React.Fragment key={userContent?.rights}>
      <Layout
        // hideAdd={!userContent?.rights?.includes(2901)}
        showDetailPage={showDetailPage}
        backDetailPage={async () => {
          setShowDetailPage(false);
          if (!insidePane) navigate("/tracker");
          setEditID(null);
        }}
        gridLoading={loading}
        title={t("Tracker")}
        filterValues={filterObject}
        filterStructure={STRUCTURE}
        onApplyFilter={fetchData}
        onAddClick={() => {
          if (!insidePane) navigate(`/tracker/create`);
          setShowDetailPage(true);
          setEditID(null);
        }}
        insidePane={insidePane}
        page={UTMTrackerService.page}
        rows={UTMTrackerService.rows}
        total={UTMTrackerService.total}
        fetch={UTMTrackerService.fetch}
      >

        <Layout.Table>
          <DataGrid
            data={UTMTrackerService.records}
            total={UTMTrackerService.total}
            uiPreference="utmTracker.grid"
            headers={UTMTrackerGridColumns}
            onSelectChange={(v) => {
              onSelect(v)
              setSelectedIDs(v)
            }}
            selectedIDs={selectedIDs}
            page={UTMTrackerService.page}
            rowsPerPage={UTMTrackerService.rowsPerPage}
            onPaginationChange={UTMTrackerService.onPaginationChange}
            renderLastCol={renderLastCol}
          />
        </Layout.Table>


        <Layout.DetailPageTitle>
          {detailData?._id ? t("Edit Tracker") : t("Add Tracker")}
        </Layout.DetailPageTitle>

        <Layout.DetailPageBody>
          <EditUTMTracker
            editId={editID}
            onChange={(v) => {
              setDetailData(v)
            }}
            hasErr={hasErr}
          />
        </Layout.DetailPageBody>

        <Layout.DetailPageFooter>

          {/* {editID && userContent?.rights?.includes(2903) && (detailData?._id) ? */}
            <DeleteButton onClick={(e) => onDelete(e, detailData?._id)} loading={loading} />
            {/* : null} */}

          {/* {(userContent?.rights?.includes(2902) || userContent?.rights?.includes(2901)) ? */}
            <SaveButton onClick={onSave} loading={loading} />
            {/* : null} */}

        </Layout.DetailPageFooter>
      </Layout>
    </React.Fragment>
  );
};

export default observer(UTMTracker);


