import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
} from "react";
import { ENDPOINTS } from "../../common/utils/Constants";
import { doGET, doPOST } from "../../util/HttpUtil";
import { ApiHandler } from "../apiHandler/apiHandler";
import { useSearchParams } from 'react-router-dom'; // Assuming you're using React Router v6

export const UserContext = React.createContext();

export const UserProvider = (props) => {
  const [allProfiles, setAllProfiles] = useState(null);
  const [userContent, setUserContent] = useState(null);
  const [currentPageName, setCurrentPageName] = useState("");
  const [forSuperadmin, setForSuperadmin] = useState(false);
  const isAdminRef = useRef(false);


  const populateUserContent = useCallback(async () => {
    const searchParams = new URLSearchParams(window.location.search);
    const authorizationToken = searchParams.get('Authorization');

    if (localStorage.getItem("BearerToken") && !authorizationToken) {
      try {
        const response = await doGET(ENDPOINTS.userContent);
        if (response.status === 200 && response.data !== userContent) {
          const content = response.data?.[0]?.userContent
          setUserContent(content);
          if (response.data.superadmin === "1") {
            isAdminRef.current = true;
          } else {
            isAdminRef.current = false;
          }
        }
      } catch (err) {
        console.log(err);
      }
    }
  }, []);


  useEffect(() => {
    populateUserContent();

  }, [populateUserContent])



  const populateAllProfiles = useCallback(async () => {
    try {
      const response = await doGET(ENDPOINTS.allProfiles(forSuperadmin));
      if (response.status === 200) {
        setAllProfiles(response.data);
      }
    } catch (err) {
      console.log(err);
    }
  }, []);




  const isAdmin = () => {
    return userContent?.isAdmin ? true : false;
  }

  const isLoggedIn = () => {
    return userContent?.owner_id ? true : false;
  }

  const login = async (data) => {
    try {
      const response = await doPOST(ENDPOINTS.login, data);
      if (response?.data?.action) {
        localStorage.setItem("BearerToken", response.data.bearerToken);
        return "choose-profile";
      }

      if (response.status === 200) {
        localStorage.setItem("BearerToken", response.data.bearerToken);
        localStorage.setItem("RefreshToken", response.data.refreshToken);
        localStorage.setItem("TokenExpiry", response.data.expiry);
        await populateUserContent();

      }
      return response;
    } catch (err) {

      console.log(err);
      return err
    }
    return false;

  };
  const switchProfile = async (index) => {
    try {
      // setLoading(true)
      if (index > -1 && index < allProfiles?.length) {
        // localStorage.setItem("BearerToken", "");
        localStorage.removeItem("BearerToken")
        localStorage.removeItem("TokenExpiry")

        const reqBody = {
          reqParam: {}, method: "GET", endPoint: "/auth/refresh-token",
          token: allProfiles[index]?.refreshToken
        };
        const newBearerTokenResponse = await ApiHandler(reqBody);

        if (newBearerTokenResponse?.status === 200) {
          localStorage.setItem("BearerToken", newBearerTokenResponse.data?.bearerToken);
          localStorage.setItem("TokenExpiry", newBearerTokenResponse.data.expiry);
        }

        // localStorage.setItem("TokenExpiry", "0");
        localStorage.setItem("RefreshToken", allProfiles[index]?.refreshToken);
        window.location.reload();
        // setUser(allProfiles[index]?.userContent);
      }
    } catch (error) {

    }
    // setLoading(false)
  };
  // const switchProfile = async (index) => {
  //   if (index > -1 && index < allProfiles?.length) {
  //     localStorage.setItem("BearerToken", " ");
  //     localStorage.setItem("TokenExpiry", 0);
  //     localStorage.setItem("RefreshToken", allProfiles[index]?.refreshToken);
  //     window.location.reload();
  //     setUserContent(allProfiles[index]?.userContent);
  //   }
  // };


  const logout = async () => {
    try {
      doGET(ENDPOINTS.logout);
    } catch (err) {
      console.log(err);
    }
    localStorage.clear();
    setUserContent(null);
    window.location = "/";
  };

  return (
    <UserContext.Provider
      value={{
        userContent,
        populateUserContent,
        login,
        logout,
        isLoggedIn,
        isAdmin,
        switchProfile,
        populateAllProfiles,
        allProfiles,
        forSuperadmin,
        setForSuperadmin,
        currentPageName,
        setCurrentPageName
      }}
    >
      {props?.children}
    </UserContext.Provider>
  );
};
